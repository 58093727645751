import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import CreateUser from "./containers/CreateUser";
import NotFound from "./containers/NotFound";
import PermissionDenied from "./containers/PermissionDenied";
import DeviceDetails from "./containers/DeviceDetails";
import Administration from "./containers/Administration/Administration";
import AppliedRoute from "./components/AppliedRoute";
import CustomerRoleAdminRoute from "./components/CustomerRoleAdminRoute";
import ActivateTOTP from "./containers/ActivateTOTP";
import AddDevice from "./containers/AddDevice";
import MultiFactorAuth from "./containers/MultiFactorAuth";
import ChangePassword from "./containers/ChangePassword";
import ForgotPassword from "./containers/ForgotPassword";
import ResetPassword from "./containers/ResetPassword";
import CreateCustomer from "./containers/CreateCustomer";
import UpdateCustomer from "./containers/UpdateCustomer";
import Changelog from "./components/Changelog";
import FeatureRoadmap from "./components/FeatureRoadmap";
import Impessum from './containers/Impessum'
import Privacy from './containers/Privacy'
import InfoAndSupport from './containers/InfoAndSupport'
import Fleet from './containers/Fleet'
import FleetAdmin from './containers/FleetAdmin'
import WhereverSIM from './containers/WhereverSimFleet'
import DeviceHealthDetails from './containers/Health'
import DeviceSoftwareDetails from './containers/Software'
import DeviceOwnershipDetails from './containers/Ownership'
import ApiDeviceManager from './components/ApiDeviceManager'
import ApiFeatureManager from './components/ApiFeatureManager'
import WhereverSimAdministration from './containers/WhereverSimAdministration'
import Statistics from './containers/Statistics'
import TunnelAndFirewall from './containers/TunnelAndFirewall'
import HawkbitOverview from "./containers/HawkbitOverview";

export default function Routes({ appProps }) {
    return (
        <Switch>
            <AppliedRoute path="/" exact component={Home} appProps={appProps} />
            <AppliedRoute path="/login" exact component={Login} appProps={appProps} />
            <AppliedRoute path="/changelog" exact component={Changelog} appProps={appProps} />
            <AppliedRoute path="/featureRoadmap" exact component={FeatureRoadmap} appProps={appProps} />
            <AppliedRoute path="/impessum" exact component={Impessum} appProps={appProps} />
            <AppliedRoute path="/infoAndSupport" exact component={InfoAndSupport} appProps={appProps} />
            <AppliedRoute path="/fleet" exact component={Fleet} appProps={appProps} />
            <AppliedRoute path="/fleetAdmin" exact component={FleetAdmin} appProps={appProps} />
            <AppliedRoute path="/whereverSimAdministration" exact component={WhereverSimAdministration} appProps={appProps} />
            <AppliedRoute path="/whereversim" exact component={WhereverSIM} appProps={appProps} />
            <AppliedRoute path="/health" exact component={DeviceHealthDetails} appProps={appProps} />
            <AppliedRoute path="/software" exact component={DeviceSoftwareDetails} appProps={appProps} />
            <AppliedRoute path="/ownership" exact component={DeviceOwnershipDetails} appProps={appProps} />
            <AppliedRoute path="/apiDevice" exact component={ApiDeviceManager} appProps={appProps} />
            <AppliedRoute path="/apiFeature" exact component={ApiFeatureManager} appProps={appProps} />
            <AppliedRoute path="/statistics" exact component={Statistics} appProps={appProps} />
            <AppliedRoute path="/privacy" exact component={Privacy} appProps={appProps} />
            <AppliedRoute path="/activateTOTP" exact component={ActivateTOTP} appProps={appProps} />
            <AppliedRoute path="/addDevice" exact component={AddDevice} appProps={appProps} />
            <AppliedRoute path="/multiFactorAuth" exact component={MultiFactorAuth} appProps={appProps} />
            <AppliedRoute path="/changePassword" exact component={ChangePassword} appProps={appProps} />
            <AppliedRoute path="/resetPassword" exact component={ResetPassword} appProps={appProps} />
            <AppliedRoute path="/forgotPassword" exact component={ForgotPassword} appProps={appProps} />
            <AppliedRoute path="/device/:id" exact component={DeviceDetails} appProps={appProps} />
            <AppliedRoute path="/tunnelAndFirewall" exact component={TunnelAndFirewall} appProps={appProps} />
            <AppliedRoute path="/hawkbitOverview" exact component={HawkbitOverview} appProps={appProps} />
            <CustomerRoleAdminRoute path="/administration" exact component={Administration}  appProps={appProps} />
            <CustomerRoleAdminRoute path="/createUser" exact component={CreateUser} appProps={appProps} />
            <CustomerRoleAdminRoute path="/createCustomer" exact component={CreateCustomer} appProps={appProps} />
            <CustomerRoleAdminRoute path="/customers/:id/:parent" exact component={UpdateCustomer} appProps={appProps} />
            <Route path="/permissionDenied" exact component={PermissionDenied} />
            <Route component={NotFound} />
        </Switch>
    );
}