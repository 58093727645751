import React, { useLayoutEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Table, Badge, Col, Button, Form, Container, Alert, Tabs, Tab } from "react-bootstrap";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import axios from 'axios';
import { faFileUpload, faHome, faTrash } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import './Home.css'
import './HawkbitOverview.css'
import { useFormFields } from "../libs/hooksLib";
import Select from 'react-select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { ListItemText } from "@material-ui/core";

import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import {TextRow} from 'react-placeholder/lib/placeholders';
 

export default function HawkbitOverview(props) {

    // Auth data
    const [userHeaders, setUserHeaders] = useState(undefined);
    const [isLoadingMain, setIsLoadingMain] = useState(true);

    // Hawkbit data
    const [hawkbitData, setHawkbitData] = useState();
    const [isLoadingHawkbitData, setIsLoadingHawkbitData] = useState(false);
    const [refreshHawkbit, setRefreshHawkbit] = useState(true);

    // Tag data
    const [tagValues, setTagValues] = useState([]);
    const [validated, setValidated] = useState(false);
    const [showInfo, setShowInfo] = useState(true);

    const [uploadButtonEnabled, setUploadButtonEnabled] = useState(true);

    // file upload data.
    const [fileUploadMessage, setFileUploadMessage] = useState({show: "", variant: "secondary", headline: "", body: ""});
    const [currentFile, setCurrentFile] = useState();
    const [fields, handleFieldChange] = useFormFields({
        name: "",
        version: "",
        description: "",
        newTag: ""
    });

    useLayoutEffect(() => {
        async function onLoadMain() {
            if (!props.isAuthenticated) {
                return;
            }
            try {
                // Getting JSON Web Token
                const session = await Auth.currentSession();

                const headers = {};
                if (session) {
                    headers.Authorization = session.getIdToken().getJwtToken();
                }
                console.log("setUserHeaders")
                setUserHeaders(headers);

                // save the path if session expired
                localStorage.setItem('prevPath', '/hawkbitOverview');

                } catch (e) {
                    alert(e);
            }
            console.log("setIsLoadingMain")
            setIsLoadingMain(false);
        }
        onLoadMain();
    }, [props.isAuthenticated]);

    // Load Hawkbit Feature Status
    useLayoutEffect(() => {
        async function onLoadHawkbit() {
            if (!userHeaders || !refreshHawkbit || isLoadingMain) {
                return;
            } else {
                console.log("onLoadHawkbit")
                try {
                        setIsLoadingHawkbitData(true);

                        // 
                        let response_hawkbit_distributions = [];

                        await API.get("hawkbit_integration", `/getDistributionset`, {
                            headers: userHeaders,
                        }).then(response => {
                            response_hawkbit_distributions = response;
                        }).catch(err => {
                            console.log("Error getting Target Distributions")
                            console.log(err.response)
                        });

                        try {
                            response_hawkbit_distributions.sort(function(first, second){
                                return  (first.lastModifiedAt < second.lastModifiedAt ? 1 : -1)
                            });
                        } catch (e){
                            console.log("Error Sorting distributon sets");
                            console.log(e);
                        }

                        // Initialize tags data

                        let tagsInitArray = [];
                        response_hawkbit_distributions.map(distribution => {
                            distribution?.tags?.map(tag =>{
                                tagsInitArray.push({value:tag.id + "", label:tag.name, distSetId:distribution.id});
                            })
                        });

                        setTagValues(tagsInitArray);

                        // Get tags
                        let response_hawkbit_get_tages = [];
                        await API.get("hawkbit_integration", "/feature/hawkbit/distributionSetTags", {
                            headers: userHeaders,
                        }).then((response) => {
                            response_hawkbit_get_tages = response;
                            // console.log(response_hawkbit_get_tages);
                        } ).catch((err) => {
                            console.log(err.message);
                        });


                        // prepare Hawkbit data
                        let data = [];

                        data['distributionTags'] = response_hawkbit_get_tages;
                        data['responseHawkbitDistributions'] = response_hawkbit_distributions;
                        setHawkbitData(data);

                    } catch (error) {
                        console.log("Error Reading Hawkbit Feature Data")
                        console.log(error.message)
                        setHawkbitData("setHawkbitData");
                        setHawkbitData([]);
                    }
            }
            setIsLoadingHawkbitData(false);
            setRefreshHawkbit(false);
    }
    onLoadHawkbit();
    }, [userHeaders, refreshHawkbit, isLoadingMain]);


    function MessageInfo() {
        if (fileUploadMessage.show && showInfo) {
          return (
            <Alert variant={fileUploadMessage.variant} onClose={() => setShowInfo(false)} dismissible>
              <Alert.Heading>{fileUploadMessage.headline}</Alert.Heading>
              {fileUploadMessage.body}
            </Alert>
          );
        }
        return <></>;
    }

    function validateFormAddNewTag() {
        let valid = false;
        if (
            fields.newTag
        ){
            valid = true
        }
        return valid;
    }

    async function fileUploadHandler(fileAction){
        if (userHeaders) {
            try {

                if (fileAction.action === "uploadSignedBundleFile"){
                    // setFileUploadMessage({
                    //     show: true,
                    //     variant: "info",
                    //     headline: "File Upload started",
                    //     body: "File Name: " + fileAction.file.name
                    // })

                    setFileUploadMessage({
                        show: true,
                        variant: "info",
                        headline: "File Upload started",
                        body: "File Name: " + fileAction.file.name
                    });
                    setShowInfo(true);

                    //console.log(fileAction);

                    const body_create_software_module = [{
                        vendor: props?.loggedInUser?.customer_name,
                        name: fileAction.name,
                        description: fileAction.description,
                        type: "raucbundle",
                        version: fileAction.version
                    }];

                    const post_create_software_module = await API.post("hawkbit_integration", "/createSoftwareModule", {
                        headers: userHeaders,
                        body: body_create_software_module
                    });

                    //console.log(post_create_software_module);

                    let sw_id = post_create_software_module[0]?.id;

                    const response_post_create_software_module = await API.post("hawkbit_integration", `/feature/hawkbit/presignedurl/${sw_id}?artifact_name=${fileAction.file.name}&expires_in_sec=20`, {
                        headers: userHeaders
                    });

                    const objForFormData = { ...response_post_create_software_module?.fields, "file":fileAction?.file };

                    console.log(response_post_create_software_module);

                    let formData = new FormData();
                    for ( var key in objForFormData ) {
                        formData.append(key, objForFormData[key]);
                    }

                    // This call has no Authorization mechanism, it only use the presigned_url given by the POST call.
                    // try {
                        await axios.post(response_post_create_software_module?.url, formData);

                        // ToDo: Check if Result is OK or Not, and make different message

                        //await timeout(2000);

                        // if (fileAction.action === "uploadFileAndSend"){
                        //     setFileUploadMessage({
                        //         show: true,
                        //         variant: "success",
                        //         headline: "File Upload finished",
                        //         body: "File Name: " + fileAction.file.name
                        //     })
                        //     await timeout(2000);
                        //     fileUploadHandler({
                        //         action: "createFileJob",
                        //         file_id: post_response.file_id,
                        //         description: fileAction.description
                        //     })
                        // }else{
                        //     setFileUploadMessage({
                        //         show: true,
                        //         variant: "success",
                        //         headline: "File Upload finished",
                        //         body: "File Name: " + fileAction.file.name
                        //     })
                        //     setRefreshFileUploads(true);
                        // }
                    // }catch (e) {
                    //     console.log("CATCH FILE UPLOAD");
                    //     console.log(e.message);
                    //     setFileUploadMessage({
                    //         show: true,
                    //         variant: "danger",
                    //         headline: "Error:",
                    //         body: e.message
                    //     });

                    //     throw e;
                    // }

                    // now create distribution set
                    //[{"name":"dist_test", "description" : "nisl quam id taciti eleifend vehicula commodo risus vitae mus", 
                    //"type" : "definitiondist","version" : "one","modules" : [ {"id" : 324}],"requiredMigrationStep" : false}]

                    const body_distribution_set = [{
                        name: fileAction.name,
                        description: fileAction.description,
                        type: "testingdist",
                        version: fileAction.version,
                        modules: [{id:sw_id}],
                        requiredMigrationStep: false
                    }];
                    
                    try {
                        await API.post("hawkbit_integration", "/postDistributionSet", {
                            headers: userHeaders,
                            body: body_distribution_set
                        })
                    } catch(e) {
                        console.log(e);
                    }

                    // if we come here, everything is successful

                    setFileUploadMessage({
                                show: true,
                                variant: "success",
                                headline: "File Upload finished",
                                body: "File Name: " + fileAction.file.name
                            })

                    setRefreshHawkbit(true);
                }

            }catch (e) {
                console.log(e);

                setFileUploadMessage({
                    show: true,
                    variant: "danger",
                    headline: "Error:",
                    body: e.response.data.message
                });

                return 0;
            }
        }
    }

    async function addTag(newTagText) {

        setFileUploadMessage({
            show: true,
            variant: "info",
            headline: "Adding new tag...",
            body: ""
        });
        setShowInfo(true);

        try {
            const result = await API.post("hawkbit_integration", `/feature/hawkbit/distributionSetTags/${newTagText}?ds_tag_colour=red&ds_tag_description=newdesc`, {
                headers: userHeaders
            });

            if(result !== 0)
            {
                fields.newTag = "";
                setRefreshHawkbit(true);
            }

            setFileUploadMessage({
                show: true,
                variant: "success",
                headline: "Tag added successfully",
                body: ""
            })

        } catch(e) {
            console.log(e);

            setFileUploadMessage({
                show: true,
                variant: "danger",
                headline: "Error:",
                body: e.message
            });
        }
    }

    async function handleRemoveTag(delTagId) {
        try {
            const filteredTag = hawkbitData?.distributionTags?.content?.filter((myTag) => (delTagId === myTag.id));

            setFileUploadMessage({
                show: true,
                variant: "info",
                headline: "Deleting tag",
                body: "Tag " + filteredTag[0].name
            });
            setShowInfo(true);

            const result = await API.del("hawkbit_integration", `/feature/hawkbit/delete/distributionSetTags/${delTagId}`, {
                headers: userHeaders
            });

            if(result !== 0)
            {
                setRefreshHawkbit(true);
                setTagValues(tagValues.filter((myData)=> (myData.value !== delTagId + "") ));
            }

            setFileUploadMessage({
                show: true,
                variant: "success",
                headline: "Deleting tag finished",
                body: "Tag " + filteredTag[0].name
            });

        } catch(e) {
            console.log(e);

            setFileUploadMessage({
                show: true,
                variant: "danger",
                headline: "Error:",
                body: e.message
            });
        }
    }

    const selectFile = (event) => {
        console.log(event?.target?.files[0])
        setCurrentFile(event?.target?.files[0]);
        console.log(currentFile)
      };

    const uploadFile = async (event) => {

        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("check validity false");
            setValidated(true);
        }
        else
        {
            // disable button before and after the function

            setUploadButtonEnabled(false);

            const result = await fileUploadHandler({
                action: "uploadSignedBundleFile",
                file: currentFile,
                name: fields.name,
                version: fields.version,
                description: fields.description
            });
            if(result !== 0)
            {
                // successful file upload
                setCurrentFile("");
                fields.name = "";
                fields.description = "";
                fields.version = "";
            }

            setValidated(false);
            setUploadButtonEnabled(true);
        }
      };

    function uploadForm(){
        return(
            <div>
                <h4><FontAwesomeIcon icon={faFileUpload} className="supportIconHome"/> Upload Signed Firmware Images</h4>
                {/* <form> */}
                <Form noValidate validated={validated} onSubmit={uploadFile}>
                    <Row>
                        <Col xs="4">
                            <Form.Group controlId="name">
                                <Form.Control
                                required
                                autoFocus
                                type="text"
                                placeholder="Firmware Image name"
                                value={fields.name}
                                onChange={handleFieldChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="4">
                            <Form.Group controlId="version">
                                <Form.Control
                                required
                                autoFocus
                                type="text"
                                placeholder="Firmware Image version"
                                value={fields.version}
                                onChange={handleFieldChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="4">
                            <Form.Group controlId="description">
                                <Form.Control
                                autoFocus
                                type="text"
                                placeholder="Description"
                                value={fields.description}
                                onChange={handleFieldChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="8">
                        <Form.Group controlId="file">
                                <Form.File
                                    required
                                    id="custom-file"
                                    label={currentFile?.name ? currentFile?.name + " [~" + Math.ceil(currentFile?.size/1000) + "kB]" : "Select File"}
                                    onChange={selectFile}
                                    custom
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="4">
                            <Button
                                type="submit"
                                className="btn btn-success"
                                disabled={!uploadButtonEnabled}
                                block>
                                <FontAwesomeIcon icon={faFileUpload} className="supportIconHome"/> Upload
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    // edit this form to display a text box and button for tag addition. implementation of on button click will trigger API request.
    function tagListAndRemoveForm() {
        return (
            <div>
                <Container className="tagListTable">
                    <Row className="dev-attributes-row">
                    <Col xs={10}>
                        <List component="nav">
                        {
                            hawkbitData?.distributionTags?.content &&
                                hawkbitData?.distributionTags?.content?.map((tag) => (
                                    <ListItem dense divider key={tag?.id}>
                                    <ListItemText primary={tag?.name}></ListItemText>
                                    <ListItemSecondaryAction>
                                        <div className="align-self-start mr-left">
                                            <Button className="supportButtonHome"
                                                variant="danger"  size="sm"
                                                onClick={() => handleRemoveTag(tag?.id)}
                                            >
                                                <FontAwesomeIcon icon={faTrash} className="supportIconHome"/>
                                            </Button>
                                        </div>
                                    </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                        }
                        </List>
                    </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    function tagAdditionForm()
    {
        return(
            <div>
                <Container>
                    <Row>
                        <Col xs="6">
                            <Form.Group controlId="newTag">
                                <Form.Control
                                autoFocus
                                type="text"
                                placeholder="New tag"
                                value={fields.newTag}
                                onChange={handleFieldChange}
                                />
                                {/* <Form.Label>Location / ... / <br/>(e.g. /home/root/)</Form.Label> */}
                            </Form.Group>
                        </Col>
                        <Col xs="6">
                            <Button
                                className="btn btn-success"
                                disabled={!validateFormAddNewTag()}
                                onClick={() => addTag(fields.newTag)}
                                block>
                                <FontAwesomeIcon icon={faFileUpload} className="supportIconHome"/> Add
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            )
    }

    async function handleOnChange(newValue, actionMeta, distributionSetId) {

            if(actionMeta.action == "select-option")
            {
                // setTagSelectLoading(true);
                // setTagSelectDisabled(true);
                try {
                    await API.post("hawkbit_integration", `/feature/hawkbit/assignDistributionSetTag/${actionMeta.option.value}?distributionId=${distributionSetId}`, {
                        headers: userHeaders
                    });
                     
                    // todo: add to view just if it was successful tag addition.


                    setTagValues([...tagValues, {value:actionMeta.option.value + "", label:actionMeta.option.label, distSetId:distributionSetId}]);
                    // setTagSelectLoading(false);
                    // setTagSelectDisabled(false);
                } catch(e) {
                    console.log("TAG CAN'T BE ADDED");
                }

            }
            else if (actionMeta.action == "remove-value")
            {
                try {
                    await API.del("hawkbit_integration", `/feature/hawkbit/unassignDistributionSetTag/${actionMeta.removedValue.value}?distributionId=${distributionSetId}`, {
                        headers: userHeaders
                    });

                    //console.log(tagValues);

                    setTagValues(tagValues.filter((myData)=> (myData.value !== actionMeta.removedValue.value + "" || myData.distSetId !== actionMeta.removedValue.distSetId) ));
                } catch(e) {
                    console.log(e);
                    console.log("TAG CAN'T BE REMOVED");
                }
            }
            // move this setTagvalues above and check if successful.... etc...
    }

    function getBundleRepositoryTable() {
        return (
                <div>
                    <div className="card-header">
                        <Row>
                            <Col xs="auto">
                                <p>All Firmware Images created by members of your organisation.</p>
                            </Col>
                        </Row>
                    </div>
                    <div className="responsiveTableHawkbit">
                    <Table striped bordered hover>
                        <thead >
                            <tr key="images-head">
                                <th>Firmware Image name</th>
                                <th>Version</th>
                                <th>Type</th>
                                <th>Created at</th>
                                <th>Info</th>
                                <th>Tags</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hawkbitData?.responseHawkbitDistributions &&
                                hawkbitData?.responseHawkbitDistributions?.map((distribution, index) => (
                                    <tr key={index}>
                                        <td>{distribution?.name}
                                        </td>
                                        <td>
                                            <Badge variant="info">{distribution?.version}</Badge>
                                        </td>
                                        <td>
                                            { distribution?.type?.includes('releasedist') ?
                                                <Badge variant="success">Release Distribution</Badge>
                                            :
                                                distribution?.type?.includes('testingdist') ?
                                                    <Badge variant="warning">Testing Distribution</Badge>
                                                :
                                                <Badge variant="secondary">{distribution?.type}</Badge>
                                            }
                                        </td>
                                        <td>
                                            <Moment format="YYYY-MM-DD HH:mm:ss" tz="Europe/Berlin" utc local>
                                                        {distribution?.createdAt}
                                            </Moment><br/>
                                            <Badge variant="info">
                                                    <Moment fromNow tz="Europe/Berlin" utc local>
                                                        {distribution?.createdAt}
                                                    </Moment>
                                            </Badge>
                                        </td>
                                        <td>
                                            <p>Type string: <Badge variant="info">{distribution?.type}</Badge></p>
                                            <p>Artifact Id: <Badge variant="info">{distribution?.id}</Badge> </p>
                                            <p>Complete: {distribution?.complete ? <Badge variant="success">Complete</Badge> : <Badge variant="warning">Not Complete</Badge>} </p>
                                            <p>Created by: <Badge variant="info">{distribution?.createdBy}</Badge></p>
                                            <p>Last modified at: <Badge variant="info"><Moment fromNow tz="Europe/Berlin" utc local>{distribution?.lastModifiedAt}</Moment></Badge></p>
                                            <p>Required Migration: {distribution?.requiredMigrationStep ? <Badge variant="warning">Migration required</Badge> : <Badge variant="success">No migration required</Badge>}</p>
                                        </td>
                                        <td width="30%">
                                            <Select
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                isClearable={false}
                                                isMulti
                                                isSearchable={true}
                                                onChange={(newValue, actionMeta) => { handleOnChange(newValue, actionMeta, distribution?.id) }}
                                                name="color"
                                                value={tagValues.filter(tag => tag.distSetId == distribution?.id)}
                                                options={hawkbitData?.distributionTags?.content?.map((tag) => {
                                                    return { value: tag.id + "",
                                                    label: tag.name }
                                                })}
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                    </div>
                    {hawkbitData?.responseHawkbitDistributions?.length === 0 ?
                    <div>There are no available images configured on the hawkbit server. Please contact our <Link to="/infoAndSupport" target="_blank">support</Link> if you think this might be an issue.</div>
                    : <></>}
                </div>
        );
    }

    return (
    <div>
    <div className="Home">
        <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
            <Breadcrumb.Item active>hawkBit Administration</Breadcrumb.Item>
        </Breadcrumb>
        <h1>hawkBit Administration</h1>
        <Container className="TunnelAndFirewallContainer">
        <Alert variant="warning" dismissible>
            <Alert.Heading>Preview Feature</Alert.Heading>
            <p style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
                {`This page can be used to upload signed firmware images to Hawkbit server. The images will be created as "Testing distributions". Firmware image tags\
 can be assigned inside the table of available firmware images. On the "Tags" page, list of all existing tags can be updated.` }
            </p>
        </Alert>
        </Container>
        <Container className="TunnelAndFirewallContainer">
            <MessageInfo />
            <div>
        <Tabs className="dev-tabs" 
            defaultActiveKey="distributionSets" 
            id="device-tab-selection"
            >
            <Tab eventKey="distributionSets" title="Upload">
            {uploadForm()}
            <h4><FontAwesomeIcon icon="tasks" /> Available Firmware Images (Self Service Software Updates)</h4>
            <ReactPlaceholder showLoadingAnimation className='text-center' ready={props.isAuthenticated && !isLoadingHawkbitData} customPlaceholder={
                    <div className='my-awesome-placeholder text-center'>
                        <br/>
                        <TextRow color='#f2f2f3' style={{width: 'auto', height: '80vh'}}/>
                    </div>
                }>
                    {getBundleRepositoryTable()}
                </ReactPlaceholder>
            </Tab>
            <Tab eventKey="tags" title="Tags">
            <h4><FontAwesomeIcon icon={faFileUpload} className="supportIconHome"/> Firmware Image tags</h4>
            <Container>
                <Row>
                    <Col xs="6">
                    <ReactPlaceholder showLoadingAnimation className='text-center' ready={props.isAuthenticated && !isLoadingHawkbitData} customPlaceholder={
                    <div className='my-awesome-placeholder text-center'>
                        <br/>
                        <TextRow color='#f2f2f3' style={{width: '100%', height: '60vh'}}/>
                    </div>
                }>
                {tagListAndRemoveForm()}
                </ReactPlaceholder>
                    </Col>
                    <Col xs="6">
                    {tagAdditionForm()}
                    </Col>
                </Row>
            </Container>
            </Tab>
            </Tabs>
        </div>
        </Container>
    </div>
    </div>)
}