import React from "react";
import TableFilter from 'react-table-filter';
import { Row, Table, Badge, Button } from "react-bootstrap";
import "../Device.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from "react-moment";
import "./DeviceLogger.css"
// React placeholder
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import {TextRow} from 'react-placeholder/lib/placeholders';
import { Link } from "react-router-dom";


export default function DeviceLogger(props) {
    // const minimalIoTClientVersion = "ESEC_BHT_V01.04.000";


    function filterUpdated(newData, filterConfiguration) {
        props.onColumnFilterChangedDeviceLogger(newData, filterConfiguration);
    }


    async function reloadDeviceLogs() {
        props.onDeviceLoggerActions({
            action: "reloadDeviceLogger"
        })
    }

    // function showInstructions(){
    //     return (
    //     <div>
    //         <Alert variant={props.device?.iot_client >= minimalIoTClientVersion ? "success" : "warning"} >
    //         <Alert.Heading>Feature Preview</Alert.Heading>

    //         {props.device?.iot_client >= minimalIoTClientVersion ?
    //             <div>The OSB IoT Client Version <Badge variant="secondary"> {props.device?.iot_client? props.device?.iot_client : "NOT SET"} </Badge> &nbsp; is &nbsp; <Badge variant="success">compatible </Badge> </div>:
    //             <div>The OSB IoT Client Version <Badge variant="secondary"> {props.device?.iot_client? props.device?.iot_client : "NOT SET"} </Badge> &nbsp; is &nbsp; <Badge variant="danger">incompatible </Badge> <br/>
    //                 Please upgrade the client software to at least <Badge variant="success">{minimalIoTClientVersion}</Badge>
    //                 &nbsp; Currently no log files can be sent by the device.</div> }
    //         </Alert>

    //         {/* <Alert variant="info" >
    //         <Alert.Heading>Basic instructions</Alert.Heading>
    //         <p>This feature allows you to send a file to any location on the target device.</p>
    //         <p>For security reasons the location were the file shall be placed needs to be in a location-whitelist. The whitelist is part of the software image. </p>
    //         <ol>
    //             <li>You can send any file from the file repository to your device.</li>
    //             <li>If you want to upload a new file to the repository, you need to specify the desired location and a sound description.</li>
    //             <li>Please notice that the whitelist check is fullfilled on the device!</li>
    //             <li>In the file repository table below you find all files uploaded, sorted by the date upload date. From here you can trigger the file to be sent to the device.</li>
    //             <li>If you triggerd a file download to the device, a job will be created. In the table below, you always see the latest status.</li>

    //         </ol>
    //         </Alert> */}
    //     </div>
    //     )
    // }

    function getDeviceLoggerData() {
        return (
            <div>
                <div className="card">
                    <div className="card-header">
                        <Row> 
                            <p>This table shows up to 50 most recent logs registered for this device.</p>
                            <Button className="ml-auto" variant="info" onClick={() => reloadDeviceLogs()} size="sm" >
                                <FontAwesomeIcon icon="redo-alt" /> Refresh
                            </Button>
                        </Row>
                    </div>
                    <Table bordered>
                        <thead>
                            <TableFilter
                                rows={props.deviceLoggerData?.Items}
                                initialFilters={props.filterConfigurationDeviceLogger}
                                onFilterUpdate={filterUpdated.bind(this)}>
                                <th key="timestamp_upload" filterkey="timestamp_upload">Uploaded at</th>
                                <th key="timestamp" filterkey="timestamp">Logged at</th>
                                <th key="id" filterkey="id">Id</th>
                                <th key="name" filterkey="name">Name</th>
                                <th key="severity" filterkey="severity">Severity</th>
                                <th key="text" filterkey="text">Text</th>
                            </TableFilter>
                        </thead>
                        <tbody>
                            {renderTableRowsDetails()}
                        </tbody>
                    </Table>
                    {props.deviceLoggerData?.Items?.length === 0 || !props.deviceLoggerData ?
                        <div>There have been no logs yet for this device. Please contact our <Link to="/infoAndSupport" target="_blank">support</Link> if you think this might be an issue.</div>
                    : <></>}
                </div>
            </div>
        );
    }

    function renderTableRowsDetails() {
        return [{}].concat(props.deviceLoggerData?.Items).map((log, i) =>
            i !== 0 ? (
                <tr key={"log"+i}>
                    <td>
                        <Moment format="YYYY-MM-DD HH:mm:ss" tz="Europe/Berlin">
                            {log?.timestamp_upload}
                        </Moment> <Badge variant="info"><Moment fromNow tz="Europe/Berlin">{log?.timestamp_upload}</Moment></Badge>
                    </td>
                    <td>
                        <Moment parse="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" utc local>
                            {log?.timestamp}
                        </Moment> <Badge variant="info"><Moment fromNow parse="YYYY-MM-DD HH:mm:ss" tz="Europe/Berlin" utc local>{log?.timestamp}</Moment></Badge>
                    </td>
                    <td>{log?.id}</td>
                    <td>{log?.name}</td>
                    <td>{log?.severity}</td>
                    <td>{log?.text}</td>
                </tr>
            ) : (<tr key={i}></tr>)
        );
    }


    return (
        <div>
        {/* {showInstructions()} */}

            <ReactPlaceholder showLoadingAnimation className='text-center' ready={!props.isLoadingDeviceLogger} customPlaceholder={
                    <div className='text-center'>
                        <br/>
                        <TextRow color='#e4e4e7' style={{width: 'auto', height: 40}}/>
                        <TextRow color='#f2f2f3' style={{width: 'auto', height: 400}}/>
                    </div>
                }>
                {getDeviceLoggerData()}
            </ReactPlaceholder>
        </div>
    )
}