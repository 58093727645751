import React, { useState } from "react";
import { API } from "aws-amplify";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import "./UpdateUser.css";
import { CognitoIdentityServiceProvider } from 'aws-sdk/dist/aws-sdk-react-native';
import LoaderButton from "../../../../LoaderButton";
import { useFormFields } from "../../../../../libs/hooksLib";
import config from "../../../../../config";



export default function UpdateUser(props) {
    const [showInfo, setShowInfo] = useState(false);
    const [messageInfoVariant, setMessageInfoVariant] = useState("success");
    const [messageInfoResponseBody, setMessageInfoResponseBody] = useState("");
    const [fields, handleFieldChange] = useFormFields({
        customer: "",
        group: ""
    });

    var cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
        region: config.cognito.REGION,
        credentials: {
            accessKeyId: props.essential_credentials?.accessKeyId,
            secretAccessKey: props.essential_credentials?.secretAccessKey,
            sessionToken: props.essential_credentials?.sessionToken
            }
    });

    function updateUsersData() {
        props.onUpdateUsersDataActions({
            action: "updateUsersData",
            showModel: false
        })
    }

    function validateForm() {
        return (fields.customer === "" && fields.group === "") ? false : (
            fields.customer !== props.user.customer_name || fields.group !== props.user.group
        );
    }

    function MessageInfo() {
        if (showInfo) {
          return (
            <Alert variant={messageInfoVariant} onClose={() => setShowInfo(false)} dismissible>
              <Alert.Heading>{messageInfoResponseBody}</Alert.Heading>
            </Alert>
          );
        }
        return <></>;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            await updateUser();
            setShowInfo(true);
            updateUsersData();
        } catch (e) {
            alert(e.message);
        }
    }

    function updateUser() {
        API.put("iot_users", `/iot_users/${props.user.user_id}`, {
            body: {
                customerName: fields.customer !== "" ? fields.customer : props.user.customer_name,
                group: fields.group !== "" ? fields.group : props.user.group
            }
        }).then(() => {
            setMessageInfoVariant("success");
            setMessageInfoResponseBody("User's attributes have been changed.");
        }).catch(err => {
            setMessageInfoVariant("danger");
            setMessageInfoResponseBody("Error: " + err.message);
        });
    }

    function customerSelection() {
        return props.customers.map((customer) => (
            <option key={customer.customer_name} value={customer.customer_name}>{customer.customer_name}</option>
        ));
    }

    async function handleDisableUser(email) {
        var params = {
            UserPoolId: config.cognito.USER_POOL_ID,
            Username: email
        };
        cognitoidentityserviceprovider.adminDisableUser(params).promise().then(() => {
            setMessageInfoVariant("success");
            setMessageInfoResponseBody(email + " has been disabled.");
        }).catch(err => {
            setMessageInfoVariant("danger");
            setMessageInfoResponseBody("Error: " + err.message);
        });
        setShowInfo(true);
        updateUsersData();
    }

    async function handleEnableUser(email) {
        var params = {
            UserPoolId: config.cognito.USER_POOL_ID,
            Username: email
        };
        cognitoidentityserviceprovider.adminEnableUser(params).promise().then(() => {
            setMessageInfoVariant("success");
            setMessageInfoResponseBody(email + " has been enabled.");
        }).catch(err => {
            setMessageInfoVariant("danger");
            setMessageInfoResponseBody("Error: " + err.message);
        });
        setShowInfo(true);
        updateUsersData();
    }

    async function handleDeleteUser(email) {
        var params = {
            UserPoolId: config.cognito.USER_POOL_ID,
            Username: email
        };
        cognitoidentityserviceprovider.adminDeleteUser(params).promise().then(() => {
            setMessageInfoVariant("success");
            setMessageInfoResponseBody(email + " has been deleted.");
        }).catch(err => {
            setMessageInfoVariant("danger");
            setMessageInfoResponseBody("Error: " + err.message);
        });
        setShowInfo(true);
        updateUsersData();
    }

    async function handleDeactivateTOTP(email) {
        var params = {
            UserPoolId: config.cognito.USER_POOL_ID,
            Username: email,
            SoftwareTokenMfaSettings: {
                Enabled: false,
                PreferredMfa: false
            }
        };
        cognitoidentityserviceprovider.adminSetUserMFAPreference(params).promise().then(() => {
            setMessageInfoVariant("success");
            setMessageInfoResponseBody("TOTP has been deactivated for " + email);
        }).catch(err => {
            setMessageInfoVariant("danger");
            setMessageInfoResponseBody("Error: " + err.message);
        });
        setShowInfo(true);
        updateUsersData();
    }

    async function handleResendVerificationMail(email) {
          var params_update = {
            Username: email,
            ClientId: config.cognito.APP_CLIENT_ID
          };
        cognitoidentityserviceprovider.resendConfirmationCode(params_update).promise().then(() => {
            setMessageInfoVariant("success");
            setMessageInfoResponseBody("Verification Mail was sent successfully to " + email);
        }).catch(err => {
            setMessageInfoVariant("danger");
            setMessageInfoResponseBody("Error: " + err.message);
        });
        setShowInfo(true);
        updateUsersData();
    }

    function renderEditUserForm() {
        return (
            <div className="UpdateUserComponent">
                <h1>Edit User</h1>
                <form onSubmit={handleSubmit}>
                    <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        autoFocus
                        type="email"
                        value={props.user.email ? props.user.email : ""}
                        disabled
                    />
                    </Form.Group>
                    <Form.Group controlId="customer">
                        <Form.Label>Customer Affiliation</Form.Label>
                        <Form.Control as="select" placeholder="Please choose..."
                            value={fields.customer === "" ? props.user.customer_name : fields.customer}
                            onChange={handleFieldChange}>
                            {customerSelection()}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="group">
                        <Form.Label>User Permissions</Form.Label>
                        <Form.Control as="select" placeholder="Please choose..."
                            value={fields.group === "" ? props.user.group : fields.group}
                            onChange={handleFieldChange}>
                            <option value="SupportView">Support View</option>
                            <option value="DeviceOwner">Device Owner</option>
                            <option value="Admin">Admin</option>
                        </Form.Control>
                    </Form.Group>
                    <LoaderButton
                        block
                        type="submit"
                        disabled={!validateForm()} >
                        Save Changes
                    </LoaderButton>
                </form>
            </div>
        )
    }

    function renderResendVerificationMailForm() {
        return (
            <div className="UpdateUserComponent">
                {/* <h1>Resend Verification Email</h1> */}
                <form className="UpdateUserComponentForm">
                    <LoaderButton
                        block
                        onClick={handleResendVerificationMail.bind(this, props.user.email)}
                        type="button"
                        variant="warning">
                        Resend Verification Email
                    </LoaderButton>
                </form>
            </div>
        )
    }

    function renderDeleteUserForm() {
        return (
            <div className="UpdateUserComponent">
                {/* <h1>Delete User</h1> */}
                <form className="UpdateUserComponentForm">
                    <LoaderButton
                        block
                        onClick={handleDeleteUser.bind(this, props.user.email)}
                        type="button"
                        variant="danger">
                        Delete this User
                    </LoaderButton>
                </form>
            </div>
        )
    }

    function enableDisableUser() {
        return (
            props.users_attributes['length'] !== 0 ?
            props.users_attributes[props.user.user_id]?.Enabled === true ?
            (
                <div className="UpdateUserComponent">
                    {/* <h1>Disable User</h1> */}
                    <form className="UpdateUserComponentForm">
                        <LoaderButton
                            block
                            onClick={handleDisableUser.bind(this, props.user.email)}
                            type="button"
                            variant="warning">
                            Disable User
                        </LoaderButton>
                    </form>
                </div>
            ) : (
                <div className="UpdateUserComponent">
                    {/* <h1>Enable User</h1> */}
                    <form className="UpdateUserComponentForm">
                        <LoaderButton
                            block
                            onClick={handleEnableUser.bind(this, props.user.email)}
                            type="button"
                            variant="success">
                            Enable User
                        </LoaderButton>
                    </form>
                </div>
            ) : (<></>)
        )
    }

    function userTOTP() {
        return (
            <div className="UpdateUserComponent">
                {/* <h1>Deactivate TOTP</h1> */}
                <form className="UpdateUserComponentForm">
                    <LoaderButton
                        block
                        onClick={handleDeactivateTOTP.bind(this, props.user.email)}
                        type="button"
                        variant="warning">
                        Deactivate TOTP
                    </LoaderButton>
                </form>
            </div>
        )
    }


    return (
        <div className="UpdateUser">
            <Container>
                <Row>
                    <Col sx={6}>
                        {renderEditUserForm()}
                    </Col>
                    <Col sx={6}>
                        {enableDisableUser()}
                        {props.users_attributes[props.user.user_id].PreferredMfaSetting === "SOFTWARE_TOKEN_MFA"?
                            userTOTP()
                        : <></>}
                        {props.users_attributes[props.user.user_id].UserStatus === "UNCONFIRMED" || props.users_attributes[props.user.user_id].UserStatus === "FORCE_CHANGE_PASSWORD"?
                            renderResendVerificationMailForm()
                        : <></>}
                        {renderDeleteUserForm()}
                        <br/>
                        <br/>
                        <MessageInfo />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}