import React, {useEffect, useState} from "react";
import "../Device.css";
import { Row, Col, Form, Alert, Button, Table, Badge, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoaderButton from "../../LoaderButton";
import "../DeviceManagement/DeviceManagement.css";
import "./RemoteAccess.css";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Moment from "react-moment";
// import Spinner from "../../Spinner";
import { Link } from "react-router-dom";
// React placeholder
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import {TextRow} from 'react-placeholder/lib/placeholders';



const IpAddressComparionsResult = {
    same_type_same_value: 0,
    same_type_different_value: 1,
    different_type: 2,
    not_valid_ip_address: 3
  };


export default function RemoteAccess(props) {
    const [hotp_state, setHOTPState] = useState(false);
    const [showInfo, setShowInfo] = useState(true);
    const [expirationDateTimestamp, setExpirationDateTimestamp] = useState(null);
    // const DESIRED_CONFIG = "https://feature-manager-configuration-rollout-prod.s3.eu-central-1.amazonaws.com/templates/remote_connection_v2.cfg";

    function MessageInfo() {
        if (props.message?.show && showInfo) {
          return (
            <Alert variant={props.message?.variant} onClose={() => setShowInfo(false)} dismissible>
              <Alert.Heading>{props.message?.headline}</Alert.Heading>
              {props.message?.body}
            </Alert>
          );
        }
        return <></>;
    }

    async function handleHOTPGeneration(event) {
        event.preventDefault();
        props.onHOTPGeneration();
    }

    async function deactivateRemoteConnection(UID) {
        props.onRemoteConnectionActions({
            action: "deactivateRemoteConnection",
            uid: UID
        })
    }

    async function activateRemoteConnection() {
        props.onRemoteConnectionActions({
            action: "activateRemoteConnection"
            // desired_config: DESIRED_CONFIG
        })
    }

    async function abortRemoteConnectionRollout(UID, DELTA_STATUS) {
        props.onRemoteConnectionActions({
            action: "abortRemoteConnectionRollout",
            delta_status: DELTA_STATUS,
            uid: UID
        })
    }

    async function whitelistIP() {
        setShowInfo(true);
        props.onWhitelistIP();
    }

    async function renewIP() {
        setShowInfo(true);
        props.onRenewIP();
    }

    async function deleteIP() {
        setShowInfo(true);
        props.onDeleteIP();
    }

    function renderTimeToLiveDate() {
        return (<>
        {expirationDateTimestamp ? 
            ( <>
                <Moment tz="Europe/Berlin" unix format="YYYY-MM-DD HH:mm:ss">{expirationDateTimestamp}</Moment>
                &nbsp;
                <Badge variant="info"><Moment tz="Europe/Berlin" unix fromNow>{expirationDateTimestamp}</Moment></Badge>
            </> ) : (
                <Badge variant="secondary">not available</Badge>
            ) } </>
        );
    }

    useEffect(() => {

        setExpirationDateTimestamp(props.remoteConnectionData?.userIpData?.expiry_date);

    }, [props.remoteConnectionData?.userIpData?.expiry_date ?? null]);

    function showRemoteConnectionInfos(){
        return (
            <div>
                <Alert variant={"secondary"}>
                <div>
                    <Alert.Heading>
                        <Row>
                            <Col xs={12}>
                                <FontAwesomeIcon icon="microchip" /> Device Tunnel Status:&nbsp;
                                 {props.remoteConnectionData?.statusData?.status === 'ACTIVE' && props.remoteConnectionData?.statusData?.delta_status === 'NO_PENDING_JOB'?
                                <Badge variant="success">{props.remoteConnectionData?.statusData?.status}</Badge>
                                : props.remoteConnectionData?.statusData?.status === 'ACTIVE' && props.remoteConnectionData?.statusData?.delta_status === 'PENDING_DEACTIVATION'?
                                <Badge variant="warning">{props.remoteConnectionData?.statusData?.delta_status.replace(/_/g, " ")}</Badge>
                                : props.remoteConnectionData?.statusData?.status === 'INACTIVE' && props.remoteConnectionData?.statusData?.delta_status === 'NO_PENDING_JOB'?
                                <Badge variant="secondary">{props.remoteConnectionData?.statusData?.status}</Badge>
                                : props.remoteConnectionData?.statusData?.status === 'INACTIVE' && props.remoteConnectionData?.statusData?.delta_status === 'PENDING_ACTIVATION'?
                                <Badge variant="info">{props.remoteConnectionData?.statusData?.delta_status.replace(/_/g, " ")}</Badge>
                                : props.remoteConnectionData?.statusData?.status === 'INCOMPLETE_CONFIG_FILE_ROLLOUT' && props.remoteConnectionData?.statusData?.delta_status === 'NO_PENDING_JOB'?
                                <Badge variant="danger">{props.remoteConnectionData?.statusData?.status.replace(/_/g, " ")}</Badge>
                                : <></> }

                    </Col>
                        </Row>
                    </Alert.Heading>
                    { props.remoteConnectionData?.statusData?.status === 'INCOMPLETE_CONFIG_FILE_ROLLOUT' && props.remoteConnectionData?.statusData?.delta_status === 'NO_PENDING_JOB'?
                        <Alert variant="danger">
                            Configuration target path seems to not exist or is not writeable. Please contact Support!
                        </Alert>
                        : <></>
                    }
                    <Row>
                        <Col xs={12}>
                                {props?.remoteConnectionData?.statusData?.timestamp ?
                                    <div>Last Change: <Moment unix tz="Europe/Berlin" format="YYYY-MM-DD HH:mm" utc local>
                                                {props.remoteConnectionData?.statusData?.timestamp*1000}
                                            </Moment> &nbsp;
                                            <Badge variant="info">
                                            <Moment fromNow unix tz="Europe/Berlin" utc local>
                                                {props.remoteConnectionData?.statusData?.timestamp*1000}
                                            </Moment>
                                        </Badge>
                                    </div>
                                : <></>
                                }
                                <hr/>

                                Available Action: {props.remoteConnectionData?.statusData?.status === "ACTIVE" && props.remoteConnectionData?.statusData?.delta_status === "NO_PENDING_JOB"?
                                <Button variant="warning" onClick={() => deactivateRemoteConnection(props.device.uid)} size="sm" >
                                    <FontAwesomeIcon icon="unlink" /> DISABLE Tunnel
                                </Button> : <></> }
                                {props.remoteConnectionData?.statusData?.status === "INACTIVE" && props.remoteConnectionData?.statusData?.delta_status === "NO_PENDING_JOB"?
                                <Button variant="success" onClick={activateRemoteConnection} size="sm" >
                                    <FontAwesomeIcon icon="link" /> ACTIVATE Tunnel
                                </Button> : <></> }
                                {props.remoteConnectionData?.statusData?.delta_status !== "NO_PENDING_JOB"?
                                <div>
                                    <Button variant="danger" onClick={() => abortRemoteConnectionRollout(
                                        props.device.uid,
                                        props.remoteConnectionData?.statusData?.delta_status.replace(/_/g, " "))}
                                        size="sm" >
                                    <FontAwesomeIcon icon="window-close" /> ABORT {props.remoteConnectionData?.statusData?.delta_status.replace(/_/g, " ")}
                                </Button>
                                <br/> Info: A change was triggered by the managamenet system, but the device did not receive its new configuration yet.
                                </div>: <></> }

                            <hr/>

                            <div><FontAwesomeIcon icon="bell" /> Device port: <Badge variant="primary">
                                {props?.remoteConnectionData?.statusData?.reported_device_port === -1 || !props?.remoteConnectionData?.statusData?.reported_device_port? 'NO PORT ASSIGNED YET'
                                : props?.remoteConnectionData?.statusData?.reported_device_port}
                            </Badge></div>

                            {props.remoteConnectionData?.statusData?.status === "ACTIVE" ?
                            <div>
                            <FontAwesomeIcon icon="terminal" /> { "ssh -p " + (props?.remoteConnectionData?.statusData?.reported_device_port === -1 ||
                                !props?.remoteConnectionData?.statusData?.reported_device_port ? 'PORT'
                                : props?.remoteConnectionData?.statusData?.reported_device_port) + " <user>@remote2.aws.iot-suite.io " }
                                <CopyToClipboard text={"ssh -p " + props?.remoteConnectionData?.statusData?.reported_device_port + " <user>@remote2.aws.iot-suite.io"}
                                    onCopy={() => setHOTPState(true)}>
                                    <Button className="btn btn-dark btn-sm"><FontAwesomeIcon icon="copy" /></Button>
                                </CopyToClipboard>
                            </div>
                            : <></> }

                            <hr/>

                            <div>
                                Last seen SSH: {props.device?.ssh_last_seen_timestamp ? 
                                ( <>
                                    <Moment tz="Europe/Berlin" unix local format="YYYY-MM-DD HH:mm:ss">{props.device?.ssh_last_seen_timestamp}</Moment>
                                    &nbsp;
                                    <Badge variant="info"><Moment tz="Europe/Berlin" unix local fromNow>{props.device?.ssh_last_seen_timestamp}</Moment></Badge>
                                </> ) : (
                                    <Badge variant="secondary">not available</Badge>
                                ) }
                            </div>

                        </Col>
                    </Row>

                </div>
                </Alert>
            </div>
        )
    }

    function renderRemoteConnectionHistoryTable(){
        return (
            <div>
            <h4><FontAwesomeIcon icon="list" /> Remote Connection event history</h4>
            <div className="card">
                <div className="card-header">All remote connection actions done by members of your organisation</div>
                <Table bordered>
                    <thead>
                        <tr>
                        <th >Date</th>
                        <th >Event Type</th>
                        <th >Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.remoteConnectionData?.historyData &&
                            props.remoteConnectionData?.historyData.Items.map((history, index) => (
                            history.status ?
                            <tr key={index}>
                                <td>
                                    <Moment format="YYYY-MM-DD HH:mm:ss" unix tz="Europe/Berlin" utc local>
                                                {history.timestamp*1000}
                                    </Moment><br/>
                                    <Badge variant="info">
                                            <Moment fromNow unix tz="Europe/Berlin" utc local>
                                                {history.timestamp*1000}
                                            </Moment>
                                    </Badge>
                                </td>
                                <td>Remote Connection Rollout</td>
                                <td>Status: {history.status === 'ACTIVE' && history.delta_status === 'NO_PENDING_JOB'?
                                    <Badge variant="success">{history.status}</Badge> : <></> }
                                    {history.status === 'ACTIVE' && history.delta_status === 'PENDING_DEACTIVATION'?
                                    <Badge variant="warning">{history.delta_status.replace(/_/g, " ")}</Badge> : <></> }
                                    {history.status === 'INACTIVE' && history.delta_status === 'NO_PENDING_JOB'?
                                    <Badge variant="secondary">{history.status}</Badge> : <></> }
                                    {history.status === 'INACTIVE' && history.delta_status === 'PENDING_ACTIVATION'?
                                    <Badge variant="info">{history.delta_status.replace(/_/g, " ")}</Badge> : <></> }
                                    <p>Reported port: <Badge variant="info">{history.reported_device_port === -1 || !history.reported_device_port ? "NO PORT REPORTED" : history.reported_device_port}</Badge></p>
                                    <p>Desired port: <Badge variant="info">{history.desired_device_port === -1 || !history.desired_device_port ? "NO PORT DESIRED" : history.desired_device_port}</Badge></p>
                                    <p>Changed by: {history.user_id}</p>
                                </td>
                            </tr>
                            :
                            <tr key={index}>
                                <td>
                                    <Moment format="YYYY-MM-DD HH:mm:ss" unix tz="Europe/Berlin" utc local>
                                                {history.timestamp*1000}
                                    </Moment><br/>
                                    <Badge variant="info">
                                            <Moment fromNow unix tz="Europe/Berlin" utc local>
                                                {history.timestamp*1000}
                                            </Moment>
                                    </Badge>
                                </td>
                                <td>One-Time Password</td>
                                <td>
                                    {/* <p>Counter: <Badge variant="info">{history.counter}</Badge></p> */}
                                    {/* <p>Revision: <Badge variant="info">{history.revision}</Badge></p> */}
                                    <p>Generated by User-ID: {history.user_id}</p>
                                </td>
                            </tr>
                            ))}
                    </tbody>
                </Table>
                {props.remoteConnectionData?.historyData?.Items?.length === 0 ?
                    <div>There have been no remote access updates for this device. Please contact our <Link to="/infoAndSupport" target="_blank">support</Link> if you think this might be an issue.</div>
                : <></>}
            </div>
        </div>
        )
    }

    function isValidIPv4(address)
    {
        const ipv4Pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return ipv4Pattern.test(address);
    }

    function isValidIPv6(address)
    {
        const ipv6Pattern = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;
        return ipv6Pattern.test(address);
    }

    function compareIPAddresses(ip1, ip2)
    {
        if(!ip1 || !ip2)
        {
            return IpAddressComparionsResult.not_valid_ip_address;
        }

        // get rid of /32 or /128 at the end
        if (ip1.includes('/32'))
        {
            ip1 = ip1.replace('/32', '');
        }
        if (ip2.includes('/32'))
        {
            ip2 = ip2.replace('/32', '');
        }

        if (ip1.includes('/128'))
        {
            ip1 = ip1.replace('/128', '');
        }
        if (ip2.includes('/128'))
        {
            ip2 = ip2.replace('/128', '');
        }

        const ip1_valid_ipv4 = isValidIPv4(ip1);
        const ip1_valid_ipv6 = isValidIPv6(ip1);
        const ip2_valid_ipv4 = isValidIPv4(ip2);
        const ip2_valid_ipv6 = isValidIPv6(ip2);

        if ( (ip1_valid_ipv4 && ip2_valid_ipv4) || (ip1_valid_ipv6 && ip2_valid_ipv6) )
        {
            // both same type and valid => to be compared later
        }
        else if( (ip1_valid_ipv4 && ip2_valid_ipv6) ||
                 (ip1_valid_ipv6 && ip2_valid_ipv4) )
        {
            // valid ip addresses but with different type
            return IpAddressComparionsResult.different_type;
        }
        else
        {
            // there is maybe a pair, but at least one ip address is not valid inside the pair.
            return IpAddressComparionsResult.not_valid_ip_address;
        }

        if(ip1 === ip2)
        {
            return IpAddressComparionsResult.same_type_same_value;
        }
        else
        {
            return IpAddressComparionsResult.same_type_different_value;
        }
    }
    
    const compareIPAddressesResult = compareIPAddresses( props.remoteConnectionData?.userIpData?.source_ip, props.remoteConnectionData?.userIpData?.previous_auth_ip );

    return (
        <div>
            <h4 className="RemoteAccessHeader"><FontAwesomeIcon icon="terminal" /> Remote Access via SSH Jump Host</h4>
            <Container>
                <h4 className="RemoteAccessHeader"><FontAwesomeIcon icon="qrcode" /> One-time password Generator</h4>
                <form onSubmit={handleHOTPGeneration}>
                    <Row>
                        <Col xs={6}>
                            <Alert variant="warning">
                                <Alert.Heading>One-time password generation</Alert.Heading>
                                <p>
                                Use the Generate OTP button to generate a single one time password for your SSH access.
                                </p>
                                <hr />
                                <p className="mb-0">
                                Do not use this function, unless you know what you are doing! <br/>Generating a one-time password can lead to inconsistent counters on the device.
                                </p>
                                <p>
                                    Note: Due to security reasons, every generation of one-time passwords will be logged!
                                </p>
                            </Alert>
                        </Col>

                        <Col xs={6}>
                            <Form.Group controlId="submit">
                                <Form.Label>Device Name</Form.Label>
                                <Form.Control type="text" placeholder={props.device?.device_name} readOnly />
                                    <div style={{marginTop: "12px"}}>
                                        {props?.hotpResponse ?
                                        <div>
                                            <Alert variant="warning">
                                                Please be careful to again create HOTP codes. It might lead to state where you cannot login, if you do not use the codes on the device!
                                            </Alert>
                                            <Form.Check
                                                    required
                                                    label="I know what I am doing and that my action will be logged."
                                                    type="checkbox"
                                                    id="userAckOTP"/>
                                            <br/>
                                            <br/>
                                            <LoaderButton variant="warning" block type="submit"  >
                                                Generate OTP!
                                            </LoaderButton>
                                        </div>
                                        :
                                        <div>
                                            <Form.Check
                                                required
                                                label="I know what I am doing and that my action will be logged."
                                                type="checkbox"
                                                id="userAckOTP"/>
                                            <LoaderButton variant="warning" block type="submit"  >
                                                Generate OTP!
                                            </LoaderButton>
                                        </div>
                                        }
                                    </div>
                            </Form.Group>

                            {props?.hotpResponse ?
                            <div>

                                    <Form.Group>
                                        <Form.Label>Your OTP Token</Form.Label>
                                        <Form.Control type="text" placeholder={props?.hotpResponse} readOnly />
                                    </Form.Group>
                                    <CopyToClipboard text={props?.hotpResponse}
                                        onCopy={() => setHOTPState(true)}>
                                        <Button variant="outline-info">Copy to clipboard</Button>
                                    </CopyToClipboard>
                                    {hotp_state ? <span style={{color: 'red'}}> Copied.</span> : null}
                                </div>
                            : <></> }
                        </Col>
                    </Row>
                </form>
                <br/>
                <hr/>
                <Row>
                <h4 className="RemoteAccessHeader"><FontAwesomeIcon icon="terminal" /> Remote Access Activation and Settings</h4>
                </Row>

                <ReactPlaceholder showLoadingAnimation className='text-center' ready={!props.isLoadingRemoteConnection} customPlaceholder={
                    <div className='text-center'>
                        <br/>
                        <Row>
                            <Col xs={6}>
                                <TextRow color='#e4e4e7' style={{width: 'auto', height: 80}}/>
                            </Col>
                            <Col xs={6}>
                                <TextRow color='#e4e4e7' style={{width: 'auto', height: 80}}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <TextRow color='#e4e4e7' style={{width: 'auto', height: 800}}/>
                            </Col>
                        </Row>
                    </div> }>
                    <MessageInfo />
                        <Row>
                            <Col xs={6}>
                            {( props?.firewallData?.SecurityGroupRules?.length === 0 || !props?.firewallData?.SecurityGroupRules)  ?
                                <Alert variant="info">
                                        <Alert.Heading><FontAwesomeIcon icon="wrench" /> Further Configuration needed</Alert.Heading>
                                        <div>This feature can only work after firewall rules were applied.<br/>
                                        Please contact our <Link to="/infoAndSupport" target="_blank">support</Link></div>
                                </Alert>
                                :
                                <></>}
                                {showRemoteConnectionInfos()}
                            </Col>
                            <Col xs={6}>
                                <div>
                                    <Alert variant={"secondary"}>
                                        <div>
                                            <Alert.Heading>
                                                Whitelist IP Status
                                            </Alert.Heading>
                                            <Row>
                                                <Col xs={4}>Login IP address:</Col> { props.remoteConnectionData?.userIpData?.previous_auth_ip }
                                            </Row>
                                            <Row>
                                                <Col xs={4}>Current IP address:</Col> { props.remoteConnectionData?.userIpData?.source_ip }
                                            </Row>
                                            <Row>
                                                <Col xs={4}>Whitelisted IP address:</Col> { props.remoteConnectionData?.userIpData?.user_inbound_rule_ip }
                                            </Row>
                                            {
                                                props.remoteConnectionData?.userIpData?.expiry_date?
                                                (<Row>
                                                    <Col xs={4}>
                                                        Whitelisted IP expires:
                                                    </Col>
                                                    { renderTimeToLiveDate() }
                                                </Row>) :
                                                <></>
                                            }
                                            <br/>

                                            <Row>
                                                <Col xs={12}>
                                                    <Alert variant="info">
                                                        {
                                                            (compareIPAddressesResult === IpAddressComparionsResult.same_type_same_value) ? 
                                                            (
                                                                <>Login IP address and Current IP address are same. Device should be accessible through SSH.</>
                                                            )
                                                            :
                                                            (compareIPAddressesResult === IpAddressComparionsResult.same_type_different_value) ?
                                                            (
                                                                <>Login IP address and Current IP address are different. You would need to switch to the Login IP address or change Whitelisted IP address to be able to SSH into device.</>
                                                            )
                                                                :
                                                            (compareIPAddressesResult === IpAddressComparionsResult.different_type) ?
                                                            (
                                                                <div>Potential IP addresses mismatch (IPv4 and IPv6).</div>
                                                            )
                                                            :
                                                            (compareIPAddressesResult === IpAddressComparionsResult.not_valid_ip_address) ?
                                                            (
                                                                <div>IP Address not valid</div>
                                                            )
                                                            :
                                                            <></>
                                                        }
                                                    </Alert>
                                                </Col>
                                            </Row>

                                            {props.remoteConnectionData?.userIpData?.user_inbound_rule_ip?
                                                (
                                                <div>
                                                    <Row>
                                                        <Container>
                                                            You can renew your IP address by clicking on Renew button which will extend its validity to 4 hours from now.
                                                        </Container>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col xs={6}>
                                                        <Button block className="btn btn-primary" variant="success" disabled = {!expirationDateTimestamp} onClick={renewIP}>Renew Whitelisted IP</Button>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Button
                                                                block
                                                                className="btn btn-primary"
                                                                variant="danger"
                                                                disabled = {
                                                                    !( props.remoteConnectionData?.userIpData?.user_inbound_rule_ip )
                                                                }
                                                                onClick={deleteIP}>Delete Whitelisted IP</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                ) :
                                                (
                                                <div>
                                                    <Row>
                                                        <Container>
                                                        To be able to access your device over SSH Jump host, you might need to whitelist your IP address to add it to firewall rules.
                                                        </Container>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <Button
                                                                block
                                                                className="btn btn-primary"
                                                                variant="warning"
                                                                disabled = {
                                                                    ( props.remoteConnectionData?.userIpData?.user_inbound_rule_ip )
                                                                }
                                                                onClick={whitelistIP}>Whitelist Login IP</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                )
                                            }
                                        </div>
                                    </Alert>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {renderRemoteConnectionHistoryTable()}
                            </Col>
                        </Row>
                </ReactPlaceholder>
            </Container>
        </div>
    )
}