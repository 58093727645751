import React from "react";
import {
     Row, Col, Alert, Container, Table
} from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import Moment from 'react-moment';
import 'moment-timezone';
import "../Device.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function DeviceHealth(props) {
    function description_to_string(description) {
        // console.log(description);
        var erg = description.toString();
        erg = erg.replace("DS_ID:", "Distribution ID: ")
        erg = erg.replace("__name:", "\nName: ")
        erg = erg.replace("_empty-", "\nCompatible String: \n")
        erg = erg.replace("--SW_IDS:", "\nSoftware Artifacts: ")
        // console.log(erg);
        return erg
    }


    function showRaucDataInfo(){
        return (
            <div>
                <Alert variant="warning">
                <Alert.Heading>RAUC Status - Production Software Image</Alert.Heading>
                <p>
                    Our system can only report software updates, that were installed via the RAUC service.<br/>
                    During production the initial filesystems are flashed without using RAUC. <br/>
                    If you see many status messages like <Badge variant="warning">unknown-or-notset</Badge> it means that this slot has never been updated via Hawkbit and RAUC.
                    </p>
                    <p>If only Rootfs Status is <Badge variant="warning">unknown-or-notset</Badge>, this is likely because you have a read only file sytstem.</p>
                <hr />
                <p className="mb-0">
                    If you think this might be an error, please contact our <Link to="/infoAndSupport" target="_blank">support</Link>.
                </p>
                <p>If only Rootfs Status is <Badge variant="warning">unknown-or-notset</Badge>, this is likely because you have a read only file sytstem.</p>
            <hr />
            <p className="mb-0">
                If you think this might be an error, please <a href="mailto:support@iot-suite.io?subject=IoT%20Device%20Management%20Support%20Request"> contact our support</a>
            </p>
            </Alert>
            </div>

        )
    }

    function renderFirmwareInformation() {

        const shadowStateData = props?.iotCoreData?.state?.reported;

        const checkForSwUpdateObj = (() => {
            if(shadowStateData)
            {
                for (const key in shadowStateData)
                {
                    if(key.startsWith("swup_"))
                    {
                        return true;
                    }
                }
            }
            return false;
        });

        if(shadowStateData)
        {
            if ("rauc" in shadowStateData)
            {
                return (
                    <>
                        {props.device?.rauc_status?.active?.bundle?.version === "unknown-or-notset"
                        || props.device?.rauc_status?.inactive?.bundle?.version === "unknown-or-notset"
                        ? showRaucDataInfo() : ""}
                        <br/>
                        <div className="dev-attributes-heading"><FontAwesomeIcon icon="clock" /> Last Seen:</div>{props.device?.last_seen ? <Moment tz="Europe/Berlin" utc local format="YYYY-MM-DD HH:mm:ss" parse="YYYY-MM-DD HH:mm:ss">{props.device?.last_seen?.trim().split("\n")[0]}</Moment> : ''} <Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DD HH:mm:ss">{props.device?.last_seen?.trim().split("\n")[0]}</Moment></Badge>
                        <div>
                            <Row className="row-no-padding">
                                <Col xs={4}><b>Attribute</b></Col>
                                <Col xs={4}><b><FontAwesomeIcon icon="toggle-on" style={{'color': 'green'}}/> Active Slot</b></Col>
                                <Col xs={4}><b><FontAwesomeIcon icon="toggle-off" style={{'color': 'gray'}}/> Fallback Slot</b></Col>
                            </Row>
                            <Row className="row-no-padding">
                                <Col xs={4} className="firmware-label">Slot ID</Col>
                                <Col xs={4} className="firmware-activeslot">{props.device?.rauc_status?.active?.slot}</Col>
                                <Col xs={4} className="firmware-inactiveslot">{props.device?.rauc_status?.inactive?.slot} </Col>
                            </Row>
                            <Row className="row-no-padding">
                                <Col xs={4} className="firmware-label">Build Version</Col>
                                <Col xs={4} className="firmware-activeslot">{props.device?.rauc_status?.active?.bundle?.version} </Col>
                                <Col xs={4} className="firmware-inactiveslot">{props.device?.rauc_status?.inactive?.bundle?.version} </Col>
                            </Row>
                            {props.device?.rauc_status?.active?.bundle?.build !== 'unknown-or-notset' ?
                            <Row className="row-no-padding">
                                <Col xs={4} className="firmware-label">Build Date</Col>
                                <Col xs={4} className="firmware-activeslot"> {props.device?.rauc_status?.active?.bundle?.build}</Col>
                                <Col xs={4} className="firmware-inactiveslot">{props.device?.rauc_status?.inactive?.bundle?.build}</Col>
                            </Row>
                            : <></>}
                            {props.device?.rauc_status?.active?.bundle?.description !== 'unknown-or-notset' ?
                            <Row className="row-no-padding">
                                <Col xs={4} className="firmware-label">Description</Col>
                                <Col xs={4} className="firmware-activeslot"> { props.device?.rauc_status?.active?.bundle?.description &&
                                    description_to_string(props.device?.rauc_status?.active?.bundle?.description).split('\n').map((item, key) => {
                                    return <span key={key}>{item}<br/></span>
                                    }) }
                                </Col>
                                <Col xs={4} className="firmware-inactiveslot"> { props.device?.rauc_status?.inactive?.bundle?.description &&
                                    description_to_string(props.device?.rauc_status?.inactive?.bundle?.description).split('\n').map((item, key) => {
                                    return <span key={key}>{item}<br/></span>
                                    }) }
                                    </Col>
                            </Row>
                            : <></>}
                            <Row className="row-no-padding">
                                <Col xs={4} className="firmware-label">Compatible String</Col>
                                <Col xs={4} className="firmware-activeslot"> {props.device?.rauc_status?.active?.bundle?.compatible}</Col>
                                <Col xs={4} className="firmware-inactiveslot">{props.device?.rauc_status?.inactive?.bundle?.compatible} </Col>
                            </Row>
                            <Row className="row-no-padding">
                                <Col xs={4} className="firmware-label">Installed Timestamp</Col>
                                <Col xs={4} className="firmware-activeslot">{props.device?.rauc_status?.active?.rootfs["installed.timestamp"]}<br/><Badge variant="light"><Moment fromNow utc local parse="YYYY-MM-DDTHH:mm:ssZ">{props.device?.rauc_status?.active?.rootfs["installed.timestamp"]}</Moment></Badge></Col>
                                <Col xs={4} className="firmware-inactiveslot">{props.device?.rauc_status?.inactive?.rootfs["installed.timestamp"]}<br/><Badge variant="light"><Moment fromNow utc local parse="YYYY-MM-DDTHH:mm:ssZ">{props.device?.rauc_status?.inactive?.rootfs["installed.timestamp"]}</Moment></Badge> </Col>
                            </Row>
                            <Row className="row-no-padding">
                                <Col xs={4} className="firmware-label">Rootfs Status</Col>
                                <Col xs={4} className="firmware-activeslot">
                                    { props.device?.rauc_status?.active?.rootfs?.status === "unknown-or-notset"
                                        ? <div> {props.device?.rauc_status?.active?.rootfs?.status} <Badge variant="info"> Maybe - Read Only FS?</Badge> </div>
                                        : <div> {props.device?.rauc_status?.active?.rootfs?.status} </div>
                                    }
                                </Col>
                                <Col xs={4} className="firmware-inactiveslot">
                                { props.device?.rauc_status?.inactive?.rootfs?.status === "unknown-or-notset"
                                        ? <div> {props.device?.rauc_status?.inactive?.rootfs?.status} <Badge variant="info"> Maybe - Read Only FS?</Badge> </div>
                                        : <div>{props.device?.rauc_status?.inactive?.rootfs?.status}</div>
                                    }
                                    </Col>
                            </Row>
                            <Row className="row-no-padding">
                                <Col xs={4} className="firmware-label">AppFS Status</Col>
                                <Col xs={4} className="firmware-activeslot">{props.device?.rauc_status?.active?.appfs?.status}</Col>
                                <Col xs={4} className="firmware-inactiveslot">{props.device?.rauc_status?.inactive?.appfs?.status} </Col>
                            </Row>
                            <Row className="row-no-padding">
                                <Col xs={4} className="firmware-label">Boot Status</Col>
                                <Col xs={4} className="firmware-activeslot">{props.device?.rauc_status?.active?.rootfs["boot-status"]}</Col>
                                <Col xs={4} className="firmware-inactiveslot">{props.device?.rauc_status?.inactive?.rootfs["boot-status"]} </Col>
                            </Row>
                        </div>
                    </> );
            }
            else if(checkForSwUpdateObj())
            {
                const tableStyle = {
                    tableLayout: 'fixed'
                };

                const columnStyle = {
                    paddingRight: '3vw'
                };

                const activePartitions = {};
                const inactivePartitions = {};
            
                for (const key in shadowStateData) {
                    if (key.startsWith('swup_act_')) {
                        activePartitions[key.replace('swup_act_', '')] = shadowStateData[key];
                    } else if (key.startsWith('swup_inact_')) {
                        inactivePartitions[key.replace('swup_inact_', '')] = shadowStateData[key];
                    }
                }

                const allPartitionNames = new Set([
                    ...Object.keys(activePartitions),
                    ...Object.keys(inactivePartitions),
                ]);

                return <>
                    <div className="dev-attributes-heading"><FontAwesomeIcon icon="clock" /> Last Seen:</div>{props.device?.last_seen ? <Moment tz="Europe/Berlin" utc local format="YYYY-MM-DD HH:mm:ss" parse="YYYY-MM-DD HH:mm:ss">{props.device?.last_seen?.trim().split("\n")[0]}</Moment> : ''} <Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DD HH:mm:ss">{props.device?.last_seen?.trim().split("\n")[0]}</Moment></Badge>
                    <Row>
                        <Col xs={5} style={columnStyle}>
                            <h4>Active System Details</h4>

                            <Table bordered style={tableStyle}>
                                <tbody>
                                    <tr>
                                        <td>Installed kernel version</td>
                                        <td>
                                            {shadowStateData.swup_installed_kernel_ver ? (
                                            <div>
                                                { (shadowStateData.swup_installed_kernel_ver).match(/^.*?((?:\d+\.)*\d+)$/)?.[1] ? (
                                                (shadowStateData.swup_installed_kernel_ver).match(/^.*?((?:\d+\.)*\d+)$/)?.[1] ) : (
                                                <div>
                                                    { shadowStateData.swup_installed_kernel_ver }
                                                    <br/>
                                                    <Badge variant="secondary">version not parseable</Badge>
                                                </div> ) }
                                            </div>
                                                )
                                                :
                                                (
                                                <Badge variant="secondary">not available</Badge>
                                                )
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Installed rootfs version</td>
                                        <td>
                                            {shadowStateData.swup_installed_rootfs_ver ? (
                                            <div>
                                                { (shadowStateData.swup_installed_rootfs_ver).match(/^.*?((?:\d+\.)*\d+)$/)?.[1] ? (
                                                (shadowStateData.swup_installed_rootfs_ver).match(/^.*?((?:\d+\.)*\d+)$/)?.[1] ) : (
                                                <div>
                                                    { shadowStateData.swup_installed_rootfs_ver }
                                                    <br/>
                                                    <Badge variant="secondary">version not parseable</Badge>
                                                </div> ) }
                                            </div>
                                                )
                                                :
                                                (
                                                <Badge variant="secondary">not available</Badge>
                                                )
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Installed obs version</td>
                                        <td>
                                            {shadowStateData.swup_installed_obs_ver ? (
                                                <div>
                                                    { (shadowStateData.swup_installed_obs_ver).match(/^.*?((?:\d+\.)*\d+)$/)?.[1] ? (
                                                    (shadowStateData.swup_installed_obs_ver).match(/^.*?((?:\d+\.)*\d+)$/)?.[1] ) : (
                                                    <div>
                                                        { shadowStateData.swup_installed_obs_ver }
                                                        <br/>
                                                        <Badge variant="secondary">version not parseable</Badge>
                                                    </div> ) }
                                                </div>
                                                )
                                                :
                                                (
                                                <Badge variant="secondary">not available</Badge>
                                                )
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Installed custom version</td>
                                        <td>
                                            {shadowStateData.swup_installed_custom_ver ? (
                                                    <div>
                                                        { (shadowStateData.swup_installed_custom_ver).match(/^.*?((?:\d+\.)*\d+)$/)?.[1] ? (
                                                        (shadowStateData.swup_installed_custom_ver).match(/^.*?((?:\d+\.)*\d+)$/)?.[1] ) : (
                                                        <div>
                                                            { shadowStateData.swup_installed_custom_ver }
                                                            <br/>
                                                            <Badge variant="secondary">version not parseable</Badge>
                                                        </div> ) }
                                                    </div>
                                                )
                                                :
                                                (
                                                <Badge variant="secondary">not available</Badge>
                                                )
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Last updated timestamp</td>
                                        <td>
                                            {shadowStateData.swup_last_updated_ts ? 
                                            ( <div>
                                                <Moment tz="Europe/Berlin" utc local format="YYYY-MM-DD HH:mm:ss" parse="YYYY-MM-DD HH:mm:ss">{shadowStateData.swup_last_updated_ts.trim().split("\n")[0]}</Moment>
                                                <br/>
                                                <Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DD HH:mm:ss">{shadowStateData.swup_last_updated_ts.trim().split("\n")[0]}</Moment></Badge>
                                            </div> ) : (
                                                <Badge variant="secondary">not available</Badge>
                                            ) }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Total updates</td>
                                        <td>
                                            { shadowStateData.swup_total_updates ? (
                                                shadowStateData.swup_total_updates ) : (
                                                    <Badge variant="secondary">not available</Badge>
                                                )
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs={7}>
                            <h4>Partition Status</h4>

                            <Table bordered style={tableStyle}>
                                <thead>
                                    <tr>
                                        <th>Attribute</th>
                                        <th><FontAwesomeIcon icon="toggle-on" style={{'color': 'green'}}/> Active Slot</th>
                                        <th><FontAwesomeIcon icon="toggle-off" style={{'color': 'gray'}}/> Fallback Slot</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from(allPartitionNames).map((partitionName) => (
                                        <tr key={partitionName}>
                                            <td>{partitionName}</td>
                                            <td className="firmware-activeslot">
                                                    {activePartitions[partitionName] ? (
                                                            <div>
                                                                { (activePartitions[partitionName]).match(/\/dev\/(.+)/)?.[0] ? (
                                                                (activePartitions[partitionName]).match(/\/dev\/(.+)/)?.[0] ) : (
                                                                <div>
                                                                    { activePartitions[partitionName] }
                                                                    <br/>
                                                                    <Badge variant="secondary">not parseable</Badge>
                                                                </div> ) }
                                                            </div>
                                                        )
                                                        :
                                                        (
                                                        <Badge variant="secondary">not available</Badge>
                                                        )
                                                    }
                                            </td>
                                            <td className="firmware-inactiveslot">
                                                    {inactivePartitions[partitionName] ? (
                                                            <div>
                                                                { (inactivePartitions[partitionName]).match(/\/dev\/(.+)/)?.[0] ? (
                                                                (inactivePartitions[partitionName]).match(/\/dev\/(.+)/)?.[0] ) : (
                                                                <div>
                                                                    { inactivePartitions[partitionName] }
                                                                    <br/>
                                                                    <Badge variant="secondary">not parseable</Badge>
                                                                </div> ) }
                                                            </div>
                                                        )
                                                        :
                                                        (
                                                        <Badge variant="secondary">not available</Badge>
                                                        )
                                                    }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </>
            }
            else
            {
                return <></>;
            }
        }
        else
        {
            return  <Row className="dev-attributes-row">
                        <Col xs={12}>
                            <Alert variant="info">No Software update mechanism detected!</Alert>
                        </Col>
                    </Row>
        }
    }


    return (
        <div>
            <h4><FontAwesomeIcon icon="window-restore" /> Firmware Information reported by the device</h4>
            <Container>
                { renderFirmwareInformation() }
            </Container>
        </div>
    )
}