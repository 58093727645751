import React, { useState } from "react";
import { Row, Table, Badge, Button, Alert, Container, Col, Dropdown } from "react-bootstrap";
import DropdownButton from 'react-bootstrap/DropdownButton'
import "../Device.css";
import { ExternalLink } from 'react-external-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from "react-moment";
import "./FeatureHawkbit.css"
// React placeholder
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import {TextRow} from 'react-placeholder/lib/placeholders';
import { Link } from "react-router-dom";


export default function FeatureHawkbit(props) {
    const [fields, setFields] = useState({
        distribution_tag_filter: "gui_release",
    });
    const [showInfo, setShowInfo] = useState(true);

    function selectTag(tag) {
        setFields({
            distribution_tag_filter: tag,
        })
    }

    function MessageInfo() {
        if (props.message?.show && showInfo) {
          return (
            <Alert variant={props.message?.variant} onClose={() => setShowInfo(false)} dismissible>
              <Alert.Heading>{props.message?.headline}</Alert.Heading>
              {props.message?.body}
            </Alert>
          );
        }
        return <></>;
    }

    async function resyncHawkbit() {
        props.onHawkbitActions({
            action: "resyncHawkbit"
        })
    }

    function displayHawkbitFeature() {
        return (
            <div>
                <h4><FontAwesomeIcon icon="dot-circle" /> Hawkbit-Target Status</h4>
                
                <div><b>Status: </b>
                    {props.hawkbitData?.hawkbitStatus?.current_hawkbit_status?.status === 'OUT_OF_SYNC' ?
                        <div>
                            <p><Badge variant="danger">configration-file hash on target not matching</Badge> { props.hawkbitData?.hawkbitStatus?.current_hawkbit_status?.delta_status === 'NO_PENDING_JOB'?
                                <Button className="ml-auto" variant="primary" onClick={() => resyncHawkbit()} size="sm" ><FontAwesomeIcon icon="redo-alt" /> Resend Config File</Button>
                            : <></>
                            }</p>
                        </div>
                    : props.hawkbitData?.hawkbitStatus?.current_hawkbit_status?.status === 'ACTIVE'?
                    <Badge variant="success">Set up and active</Badge>
                    : props.hawkbitData?.hawkbitStatus?.current_hawkbit_status?.status === 'INACTIVE'?
                    <Badge variant="secondary">No Hawkbit Server configured</Badge>
                    : props.hawkbitData?.hawkbitStatus?.current_hawkbit_status?.delta_status ?
                    <Badge variant="warning">{props.hawkbitData?.hawkbitStatus?.current_hawkbit_status?.delta_status}</Badge>
                    : "No status data"
                    }
                </div>

                
                    {props.hawkbitData?.hawkbitStatus?.current_hawkbit_status?.delta_status !== 'NO_PENDING_JOB'?
                        <div><b>Hawkbit configuration change is ongoing: </b>
                            <Badge variant="warning">Status: {props.hawkbitData?.hawkbitStatus?.current_hawkbit_status?.delta_status}</Badge>
                        </div>: <></>
                    }
                
                <div>
                    {props.hawkbitData?.hawkbitStatus?.hawkbit_target?.pollStatus?.lastRequestAt ?
                        <div>
                        <b>Last poll: </b><Moment format="YYYY-MM-DD HH:mm:ss" tz="Europe/Berlin" utc local>
                                {props.hawkbitData?.hawkbitStatus?.hawkbit_target?.pollStatus?.lastRequestAt}
                            </Moment> &nbsp;
                            <Badge variant="info">
                                <Moment fromNow utc local>
                                    {props.hawkbitData?.hawkbitStatus?.hawkbit_target?.pollStatus?.lastRequestAt}
                                </Moment>
                            </Badge>
                        </div>
                    :
                        <div>
                            <b>Last poll: </b> Device never communicated to the Hawkbit server.
                        </div> }
                </div>
                <div><b>Description: </b>
                    {props.hawkbitData?.hawkbitStatus?.hawkbit_target?.description ?
                    props.hawkbitData?.hawkbitStatus?.hawkbit_target?.description : "No description"}
                </div>
                <div><b>Last poll from Target IP: </b>
                    {props.hawkbitData?.hawkbitStatus?.hawkbit_target?.address ?
                    props.hawkbitData?.hawkbitStatus?.hawkbit_target?.address : "No metadata"}
                </div>
                <div><b>Hawkbit Server (reported): </b>
                    <ExternalLink target="blank" href={props.hawkbitData?.hawkbitStatus?.current_hawkbit_status?.reported_hawkbit_server}>
                        <span> {props.hawkbitData?.hawkbitStatus?.current_hawkbit_status?.reported_hawkbit_server || "undefined"}  <FontAwesomeIcon icon="external-link-alt" /></span>
                    </ExternalLink>
                </div>
            </div>

            //     {/* <Row className="dev-attributes-row">
            //     <Col xs={6}>
            //         <ListGroup>
            //             <ListGroup.Item><b>Hawkbit Onboarding</b></ListGroup.Item>
            //             <ListGroup.Item><span className="listItemHeading">Desired Server</span><p>{props.hawkbitData?.current_hawkbit_status?.[0].desired_hawkbit_server}</p></ListGroup.Item>
            //             <ListGroup.Item><span className="listItemHeading">Reported Server</span><p>{props.hawkbitData?.feature_status_delta}</p></ListGroup.Item>
            //         </ListGroup>
            //         <br/>
            //         <ListGroup>
            //             <ListGroup.Item><b>Feature Dependent Info</b></ListGroup.Item>
            //             <ListGroup.Item><span className="listItemHeading">Delta Config</span><p>{props.featureHawkbit?.feature_dependent_info?.delta_config}</p></ListGroup.Item>
            //         </ListGroup>
            //     </Col>
            //     <Col xs={6}>
            //         <ListGroup>
            //             <ListGroup.Item><b>Current Config</b></ListGroup.Item>
            //             <ListGroup.Item><span className="listItemHeading">Auth Token</span><p>{props.featureHawkbit?.feature_dependent_info?.current_config?.auth_token}</p></ListGroup.Item>
            //             <ListGroup.Item><span className="listItemHeading">Hawkbit Server</span><p>{props.featureHawkbit?.feature_dependent_info?.current_config?.hawkbit_server}</p></ListGroup.Item>
            //             <ListGroup.Item><span className="listItemHeading">Log Level</span><p>{props.featureHawkbit?.feature_dependent_info?.current_config?.log_level}</p></ListGroup.Item>
            //             <ListGroup.Item><span className="listItemHeading">Mac Address</span><p>{props.featureHawkbit?.feature_dependent_info?.current_config?.mac_address}</p></ListGroup.Item>
            //             <ListGroup.Item><span className="listItemHeading">rauc_bundle_path</span><p>{props.featureHawkbit?.feature_dependent_info?.current_config?.rauc_bundle_path}</p></ListGroup.Item>
            //             <ListGroup.Item><span className="listItemHeading">Ssl</span><p>{props.featureHawkbit?.feature_dependent_info?.current_config?.ssl}</p></ListGroup.Item>
            //             <ListGroup.Item><span className="listItemHeading">Tenant ID</span><p>{props.featureHawkbit?.feature_dependent_info?.current_config?.tenant_id}</p></ListGroup.Item>
            //             <ListGroup.Item><span className="listItemHeading">Thing Name</span><p>{props.featureHawkbit?.feature_dependent_info?.current_config?.thing_name}</p></ListGroup.Item>
            //         </ListGroup>
            //     </Col>
            // </Row> */}
        );
    }

    async function updateTarget(distributionId) {
        props.onHawkbitActions({
            action: "updateTarget",
            distribution_id: distributionId
        })
    }

    async function reloadDeploymentList() {
        props.onHawkbitActions({
            action: "reloadDeploymentList"
        })
    }

    function getBundleRepositoryTable() {
        return (
            <div>
                <h4><FontAwesomeIcon icon="tasks" /> Available Firmware Images (Self Service Software Updates)</h4>
                <MessageInfo />
                <div className="card" >
                    <div className="card-header">
                        <Row>
                            <Col xs="auto">
                                <p>All Firmware Images created by members of your organisation.</p>
                            </Col>
                            <Col className="ml-auto" xs="auto">
                                <DropdownButton id="dropdown-basic-button" title={fields.distribution_tag_filter} size="sm" variant="info">
                                    {props.hawkbitData?.distributionTags?.length !== 0 ?
                                        props.hawkbitData?.distributionTags?.content?.map((tag, i) => (
                                            <Dropdown.Item as="button" key={i} onClick={() => selectTag(tag?.name)}>{tag?.name}</Dropdown.Item>
                                        ))
                                    :
                                        <Dropdown.Item>gui_release</Dropdown.Item>
                                    }
                                </DropdownButton>

                                {/* <Form.Control size="sm" as="select" placeholder="select"
                                    value={fields.distribution_tag_filter}
                                    onChange={handleFieldChange}>
                                    {}
                                    <option value={undefined}>gui_release</option>
                                    {tagSelection()}
                                </Form.Control> */}
                            </Col>
                        </Row>
                    </div>
                    <Table bordered>
                        <thead >
                            <tr key="images-head">
                                <th>RAUC Bundle</th>
                                <th>Type</th>
                                <th>Created at</th>
                                <th>Info</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.hawkbitData?.targetDistributions &&
                                props.hawkbitData?.targetDistributions?.map((distribution, index) => (
                                    <tr key={index}>
                                        <td>{distribution?.name} {distribution?.version === props.device?.rauc_status?.active?.bundle?.version ?
                                            <Badge variant="success">Currently Installed</Badge> : <></>}
                                        </td>
                                        <td>
                                            { distribution?.type?.includes('releasedist') ?
                                                <Badge variant="success">Release Distribution</Badge>
                                            :
                                                distribution?.type?.includes('testingdist') ?
                                                    <Badge variant="warning">Testing Distribution</Badge>
                                                :
                                                <Badge variant="secondary">{distribution?.type}</Badge>
                                            }
                                        </td>
                                        <td>
                                            <Moment format="YYYY-MM-DD HH:mm:ss" tz="Europe/Berlin" utc local>
                                                        {distribution?.createdAt}
                                            </Moment><br/>
                                            <Badge variant="info">
                                                    <Moment fromNow tz="Europe/Berlin" utc local>
                                                        {distribution?.createdAt}
                                                    </Moment>
                                            </Badge>
                                        </td>
                                        <td>
                                            <p>Version: <Badge variant="info">{distribution?.version}</Badge></p>
                                            <p>Type string: <Badge variant="info">{distribution?.type}</Badge></p>
                                            <p>Artifact Id: <Badge variant="info">{distribution?.id}</Badge> </p>
                                            <p>Complete: {distribution?.complete ? <Badge variant="success">Complete</Badge> : <Badge variant="warning">Not Complete</Badge>} </p>
                                            <p>Created by: <Badge variant="info">{distribution?.createdBy}</Badge></p>
                                            <p>Last modified at: <Badge variant="info"><Moment fromNow tz="Europe/Berlin" utc local>{distribution?.lastModifiedAt}</Moment></Badge></p>
                                            <p>Required Migration: {distribution?.requiredMigrationStep ? <Badge variant="warning">Migration required</Badge> : <Badge variant="success">No migration required</Badge>}</p>
                                        </td>
                                        <td>
                                            {distribution?.arranged_metadata?.compatibility ?
                                                distribution?.arranged_metadata?.compatibility === props.device?.rauc_status?.active?.bundle?.compatible ?
                                                    <div>
                                                        <p style={{"color": "green"}}>Compatibility strings match</p>
                                                        <br/>
                                                        <Button block variant="info" onClick={() => updateTarget(distribution.id)} >
                                                            <FontAwesomeIcon icon="rocket" /> Deploy to device
                                                        </Button>
                                                        <br/>
                                                        <p>Bundle compatibility string: <Badge variant="secondary">{distribution?.arranged_metadata?.compatibility || "UNKNOWN"}</Badge></p>
                                                        <p>Device compatibility string: <Badge variant="secondary">{props.device?.rauc_status?.active?.bundle?.compatible || "UNKNOWN"}</Badge></p>
                                                    </div>
                                                :   <div>
                                                        <p style={{"color": "red"}}>Compatibility strings don&apos;t match</p>
                                                        <br/>
                                                        <Button  block variant="danger" onClick={() => updateTarget(distribution.id)} >
                                                            <FontAwesomeIcon icon="rocket" /> Deploy to device
                                                        </Button>
                                                        <br/>
                                                        <p>Bundle compatibility string: <Badge variant="secondary">{distribution?.arranged_metadata?.compatibility || "UNKNOWN"}</Badge></p>
                                                        <p>Device compatibility string: <Badge variant="secondary">{props.device?.rauc_status?.active?.bundle?.compatible || "UNKNOWN"}</Badge></p>
                                                    </div>
                                            :
                                                <div>
                                                    <p style={{"color": "red"}}>Compatibility string is undefined</p>
                                                    <br/>
                                                    <Button  block variant="warning" onClick={() => updateTarget(distribution.id)} >
                                                        <FontAwesomeIcon icon="rocket" /> Deploy to device
                                                    </Button>
                                                    <br/>
                                                    <p>Bundle compatibility string: <Badge variant="secondary">{distribution?.arranged_metadata?.compatibility || "UNKNOWN"}</Badge></p>
                                                    <p>Device compatibility string: <Badge variant="secondary">{props.device?.rauc_status?.active?.bundle?.compatible || "UNKNOWN"}</Badge></p>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                    {props.hawkbitData?.targetDistributions?.length === 0 ?
                    <div>There are no available images for this device configured on the hawkbit server. Please contact our <Link to="/infoAndSupport" target="_blank">support</Link> if you think this might be an issue.</div>
                    : <></>}
                </div>
            </div>
        );
    }

    function getHawkbitDeploymentList() {
        return (
            <div>
                <h4><FontAwesomeIcon icon="list-alt" /> Firmware Update Log </h4>
                <div className="card">
                    <div className="card-header">
                        <Row>
                            <p>This table shows the 5 most recent software deployments registered from current hawkbit server.</p>
                            <Button className="ml-auto" variant="info" onClick={() => reloadDeploymentList()} size="sm" >
                                <FontAwesomeIcon icon="redo-alt" /> Refresh
                            </Button>
                        </Row>
                    </div>
                    <Table bordered>
                        <thead>
                            <tr key="deploy-head">
                                <th >Date</th>
                                <th >Action</th>
                                <th >Info</th>
                                <th >Update Message Log:</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.hawkbitDeploymentListData?.deploymentData ?
                                props.hawkbitDeploymentListData?.deploymentData.map((deployment, index) => (
                                <tr key={"deploy"+index}>
                                    <td>
                                        <Moment format="YYYY-MM-DD HH:mm:ss" tz="Europe/Berlin" utc local>
                                                    {deployment?.action?.createdAt}
                                        </Moment><br/>
                                        <Badge variant="info">
                                                <Moment fromNow tz="Europe/Berlin" utc local>
                                                    {deployment?.action?.createdAt}
                                                </Moment>
                                        </Badge>
                                    </td>
                                    <td>{deployment?.action?.type}<br/>
                                     {deployment?.action_states?.content[0].type === 'finished'?
                                        <Badge variant="success">{deployment?.action_states?.content[0].type}</Badge>
                                        : deployment?.action_states?.content[0].type === 'error'?
                                        <Badge variant="danger">Failed</Badge>
                                        : <Badge variant="warning">{deployment?.action_states?.content[0].type}</Badge>} <br/>
                                    </td>

                                    <td>
                                        Bundle Name: <br/>{deployment?.distribution?.name} <br/>
                                        Version:{deployment?.distribution?.version} <br/>
                                        Type: {deployment?.distribution?.type} <br/>
                                        Artifact ID: {deployment?.distribution?.id} <br/>
                                        Description: <br/>{deployment?.distribution?.description} <br/>
                                        <hr/>
                                        Deployment Info: <br/>
                                        Created by: <Badge variant="info">{deployment.action?.createdBy}</Badge>
                                        <br/>
                                        Last modified by: <Badge variant="info">{deployment.action?.lastModifiedBy}</Badge>
                                        <br/>
                                        Last modified at: <Badge variant="info"><Moment fromNow tz="Europe/Berlin" utc local>{deployment.action?.lastModifiedAt}</Moment></Badge>
                                        <br/>
                                        Force type: <Badge variant="info">{deployment.action?.forceType}</Badge>
                                    </td>
                                    <td>
                                        <ul className="scrolledList list-group">
                                            {deployment?.action_states?.content.map((state, index) => (
                                                <li key={index} className="list-group-item listItem">
                                                    <p>
                                                        <b><Moment format="YYYY-MM-DD HH:mm:ss" tz="Europe/Berlin" utc local>
                                                        {state.reportedAt}</Moment></b>: {state.type === "error" ? <Badge variant="danger">{state.type}</Badge>
                                                        : state.type === "running" ? <Badge variant="info">{state.type}</Badge>
                                                        : state.type === "finished" ? <Badge variant="success">{state.type}</Badge>
                                                        : state.type === "download" ? <Badge variant="primary">{state.type}</Badge>
                                                        : state.type === "retrieved" ? <Badge variant="light">{state.type}</Badge>
                                                        : <Badge variant="warning">{state.type}</Badge> }
                                                        <br/>
                                                        {state.messages.map((message, index) => (
                                                            <>
                                                                { (state.messages.length > 1) ? (
                                                                    <>
                                                                    <Row>
                                                                        <Col xs={1}>
                                                                            [{index + 1}]
                                                                        </Col>
                                                                        <Col xs={11}>
                                                                            {message}
                                                                        </Col>
                                                                    </Row>
                                                                    </>
                                                                    )
                                                                    : 
                                                                    <>
                                                                        {message}
                                                                    </>
                                                                }
                                                            </>
                                                        )) }
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </td>
                                </tr>
                                )): <></>}
                        </tbody>

                    </Table>
                    {props.hawkbitDeploymentListData?.deploymentData?.length === 0 ?
                        <div>There have been no software updates for this device so far. Please contact our <Link to="/infoAndSupport" target="_blank">support</Link> if you think this might be an issue.</div>
                    : <></>}
                </div>
            </div>
        );
    }


    return (
        <div>
            <br/>
            <hr/>
            <h4><FontAwesomeIcon icon="server" /> Hawkbit Server Integration</h4>
            <Container>
                <ReactPlaceholder showLoadingAnimation className='text-center' ready={!props.isLoadingHawkbitDeploymentListData} customPlaceholder={
                        <div className='text-center'>
                            <br/>
                            <TextRow color='#f2f2f3' style={{width: 'auto', height: 200}}/>
                            <br/>
                        </div>
                    }>
                    {displayHawkbitFeature()}
                </ReactPlaceholder>

                <ReactPlaceholder showLoadingAnimation className='text-center' ready={!props.isLoadingHawkbitData} customPlaceholder={
                        <div className='text-center'>
                            <br/>
                            <TextRow color='#e4e4e7' style={{width: 'auto', height: 40}}/>
                            <TextRow color='#f2f2f3' style={{width: 'auto', height: 350}}/>
                            <br/>
                        </div>
                    }>
                    {getBundleRepositoryTable()}
                </ReactPlaceholder>

                <ReactPlaceholder showLoadingAnimation className='text-center' ready={!props.isLoadingHawkbitDeploymentListData} customPlaceholder={
                        <div className='text-center'>
                            <br/>
                            <TextRow color='#e4e4e7' style={{width: 'auto', height: 40}}/>
                            <TextRow color='#f2f2f3' style={{width: 'auto', height: 400}}/>
                        </div>
                    }>
                    {getHawkbitDeploymentList()}
                </ReactPlaceholder>
            </Container>
        </div>
    )
}