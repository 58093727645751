import React from "react";
import {
    Row, Col, Form, Container
} from "react-bootstrap";
import { useFormFields } from "../../../libs/hooksLib";
import "../Device.css";
import "./DeviceManagement.css";
import LoaderButton from "../../LoaderButton";

export default function DeviceManagement(props) {
    const [fields, handleFieldChange] = useFormFields({
        device_owner: "",
        device_reseller: ""
    });

    function customerSelection() {
        return props.customers?.map((customer) => (
        <option key={customer?.customer_name} value={customer?.customer_name}>{customer?.customer_name}</option>
        ));
    }

    function validateDeviceAssignmentForm() {
        return fields.device_owner !== props.device?.device_owner ||
        fields.device_reseller !== props.device?.device_reseller;
    }

    async function handleDeviceAssignment(event) {
        event.preventDefault();

        props.onDeviceAssignment(
            {
                device_owner: fields.device_owner !== "" ? fields.device_owner : props.device?.device_owner,
                device_reseller: fields.device_reseller !== "" ? fields.device_reseller : props.device?.device_reseller
            }
        );
    }

    return (
        <div style={{marginBottom: "100px"}}>
            <Container>
                <form onSubmit={handleDeviceAssignment}>
                    <Row className="rowWidth">
                        <Col xs={4}>
                            <Form.Label>Owner</Form.Label>
                            {props.isCustomerRoleAdmin ?
                                <Form.Group controlId="device_owner">
                                    <Form.Control as="select" placeholder="select"
                                        value={ fields.device_owner === "" ? props.device?.device_owner : fields.device_owner}
                                        onChange={handleFieldChange}>
                                        <option value={undefined}>Please choose...</option>
                                        {customerSelection()}
                                    </Form.Control>
                                </Form.Group>
                                :
                                <Form.Group controlId="device_owner">
                                    <Form.Control
                                        type="device_owner"
                                        value={props.device?.device_owner || ""}
                                        disabled
                                    />
                                </Form.Group>
                                }
                        </Col>
                        <Col xs={4}>
                            <Form.Label>Reseller</Form.Label>
                            {props.isCustomerRoleAdmin ?
                                <Form.Group controlId="device_reseller">
                                    <Form.Control as="select" placeholder="select"
                                        value={fields.device_reseller === "" ?  props?.device?.device?.device_reseller : fields.device_reseller}
                                        onChange={handleFieldChange}>
                                        <option value={undefined}>Please choose...</option>
                                        {customerSelection()}
                                    </Form.Control>
                                </Form.Group>
                                :
                                <Form.Group controlId="device_reseller">
                                    <Form.Control
                                        type="device_reseller"
                                        value={props.device?.device_reseller || ""}
                                        disabled
                                    />
                                </Form.Group>
                                }
                            </Col>
                            <Row>
                            {props.isCustomerRoleAdmin ?
                                <Col xs={4}>
                            <div className="saveButton">
                                <LoaderButton
                                block
                                type="submit"
                                isLoading={props.isLoadingAssignment}
                                disabled={!validateDeviceAssignmentForm()}
                                >
                                Save Changes
                                </LoaderButton>
                            </div> </Col> : <></>}
                            </Row>
                    </Row>
                </form>
            </Container>
        </div>
    )
}