import React from "react";
import { Container, Col, Row, Image, Alert } from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import './Privacy.css'
import { Link } from "react-router-dom";

export default function Privacy() {

    function showInfo(){
        return (
            <div>
                <Row className="text-center">
                <Col xs={12}><Image style={{"marginBottom": "15px", width: 400, height: 'auto'}} src="/images/underConstraction.png"/></Col>
                </Row>
                <Alert variant="secondary">
                    <hr />
                    <p>
                        This section is under Construction! Please <a href="mailto:support@iot-suite.io?subject=IoT%20Device%20Management%20Support%20Request"> contact our support</a> for more informations.<br/>


                    </p>
                    <hr />
                </Alert>
            </div>
        )
    }

    return (
        <div>
            <Container className='privacy'>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Privacy</Breadcrumb.Item>
                </Breadcrumb>
                <h1>Privacy</h1>
                {showInfo()}
            </Container>
        </div>
    );
}