import React, { useState } from "react";
import TableFilter from 'react-table-filter';
import "../../../../../node_modules/react-table-filter/lib/styles.css";
import { Table, ListGroup } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";
import "./CustomersTable.css";

export default function CustomersTable(props) {
    const [filteredCustomers, setFilteredCustomers] = useState(null);

    function filterUpdated(newData) {
        setFilteredCustomers(newData);
    }

    function renderCustomersTable() {
        return (
            <Table striped bordered hover>
                <thead>
                    <TableFilter
                        rows={props.customers}
                        onFilterUpdate={filterUpdated.bind(this)}>
                        <th key="customer_name" filterkey="customer_name">Customer Name</th>
                        <th key="parent" filterkey="parent" >Parent</th>
                        <th key="groups" filterkey="groups">Customer Groups</th>
                        <th key="communication">Contact Details (Email)</th>
                        <th key="features">Available Features</th>
                        <th key="hawkbit-servers">Hawkbit Servers</th>
                    </TableFilter>
                </thead>
                <tbody>
                    {renderTableRows()}
                </tbody>
            </Table>
        );
    }

    function displayGroups(groups) {
        if (groups) {
            return groups.map((group, i) => (
                <p key={i}>{group}</p>
            ));
        } else {
            return <></>;
        }
    }

    function displayCommunication(communication) {
        if (communication) {
            return (
            <div>
                <p className="inTableLabel">Error</p><p>{communication.mail_error}</p>
                <p className="inTableLabel">Feature</p><p>{communication.mail_feature}</p>
                <p className="inTableLabel">Info</p><p>{communication.mail_info}</p>
                <p className="inTableLabel">Warning</p><p>{communication.mail_warn}</p>
            </div>
        );
        } else {
            return <></>;
        }
    }

    function displayFeatures(features) {
        if (features && features.remote_connection) {
            return (
            <div>
                <p className="inTableLabel">Remote Connection</p>
                <p>Tunnel Port Start: {features.remote_connection.tunnel_remote_port || ''}</p>
                <p>Tunnel Port Range: {features.remote_connection.tunnel_remote_range || ''}</p>
                <p>Tunnel Port Reservation: {features.remote_connection.tunnel_remote_reservation || ''}</p>
                <p>Tunnel Server: {features.remote_connection.tunnel_server || ''}</p>

            </div>
        );
        } else {
            return <></>;
        }
    }

    function displayHawkbitServers(features) {
        if (features && features.hawkbit) {
            return (
                <div>
                    <p className="inTableLabel">Default Hawkbit Server</p>
                    <p>{features.hawkbit.hawkbit_servers[0]? features.hawkbit.hawkbit_servers[0] : "unknown"}</p>
                </div>
                );
        } else {
            return <></>;
        }
    }

    function renderTableRows() {
        const customers = filteredCustomers !== null ? filteredCustomers : props.customers;
        return [{}].concat(customers).map((customer, i) =>
            i !== 0 ? (
                // <LinkContainer key={customer.customer_name} to={{pathname: `/customers/${customer.customer_name}/${customer.parent}`, param: customer}}>
                    <tr key={customer.customer_name} to={`/iot-customer_config/${customer.customer_name}`}>
                        <td>{customer.customer_name.trim().split("\n")[0]}</td>
                        <td>{customer.parent.trim().split("\n")[0]}</td>
                        <td>{displayGroups(customer.groups)}</td>
                        <td>{displayCommunication(customer.communication)}</td>
                        <td>{displayFeatures(customer.features)}</td>
                        <td>{displayHawkbitServers(customer.features)}</td>
                    </tr>
                // </LinkContainer>
            ) : (<tr key={i}></tr>)
        );
    }

    return (
        <ListGroup>
            <div className="responsiveTableCustomersTable">
                {renderCustomersTable()}
            </div>
        </ListGroup>
    )
}
