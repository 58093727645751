import React from "react";
import {
    Row, Col, Badge, Container, Alert
} from "react-bootstrap";
import "../Device.css";
import { ExternalLink } from 'react-external-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment';
import 'moment-timezone';
import GaugeChart from 'react-gauge-chart'
import 'react-edit-text/dist/index.css';
// import Markdown from 'react-markdown';
// import rehypeRaw from 'rehype-raw';
// import rehypeSanitize from 'rehype-sanitize';
import "./DeviceStatus.css";


export default function DeviceStatus(props) {
    
    function convert_to_string(num) {
        if (num === "0") {
            return "ESEC IoT Testing"
        } else if (num === "2") {
            return "ISOconnect ECU"
        } else if (num === "1"){
            return "ISOconnect Incab"
        }
        else if (num === "99"){
                return "ESEC IoT Demonstrator"
            }
        else return num
    }

    function render_firmware_row() {

        const shadowStateData = props?.iotCoreData?.state?.reported;

        if(!shadowStateData)
        {
            return  <Row className="dev-attributes-row">
                        <Col xs={12}>
                            <h5>Firmware: </h5>
                            <Alert variant="info">No Software update mechanism detected!</Alert>
                        </Col>
                    </Row>
        }

        const checkForSwUpdateObj = (() => {
            for (const key in shadowStateData)
            {
                if(key.startsWith("swup_"))
                {
                    return true;
                }
            }
            return false;
        });

        if ("rauc" in shadowStateData)
        {
            return <div>
            <Row className="dev-attributes-row">
                <Col xs={12}><h5>Firmware: rauc</h5></Col>
            </Row>
            <Row className="dev-attributes-row">
                <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="code" /> Current Firmware:</div>
                    {props.device?.rauc_status?.active?.bundle?.version}
                    <br/><Badge variant="secondary">Build: {props.device?.rauc_status?.active?.bundle.build}</Badge>
                </Col>
                <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="hourglass-half" /> Last Firmware Update:</div>{props.device?.rauc_status?.active?.rootfs["installed.timestamp"]} <br/> <Badge variant="info"><Moment fromNow parse="YYYY-MM-DDTHH:mm:ssZ" utc local>{props.device?.rauc_status?.active?.rootfs["installed.timestamp"]}</Moment></Badge></Col>
                <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="tag" /> RAUC Compatible:</div>{props.device?.rauc_status?.active?.bundle?.compatible}</Col>
                <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="clone" /> RAUC Slot:</div>{props.device?.rauc_status?.active?.slot === "0" ? <div> <Badge variant="success">Slot 0 (active)</Badge> <Badge variant="secondary">Slot 1 (fallback)</Badge> </div>: <div><Badge variant="secondary">Slot 0 (fallback)</Badge> <Badge variant="success">Slot 1 (active)</Badge></div>}</Col>
            </Row>
            </div>
        }
        else if(checkForSwUpdateObj())
        {
            return <div>
                <Row className="dev-attributes-row">
                    <Col xs={12}><h5>Firmware: swupdate</h5></Col>
                </Row>
                <Row className="dev-attributes-row">
                <Col xs={2}>
                    <div className="dev-attributes-heading"><FontAwesomeIcon icon="tag" /> Kernel version:</div>
                    {shadowStateData.swup_installed_kernel_ver ? (
                            <div>
                                { (shadowStateData.swup_installed_kernel_ver).match(/^.*?((?:\d+\.)*\d+)$/)?.[1] ? (
                                  (shadowStateData.swup_installed_kernel_ver).match(/^.*?((?:\d+\.)*\d+)$/)?.[1] ) : (
                                <div>
                                    { shadowStateData.swup_installed_kernel_ver }
                                    <br/>
                                    <Badge variant="secondary">version not parseable</Badge>
                                </div> ) }
                            </div>
                        )
                        :
                        (
                        <Badge variant="secondary">not available</Badge>
                        )
                    }
                </Col>
                <Col xs={2}>
                    <div className="dev-attributes-heading"><FontAwesomeIcon icon="tag" /> Root FS version:</div>
                    {shadowStateData.swup_installed_rootfs_ver ? (
                            <div>
                                { (shadowStateData.swup_installed_rootfs_ver).match(/^.*?((?:\d+\.)*\d+)$/)?.[1] ? (
                                  (shadowStateData.swup_installed_rootfs_ver).match(/^.*?((?:\d+\.)*\d+)$/)?.[1] ) : (
                                <div>
                                    { shadowStateData.swup_installed_rootfs_ver }
                                    <br/>
                                    <Badge variant="secondary">version not parseable</Badge>
                                </div> ) }
                            </div>
                        )
                        :
                        (
                        <Badge variant="secondary">not available</Badge>
                        )
                    }
                </Col>
                <Col xs={2}>
                    <div className="dev-attributes-heading"><FontAwesomeIcon icon="clock" /> Last Installed:</div>
                    {shadowStateData.swup_last_updated_ts ? 
                     ( <div>
                        <Moment tz="Europe/Berlin" utc local format="YYYY-MM-DD HH:mm:ss" parse="YYYY-MM-DD HH:mm:ss">{shadowStateData.swup_last_updated_ts.trim().split("\n")[0]}</Moment>
                        <br/>
                        <Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DD HH:mm:ss">{shadowStateData.swup_last_updated_ts.trim().split("\n")[0]}</Moment></Badge>
                      </div> ) : (
                        <Badge variant="secondary">not available</Badge>
                      ) }
                </Col>
                <Col xs={2}>
                    <div className="dev-attributes-heading"><FontAwesomeIcon icon="tag" /> Total updates:</div>
                    { shadowStateData.swup_total_updates ? (
                        shadowStateData.swup_total_updates ) : (
                            <Badge variant="secondary">not available</Badge>
                        )
                    }
                </Col>
                <Col xs={2}>
                    <div className="dev-attributes-heading"><FontAwesomeIcon icon="tag" /> Boot partition:</div>
                    { shadowStateData.swup_act_uboot ? (
                        (shadowStateData.swup_act_uboot).split("/").pop() ) : (
                            <Badge variant="secondary">not available</Badge>
                        )
                    }
                </Col>
                <Col xs={2}>
                    <div className="dev-attributes-heading"><FontAwesomeIcon icon="tag" /> Fallback partition:</div>
                    { shadowStateData.swup_inact_uboot ? (
                        (shadowStateData.swup_inact_uboot).split("/").pop() ) : (
                            <Badge variant="secondary">not available</Badge>
                        )
                    }
                </Col>
            </Row>
            </div>
        }
        else
        {
            return <></>;
        }
    }

    return (
            <div style={{marginBottom: "100px"}}>
            <Container>
                <Row className="dev-attributes-row">
                <Col xs={12}><h5>General Information</h5></Col>
                </Row>
                <Row className="dev-attributes-row">
                    <Col xs={2}><div className="dev-attributes-heading"><FontAwesomeIcon icon="microchip" /> Device ID:</div> {props.device?.device_name} <br/><Badge variant="primary">{ convert_to_string(props.device?.device_type) }</Badge></Col>
                    <Col xs={2}><div className="dev-attributes-heading"><FontAwesomeIcon icon="clock" /> Last Seen:</div>{props.device?.last_seen ? <Moment tz="Europe/Berlin" utc local format="YYYY-MM-DD HH:mm:ss" parse="YYYY-MM-DD HH:mm:ss">{props.device?.last_seen?.trim().split("\n")[0]}</Moment> : ''}<br/><Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DD HH:mm:ss">{props.device?.last_seen?.trim().split("\n")[0]}</Moment></Badge></Col>
                    <Col xs={2}><div className="dev-attributes-heading"><FontAwesomeIcon icon="heartbeat" /> Health Status:</div>Shadow # {props.device?.shadow_version}</Col>
                    <Col xs={2}><div className="dev-attributes-heading"><FontAwesomeIcon icon="cloud-upload-alt" /> iot rocket&apos;s IoT Client:</div>{props.device?.iot_client}<br/>{props.device?.timer ? <Badge variant="info">Period: {props.device?.timer} </Badge> : <></>}</Col>
                <Col xs={2}><div className="dev-attributes-heading"><FontAwesomeIcon icon="clock" /> Onboarding timestamp:</div>
                {props.device?.onboarding_timestamp? 
                                ( <>
                                    <Moment tz="Europe/Berlin" unix local format="YYYY-MM-DD HH:mm:ss">{props.device?.onboarding_timestamp}</Moment>
                                    <br/>
                                    <Badge variant="info"><Moment tz="Europe/Berlin" unix local fromNow>{props.device?.onboarding_timestamp}</Moment></Badge>
                                </> ) : (
                                    <Badge variant="secondary">not available</Badge>
                                ) }
                                </Col>
                                <Col xs={2}><div className="dev-attributes-heading"><FontAwesomeIcon icon="clock" /> Activation timestamp:</div>
                {props.device?.onboarding_timestamp? 
                                ( <>
                                    <Moment tz="Europe/Berlin" unix local format="YYYY-MM-DD HH:mm:ss">{props.device?.shadow_version_15_timestamp}</Moment>
                                    <br/>
                                    <Badge variant="info"><Moment tz="Europe/Berlin" unix local fromNow>{props.device?.shadow_version_15_timestamp}</Moment></Badge>
                                </> ) : (
                                    <Badge variant="secondary">not available</Badge>
                                ) }
                                </Col>
                </Row>
                <hr/>
                <Row className="dev-attributes-row">
                <Col xs={12}><h5>System Usage</h5></Col>
                </Row>
                <Row className="dev-attributes-row">
                    <Col xs={2}>
                        <GaugeChart id="gauge-logfs"
                            nrOfLevels={20}
                            colors={['#5BE12C', '#F5CD19', '#EA4228']}
                            textColor="#101010"
                            percent={ props.device?.device_health?.cpu?.avg_15min/5 }
                            className="gauge-device-overview"
                            formatTextValue = {value => value*5/100}
                            />
                        <p style={{"textAlign":"center"}}>Load (CPU)</p>
                    </Col>
                    <Col xs={2}>
                    <GaugeChart id="gauge-ram"
                        nrOfLevels={20}
                        colors={['#5BE12C', '#F5CD19', '#EA4228']}
                        textColor="#101010"
                        percent={Math.round(100-100*props.device?.device_health?.memory?.mem_a / props.device?.device_health?.memory?.mem_t) /100}
                        //formatTextValue = {value => "RAM " + value/4 + '%'}
                        className="gauge-device-overview"
                        />
                     <p style={{"textAlign":"center"}}>RAM</p>
                    </Col>

                    <Col xs={2}>
                    <GaugeChart id="gauge-rootfs"
                        nrOfLevels={20}
                        colors={['#5BE12C', '#F5CD19', '#EA4228']}
                        textColor="#101010"
                        percent={props.device?.device_health?.fs_root?.used_perc.replace("%","")/100}
                        className="gauge-device-overview"
                        />
                    <p style={{"textAlign":"center"}}>RootFS (SOM)</p>
                    </Col>
                    <Col xs={2}>
                    <GaugeChart id="gauge-appfs"
                        nrOfLevels={20}
                        colors={['#5BE12C', '#F5CD19', '#EA4228']}
                        textColor="#101010"
                        percent={props.device?.device_health?.fs_app?.used_perc.replace("%","")/100}
                        className="gauge-device-overview"
                        />
                    <p style={{"textAlign":"center"}}>AppFS (SOM)</p>
                    </Col>
                    <Col xs={2}>
                    <GaugeChart id="gauge-confs"
                        nrOfLevels={20}
                        colors={['#5BE12C', '#F5CD19', '#EA4228']}
                        textColor="#101010"
                        percent={props.device?.device_health?.fs_cnf?.used_perc.replace("%","")/100}
                        className="gauge-device-overview"
                        />
                    <p style={{"textAlign":"center"}}>ConfFS (SOM)</p>
                    </Col>
                    <Col xs={2}>
                    <GaugeChart id="gauge-datafs"
                        nrOfLevels={20}
                        colors={['#5BE12C', '#F5CD19', '#EA4228']}
                        textColor="#101010"
                        percent={props.device?.device_health?.fs_data?.used_perc.replace("%","")/100}
                        className="gauge-device-overview"
                        />
                    <p style={{"textAlign":"center"}}>Data (SD-Card)</p>
                    </Col>
                </Row>
                <hr/>
                { render_firmware_row() }
                <hr/>
                <Row className="dev-attributes-row">
                    <Col xs={12}><div className="dev-attributes-heading"><FontAwesomeIcon icon="cloud" /> Hawkbit Server:</div>
                    <ExternalLink target="blank" href={props.hawkbitData?.hawkbitStatus?.current_hawkbit_status?.reported_hawkbit_server} > {props.hawkbitData?.hawkbitStatus?.current_hawkbit_status?.reported_hawkbit_server}
                    </ExternalLink>
                    </Col>
                </Row>
                <hr/>
                <Row className="dev-attributes-row">
                <Col xs={12}><h5>Connectivity</h5></Col>
                </Row>
                <Row className="dev-attributes-row">
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="sim-card" /> SIM-ID:</div>{props.device?.simno?.substring(0,19) || 'unknown'}</Col>
                    <Col xs={2}><div className="dev-attributes-heading"><FontAwesomeIcon icon="long-arrow-alt-right" /> VPN-IP:</div>{props.whereverSimData?.endpoint?.ip_address || 'unknown'}</Col>
                    <Col xs={4}>
                    {props.remoteConnectionData?.statusData?.status === 'ACTIVE'?
                        <div>
                            <div className="dev-attributes-heading">
                                <FontAwesomeIcon icon="terminal" /> Remote Access: <Badge variant="success">{props.remoteConnectionData?.statusData?.status}</Badge>
                            </div>{ "ssh -p " + (props?.remoteConnectionData?.statusData?.reported_device_port) + " <user>@remote2.aws.iot-suite.io" }
                        </div>
                        : props.remoteConnectionData?.statusData?.status === 'INACTIVE'?
                            <div className="dev-attributes-heading">
                                <FontAwesomeIcon icon="terminal" /> Remote Access: <Badge variant="warning">{props.remoteConnectionData?.statusData?.status}</Badge>
                            </div>
                        :
                            <div className="dev-attributes-heading">
                                <FontAwesomeIcon icon="terminal" /> Remote Access: <Badge variant="secondary">Unknown</Badge>
                            </div>
                        }
                    </Col>
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="satellite" /> Modem Variant:</div>{props.device?.modem_variant ? props.device?.modem_variant === 'NOT_SET'? 'NOT SET' : props.device?.modem_variant : 'NOT SET'}</Col>
                </Row>

                <hr/>

                <Row className="dev-attributes-row">
                    <Col xs={12}><h5>Ownership and Administration</h5></Col>
                </Row>
                <Row className="dev-attributes-row">
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="user" /> Owner:</div>{props.device?.device_owner}</Col>
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="user-friends" /> Reseller:</div>{props.device?.device_reseller}</Col>
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="hand-holding-heart" /> Admin:</div>{props.device?.admin}</Col>
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="industry" /> ECU-Manufacturer:</div>{props.device?.oem}</Col>
                </Row>

                <hr/>

                <Row className="dev-attributes-row">
                <Col xs={12}><h5>Integrations</h5></Col>
                </Row>
                <Row className="dev-attributes-row">
                    <Col xs={3}>
                    <div className="dev-attributes-heading"><FontAwesomeIcon icon="sim-card" /> WhereverSIM</div>
                    { props.whereverSimData?.sim?.iccid?
                        <Badge variant="success"> <FontAwesomeIcon icon="check"/> Configured: {props.whereverSimData?.sim?.customer_org?.id} </Badge>
                        : <Badge variant="secondary">not configured</Badge>
                    }
                    </Col>

                </Row>

            </Container>
            </div>
    )
}