import React , { useState, useEffect } from "react";
import {
    Button, Form, Row, Col, Container, Alert
} from "react-bootstrap";
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import { useFormFields } from "../../../libs/hooksLib";
import CreatableSelect from 'react-select/creatable';

import "../Device.css";


const isValidNewOption = ((inputValue, value) => {
    let returnValue = true;
    value.forEach((val) => {
        if (inputValue.toLowerCase() === val.value.toLowerCase()) { returnValue = false; }
    });
    return returnValue;
});

const createTagValue = (label) => ({
    label: label,
    value: label,
  });

const parseTagsIntoArray = (tagsString) =>
    {
        if(!tagsString)
        {
            return [];
        }

        return tagsString.split('_').filter(value => value.trim() !== '').map((tag) => {
            return createTagValue(tag);
        });
    }

export default function DeviceMeta(props) {
    const [customname_fields, handleCustomNameFieldChange] = useFormFields({
        custom_device_name: props.device?.custom_name, 
    });

    const [usercomment_fields, handleCustomCommentFieldChange] = useFormFields({
        user_remark: "", 
    });

    const [admincomment_fields, handleAdminCommentFieldChange] = useFormFields({
        admin_remark: "", 
    });

    const [showInfo, setShowInfo] = useState(true);

    // device tags variables
    const [inputDeviceTagField, setInputDeviceTagField] = useState("");
    const [deviceTagValues, setDeviceTagValues] = useState();

    useEffect(() => {
        setDeviceTagValues(parseTagsIntoArray(props.deviceTagsData?.Items[0]?.tags))
      }, [props.deviceTagsData?.Items[0]?.tags]);

    async function updateCustomDeviceName(){
        setShowInfo(true);
        props.onMetaDataChange({
            action: "updateCustomDeviceName",
            custom_device_name: customname_fields.custom_device_name
        })
    }

    async function updateUserComment(){
        props.onMetaDataChange({
            action: "updateUserRemark",
            user_remark: usercomment_fields.user_remark,
            comment_prev: props.device?.user_remark
        })
    }

    
    async function clearUserComment(){
        props.onMetaDataChange({
            action: "updateUserRemark",
            user_remark: "CLEARING HISTORY",
            comment_prev: ""
        })
    }

    async function updateAdminComment(){
        setShowInfo(true);
        props.onMetaDataChange({
            action: "updateAdminRemark",
            admin_remark: admincomment_fields.admin_remark,
            comment_prev: props.device?.admin_remark
        })
    }
    async function clearAdminComment(){
        setShowInfo(true);
        props.onMetaDataChange({
            action: "updateAdminRemark",
            admin_remark: "CLEARING HISTORY",
            comment_prev: ""
        })
    }

    
    function validateDeviceName() {
        var re = new RegExp("^[a-zA-Z0-9_.-]{1,255}$")
        return re.test(customname_fields.custom_device_name)
    }
          

    function MessageInfo() {
        if (props.metaDataMessage?.show && showInfo) {
          return (
            <Alert variant={props.metaDataMessage?.variant} onClose={() => setShowInfo(false)} dismissible>
              <Alert.Heading>{props.metaDataMessage?.headline}</Alert.Heading>
              {props.metaDataMessage?.body}
            </Alert>
          );
        }
        return <></>;
      }

    const handleKeyDownDeviceTagInput = async (event) => {

        if (!inputDeviceTagField) return;
        switch (event.key) {
          case 'Enter':
            {
                const backupTagList = deviceTagValues;

                const newTagValue = createTagValue(inputDeviceTagField);
                if (!deviceTagValues.some((tag) => tag.value === newTagValue.value))
                {
                    // if it is NOT duplicate => add
                    setDeviceTagValues((prev) => [...prev, newTagValue]);
                }
                setInputDeviceTagField("");
                event.preventDefault();

                // still do API call with duplicates (nothing should happen)
                props.onDeviceTagAction({
                    action: "addTag",
                    device: props.device?.uid,
                    value: [ inputDeviceTagField ]
                }).then(retval => {
                    // retval is returned as an indicator if API call was successful or not.
                    if(!retval)
                    {
                        // tag API call failed. Remove tag from the list (go to backup list)
                        setDeviceTagValues(backupTagList);
                    }
                    else
                    {
                        // tag API call succesfful. It could be nice to handle duplicate in API call and also here
                    }
                });
            }
        }
    };

    const handleOnChange = ((newValue, actionMeta) => {
        if(actionMeta.action === "remove-value" || actionMeta.action === "pop-value")
        {
            const backupTagList = deviceTagValues;

            const newTagsList = deviceTagValues.filter((item) => item.value !== actionMeta.removedValue.value);
            setDeviceTagValues(newTagsList);

            props.onDeviceTagAction({
                action: "deleteTag",
                device: props.device?.uid,
                value: [ actionMeta.removedValue.value ]
            }).then((retval) => {
                if(!retval)
                {
                    // tag deletion API call failed. Add tag back to the list.
                    setDeviceTagValues(backupTagList);
                }
            });
        }
    });

    return (
            <div style={{marginBottom: "100px"}}>
                <MessageInfo />
                <Container>
                    <Row className="dev-attributes-row">
                        <Col xs={6}>
                            <Row>
                                <Col xs={12}><h5>Custom Device Name</h5></Col>
                            </Row>
                            <Row>
                                <Col xs="8">
                                <Form.Group controlId="custom_device_name">
                                    <Form.Control
                                    autoFocus
                                    type="text"
                                    placeholder="Custom Device Name"
                                    value={customname_fields.custom_device_name}
                                    onChange={handleCustomNameFieldChange}
                                    />
                                    <Form.Label>Hint: No whitespaces allowed</Form.Label>
                                </Form.Group>
                                </Col>
                                <Col xs="4">
                                    <Button
                                        className="btn btn-success"
                                        disabled={!validateDeviceName()}
                                        type="submit"
                                        onClick={updateCustomDeviceName}
                                        block>
                                        Set Custom Name
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={6}>
                            <Row>
                                <Col xs={12}><h5>Device tags</h5></Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <CreatableSelect
                                    components={{ DropdownIndicator:() => null, ClearIndicator:() => null }}
                                    menuIsOpen={false}
                                    isClearable
                                    onInputChange={(newValue) => setInputDeviceTagField(newValue)}
                                    onChange={(newValue, actionMeta) => { handleOnChange(newValue, actionMeta) }}
                                    inputValue={inputDeviceTagField}
                                    isMulti
                                    isValidNewOption={isValidNewOption}
                                    onKeyDown={handleKeyDownDeviceTagInput}
                                    value={deviceTagValues}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr/>

                    <Row className="dev-attributes-row">
                    <Col xs="6">
                    <h5>Owner Remarks</h5>

                    <Form.Group className="remark-box-input" controlId="user_remark">
                        <Form.Label>MARKDOWN Syntax can be used</Form.Label>
                        <Form.Control as="textarea" rows={6} 
                            onChange={handleCustomCommentFieldChange}
                            value={usercomment_fields.user_remark}
                        />
                    </Form.Group>

                    <Button
                                className="btn btn-info"
                                //disabled={!validateForm()}
                                type="submit"
                                onClick={updateUserComment}
                                block>
                                Submit Comment
                            </Button>
                    

                    <Markdown className="customer-remark-box"
                        rehypePlugins={[rehypeRaw, rehypeSanitize]}>
                        {props.device?.user_remark || ""}
                    </Markdown>
                    <Button
                        variant="outline-danger"
                        size="sm"
                        //disabled={!validateForm()}
                        type="submit"
                        onClick={clearUserComment}
                        block>
                        Clear Comment History
                    </Button>
                    </Col>
                    {props.isCustomerRoleAdmin? 
                        <Col xs="6">
                        <h5>Admin Remarks</h5>
                        <Form.Group className="remark-box-input" controlId="admin_remark">
                            <Form.Label>MARKDOWN Syntax can be used</Form.Label>
                            <Form.Control as="textarea" rows={6} 
                                onChange={handleAdminCommentFieldChange}
                                value={admincomment_fields.admin_remark}
                                type="text"
                            />
                        </Form.Group>
                        <Button
                                    className="btn btn-info"
                                    //disabled={!validateForm()}
                                    type="submit"
                                    onClick={updateAdminComment}
                                    block>
                                    Submit Comment
                                </Button>
                                
                        <Markdown className="admin-remark-box"
                            rehypePlugins={[rehypeRaw, rehypeSanitize]}>
                            {props.device?.admin_remark || ""}
                        </Markdown>
                        <Button
                                    variant="outline-danger"
                                    size="sm"
                                    //disabled={!validateForm()}
                                    type="submit"
                                    onClick={clearAdminComment}
                                    block>
                                    Clear Comment History 
                                </Button>
                        </Col>
                        : 
                        <></>}
                        
                    </Row>
                </Container>
            </div>

    )
}