import React, { useEffect, useState } from "react";
import { Form, Alert, Breadcrumb, Container, Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "./ActivateTOTP.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Grid from '@material-ui/core/Grid';
import QRCode from 'qrcode.react';

export default function ActivateTOTP() {
    const [isLoadingQRCode, setIsLoadingQRCode] = useState(false);
    const [isLoadingVerifyCode, setIsLoadingVerifyCode] = useState(false);
    const [isLoadingUser, setIsLoadingUser] = useState(false);
    const [isTokenVerificationComplete, setIsTokenVerificationComplete] = useState(false);
    const [qrCode, setQrCode] = useState();
    const [plainCode, setPlainCode] = useState();
    const [currentAuthUser, setCurrentAuthUser] = useState([]);
    const [errorMessageQRCode, setErrorMessageQRCode] = useState();
    const [errorMessageVerifyCode, setErrorMessageVerifyCode] = useState();
    const [errorMessageRegenarateQRCode, setErrorMessageRegenarateQRCode] = useState();
    const [fields, handleFieldChange] = useFormFields({
        totp_password: ""
    });


    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function validateForm() {
        return ( fields.totp_password.length > 5 );
    // TODO: Check if input is exactly 6 DIGITS and all are numbers.
    }

    useEffect(() => {
		async function onLoad() {
			try {
                setIsLoadingUser(true);
                const user = await Auth.currentAuthenticatedUser();
                // console.log(user);
                setCurrentAuthUser(user);
			}
			catch (e) {
				alert(e);
			}
		}
        onLoad();
        setIsLoadingUser(false);
	}, []);

    async function handleQRCodeGenerator(event) {
        event.preventDefault();

        setIsLoadingQRCode(true);

        try {
            if (currentAuthUser?.preferredMFA === 'SOFTWARE_TOKEN_MFA') {
                try {
                    await Auth.setPreferredMFA(currentAuthUser, 'NOMFA');
                    setErrorMessageRegenarateQRCode(
                        <Alert variant="success">
                            <p>Your old TOTP-Token has been deactivated. A new one will get generated.</p>
                        </Alert>
                    );
                } catch (e) {
                    setErrorMessageRegenarateQRCode(
                        <Alert variant="success">
                            <p>{e.message}</p>
                        </Alert>
                    );
                }
            }

            await Auth.setupTOTP(currentAuthUser).then(code => {
                        const generatedQrCode = 'otpauth://totp/AWSCognito:' + currentAuthUser.username + '?secret=' + code + '&issuer=IoT-Suite.io ('+currentAuthUser.attributes.email+')';
                        setQrCode(generatedQrCode);
                        setPlainCode(code);
                    });
            setIsLoadingQRCode(false);
            setErrorMessageQRCode(
                <Alert variant="success">
                    <p>Please scan the QR Code above with your authentication app and enter the current 6 digit code in the field below.</p>
                </Alert>
            );
            await timeout(5000);
        } catch (e) {
            setErrorMessageQRCode(
                <Alert variant="danger">
                    <p>{e.message}</p>
                </Alert>
            );
            setIsLoadingQRCode(false);
        }
    }

    async function handleVerifyCode(event) {
        event.preventDefault();
        setIsLoadingVerifyCode(true);

        await Auth.verifyTotpToken(currentAuthUser, fields.totp_password).then(async () => {

            // don't forget to set TOTP as the preferred MFA method
            Auth.setPreferredMFA(currentAuthUser, 'TOTP');

            setIsLoadingVerifyCode(false);
            setIsTokenVerificationComplete(true);
            await timeout(5000);
        }).catch( e => {
            setErrorMessageVerifyCode(
                <Alert variant="danger">
                    <p>{e.message}</p>
                </Alert>
            );
            setIsLoadingVerifyCode(false);
        });
    }

    function renderActivateTOTPForm() {
        return (
            <div>
                <div className="description">
                    <h1>Activate Multifactor Authentication (MFA)</h1>
                    <br/>
                    {currentAuthUser?.preferredMFA === 'SOFTWARE_TOKEN_MFA' ?
                        <Alert variant="success" className="pwAlert">
                            <h5>You have already activated the MFA-TOTP Token. You can re-generate a new key for security purposes.</h5>
                        </Alert>
                    : <></>}
                    <br/>
                    <Alert variant="info" className="pwAlert">
                        <h4>Please define a second factor besides your password to authenticate.</h4>
                        <p>Currently, We support the Time-based One Time Password (TOTP) mechanism. It is well supported by many smartphones or computer applications <br/>
                        Examples are: Google Authenticator, Microsoft Authenticator, 2FA Authenticator, Authy, Lastpass Authenticator, DUO or FreeOTP. </p>
                        <br/>

                        <h4>How to proceed?</h4>

                        <ul>
                            <li>First: Install an app that supports TOTP.</li>
                            <li>Click on the &quot;Generate Token&quot; Button.</li>
                            <li>A QR Tag will be displayed.</li>
                            <li>Add an account in your app and scan the code with your camera.</li>
                            <li>Verify that your token was correctly imported by entering the current number into the validation field.</li>
                            <li>If the code was correct, your account will need the TOTP token for every login.</li>
                            <li>Advice: Store a copy of the code (plaintext version) in a safe and secure place.</li>
                            <li>Info: For technical users we can setup special authentication functions.</li>
                            <li>If you loose your MFA device, you can use the plaintext key as a backup, or ask our support to deactivate MFA for your account.</li>
                        </ul>

                    </Alert>

                    <form onSubmit={handleQRCodeGenerator}>

                        <Form.Group controlId="activateTOTP">
                            <Form.Label>Start the activation of your MFA Token</Form.Label>
                            <br/>
                            {currentAuthUser?.preferredMFA === 'SOFTWARE_TOKEN_MFA' ?
                                <LoaderButton
                                    block
                                    type="submit"
                                    isLoading={isLoadingQRCode}
                                    size="lg">
                                    Re-Generate Token
                                </LoaderButton>
                            :
                                <LoaderButton
                                    block
                                    type="submit"
                                    isLoading={isLoadingQRCode}
                                    size="lg">
                                    Generate Token
                                </LoaderButton>
                            }
                            <br/>
                            {errorMessageRegenarateQRCode}
                            <br/>
                            <Grid container direction="row" justify="center">
                                <Grid item xs={'auto'} sm="auto" md="auto" lg="auto">
                                    {qrCode ? <QRCode value={qrCode} /> : <></>}
                                </Grid>
                            </Grid>
                            {plainCode?
                                <div>
                                    Plain Code: {plainCode}
                                    <br/>
                                    <br/>
                                    <Alert variant="warning" className="pwAlert">
                                        <p>Please store a copy of the plain code in secure storage. You can use it as a backup for the case that you might loose your device.</p>
                                        <p>In case you loose your MFA-Token device and don&apos;t have a backup, you can contact our support to reset it.</p>
                                    </Alert>
                                </div>
                            : <></> }
                        </Form.Group>
                        {errorMessageQRCode}
                    </form>



                    <br/>

                    {qrCode ?
                    <div>
                    <h1>Verify your token setup</h1>
                    <br/>

                        <form onSubmit={handleVerifyCode}>
                            <Form.Group controlId="totp_password">
                                <Form.Label>Please enter your validation code</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={fields.totp_password}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>

                            {errorMessageVerifyCode}
                            <LoaderButton
                                block
                                type="submit"
                                isLoading={isLoadingVerifyCode}
                                disabled={!validateForm()}>
                                Verify Token
                            </LoaderButton>
                        </form>
                    </div>
                    :
                    <></>}
                </div>
            </div>
        );
    }

    function renderTOTPActivatedForm() {
        return (
            <form className="ActivatedTOTPForm">
                <Alert variant="success">
                    <p>Congratulations! Your MFA token has been activated</p>
                </Alert>
                <Link to='/'><Button variant="info" className="ActivatedTOTPHomeButton">Proceed to Home <FontAwesomeIcon icon="home"/></Button></Link>
            </form>
        )
    }

    return (
        <div className="ActivateTOTP">
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Activate MFA</Breadcrumb.Item>
                </Breadcrumb>

                {!isLoadingUser && !isTokenVerificationComplete ?
                    renderActivateTOTPForm()
                :
                    renderTOTPActivatedForm()
                }
            </Container>
        </div>
    );
    }