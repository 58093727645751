import React, { useState } from "react";
import { Alert, Badge, Button, Card, Col,  Form,  Modal,  Row } from "react-bootstrap";
import '../../../containers/Home.css'
import "./LicenseRollout.css";
// import { useFormFields } from "../../../libs/hooksLib";
// import Badge from "react-bootstrap/Badge";
// import Moment from 'react-moment';
import 'moment-timezone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// React placeholder
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import {TextRow} from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';
import Moment from "react-moment";
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import { useFormFields } from "../../../libs/hooksLib";

export default function LicenseRollout(props){
	const [showInfo, setShowInfo] = useState(true);
	const [modalShow, setModalShow] = useState(false);
	const [licenseDetails, setLicenseDetails] = useState();
	const img_URL_prefix = "/images/licenses/";
	const [orderValidated, setOrderValidated] = useState(true);
	const [fields, handleFieldChange] = useFormFields({
		endcustomer: "",
		endcustomer_id: "",
		comment_1: "",
		comment_2: "",
		custom_key: ""
    });

	async function addLicenseToDevice(event) {
		const form = event.currentTarget;

		if (form.checkValidity() === false) {
			event.stopPropagation();
			event.preventDefault();
		} else {
			setOrderValidated(true);

			const invoiceMetadata = {
				"endcustomer": fields?.endcustomer,
				"endcustomer_id": fields?.endcustomer_id,
				"comment_1": fields?.comment_1,
				"comment_2": fields?.comment_2
			};

			let licenseMetadata = {};

			if(licenseDetails.feature_name === "poettingerconnect-dl__lifetime__generic")
			{
				licenseMetadata = {
					"custom_key": fields?.custom_key
				}
			}

			props.onLicenseManagerActions({
				action: "addLicenseToDevice",
				feature_name: licenseDetails.feature_name,
				license_metadata: licenseMetadata,
				description: "Feature activated via GUI!",
				license_type_metadata: {},
				invoice_metadata: invoiceMetadata
			})
			setModalShow(!modalShow)
		}
	}

	function MessageInfo() {
        if (props.message.show && showInfo) {
          return (
            <Alert variant={props.message.variant} onClose={() => setShowInfo(false)} dismissible>
              <Alert.Heading>{props.message.headline}</Alert.Heading>
              {props.message.body}
            </Alert>
          );
        }
        return <></>;
    }

	function LicenseManagerModel() {
		return (
			<Modal
				show={modalShow}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				onHide={() => setModalShow(false)}
				className="LicenseManagerModel">
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						{licenseDetails.feature_name_label}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col xs={6}>
							<Row>
								<Col xs='5' className="m-auto text-center">
									<Card.Img className="mx-auto" src={img_URL_prefix+licenseDetails?.picture?.thumb_name} title={licenseDetails.picture_thumb_name}/>
								</Col>
								<Col xs='7' className="m-auto text-center">
									<p><FontAwesomeIcon icon="award"/> license: {licenseDetails.license_type_label || "unknown"}</p>
									<p><FontAwesomeIcon icon="puzzle-piece"/> this device is {licenseDetails.compatibility_info || "in an unknown compatibility state."}</p>
									{licenseDetails?.license_count ?
										licenseDetails?.license_count === 1 ?
											<p><FontAwesomeIcon icon="dolly"/> {licenseDetails?.license_count} license was rolled out for this device</p>
										:
											<p><FontAwesomeIcon icon="dolly"/> {licenseDetails?.license_count} licenses were rolled out for this device</p>
									:
										<></>
									}
								</Col>
							</Row>
							<p><FontAwesomeIcon icon="tag"/> {licenseDetails.feature_category || "unknown"}</p>
						</Col>
						<Col xs={6} className="m-auto text-center">
							<div className="m-auto text-center">
								{licenseDetails.order_info?.invoice_metadata?.endcustomer &&  licenseDetails.order_info?.invoice_metadata?.endcustomer_id?
									<div>
										<p><strong>End customer:</strong> {licenseDetails.order_info?.invoice_metadata?.endcustomer}</p>
										<p><strong>End customer id:</strong> {licenseDetails.order_info?.invoice_metadata?.endcustomer_id}</p>
										<p><strong>Comment:</strong> {licenseDetails.order_info?.invoice_metadata?.comment_1}</p>
										<p><strong>Note:</strong> {licenseDetails.order_info?.invoice_metadata?.comment_2}</p>
									</div>
								: 	<></>
								}
								<br/>
								{ licenseDetails.order_info?.log ?
									licenseDetails.order_info?.log?.map((details, i) =>
									!(/^\d+$/.test(details?.timestamp)) ?
										(<div key={i}>
											<p><FontAwesomeIcon icon={details?.icon}/> <Moment format="YYYY-MM-DD HH:mm:ss" tz="Europe/Berlin" utc local>
												{details?.timestamp}
											</Moment> <Badge variant="primary">
												<Moment tz="Europe/Berlin" utc local fromNow>
													{details?.timestamp}
												</Moment>
											</Badge> <Badge variant={details?.status === "SUCCEEDED"? "success" : details?.status === "FAILED"? "danger" : "info"}>{details?.status}</Badge> {details?.label}
											</p>
										</div>)
									:
										(<div key={i}>
											<p><FontAwesomeIcon icon={details?.icon}/> <Moment unix format="YYYY-MM-DD HH:mm:ss" tz="Europe/Berlin" utc local>
												{details?.timestamp*1000}
											</Moment> <Badge variant="primary">
												<Moment tz="Europe/Berlin" unix utc local fromNow>
													{details?.timestamp*1000}
												</Moment>
											</Badge> <Badge variant={details?.status === "SUCCEEDED"? "success" : details?.status === "FAILED"? "danger" : "info"}>{details?.status}</Badge> {details?.label}
											</p>
										</div>)
									)
								:
									<></>
								}
							</div>
						</Col>
					</Row>
					<br/>
					<Form validated={orderValidated} onSubmit={(event) => addLicenseToDevice(event)}>
						<Row>
							<Col xs="12">
								<p><strong className="FillFormStyle">If you want to order this license for an end customer, please fill in the details below:</strong></p>
							</Col>
						</Row>
						<br/>
							{
								(licenseDetails.feature_name === "poettingerconnect-dl__lifetime__generic") ?
								(
									<div>
										<Row>
											<Col xs="12">
												<Form.Group controlId="custom_key">
													<Form.Label>Azure custom key (mandatory)</Form.Label>
													<Form.Control
														required
														size="sm"
														autoFocus
														type="text"
														placeholder="custom_key"
														value={fields.custom_key}
														onChange={handleFieldChange}
													/>
												</Form.Group>
											</Col>
										</Row>
									</div>
								)
								: <></>
							}
						<Row>
							<Col xs="6">
								<Form.Group controlId="endcustomer">
									<Form.Label>End Customer</Form.Label>
									<Form.Control
										size="sm"
										autoFocus
										type="text"
										placeholder="end customer name"
										value={fields.endcustomer}
										onChange={handleFieldChange}
									/>
								</Form.Group>
							</Col>
							<Col xs="6">
								<Form.Group controlId="endcustomer_id">
									<Form.Label>End Customer ID</Form.Label>
									<Form.Control
										size="sm"
										autoFocus
										type="text"
										placeholder="end customer id"
										value={fields.endcustomer_id}
										onChange={handleFieldChange}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col xs="6">
								<Form.Group controlId="comment_1">
									<Form.Label>Comment</Form.Label>
									<Form.Control
										size="sm"
										autoFocus
										type="text"
										placeholder="write a comment"
										value={fields.comment_1}
										onChange={handleFieldChange}
									/>
								</Form.Group>
							</Col>
							<Col xs="6">
								<Form.Group controlId="comment_2">
									<Form.Label>Note</Form.Label>
									<Form.Control
										size="sm"
										autoFocus
										type="text"
										placeholder="write a comment"
										value={fields.comment_2}
										onChange={handleFieldChange}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col xs={6}>
								<Form.Group className="mb-3">
									<Form.Check
									required
									label="Agree to terms, conditions and pricing information."
									feedback="You must agree before submitting."
									feedbacktype="invalid"
									/>
								</Form.Group>
								<Button variant="success" type="submit">Order for &quot;{props?.device?.device_name || "unknown device!"}&quot;</Button>
							</Col>
							<Col xs={6}>
							</Col>
						</Row>
					</Form>
					<br/>
					<Row>
						{licenseDetails?.detail?.description.map((element, i) => (
							element?.render_in_detail ?
								<Col xs={element?.width} key={i} className="RenderDetails">
									<h5>{element?.label}</h5>
									<Markdown
										rehypePlugins={[rehypeRaw, rehypeSanitize]}>
										{element?.description || ""}
									</Markdown>
								</Col>
							: <></>
						))
						}
					</Row>
					{/* <Row>
						<Col xs={12} className="m-auto text-center">
							<a href="https://connagtive.com/" target="_blank" rel="noopener noreferrer">
								<Button
									size="sm"
									className="btn btn-secondary SupportButton"
									block> Learn more
								</Button>
							</a>
						</Col>
						<br/>
						<br/>
						<Col xs={12} className="m-auto text-center">
							<Link to="/infoAndSupport" target="_blank">
								<Button
									size="sm"
									className="btn btn-secondary SupportButton"
									block> Need support?
								</Button>
							</Link>
						</Col>
					</Row> */}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => setModalShow(false)}>Close</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	function showLicenseDetailsPopUp(license_datails) {
		setLicenseDetails(license_datails);
		setModalShow(!modalShow);
	}

	function renderLicenseCards(){
		return (
			<div>
			<h4><FontAwesomeIcon icon="bookmark"/> Device Upgrades</h4>
			<Row>
				{props.licenseManagerData?
				props.licenseManagerData.Items.map((license_datails, i) => (
					<Col xs='4' key={i}>
					<Card className="cardLicenseManager" border="dark">
						<Card.Body>
							<Row>
								<Col xs='4' className="m-auto text-center">
									<Card.Img className="mx-auto" src={img_URL_prefix+license_datails?.picture?.thumb_name} title={license_datails.picture_thumb_name}/>
								</Col>
								<Col xs='8'>
									<Card.Title>{license_datails.feature_name_label || "unknown"}</Card.Title>
									<p><FontAwesomeIcon icon="award"/> license: {license_datails.license_type_label || "unknown"}</p>
									<p><FontAwesomeIcon icon="puzzle-piece"/> this device is {license_datails.compatibility_info || "in an unknown compatibility state"}</p>
									{license_datails?.license_count ?
										license_datails?.license_count === 1 ?
											<p><FontAwesomeIcon icon="dolly"/> {license_datails?.license_count} license was rolled out for this device</p>
										:
											<p><FontAwesomeIcon icon="dolly"/> {license_datails?.license_count} licenses were rolled out for this device</p>
									:
										<></>
									}
								</Col>
							</Row>
							<br/>
							<Row>
								<Col xs='5'>
									<p><FontAwesomeIcon icon="tag"/> {license_datails?.feature_category ? license_datails?.feature_category : "unknown"}</p>
								</Col>
								<Col xs='7'>
									<Button
										size="sm"
										className="btn btn-secondary DetailsButton"
										onClick={() => showLicenseDetailsPopUp(license_datails)}
										block> Details & Ordering Info
									</Button>
								</Col>
							</Row>
							<br/>
							<Markdown
								rehypePlugins={[rehypeRaw, rehypeSanitize]}>
								{license_datails.feature_brief || ""}
							</Markdown>
						</Card.Body>
					</Card>
					</Col>
				))
				: <></>
				}
			</Row>
		</div>
		)
	}


	return (
		<div>
			<MessageInfo />

			<ReactPlaceholder className='text-center' ready={!props.isLoadingLicenseManagerData} customPlaceholder={
				<div className='text-center'>
					<br/>
					<Row>
						<Col xs="4">
							<TextRow color='#f2f2f3' style={{width: 'auto', height: 400}}/>
						</Col>
						<Col xs="4">
							<TextRow color='#f2f2f3' style={{width: 'auto', height: 400}}/>
						</Col>
						<Col xs="4">
							<TextRow color='#f2f2f3' style={{width: 'auto', height: 400}}/>
						</Col>
					</Row>
				</div>
			}>
			{renderLicenseCards()}
			{modalShow ? LicenseManagerModel() : <></>}
			</ReactPlaceholder>
		</div>
	);
}
