import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Form, OverlayTrigger, Tooltip, Container, InputGroup, Alert } from "react-bootstrap";
import { Icon } from '@iconify/react';
import infoCircle from '@iconify/icons-fa-solid/info-circle';
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "./CreateUser.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from "react-router-dom";
import { CognitoIdentityServiceProvider } from "aws-sdk";
import config from "../config";

export default function CreateUser(props) {
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        customer: "",
        group: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [errorMessage, setErrorMessage] = useState();
    const [current_credentials, setCurrent_credentials] = useState();
    const [userSession, setUserSession] = useState(undefined);
    const tooltip = (
        <Tooltip id="tooltip">
            Admin: Has the right to manage devices.<br></br>
            Device Owner: Has the right to book features.<br></br>
            Support View: Has the right to view devices.
        </Tooltip>
      );
    var cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
        region: config.cognito.REGION,
        credentials: {
            accessKeyId: current_credentials?.accessKeyId,
            secretAccessKey: current_credentials?.secretAccessKey,
            sessionToken: current_credentials?.sessionToken
        }
    });

    useEffect(() => {
        async function onLoad() {
            if (!props.isAuthenticated || !props.loggedInUser) {
                return;
            }
            try {
                if (!props.location.param) {
                    const allCustomers = await loadCustomers();
                    console.log(allCustomers);
                    // Sorting all users (alphabetically)
                    try {
                        allCustomers.sort(function(first, second){
                            return  (first.customer_name.toLowerCase() > second.customer_name.toLowerCase() ? 1 : -1);
                        });
                    } catch (e){
                        console.log("Error Sorting users");
                        console.log(e);
                    }
                    setCustomers(allCustomers);
                    setCurrent_credentials(await Auth.currentCredentials());
                    const session = await Auth.currentSession();
                    setUserSession(session);
                } else {
                    setCustomers(props.location.param);
                }
                } catch (e) {
                    alert(e);
                }

                setIsLoading(false);
            }

            onLoad();
        }, [props.isAuthenticated, props.loggedInUser, props.location.param]);

    function loadCustomers() {
        return API.get("iot_customer_config", "/iot_customer_config");
    }

    function validateForm() {
        return (
            fields.email.length > 0 &&
            fields.customer.length > 0 &&
            fields.group.length > 0
        );
    }

    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        var params = {
            UserPoolId: config.cognito.USER_POOL_ID,
            Username: fields.email,
            UserAttributes: [
                {
                    Name: 'email',
                    Value: fields.email
                },
                {
                    Name: 'custom:customer_name',
                    Value: fields.customer
                },
                {
                    Name: 'custom:group',
                    Value: fields.group
                },
                {
                    Name: 'custom:created_by',
                    Value: userSession?.idToken?.payload?.sub
                },
                {
                    Name: 'email_verified',
                    Value: 'true'
                },
            ]
        };
        try {
            cognitoidentityserviceprovider.adminCreateUser(params).promise().then(async newUser => {
                createUser(newUser.User.Username);
                setErrorMessage(
                    <Alert variant="success">
                        <p>User has been Created successfully</p>
                    </Alert>
                );
                await timeout(10000);
                props.history.push("administration");
            }
            ).catch (async e => {
                setErrorMessage(
                    <Alert variant="danger">
                        <p>Error: {e.message}</p>
                    </Alert>
                )
            });

        } catch (e) {
            setErrorMessage(
                <Alert variant="danger">
                    <p>Error: {e.message}</p>
                </Alert>
            );
        }
        setIsLoading(false);
    }

    function createUser(newUser) {
        return API.post("iot_users", "/iot_users", {
            body : {
                userId: newUser,
                email: fields.email,
                customerName: fields.customer,
                group: fields.group
            }
        });
    }

    function customerSelection() {
        return customers.map((customer) => (
        <option key={customer.customer_name} value={customer.customer_name}>{customer.customer_name}</option>
        ));
    }

    function renderForm() {
        return (
            <div className="description">
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/administration" }}>Administration</Breadcrumb.Item>
                    <Breadcrumb.Item active>Create User</Breadcrumb.Item>
                </Breadcrumb>

                <h1>Create User</h1>

                <Alert variant="info">
                    <p>The created user will recieve a verification code via email with a temporary password. In the first log-in, the
                    user has to confirm his account and change the password.</p>
                </Alert>

                {errorMessage}

                <form onSubmit={handleSubmit}>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon="envelope" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                autoFocus
                                type="email"
                                value={fields.email}
                                onChange={handleFieldChange}
                                placeholder="Enter email"
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="customer">
                        <Form.Label>Customer Affiliation</Form.Label>
                        <Form.Control as="select" placeholder="Please choose..."
                            value={fields.customer}
                            onChange={handleFieldChange}>
                            <option value={undefined}>Please choose...</option>
                                {customerSelection()}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="group">
                        <Form.Label>User Permissions</Form.Label>
                        <OverlayTrigger placement="top" overlay={tooltip}>
                            <Icon style={{marginLeft: "5px", cursor: "pointer"}} icon={infoCircle} />
                        </OverlayTrigger>
                        <Form.Control as="select" placeholder="Please choose..."
                            value={fields.group}
                            onChange={handleFieldChange}>
                            <option value={undefined}>Please choose...</option>
                            <option value="SupportView">SupportView</option>
                            <option value="DeviceOwner">DeviceOwner</option>
                            <option value="Admin">Admin</option>
                        </Form.Control>
                    </Form.Group>
                    <LoaderButton
                        block
                        type="submit"
                        isLoading={isLoading}
                        disabled={!validateForm()}
                    >
                        Create User
                    </LoaderButton>
                </form>
            </div>
        );
    }

    return (
        <div className="CreateUser">
            <Container>
                {renderForm()}
            </Container>
        </div>
    );
}