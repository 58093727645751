import React, { useState } from "react";
import { Form, Alert } from "react-bootstrap";
import { useFormFields } from "../../../libs/hooksLib";
import LoaderButton from "../../LoaderButton";


export default function UpdateSimServiceProfile(props) {

    const [showInfo, setShowInfo] = useState(false);
    const [messageInfoVariant, setMessageInfoVariant] = useState("success");
    const [messageInfoResponseBody, setMessageInfoResponseBody] = useState("");

    const [fields, handleFieldChange] = useFormFields({
        tariff_profile: props.whereverSimData?.endpoint?.tariff_profile?.name,
        service_profile: props.whereverSimData?.endpoint?.service_profile?.name
    });

    async function updateSimProfile()
    {
        console.log("updateSimProfile");
        console.log(fields.service_profile);

        const targetServiceProfile = props.whereverSimProfiles?.serviceProfile?.find(service_profile => service_profile.name === fields.service_profile);
        const targetTariffProfile = props.whereverSimProfiles?.tariffProfile?.find(tariff_profile => tariff_profile.name === fields.tariff_profile);

        let whereverSimAction = {
            action: "change_sim_profile",
            device: props.device?.uid,
            service_profile_id: targetServiceProfile?.id,
            tariff_profile_id: targetTariffProfile?.id,
            ret_val: false,
            ret_error_msg: ""
        }

        await props.onWhereverSimActions(whereverSimAction);

        if(whereverSimAction.ret_val === true)
        {
            setMessageInfoVariant("success");
            setMessageInfoResponseBody("SIM profile changed successfully!");
        }
        else
        {
            setMessageInfoVariant("danger");
            setMessageInfoResponseBody("Error: " + whereverSimAction.ret_error_msg);
        }
    }


    function handleSubmit(event) {
        event.preventDefault();
        try {
            updateSimProfile();
            setShowInfo(true);
            setMessageInfoVariant("info");
            setMessageInfoResponseBody("Changing SIM profile...");
        } catch (e) {
            alert(e.message);
        }
    }

    function validateForm() {
        return true;
    }

    function tariffProfileSelection() {
        return props.whereverSimProfiles?.tariffProfile?.map((tariffProfile) => (
            <option key={tariffProfile.id} value={tariffProfile.name}>{tariffProfile.name}</option>
        ));
    }

    function serviceProfileSelection() {
        return props.whereverSimProfiles?.serviceProfile?.map((serviceProfile) => (
            <option key={serviceProfile.id} value={serviceProfile.name}>{serviceProfile.name}</option>
        ));
    }

    function MessageInfo() {
        if (showInfo) {
          return (
            <Alert variant={messageInfoVariant} onClose={() => setShowInfo(false)} dismissible>
              <Alert.Heading>{messageInfoResponseBody}</Alert.Heading>
            </Alert>
          );
        }
        return <></>;
    }


    return (
        <div>
            <div>
                <MessageInfo />
                <form onSubmit={handleSubmit}>
                    <Form.Group controlId="service_profile">
                        <Form.Label>Service profile</Form.Label>
                        <Form.Control as="select" placeholder="Please choose..."
                            value={fields.service_profile}
                            onChange={handleFieldChange}>
                            {serviceProfileSelection()}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="tariff_profile">
                        <Form.Label>Tariff</Form.Label>
                        <Form.Control as="select" placeholder="Please choose..."
                            value={fields.tariff_profile}
                            onChange={handleFieldChange}>
                            {tariffProfileSelection()}
                        </Form.Control>
                    </Form.Group>
                    <LoaderButton
                        block
                        type="submit"
                        disabled={!validateForm()} >
                        Save Changes
                    </LoaderButton>
                </form>
            </div>
            <br></br>
            <h6>Contact our Sales team for pricing details</h6>
            <h6>sales@iot-rocket.com</h6>
        </div>
    )
}