import React from "react";
import { Container, Table, Badge } from "react-bootstrap";

import "../Device.css";

export default function UserData(props) {

    function parseJsonString(jsonString)
    {
        try
        {
            const jsonObj = JSON.parse(jsonString);
            const beautifiedJson = JSON.stringify(jsonObj, null, 2);

            return <div>
                <pre className="dev-json-beautify">
                    <code >{ beautifiedJson }</code>
                </pre>
            </div>

        } catch (error) {
            return <div>
                { jsonString }
                <br/>
                <Badge variant="warning">json string not parseable</Badge>
            </div>;
        }
    }

    function showUserData(renderType)
    {
          const shadowStateData = props.iotCoreData?.state?.reported;

          let filteredKeys = [];

          if(shadowStateData)
          {
            switch(renderType) {
                case "params":
                    filteredKeys = Object.keys(shadowStateData)?.filter((key) =>
                    /^ui_|^us_|^uf_/.test(key));
                    break;

                case "json_objects":
                    filteredKeys = Object.keys(shadowStateData)?.filter((key) =>
                    /^uj_/.test(key));
                    break;

                default:
                    return <></>;
            }
          }

          const dataArray = filteredKeys.map((key) => {
            const label = key.substring(key.indexOf("_") + 1);
            let value = shadowStateData[key];
            const type = key.charAt(1); // Extract the second character as the type
          
            let valueType;
            switch (type) {
              case "f":
                valueType = "float";
                break;
              case "i":
                valueType = "integer";
                break;
              case "s":
                valueType = "string";
                break;
              case "j":
                valueType = "json object"
                break;
              default:
                valueType = "unknown";
            }
          
            return ({key, label, value, valueType});
          }).sort((a, b) => a.label.localeCompare(b.label));

          return dataArray.map((entry) => {
                return <tr key={entry.key} >
                        <td key="label">{entry.label}</td>
                        <td key="value">
                            { (entry.valueType == "json object") ?
                              parseJsonString(entry.value) :
                              entry.value
                            }
                        </td>
                        <td key="type">{entry.valueType}</td>
                    </tr>
          });
    }

    return (<div>
        <Container>
            <h4>Parameters</h4>
            <Table responsive>
                <thead>
                    <tr>
                        <th>Label</th>
                        <th>Value</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>{ showUserData("params") }</tbody>
            </Table>

            <h4>JSON Objects</h4>
            <Table responsive>
                <thead>
                    <tr>
                        <th>Label</th>
                        <th>Value</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>{ showUserData("json_objects") }</tbody>
            </Table>
        </Container>
    </div>)
}