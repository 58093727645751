import './Home.css'
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DevicesOwnershipTable from "../components/Devices/DevicesTable/DevicesOwnershipTable";
// import Spinner from "../components/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from "react-router-dom";
// React placeholder
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import {TextRow} from 'react-placeholder/lib/placeholders';

import ConvertToCSV from './ConvertToCSV.js'

export default function DeviceAdministreationDetails(props) {
    // const [filterConfiguration, setFilterConfiguration] = useState([]);
    const filterConfiguration = [];
    const [devices, setDevices] = useState([]);
    const [devicesLength, setDevicesLength] = useState();
    const [loadTime, setLoadTime] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [fleetDataError, setFleetDataError] = useState(false);

    useEffect(() => {
        async function onLoad() {
            if (!props.isAuthenticated || !props.loggedInUser) {
                return;
            }
            try {
                const starDbQuery_timestamp = Date.now()
                const devices = await loadDevices();
                setDevices(devices);
                setDevicesLength(devices.length);
                // const filterConfigurationLocalStorage = JSON.parse(localStorage.getItem('filterConfigurationOwnership'));
                // setFilterConfiguration(filterConfigurationLocalStorage);
                const stopDbQuery_timestamp = Date.now()
                const loadTime = stopDbQuery_timestamp - starDbQuery_timestamp;
                setLoadTime(loadTime);

                // save the path if session expired
                localStorage.setItem('prevPath', '/ownership');
                setFleetDataError(false);
            } catch (e) {
                console.log(e);
                setFleetDataError(true);
            }

            setIsLoading(false);

        }
        onLoad();
    }, [props.isAuthenticated, props.loggedInUser]);

    function loadDevices() {
        return API.get("iot_registry-endpoint", "/iot_registry");
    }

    function setColumnFilteredDevices(filteredDevices, updatedFilterConfiguration) {
        setDevices(filteredDevices);
        localStorage.setItem('filterConfigurationOwnership', JSON.stringify(updatedFilterConfiguration));

        if (devicesLength === filteredDevices.length) {
            setIsDataChanged(false);
        } else {
            setIsDataChanged(true);
        }
    }

    function renderDevices() {
        let filename_prefix = "iot_fleet_Ownership_" + props?.loggedInUser?.customer_name;
        let excluded_elements = ["device_rauc_status", "device_health"];
        return (
            <div className="devices">
                {fleetDataError === false ?
                    <div>
                        <Row className="dev-attributes-row">
                            <Col xs={4}>
                                <ConvertToCSV data={devices} excluded={excluded_elements} filename={filename_prefix} isDataChanged={isDataChanged} />
                            </Col>
                            <Col xs={4}></Col>
                            <Col xs={4}><div className="dev-attributes-heading-annotations"> <FontAwesomeIcon icon="microchip" /> Devices: {devices.length} &nbsp; &nbsp; <FontAwesomeIcon icon="hourglass-end" /> {loadTime} ms</div>  </Col>
                        </Row>
                        <DevicesOwnershipTable onColumnFilterChanged={setColumnFilteredDevices} devices={devices} filterConfiguration={filterConfiguration}></DevicesOwnershipTable>
                    </div>
                :
                    <div className="text-center" style={{"paddingTop": "100px"}}>
                        <h2><FontAwesomeIcon icon="exclamation-triangle" /></h2>
                        <h4>Oops! Something went Wrong. Please try again later or contact our <Link to="/infoAndSupport" target="_blank">support</Link>.</h4>
                    </div>
                }
            </div>
        );
    }

    return (
        <div className="Home">
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/fleet" }}>Fleet</Breadcrumb.Item>
                    <Breadcrumb.Item active>Ownership & Integrations</Breadcrumb.Item>
                </Breadcrumb>
                <h1>Ownership & Integrations View</h1>
                <ReactPlaceholder showLoadingAnimation className='text-center' ready={props.isAuthenticated && !isLoading} customPlaceholder={
                    <div className='my-awesome-placeholder text-center'>
                        <br/>
                        <TextRow color='#e4e4e7' style={{width: 'auto', height: 40}}/>
                        <TextRow color='#f2f2f3' style={{width: 'auto', height: 600}}/>
                    </div>
                }>{renderDevices()}
                </ReactPlaceholder>
            </Container>
        </div>
    );
}