import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import config from './config';
import * as serviceWorker from './serviceWorker';

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
        endpoints: [
            {
                name: "iot_registry-endpoint",
                endpoint: config.apiMainGateway.URL,
                region: config.apiMainGateway.REGION
            },
            {
                name: "iot_users",
                endpoint: config.apiMainGateway.URL,
                region: config.apiMainGateway.REGION
            },
            {
                name: "iot_create_users",
                endpoint: config.apiCreateUsersGateway.URL,
                region: config.apiCreateUsersGateway.REGION
            },
            {
                name: "iot_customer_config",
                endpoint: config.apiMainGateway.URL,
                region: config.apiMainGateway.REGION
            },
            {
                name: "iot_statistics",
                endpoint: config.apiMainGateway.URL,
                region: config.apiMainGateway.REGION
            },
            {
                name: "feature_management",
                endpoint: config.apiFeatureGateway.URL,
                region: config.apiFeatureGateway.REGION
            },
            {
                name: "registry_management",
                endpoint: config.apiFeatureGateway.URL,
                region: config.apiFeatureGateway.REGION
            },
            {
                name: "file_upload",
                endpoint: config.fileManagerGateway.URL,
                region: config.apiFeatureGateway.REGION
            },
            {
                name: "job_manager",
                endpoint: config.jobManagerGateway.URL,
                region: config.apiFeatureGateway.REGION
            },
            {
                name: "whereversim",
                endpoint: config.wherverSimEsecGateway.URL,
                region: config.wherverSimEsecGateway.REGION
            },
            {
                name: "whereversimintegration",
                endpoint: config.wherverSimIntegration.URL,
                region: config.wherverSimIntegration.REGION
            },
            {
                name: "remote_command",
                endpoint: config.remoteCommandGateway.URL,
                region: config.remoteCommandGateway.REGION
            },
            {
                name: "hawkbit_integration",
                endpoint: config.hawkbitIntegrationGateway.URL,
                region: config.hawkbitIntegrationGateway.REGION
            },
            {
                name: "device_logger",
                endpoint: config.deviceLoggerGateway.URL,
                region: config.deviceLoggerGateway.REGION
            },
            {
                name: "license_manager",
                endpoint: config.licenseManagerGateway.URL,
                region: config.licenseManagerGateway.REGION
            },
            {
                name: "iot_fota_api_python",
                endpoint: config.apiCreateCustomerGateway.URL,
                region: config.apiCreateCustomerGateway.REGION
            },
            {
                name: "firewall",
                endpoint: config.apiFirewallGateway.URL,
                region: config.apiFirewallGateway.REGION
            }
        ]
    }
});

ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
