import React, { Component } from 'react'
import { Container, Alert} from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import './FeatureRoadmap.css'
import { Link } from 'react-router-dom';

class ApiDeviceManager extends Component {

  render() {
    return (
        <div className="Featureroadmap">
            <Container className='apidocumentation'>
                <Breadcrumb>
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>API - Device Suite</Breadcrumb.Item>
                </Breadcrumb>

                <h1>IoT Device Suite API</h1>

                <Alert variant="warning">
                  <Alert.Heading>Preview - Documentation not finished</Alert.Heading>
                  This API can be currently used by any user of our GUI. The description and examples will be added.
                </Alert>

                <rapi-doc
                    spec-url = "/api-doc/prod-iot-sota-api-prod-swagger.json"
                    render-style = "read"
                    allow-spec-url-load 	 = "false"
                    allow-spec-file-load  = "false"
                    allow-try = "false"
                    allow-authentication = "false"
                    show-header = "false"
                    style = {{ height: "90vh", width: "100%" }}>
                </rapi-doc>
            </Container>
        </div>
    )
  }
}

export default ApiDeviceManager