import React from "react";
import TableFilter from 'react-table-filter';
import "../../../../node_modules/react-table-filter/lib/styles.css";
import 'moment-timezone';
import Badge from "react-bootstrap/Badge";
import Moment from 'react-moment';
import { Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./DevicesTable.css";

export default function DevicesHealthTable(props) {
    function filterUpdated(newData, filterConfiguration) {
        let filteredDevicesByColumn = newData;
        props.onColumnFilterChanged(filteredDevicesByColumn, filterConfiguration);
    }


    function renderDevicesHealthTableDetails() {
        return (
            <div>
                <Table striped bordered hover>
                    <thead>
                        <TableFilter
                            rows={props.devices}
                            initialFilters={props.filterConfiguration}
                            onFilterUpdate={filterUpdated.bind(this)}>
                            <th key="device_name" filterkey="device_name">Device Name</th>
                            <th key="memory" filterkey="memory">RAM</th>
                            <th key="fs_root" filterkey="fs_root">RootFS</th>
                            <th key="fs_app" filterkey="fs_app">AppFS</th>
                            <th key="fs_data" filterkey="fs_data">Data</th>
                            <th key="uptime_conv" filterkey="uptime_conv">Uptime</th>
                            <th key="last_seen" filterkey="last_seen">LastSeen</th>
                        </TableFilter>
                    </thead>
                    <tbody>
                        {renderTableRowsDetails()}
                    </tbody>
                </Table>
            </div>
        );
    }

    function openDeviceDetail(uid, event) {
        if (event.ctrlKey || event.metaKey) {
            window.open(`/device/${uid}`);
        }
    }


    function renderTableRowsDetails() {
        return [{}].concat(props.devices).map((device, i) =>
            i !== 0 ? (
                <LinkContainer onClick={openDeviceDetail.bind(this, device.uid)} key={device.uid} to={`/device/${device.uid}`}>
                    <tr key={device.uid} to={`/iot-device-health/${device.uid}`}>
                    <td>{device.device_name.trim().split("\n")[0]}</td>
                    <td>{Math.round(100-100*device?.device_health?.memory?.mem_a / device?.device_health?.memory?.mem_t)}{" %"}</td>
                    <td>{device?.device_health?.fs_root?.used_perc.replace("%","")}{" %"}</td>
                    <td>{device?.device_health?.fs_app?.used_perc.replace("%","")}{" %"}</td>
                    <td>{device?.device_health?.fs_data?.used_perc.replace("%","")}{" %"}</td>
                    <td>{device?.device_health?.up?.uptime_conv}</td>
                    <td>{device.last_seen ? <div>
                        <Moment tz="Europe/Berlin" utc local format="YYYY-MM-DD HH:mm:ss" parse="YYYY-MM-DD HH:mm:ss"> {device.last_seen?.trim().split("\n")[0]}</Moment>
                        <br/>
                        <Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DD HH:mm:ss">{device.last_seen?.trim().split("\n")[0]}</Moment></Badge>
                        </div>
                        :
                        ''}
                    </td>
                    </tr>
                </LinkContainer>
            ) : (<tr key={i}></tr>)
        );
    }


    return (
            <div className="responsiveTable">
            {renderDevicesHealthTableDetails()}
            </div>
    )
}
