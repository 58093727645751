import React from "react";
import TableFilter from 'react-table-filter';
import "../../../../node_modules/react-table-filter/lib/styles.css";
import 'moment-timezone';
import Badge from "react-bootstrap/Badge";
import Moment from 'react-moment';
import { Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./DevicesTable.css";

export default function DevicesOwnershipTable(props) {
    function filterUpdated(newData, filterConfiguration) {
        let filteredDevicesByColumn = newData;
        props.onColumnFilterChanged(filteredDevicesByColumn, filterConfiguration);
    }


    function renderDevicesOwnershipTableDetails() {
        return (
            <div>
                <Table striped bordered hover>
                    <thead>
                        <TableFilter
                            rows={props.devices}
                            initialFilters={props.filterConfiguration}
                            onFilterUpdate={filterUpdated.bind(this)}>
                            <th key="device_name" filterkey="device_name">Device Name</th>
                            <th key="iccid" filterkey="iccid">SIM ID</th>
                            <th key="customer_org" filterkey="customer_org">WhereverSIM Account</th>
                            {/* <th key="teamviewer_id" filterkey="teamviewer_id">Teamviewer ID</th> */}
                            <th key="hawkbit_url" filterkey="hawkbit_url">Hawkbit URL</th>
                            <th key="device_owner" filterkey="device_owner">Owner</th>
                            <th key="device_reseller" filterkey="device_reseller">Reseller</th>
                            <th key="last_seen" filterkey="last_seen">LastSeen</th>
                        </TableFilter>
                    </thead>
                    <tbody>
                        {renderTableRowsDetails()}
                    </tbody>
                </Table>
            </div>
        );
    }

    function openDeviceDetail(uid, event) {
        if (event.ctrlKey || event.metaKey) {
            window.open(`/device/${uid}`);
        }
    }


    function renderTableRowsDetails() {
        return [{}].concat(props.devices).map((device, i) =>
            i !== 0 ? (
                <LinkContainer onClick={openDeviceDetail.bind(this, device.uid)} key={device.uid} to={`/device/${device.uid}`}>
                    <tr key={device.uid} to={`/iot-device-health/${device.uid}`}>
                    <td>{device.device_name.trim().split("\n")[0]}</td>
                    <td>{device.whereversim?.sim?.iccid ? device.whereversim.sim.iccid : "No WhereverSIM"}</td>
                    <td>{device.whereversim?.sim?.customer_org.name ? device.whereversim.sim.customer_org.name : ""}</td>
                    {/* <td>{device.teamviewer_id ? device.teamviewer_id.trim().split("\n")[0] : 'NOT SET'}</td> */}
                    <td>{device.hawkbit_url ? device.hawkbit_url.trim().split("\n")[0] : ''}</td>
                    <td>{device.device_owner ? device.device_owner.trim().split("\n")[0] : ''}</td>
                    <td>{device.device_reseller ? device.device_reseller.trim().split("\n")[0] : ''}</td>
                    <td>{device.last_seen ? <div>
                        <Moment tz="Europe/Berlin" utc local format="YYYY-MM-DD HH:mm:ss" parse="YYYY-MM-DD HH:mm:ss"> {device.last_seen?.trim().split("\n")[0]}</Moment>
                        <br/>
                        <Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DD HH:mm:ss">{device.last_seen?.trim().split("\n")[0]}</Moment></Badge>
                        </div>
                        :
                        ''}
                    </td>
                    </tr>
                </LinkContainer>
            ) : (<tr key={i}></tr>)
        );
    }


    return (
            <div  className="responsiveTable">
            {renderDevicesOwnershipTableDetails()}
            </div>
    )
}
