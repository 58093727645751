import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import './Footer.css'

const Footer = () => {
  return (
      <div className='footer border' id="Footer">
        <footer className="font-small pt-3 pb-3 footer">
            <Container fluid>
                <Row>
                <Col md="6" className="text-left footer-text">
                    <a rel="noopener noreferrer" target="_blank" href="https://doc.iot-suite.io/Releases_&_Changelog.html" className='p-2 bd-highlight border-left leftborders footer-text '>Changelog</a>
                    <a rel="noopener noreferrer" target="_blank" href="https://doc.iot-suite.io/Roadmap.html" className='p-2 bd-highlight border-left leftborders footer-text '>Feature Roadmap</a>
                </Col>
                <Col md="6" className="footer-copyright text-right footer-text">
                    &copy;{new Date().getFullYear()}<a href="https://iot-suite.io/en/" className='p-2'>iot-suite.io</a>
                    <a rel="noopener noreferrer" target="_blank" href="https://iot-suite.io/en/impressum/" className='p-2 bd-highlight border-left leftborders footer-text '>Legal notice</a>
                    <a rel="noopener noreferrer" target="_blank" href="https://iot-suite.io/en/datenschutz/" className='p-2 bd-highlight border-left leftborders footer-text '>Privacy</a>
                    <a rel="noopener noreferrer" target="_blank" href="https://iot-suite.io/en/agb/" className='p-2 bd-highlight border-left leftborders footer-text '>Terms & Conditions</a>
                </Col>
                </Row>
            </Container>
        </footer>
    </div>
  );
}

export default Footer;
