import React, { Component } from 'react'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import { Container, Alert} from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FeatureRoadmap.css'
import { faHome } from "@fortawesome/free-solid-svg-icons";


import markdownText from '../markdown/roadmap.md'
import { Link } from 'react-router-dom';

class FeatureRoadmap extends Component {
  constructor(props) {
    super(props)

    this.state = { markdown: null }
    fetch(markdownText).then((response) => response.text()).then((text) => {
        this.setState({ markdown: text })
      })
  }

  render() {
    return (
        <div className='Featureroadmap'>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Feature Roadmap</Breadcrumb.Item>
                </Breadcrumb>
                <h1>Feature Roadmap</h1>
                <Alert variant="primary">
                <Alert.Heading>We develop what is actually needed</Alert.Heading>
                <p>We work agile, which means we develop features one by one which gives the best business value to our customers. </p>

                <p>We started with the most important features to manage devices. Now it&apos;s getting more and more about improving workflows and saving costs. </p>

                <p>If you miss any feature, we are glad to develop it. </p>

                <p>Please contact our team <a href="mailto:support@iot-suite.io?subject=IoT%20Device%20Management%20Feature%20Request">Feature Request</a></p>
            </Alert>
              <Markdown
                rehypePlugins={[rehypeRaw, rehypeSanitize]}>
                {this.state.markdown}
              </Markdown>
            </Container>
        </div>
    )
  }
}

export default FeatureRoadmap