import React from "react";
import TableFilter from 'react-table-filter';
import "../../../../node_modules/react-table-filter/lib/styles.css";
import 'moment-timezone';
import Moment from 'react-moment';
import { Table, Badge } from "react-bootstrap";
import "./DevicesTable.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkContainer } from "react-router-bootstrap";

export default function TableWhereverSim(props) {
    function filterUpdated(newData, filterConfiguration) {
        let filteredDevicesByColumn = newData;
        props.onColumnFilterChanged(filteredDevicesByColumn, filterConfiguration);
    }

    function renderWhereverSimTableDetails() {
        return (
            <Table striped bordered hover>
                <thead>
                    <TableFilter
                        rows={props.whereversim}
                        initialFilters={props.filterConfiguration}
                        onFilterUpdate={filterUpdated.bind(this)}>
                        <th key="endpoint_name" filterkey="endpoint_name">Endpoint Name</th>
                        <th key="device_name" filterkey="device_name">Device Name</th>
                        <th key="iccid" filterkey="iccid">SIM ID</th>
                        {props.isCustomerRoleAdmin ?
                            <th key="customer_name" filterkey="device_owner">Device Owner</th>
                            :
                            <th key="customer_name"></th>
                        }
                        {props.isCustomerRoleAdmin ?
                            <th key="whereversim_customer" filterkey="whereversim_customer">SIM Account</th>
                            :
                            <th key="whereversim_customer"></th>
                        }
                        <th key="sim" filterkey="sim">SIM</th>
                        <th key="endpoint" filterkey="endpoint">Endpoint</th>
                        <th key="ip_address" filterkey="ip_address">IP Address</th>
                        <th key="tariff_profile" filterkey="tariff_profile">Tariff</th>
                        <th key="imei_lock" filterkey="imei_lock">IMEI Lock</th>
                        <th key="data_limit" filterkey="data_limit">Data Limit</th>
                        <th key="activation_date" filterkey="activation_date">Activation Date</th>
                    </TableFilter>
                </thead>
                <tbody>
                    {renderWhereverSimTableRowsDetails()}
                </tbody>
            </Table>
        );
    }

    function renderWhereverSimTableRowsDetails() {
        return [{}].concat(props.whereversim).map((customer, i) =>
            i !== 0 ? (
                [{}].concat(customer.devices).map((device, j) =>
                    j !== 0 ? (
                        <LinkContainer key={i+"-"+j} to={device?.db?.uid ? `/device/${device?.db?.uid}` : `#`}>
                            <tr key={i+"-"+j}>
                                <td>{device?.endpoint?.name || "---check-data---"}</td>
                                <td>{device?.db?.device_name || <Badge variant="secondary">no corresponing device</Badge>}</td>
                                <td>{device?.sim?.iccid || "---check-data---"}</td>
                                {props.isCustomerRoleAdmin ?
                                    <td>{customer?.customer_name || ""}</td>
                                    :
                                    <td></td>
                                }
                                {props.isCustomerRoleAdmin ?
                                    <td>{device?.sim?.customer_org?.name || ""}</td>
                                    :
                                    <td></td>
                                }
                                <td>{device?.sim?.status?.description ? <Badge variant={device?.sim?.status?.description === "Activated" ? 'success' : 'warning'}>{device?.sim?.status?.description}</Badge> : ''}</td>
                                <td>{device?.endpoint?.status?.description ? <Badge variant={device?.endpoint?.status?.description === "Enabled" ? 'success' : 'warning'}>{device?.endpoint?.status?.description}</Badge> : ''}</td>
                                <td>{device?.endpoint?.ip_address || ''}</td>
                                <td>{device?.endpoint?.tariff_profile?.name || ''}</td>
                                <td>{device?.endpoint?.imei_lock ? <Badge variant="success"><FontAwesomeIcon icon="lock"/> locked</Badge> : <Badge variant="danger"><FontAwesomeIcon icon="lock-open" /> unlocked</Badge> }</td>
                                <td>{device?.endpoint?.service_profile?.name || ''}</td>
                                <td>{device?.sim?.activation_date ?
                                    <div>
                                        <Moment parse="YYYY-MM-DDTHH-mm:ss" format="YYYY-MM-DD HH:mm:ss" utc local>{device?.sim?.activation_date}</Moment> <br/>
                                        <Badge variant="info"><Moment fromNow parse="YYYY-MM-DDTHH-mm:ss" utc local> {device?.sim?.activation_date}</Moment></Badge>
                                    </div>
                                    : ''}
                                </td>
                            </tr>
                        </LinkContainer>
                    ) : (<tr key={i+"-"+j}></tr>)
                )
            ) : (<></>)
        );
    }


    return (
            <div className="responsiveTable">
                {renderWhereverSimTableDetails()}
            </div>
    )
}
