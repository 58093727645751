import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./PermissionDenied.css";

export default function PermissionDenied() {


    function refreshPage() {
        window.location.reload(false);
    }


    return (
        <div className="PermissionDenied">
            <h3>You are not authorized to perform this operation. Please <a href="mailto:support@iot-suite.io?subject=IoT%20Device%20Management%20Support%20Request"> contact our support</a> for further help.</h3>
            <br/>
            <br/>
            <Button onClick={refreshPage}>Click to reload</Button>
            <br/>
            <br/>
            <Link to="/" className="btn btn-primary">Go back to Home page</Link>
        </div>
    );
}