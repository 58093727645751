import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Tabs, Tab, Badge, Row, Col } from "react-bootstrap";
import "./Administration.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faIndustry, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Button from "react-bootstrap/Button";
// import Spinner from "../../components/Spinner";
import UsersTable from "../../components/Administration/UserAdministration/UsersTable/UsersTable";
import { API, Auth } from "aws-amplify";
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import config from "../../config";
import CustomersTable from "../../components/Administration/CustomerAdministration/CustomersTable/CustomersTable";
// React placeholder
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import {TextRow} from 'react-placeholder/lib/placeholders';
import DeletedUsersTable from "../../components/Administration/UserAdministration/UsersTable/DeletedUsersTable";
import DisabledUsersTable from "../../components/Administration/UserAdministration/UsersTable/DisabledUsersTable";
import UnconfirmedUsersTable from "../../components/Administration/UserAdministration/UsersTable/UnconfirmedUsersTable";

export default function Administration(props) {
    const [users_attributes, setAttributes] = useState([]);
    const [essential_credentials, setEssentialCredentials] = useState({});
    const [users, setUsers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [demoUsers, setDemoUsers] = useState([]);
    const [adminUsers, setAdminUsers] = useState([]);
    const [customerUsers, setCustomerUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [refreshUsersData, setRefreshUsersData] = useState(true);

    useEffect(() => {
        async function onLoad() {
            console.log("onLoad Users")
            if (!props.isAuthenticated) {
                return;
            }
            try {
                // save the path if session expired
                localStorage.setItem('prevPath', '/administration');

                const users = await loadUsers();
                // Sorting all users (alphabetically)
                try {
                    users.sort(function(first, second){
                        return  (first.email.toLowerCase() > second.email.toLowerCase() ? 1 : -1);
                    });
                } catch (e){
                    console.log("Error Sorting users");
                    console.log(e);
                }
                setUsers(users);

                setCustomerUsers(getCustomerUsers(users));
                setAdminUsers(getAdminUsers(users));
                setDemoUsers(getDemoUsers(users));

                const customers = await loadCustomers();
                // Sorting all customer (alphabetically)
                try {
                    customers.sort(function(first, second){
                        return  (first.customer_name > second.customer_name ? 1 : -1);
                    });
                } catch (e){
                    console.log("Error Sorting customers");
                    console.log(e);
                }
                setCustomers(customers);

                // react-hooks/exhaustive-deps
                // eslint-disable-next-line
                try {
                    var jsonAttributes = [];
                    const res_current_session = await Auth.currentSession();
                    if (res_current_session.idToken.payload['cognito:groups'] &&
                        res_current_session.idToken.payload['cognito:groups'].includes("AdminUsers")) {
                            const await_users_attributes = await getCognitoUserAttributes(users);
                            await_users_attributes.forEach(function (item){
                                if(item.Username !== undefined){
                                    let key = item['Username'];
                                    jsonAttributes[key] = item;
                                }
                            });
                            jsonAttributes['length'] = await_users_attributes.length;
                    }
                    setAttributes(jsonAttributes);
                    // console.log(jsonAttributes)
                } catch(e) {
                    console.log("You are not authorised to get users attributes");
                }

                setIsLoading(false);
                setRefreshUsersData(false);
            } catch (e) {
                alert(e);
            }
        }
        onLoad();
    }, [props.isAuthenticated, refreshUsersData]);


    function loadUsers() {
        let loadUsers = []
        try {
            loadUsers = API.get("iot_users", "/iot_users");
        } catch (e) {
            console.log("Error in Loading Users!");
            console.log(e.message);
        }
        return loadUsers;
    }

    function loadCustomers() {
        let loadCustomers = []
        try {
            loadCustomers = API.get("iot_customer_config", "/iot_customer_config");
        } catch (e) {
            console.log("Error in Loading Customers!");
            console.log(e.message);
        }
        return loadCustomers;
    }

    function getAdminUsers(users) {
        var filtred_user = [];
        users.map( (user) => {
            if (user.customer_name === "osb") {
                filtred_user.push(user);
            }
            return user;
        } )
        return filtred_user;
    }

    function getCustomerUsers(users) {
        var filtred_user = [];
        users.map( (user) => {
            if (user.customer_name !== "osb" && user.customer_name !== "demo-account") {
                filtred_user.push(user);
            }
            return user;
        } )
        return filtred_user;
    }

    function getDemoUsers(users) {
        var filtred_user = [];
        users.map( (user) => {
            if (user.customer_name === "demo-account") {
                filtred_user.push(user);
            }
            return user;
        } )
        return filtred_user;
    }

    async function updateUsersDataHandler(updateUsersDataActions){
        try {
            if ((updateUsersDataActions.action === "updateUsersData")){
                setRefreshUsersData(true);
            }
        }catch (e) {
            console.log(e.message);
        }
    }

    async function getCognitoUserAttributes(users) {
        const user_attributes_array =  users.map(async user => {
            var params = {
                UserPoolId: config.cognito.USER_POOL_ID,
                Username: user.email
            };
            const current_credentials = await Auth.currentCredentials();
            setEssentialCredentials(current_credentials);
            var cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
                region: config.cognito.REGION,
                credentials: {
                    accessKeyId: current_credentials.accessKeyId,
                    secretAccessKey: current_credentials.secretAccessKey,
                    sessionToken: current_credentials.sessionToken
                }
            });
            let response = {};

            await cognitoidentityserviceprovider.adminGetUser(params).promise().then(resp => {
                response = resp;
            }).catch( err => {
                console.log(err.message);
                console.log("User could be deleted from DB.");
            });

            return response;
        });
        const await_users_attributes = await Promise.all(user_attributes_array);
        return await_users_attributes;
    }


    return (
        <div className="Administration">
            <Container className="Administration">
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Administration</Breadcrumb.Item>
                </Breadcrumb>
                <h1 className="text-left">System Administration</h1>
                {/* <p>Manage Users, customers and devices.</p> */}

                <ReactPlaceholder showLoadingAnimation className='text-center' ready={!isLoading} customPlaceholder={
                    <div className='text-center'>
                        <br/>
                        <TextRow color='#f2f2f3' style={{width: 'auto', height: 40}}/>
                        <br/>
                        <Row>
                            <Col xs="3">
                                <TextRow color='#f2f2f3' style={{width: 'auto', height: 200}}/>
                            </Col>
                        </Row>
                    </div>
                    }>
                    <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example">
                        <Tab eventKey="overview" title={<span className={"text-secondary"}><FontAwesomeIcon icon="microscope"/> Overview</span>}>
                            <h4>Administration Tasks</h4>

                            <Link to="/createUser">
                                <Button style={{margin: "5px"}} variant="primary" >
                                    <FontAwesomeIcon icon={faUserPlus} className="supportIcon"/> Create User
                                </Button>
                            </Link>
                            <Link to="/createCustomer">
                                <Button style={{margin: "5px"}} variant="primary" >
                                    <FontAwesomeIcon icon={faIndustry} className="supportIcon"/> Create Customer
                                </Button>
                            </Link>

                            <h4>Statistics</h4>
                            <p>Total Users: <Badge variant="primary">{users.length}</Badge></p>

                        </Tab>
                        {/* <Tab eventKey="statistics" title={<span className={"text-secondary"}><FontAwesomeIcon icon={faChartPie}/> Statistics </span>}>
                            <h4>Statistics</h4>
                            <p>Total Users: <Badge variant="primary">{users.length}</Badge></p>

                        </Tab> */}


                        <Tab eventKey="user_demo" title={<span className={"text-info"}><FontAwesomeIcon icon="user-secret"/> Demo Users <Badge variant="info">{demoUsers.length}</Badge></span>}>
                            <div className="tableTop">
                                <UsersTable
                                    users={demoUsers}
                                    customers={customers}
                                    users_attributes={users_attributes}
                                    essential_credentials={essential_credentials}
                                    onUpdateUsersDataActions={updateUsersDataHandler}>
                                </UsersTable>
                            </div>
                        </Tab>
                        <Tab eventKey="user_customers" title={<span className={"text-secondary"}><FontAwesomeIcon icon="user-tie"/> Customer Users <Badge variant="secondary">{customerUsers.length}</Badge></span>}>
                            <div className="tableTop">
                                <UsersTable
                                    users={customerUsers}
                                    customers={customers}
                                    users_attributes={users_attributes}
                                    essential_credentials={essential_credentials}
                                    onUpdateUsersDataActions={updateUsersDataHandler}>
                                </UsersTable>
                            </div>
                        </Tab>
                        <Tab eventKey="user_osb" title={<span className={"text-danger"}><FontAwesomeIcon icon="user-cog"/> Admin Users <Badge variant="danger">{adminUsers.length}</Badge></span>}>
                            <div className="tableTop">
                                <UsersTable
                                    users={adminUsers}
                                    customers={customers}
                                    users_attributes={users_attributes}
                                    essential_credentials={essential_credentials}
                                    onUpdateUsersDataActions={updateUsersDataHandler}>
                                </UsersTable>
                            </div>
                        </Tab>
                        <Tab eventKey="customers" title={<span className={"text-secondary"}><FontAwesomeIcon icon="building"/> Customer Accounts <Badge variant="secondary">{customers.length}</Badge></span>} >
                            <div className="tableTop">
                                <CustomersTable customers={customers}></CustomersTable>
                            </div>
                        </Tab>
                        <Tab eventKey="deleted_users" title={<span className={"text-secondary"}><FontAwesomeIcon icon="user-slash"/> Deleted Users </span>}>
                            <div className="tableTop">
                                <DeletedUsersTable
                                    users={users}
                                    users_attributes={users_attributes}>
                                </DeletedUsersTable>
                            </div>
                        </Tab>
                        <Tab eventKey="disabled_users" title={<span className={"text-dark"}><FontAwesomeIcon icon="user-times"/> Disabled Users </span>}>
                            <div className="tableTop">
                                <DisabledUsersTable
                                    users={users}
                                    users_attributes={users_attributes}
                                    essential_credentials={essential_credentials}
                                    onUpdateUsersDataActions={updateUsersDataHandler}>
                                </DisabledUsersTable>
                            </div>
                        </Tab>
                        <Tab eventKey="unconfirmed_users" title={<span className={"text-secondary"}><FontAwesomeIcon icon="user-clock"/> Unconfirmed Users </span>}>
                            <div className="tableTop">
                                <UnconfirmedUsersTable
                                    users={users}
                                    users_attributes={users_attributes}
                                    essential_credentials={essential_credentials}
                                    onUpdateUsersDataActions={updateUsersDataHandler}>
                                </UnconfirmedUsersTable>
                            </div>
                        </Tab>
                    </Tabs>
                </ReactPlaceholder>
            </Container>
        </div>
    );
}


