import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Alert, Container, Form } from "react-bootstrap";
import { Multiselect } from 'multiselect-react-dropdown';
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "./CreateCustomer.css";
import Spinner from "../components/Spinner";
import RolloutServerManagement from "../components/Administration/CustomerAdministration/CustomerCreation/RolloutServerManagement";

export default function UpdateCustomer(props) {
    const customerGroups = [{name: 'Owner', id: 1}, {name: 'Reseller', id: 2}, {name: 'Admin', id: 3}];
    const [fields, handleFieldChange] = useFormFields({
        mail_error: "",
        mail_feature: "",
        mail_info: "",
        mail_warn: "",
        rollout_server_url1: "",
        rollout_server_url2: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [customer, setCustomer] = useState({});
    const [groups, setGroups] = useState([]);

    const customerGroupMultiSelectStyle = {
        searchBox: { // To change search box element look
        minHeight: "46px"
        },
        chips: { // To change css chips(Selected options)
        background:  "#114c92"
        }
    }

    useEffect(() => {
        async function onLoad() {
            if (!props.isAuthenticated || !props.loggedInUser) {
                return;
            }
            try {
                if (!props.location.param) {
                    const customer = await API.get("iot_customer_config", `/iot_customer_config/${props.match.params.id}/${props.match.params.parent}`);
                    setCustomer(customer);
                    fields.rollout_server_url1=customer["hawkbit-servers"][0];
                    fields.rollout_server_url2=customer["hawkbit-servers"][1];
                    setGroups(customer.groups?.map((group, i) => {
                        return { name: group, id: i };
                    }));
                } else {
                    const customer = props.location.param;
                    setCustomer(customer);
                    fields.rollout_server_url1=customer["hawkbit-servers"][0];
                    fields.rollout_server_url2=customer["hawkbit-servers"][1];
                    setGroups(props.location.param.groups?.map((group, i) => {
                        return { name: group, id: i };
                    }));
                }
            } catch (e) {
                alert(e);
            }
            setIsLoading(false);
        }
        onLoad();
    }, [props.isAuthenticated, props.loggedInUser, props.location.param, props.match.params.id, props.match.params.parent, fields, customer]);

    // function validateForm() {
    //     return groups && groups.length > 0
    // }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await updateCustomer();
            props.history.push("/administration");
        } catch (e) {
            alert(e.message);
            setIsLoading(false);
        }
    }

    function updateCustomer() {
        const customerGroups = groups.map(group => {
            return group.name;
        });
        return API.put("iot_customer_config", `/iot_customer_config/${props.match.params.id}/${props.match.params.parent}`, {
            body: {
                groups: customerGroups,
                communication: {
                    mail_error: fields.mail_error ? fields.mail_error : customer.communication?.mail_error,
                    mail_feature: fields.mail_feature ?  fields.mail_feature : customer.communication?.mail_feature,
                    mail_info: fields.mail_info ? fields.mail_info : customer.communication?.mail_info,
                    mail_warn: fields.mail_warn ? fields.mail_warn : customer.communication?.mail_warn
                },
                hawkbit_servers: [ `${fields.rollout_server_url1}`, `${fields.rollout_server_url2}` ]
                }
            }
        );
    }

    function onSelectGroup(selectedList) {
        setGroups(selectedList);
    }
     
    function onRemoveGroup(selectedList) {
        setGroups(selectedList);
    }

    function onHandleFieldChangeRolloutServer1(field, event) {
        fields[`${field}1`] = event.target.value;
    }

    function onHandleFieldChangeRolloutServer2(field, event) {
        fields[`${field}2`] = event.target.value;
    }

    function renderForm() {
        return (
            <Container>
                <div className="description">
                    <h1>Edit Customer</h1>

                    <form onSubmit={handleSubmit}>
                        <Form.Group controlId="customerName">
                            <Form.Label>Customer Name</Form.Label>
                            <Form.Control
                                type="text"
                                disabled
                                value={customer && customer.customer_name ? customer.customer_name : ""}
                            />
                        </Form.Group>
                        <Form.Group controlId="parent">
                            <Form.Label>Parent</Form.Label>
                            <Form.Control type="text"
                                value={customer && customer.parent ? customer.parent : ""}
                                disabled>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="group">
                            <Form.Label>Customer Groups</Form.Label>
                            <Multiselect options={customerGroups} style={customerGroupMultiSelectStyle}
                                placeholder="Please choose..."
                                selectedValues={groups}
                                onSelect={onSelectGroup}
                                onRemove={onRemoveGroup}
                                displayValue="name"/>
                        </Form.Group>
                        <h3>Contact Details</h3>
                        <Form.Group controlId="mail_error">
                            <Form.Label>Error Mail</Form.Label>
                            <Form.Control
                                type="email"
                                value={fields.mail_error === "" ? customer.communication?.mail_error : fields.mail_error}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="mail_feature">
                            <Form.Label>Feature Mail</Form.Label>
                            <Form.Control
                                type="email"
                                value={fields.mail_feature === "" ? customer.communication?.mail_feature : fields.mail_feature}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="mail_info">
                            <Form.Label>Info Mail</Form.Label>
                            <Form.Control
                                type="email"
                                value={fields.mail_info === "" ? customer.communication?.mail_info : fields.mail_info}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="mail_warn">
                            <Form.Label>Warning Mail</Form.Label>
                            <Form.Control
                                type="email"
                                value={fields.mail_warn === "" ? customer.communication?.mail_warn : fields.mail_warn}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <h3>Rollout Servers</h3>
                        <h4 className="subHeadline" style={{marginTop: "-24px"}}>Rollout Server 1</h4>
                        <RolloutServerManagement
                        handleFieldChange={onHandleFieldChangeRolloutServer1}
                        rollout_server_url={fields.rollout_server_url1}
                        ></RolloutServerManagement>
                        <h4 className="subHeadline">Rollout Server 2</h4>
                        <RolloutServerManagement
                        handleFieldChange={onHandleFieldChangeRolloutServer2}
                        rollout_server_url={fields.rollout_server_url2}
                        ></RolloutServerManagement>
                        <LoaderButton
                            block
                            type="submit"
                            isLoading={isLoading}
                            // disabled={!validateForm()}
                            disabled="true"
                        >
                            Save Changes
                        </LoaderButton>

                    </form>
                    <Alert variant="warning">
                    <Alert.Heading>Under Construction</Alert.Heading>
                        This API call cannot be used at the moment. Please update via the Database Backend.
                    </Alert>
                </div>
            </Container>
        );
    }

    return (
        <div className="CreateUser">
            {isLoading ? <Spinner></Spinner> : renderForm()}
        </div>
    );
}