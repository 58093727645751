import React from "react";
import TableFilter from 'react-table-filter';
import "../../../../node_modules/react-table-filter/lib/styles.css";
import Badge from "react-bootstrap/Badge";
import Moment from 'react-moment';
import 'moment-timezone';
import { Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./DevicesTable.css";

export default function DevicesTable(props) {
    function filterUpdated(newData, filterConfiguration) {
        let filteredDevicesByColumn = newData;
        props.onColumnFilterChanged(filteredDevicesByColumn, filterConfiguration);
    }


    function renderDevicesTableDetails() {
        return (
            <Table striped bordered hover>
                <thead>
                    <TableFilter
                        rows={props.devices}
                        initialFilters={props.filterConfiguration}
                        onFilterUpdate={filterUpdated.bind(this)}>
                        <th key="device_name" filterkey="device_name">Device Name</th>
                        <th key="custom_name" filterkey="custom_name">Custom Name</th>
                        <th key="last_seen" filterkey="last_seen">LastSeen</th>
                        {/* <th key="health" filterkey="device_health.device_health_status">Health-Summary</th> */}
                        <th key="simno" filterkey="simno">SIM ID</th>
                        <th key="shadow_version" filterkey="shadow_version">Shadow</th>
                        {/* <th key="shadow_size" filterkey="shadow_size">Shadow Size</th> */}
                        {/* <th key="hawkbit_url" filterkey="hawkbit_url">RolloutSrv</th> */}
                        <th key="oem" filterkey="oem">OEM</th>
                        <th key="device_owner" filterkey="device_owner">Owner</th>
                        <th key="device_reseller" filterkey="device_reseller">Reseller</th>
                    </TableFilter>
                </thead>
                <tbody>
                    {renderTableRowsDetails()}
                </tbody>
            </Table>
        );
    }

    function openDeviceDetail(uid, event) {
        if (event.ctrlKey || event.metaKey) {
            window.open(`/device/${uid}`);
        }
    }

    function renderTableRowsDetails() {
        return [{}].concat(props.devices).map((device, i) =>
            i !== 0 ? (
                <LinkContainer onClick={openDeviceDetail.bind(this, device.uid)} key={device.uid} to={`/device/${device.uid}`}>
                    <tr key={device.uid} >
                    <td>{device.device_name.trim().split("\n")[0]}</td>
                    <td>{device.custom_name ? device.custom_name.trim().split("\n")[0] : ''}</td>
                    <td>{device.last_seen ? <div>
                        <Moment tz="Europe/Berlin" utc local format="YYYY-MM-DD HH:mm:ss" parse="YYYY-MM-DD HH:mm:ss"> {device.last_seen?.trim().split("\n")[0]}</Moment>
                        <br/>
                        <Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DD HH:mm:ss">{device.last_seen?.trim().split("\n")[0]}</Moment></Badge>
                        </div>
                        :
                        ''}
                    </td>
                    {/* <td>{device.device_health?.device_health_status ? device.device_health?.device_health_status : "No data"}</td> */}
                    <td>{device.simno ? device.simno.substring(0,19) : 'NONE'}</td>
                    <td>{device.shadow_version ? device.shadow_version : 'NONE'}</td>
                    {/* <td>{device.shadow_size ? device.shadow_size : 'NONE'}</td> */}
                    {/* <td>{device.hawkbit_url?.trim().split("\n")[0].replace(".aws.", "\n.aws.")}</td> */}
                    <td>{device.oem ? device.oem.trim().split("\n")[0] : ''}</td>
                    <td>{device.device_owner ? device.device_owner.trim().split("\n")[0] : ''}</td>
                    <td>{device.device_reseller ? device.device_reseller.trim().split("\n")[0] : ''}</td>
                    </tr>
                </LinkContainer>
            ) : (<tr key={i}></tr>)
        );
    }


    return (
            <div className="responsiveTable">
            {renderDevicesTableDetails()}
            </div>
    )
}
