import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row, Table } from "react-bootstrap";
import '../../../containers/Home.css';
import "./UploadFiles.css";
import { useFormFields } from "../../../libs/hooksLib";
import Badge from "react-bootstrap/Badge";
import Moment from 'react-moment';
import 'moment-timezone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFileUpload, faRocket, faFileDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
// React placeholder
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import {TextRow} from 'react-placeholder/lib/placeholders';
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";

export default function UploadFiles(props){
    const [currentFile, setCurrentFile] = useState();
    const [downloadFile, setDownloadFile] = useState();
    const [hotp_state, setHOTPState] = useState(false);
    // const [sortUp, setSortUp] = useState(true);
    const [fields, handleFieldChange] = useFormFields({
        desired_location: "",
        description: ""
    });
    // const minimalIoTClientVersion = "ESEC_BHT_V01.03.000";
    const [showInfo, setShowInfo] = useState(true);


    useEffect(() => {
        async function onLoad() {
            return 0
            }
            onLoad();
        }, [props.fileUploadData?.jobsInfo]);

    // function sortUpLogs() {
    //     try {
    //         props.fileUploadData?.jobsInfo.sort(function(first, second){
    //             return  (first.jobid < second.jobid ? 1 : -1)
    //         });
    //     } catch (e){
    //         console.log("Error Sorting Jobs");
    //         console.log(e);
    //     }
    //     setSortUp(true);
    // }

    // function sortDownLogs() {
    //     try {
    //         props.fileUploadData?.jobsInfo.sort(function(first, second){
    //             return  (first.jobid > second.jobid ? 1 : -1)
    //         });
    //     } catch (e){
    //         console.log("Error Sorting Jobs");
    //         console.log(e);
    //     }
    //     setSortUp(false);
    // }

    function validateForm() {
        let valid = false;
        if (
            fields.desired_location?.charAt(0) === "/"
            && fields.desired_location?.charAt(fields.desired_location.length-1) === "/"
            && currentFile
        ){
            valid = true
        }
        return valid;
    }

    const selectFile = (event) => {
        console.log(event?.target?.files[0])
        setCurrentFile(event?.target?.files[0]);
        console.log(currentFile)
      };

    async function loadMoreJobs() {
        props.onFileUploadActions({
            action: "loadMoreJobs"
        })
    }

    async function uploadFile(file) {
        props.onFileUploadActions({
            action: "uploadFile",
            file: file,
            desired_location: fields.desired_location,
            description: fields.description,
        })
        setCurrentFile("");
        fields.desired_location = "";
        fields.description = "";
    }


    async function uploadFileAndSend(file) {
        props.onFileUploadActions({
            action: "uploadFileAndSend",
            file: file,
            desired_location: fields.desired_location,
            description: fields.description,
        })
        setCurrentFile("");
        fields.desired_location = "";
        fields.description = "";
    }

    async function createJob(file_id, description) {
        props.onFileUploadActions({
            action: "createFileJob",
            file_id: file_id,
            description: description
        })
    }

    async function getDownloadLink(file_id) {
        const file = await props.onFileUploadActions({
            action: "getDownloadLink",
            file_id: file_id,
        })
        console.log("Download Link = " + file)
        setDownloadFile(file);
    }

    // async function getFileInfos(file_id) {
    //     props.onFileUploadActions({
    //         action: "getFileInfos",
    //         file_id: file_id,
    //     })
    // }

    async function deleteFile(file_id) {
        props.onFileUploadActions({
            action: "deleteFile",
            file_id: file_id
        })
    }

    // function download(){
    //     axios({
    //         url:'https://source.unsplash.com/random/500x500',
    //         method:'GET',
    //         responseType: 'blob'
    // })
    // .then((response) => {
    //        const url = window.URL
    //        .createObjectURL(new Blob([response.data]));
    //               const link = document.createElement('a');
    //               link.href = url;
    //               link.setAttribute('download', 'image.jpg');
    //               document.body.appendChild(link);
    //               link.click();
    // })
    // }


    function MessageInfo() {
        if (props.message.show && showInfo) {
          return (
            <Alert variant={props.message.variant} onClose={() => setShowInfo(false)} dismissible>
              <Alert.Heading>{props.message.headline}</Alert.Heading>
              {props.message.body}
            </Alert>
          );
        }
        return <></>;
    }


    // function showInstructions(){
    //     return (
    //     <div>
    //         <Alert variant={props.device?.iot_client >= minimalIoTClientVersion ? "success" : "warning"} >
    //         <Alert.Heading>Feature Preview</Alert.Heading>

    //         {props.device?.iot_client >= minimalIoTClientVersion ?
    //             <div>The OSB IoT Client Version <Badge variant="secondary"> {props.device?.iot_client? props.device?.iot_client : "NOT SET"} </Badge> &nbsp; is &nbsp; <Badge variant="success">compatible </Badge> </div>:
    //             <div>The OSB IoT Client Version <Badge variant="secondary"> {props.device?.iot_client? props.device?.iot_client : "NOT SET"} </Badge> &nbsp; is &nbsp; <Badge variant="danger">incompatible </Badge> <br/>
    //                 Please upgrade to at least <Badge variant="success">{minimalIoTClientVersion}</Badge>
    //                 &nbsp; Files cannot be processed by this device.</div> }
    //         </Alert>


    //     </div>
    //     )
    // }

    function uploadForm(){
        return(
            <div>
                <h4><FontAwesomeIcon icon={faFileUpload} className="supportIconHome"/> Upload new files to the repository (optional)</h4>
                <form>
                    <Row>
                        <Col xs="12">
                        <Form.Group controlId="file">
                                <Form.Label>Select File</Form.Label>
                                <Form.File
                                    id="custom-file"
                                    label={currentFile?.name ? currentFile?.name + " [~" + Math.ceil(currentFile?.size/1000) + "kB]" : "Select File"}
                                    onChange={selectFile}
                                    custom
                                />
                            </Form.Group>
                        </Col>
                        </Row>
                        <Row>
                        <Col xs="4">
                            <Form.Group controlId="desired_location">

                                <Form.Control
                                autoFocus
                                type="text"
                                placeholder="Put desired location"
                                value={fields.desired_location}
                                onChange={handleFieldChange}
                                />
                                <Form.Label>Location / ... / <br/>(e.g. /home/root/)</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs="4">
                            <Form.Group controlId="description">

                                <Form.Control
                                autoFocus
                                type="text"
                                placeholder="Description"
                                value={fields.description}
                                onChange={handleFieldChange}
                                />
                                <Form.Label>Please enter a description<br/>(e.g. Configuration Testdevice 152)</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs="4">
                            <Button
                                className="btn btn-success"
                                disabled={!validateForm()}
                                onClick={() => uploadFile(currentFile)}
                                block>
                                <FontAwesomeIcon icon={faFileUpload} className="supportIconHome"/> Upload
                            </Button>
                            <Button
                                className="btn btn-primary"
                                disabled={!validateForm()}
                                onClick={() => uploadFileAndSend(currentFile)}
                                block>
                                <FontAwesomeIcon icon={faRocket} className="supportIconHome"/> Upload and send to device
                            </Button>
                        </Col>
                    </Row>
                {/* <Alert show={showFileAlert} variant={showFileAlertVariant}>
                    <hr />
                    Workaround: Please click on reload to see the new file in the file table.
                    <div className="d-flex justify-content-end">
                    <Button onClick={() => setShowFileAlert(false)} variant="outline-warning">
                       Dismiss - No Reload
                    </Button>
                    <Button onClick={() => refreshPage()} variant="outline-success">
                       Reload Page
                    </Button>
                    </div>
                </Alert> */}

                </form>

                {/* {currentFile && (
                        <div className="progress" style={{marginBottom: "20px"}}>
                        <div
                            className="progress-bar progress-bar-info progress-bar-striped"
                            role="progressbar"
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress + "%" }}
                        >
                            {progress}%
                        </div>
                        </div>
                    )} */}
            </div>
        )
    }

    function renderJobsTable(){
        return (
            <div>
                <h4><FontAwesomeIcon icon="history" /> Job History Table</h4>
                <div className="card-header d-flex">
                    <div className="align-self-start mr-auto">
                        All Jobs created by members of your organisation
                    </div>
                    <div className="align-self-end ml-auto">
                        {props.fileUploadData?.lastEvaluatedKey !== "" &&  props.fileUploadData?.jobsInfo?.length !== 0 ?
                            <Button variant="info" onClick={() => loadMoreJobs()} size="sm">{props.fileUploadData?.jobsInfo?.length} jobs - Fetch more... <FontAwesomeIcon icon="chevron-circle-down" /></Button>
                        :
                            <Button variant="secondary" size="sm" disabled> {props.fileUploadData?.jobsInfo?.length} jobs - All jobs fetched</Button>
                        }
                    </div>
                </div>
                <div className="table table-responsive border tableFixHeadUploadFile">
                    <Table bordered>
                        <thead className="border">
                            <tr key="files-jobs">
                            <th>Job Id</th>
                            <th>Job Info</th>
                            <th>Logs
                                {/* <Row className="sortClass">
                                    Logs
                                    { sortUp === true?
                                        <Button className="ml-auto" variant="success" onClick={() => sortDownLogs()} size="sm">Sort <FontAwesomeIcon icon="sort-numeric-down-alt"/></Button>
                                    :
                                        <Button className="ml-auto" variant="success" onClick={() => sortUpLogs()} size="sm">Sort <FontAwesomeIcon icon="sort-numeric-up" /></Button>
                                    }
                                </Row> */}
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.fileUploadData?.jobsInfo &&
                                props.fileUploadData?.jobsInfo?.map((job, index) => (
                                <tr key={index}>
                                    <td>{job.actions[0]?.jobid}</td>
                                    <td>
                                        File name: <Badge variant="info">{job.actions[0]?.operation_details?.file_name}</Badge>&nbsp;<br/>
                                        Desired location: <Badge variant="info">{job.actions[0]?.operation_details?.desired_location}</Badge>&nbsp;<br/>
                                        Description: <Badge variant="info">{job.actions[0]?.operation_details?.description}</Badge>&nbsp;<br/>
                                        File size: <Badge variant="info">{job.actions[0]?.operation_details?.file_size} B</Badge>&nbsp;<br/>
                                        File id: <Badge variant="info">{job.actions[0]?.operation_details?.file_id}</Badge>&nbsp;<br/>
                                        Created by: <Badge variant="info">{job.actions[0]?.user_id}</Badge>&nbsp;<br/>
                                        {/* Device owner: <Badge variant="info">{job.actions[0].device_owner}</Badge>&nbsp;<br/> */}
                                        {/* Hold on fail: <Badge variant="info">{job.actions[0].hold_on_fail? job.actions[0].hold_on_fail : "No"}</Badge>&nbsp;<br/> */}
                                        {downloadFile?.file_id === job.actions[0]?.operation_details?.file_id ?
                                            <Button download={downloadFile?.file_name} href={downloadFile?.presigned_url} variant="success" className="supportButtonHome" size="sm">
                                                <FontAwesomeIcon icon={faFileDownload} className="supportIconHome"/> Click to Download
                                            </Button>
                                            :
                                            <Button variant="secondary" className="supportButtonHome" size="sm" onClick={() => getDownloadLink(job?.actions[0]?.operation_details?.file_id)}>
                                                <FontAwesomeIcon icon={faDownload} className="supportIconHome"/> Get Download Link
                                            </Button>
                                        }
                                    </td>
                                    <td>
                                        {job?.actions.map((jobid, index_job) => (
                                            <li key={index_job} className="list-group-item listItem">
                                                <p>
                                                    <b><Moment format="YYYY-MM-DD HH:mm:ss" tz="Europe/Berlin" utc local>{jobid?.last_updated_timestamp*1000}</Moment></b> <Badge
                                                        variant={jobid?.operation_status === "FAILED" ? "danger": jobid?.operation_status === "SUCCEEDED" ? "success" : "info"}>
                                                        {jobid?.operation_status}
                                                    </Badge> {jobid?.description}
                                                </p>
                                            </li>
                                        ))}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {props.fileUploadData?.jobsInfo?.length === 0 ?
                        <div> There have been no jobs for this device. Please contact our <Link to="/infoAndSupport" target="_blank">support</Link> if you think this might be an issue.</div>
                    : <></>}
                </div>
            </div>
        )
    }

    function renderFileTable(){
        return (
            <div>
            <h4><FontAwesomeIcon icon="folder-open" /> File Repository Table</h4>
            <div className="card">
                <div className="card-header">All files uploaded by members of your organisation.
                <br/>{hotp_state ? <span style={{color: 'green'}}> File ID has been copied to your Clipboard.</span> : null}
                </div>

                <Table bordered>
                    <thead>
                        <tr>
                        <th >File Name</th>
                        <th>File ID</th>
                        <th >Uploaded</th>
                        <th >File Size</th>
                        <th >Desired Location</th>
                        <th >Description</th>
                        {/* <th >Uploaded by</th>                             */}
                        <th >Create Job</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.fileUploadData.fileInfos &&
                            props.fileUploadData.fileInfos.map((file, index) => (
                            <tr key={index}>
                                <td>{file?.file_name}</td>
                                <td> {file?.file_id?.split("-", 1)}-...
                                    <br/>
                                    <CopyToClipboard text={file?.file_id}
                                        onCopy={() => setHOTPState(true)}>
                                        <Button className="btn btn-dark btn-sm" size="sm"><FontAwesomeIcon icon="copy" /></Button>
                                    </CopyToClipboard>
                                </td>
                                <td>
                                    <Moment format="YYYY-MM-DD HH:mm:ss" unix tz="Europe/Berlin" local>
                                        {file?.uploaded_at}
                                    </Moment><br/>
                                    <Badge variant="info">
                                        <Moment fromNow unix tz="Europe/Berlin" local>
                                            {file?.uploaded_at}
                                        </Moment>
                                    </Badge>
                                </td>
                                <td>{file?.file_size > 1000 ? "~"+ Math.ceil(file?.file_size / 1000) +"kB" : file?.file_size +"B"}</td>
                                <td>{file?.desired_location}</td>
                                <td>{file?.description}</td>
                                <td>
                                    <Button variant="primary" className="supportButtonHome" size="sm" onClick={() => createJob(file.file_id, file.description)}>
                                        <FontAwesomeIcon icon={faRocket} className="supportIconHome"/> Send to Device
                                    </Button><br/>
                                    {downloadFile?.file_id === file?.file_id ?
                                    <Button download={downloadFile?.file_name} href={downloadFile?.presigned_url} variant="success" className="supportButtonHome" size="sm">
                                        <FontAwesomeIcon icon={faFileDownload} className="supportIconHome"/> Click to Download
                                    </Button>
                                    :
                                    <Button variant="secondary" className="supportButtonHome" size="sm" onClick={() => getDownloadLink(file?.file_id)}>
                                        <FontAwesomeIcon icon={faDownload} className="supportIconHome"/> Get Download Link
                                    </Button>
                                    }
                                    <br/>
                                    {file?.jobs === undefined || (file?.jobs?.length === 0 && props.customer_name === "osb") ?
                                    <Button variant="danger" className="supportButtonHome" size="sm" onClick={() => deleteFile(file.file_id)}>
                                        <FontAwesomeIcon icon={faTrash} className="supportIconHome"/> Delete File
                                    </Button>
                                    :
                                    <></>
                                    }
                                </td>
                            </tr>
                            ))}
                    </tbody>
                </Table>
                {props.fileUploadData?.fileInfos?.length === 0 ?
                    <div>There have been no Files added by your community. Please contact our <Link to="/infoAndSupport" target="_blank">support</Link> if you think this might be an issue.</div>
                : <></>}
            </div>
        </div>
        )
    }


    return (
        <div>
        {/* {showInstructions()} */}

        <MessageInfo />

        {uploadForm()}

        <ReactPlaceholder showLoadingAnimation className='text-center' ready={!props.isLoadingFileUpload} customPlaceholder={
                <div className='text-center'>
                    <br/>
                    <TextRow color='#e4e4e7' style={{width: 'auto', height: 40}}/>
                    <TextRow color='#f2f2f3' style={{width: 'auto', height: 400}}/>
                </div>
            }>
        {renderJobsTable()}
        </ReactPlaceholder>

        <ReactPlaceholder showLoadingAnimation className='text-center' ready={!props.isLoadingFileUpload} customPlaceholder={
                <div className='text-center'>
                    <br/>
                    <TextRow color='#e4e4e7' style={{width: 'auto', height: 40}}/>
                    <TextRow color='#f2f2f3' style={{width: 'auto', height: 400}}/>
                </div>
            }>
        {renderFileTable()}
        </ReactPlaceholder>

        </div>
    );
}
