import React from "react";
import { useFormFields } from "../../../../libs/hooksLib";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from "@fortawesome/free-solid-svg-icons";

export default function RolloutServerManagement(props) {
    const [fields, handleFieldChange] = useFormFields({
        rollout_server_url: "",
    });

    function handleInput(fieldName, event) {
        handleFieldChange(event);
        props.handleFieldChange(fieldName, event)
    }
    return (
        <div>
            <Form.Group controlId="rollout_server_url">
                <Form.Label>URL</Form.Label>
                <InputGroup>
                    <InputGroup.Prepend>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faLink} />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        value={fields.rollout_server_url === "" ? props.rollout_server_url : fields.rollout_server_url}
                        onChange={handleInput.bind(this, 'rollout_server_url')}
                        placeholder="Enter URL"
                    />
                </InputGroup>
            </Form.Group>
        </div>
    );
}