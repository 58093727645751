import React from "react";
import { Button } from "react-bootstrap";
import Spinner from "./Spinner";
import "./LoaderButton.css";

export default function LoaderButton({
    isLoading,
    disabled = false,
    ...props
}) {
    return (
        <Button disabled={disabled || isLoading} {...props} className="LoginButton">
            {isLoading && <Spinner button="true"></Spinner>}
            {!isLoading && props.children}
        </Button>
    );
}