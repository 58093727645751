import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import {
    Row, Col, Container, Breadcrumb, Form, Table, Card, Badge, Alert
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Statistics.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from "@fortawesome/free-solid-svg-icons";
// import Spinner from "../components/Spinner";
import { PieChart } from 'react-minimal-pie-chart';
import Moment from "react-moment";
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import {TextRow} from 'react-placeholder/lib/placeholders';

export default function DeviceStatus(props) {
    const [statistics, setStatistics] = useState([]);
    const [deviceStatisticsList, setDeviceStatisticsList] = useState([]);
    const [activeDevices, setActiveDevices] = useState([]);
    const [inactiveDevices, setInactiveDevices] = useState([]);
    const [newDevicesList, setNewDevicesList] = useState([]);
    const [producedDevicesList, setproducedDevicesList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const DefaultTimeRange = "Last_30_days";
    const [currentTimeRange, setCurrenttimeRange] = useState(DefaultTimeRange);
    const MaxShadowVersionForNewDevice = 30;
    const MaxShadowVersionForProduction = 4;
    const MinRaucBundleInstallations = 1;
    const maxBundlesElements = 5;

    useEffect(() => {
        async function onLoad() {
            if (!props.isAuthenticated || !props.loggedInUser) {
                return;
            }
            try {
                console.log("onLoad");
                const statistics = await loadStatistics();
                // console.log(statistics);

                const devices_loaded = await loadDevices();
                // console.log(devices_loaded);

                // save the path if session expired
                localStorage.setItem('prevPath', '/statistics');

                let devices_array = [];
                let statistics_all = [];
                // console.log(props.loggedInUser.customer_name);
                if (props.loggedInUser.customer_name === "osb") {
                    let customer_devices = [];
                    let devices_bundle = [];

                    statistics_all['customer_devices']=customer_devices;
                    statistics_all['devices_bundle']=devices_bundle;

                    // As Admin every Element is the statistics of a customer!
                    // This flattens the array
                    statistics.forEach((element) => {
                        statistics_all.customer_devices.push(...element.customer_devices);
                        statistics_all.devices_bundle.push(...element.devices_bundle);
                    })
                    // console.log(statistics_all);
                    statistics_all.customer_devices.forEach(element => {
                        const device_data = devices_loaded.find( device => device.device_name === element.device_name );

                        devices_array.push({
                            device_name : element.device_name,
                            number_rauc_updates: element.data.statistics.rauc_updates[DefaultTimeRange],
                            number_shadow_updates: element.data.statistics.shadow_updates[DefaultTimeRange],
                            last_seen : element.data.last_seen,
                            reference_timestamp: element.data.reference_timestamp,
                            extra_data: device_data
                        });
                    })
                    // console.log(devices_array)
                    setDeviceStatisticsList(devices_array);

                    setStatistics(statistics_all);
                } else {
                    statistics.customer_devices.forEach(element => {
                        const device_data = devices_loaded.find( device => device.device_name === element.device_name );
                        devices_array.push({
                            device_name : element.device_name,
                            number_rauc_updates: element.data.statistics.rauc_updates[DefaultTimeRange],
                            number_shadow_updates: element.data.statistics.shadow_updates[DefaultTimeRange],
                            last_seen : element.data.last_seen,
                            reference_timestamp: element.data.reference_timestamp,
                            extra_data: device_data
                        });
                    })
                    setDeviceStatisticsList(devices_array);
                    setStatistics(statistics);
                }

                let new_devices_array = [];
                let produced_devices_array = [];
                devices_array.forEach(element => {
                    if (element.extra_data) {
                        if (element.extra_data.shadow_version <= MaxShadowVersionForNewDevice) {
                            if (element.extra_data.shadow_version <= MaxShadowVersionForProduction) {
                                produced_devices_array.push(element);
                            }else {
                                new_devices_array.push(element);
                            }
                        }
                    }
                })
                new_devices_array.sort(function(first, second){
                    return  (first.device_name > second.device_name ? 1 : -1)
                });
                setNewDevicesList(new_devices_array);

                produced_devices_array.sort(function(first, second){
                    return  (first.device_name > second.device_name ? 1 : -1)
                });
                setproducedDevicesList(produced_devices_array);


            } catch (e) {
                alert(e);
            }
            setIsLoading(false);
        }
        onLoad();
    }, [props.isAuthenticated, props.loggedInUser]);

    function loadStatistics() {
        return API.get("iot_statistics", "/statistics");
    }

    function loadDevices() {
        return API.get("iot_registry-endpoint", "/iot_devices_overview");
    }

    function onChangeDropdown(event) {
        const timerange = event.target.value;
        setCurrenttimeRange(timerange)
        let devices_array = [];
        if (statistics) {
            statistics.customer_devices.forEach(element => {
                devices_array.push({
                    device_name : element.device_name,
                    number_rauc_updates: element.data.statistics.rauc_updates[timerange],
                    number_shadow_updates: element.data.statistics.shadow_updates[timerange],
                    last_seen : element.data.last_seen,
                    reference_timestamp: element.data.reference_timestamp,
                    extra_data: element.extra_data
                });
            })
        }
        setDeviceStatisticsList(devices_array);
    }

    useEffect(() => {
        function getActiveDevices(){
            if (statistics) {
                let active_devices = [];
                let inactive_devices = [];
                deviceStatisticsList.forEach((element) => {
                    if(element.number_shadow_updates > 0) {
                        active_devices.push(element)
                    } else {
                        inactive_devices.push(element)
                    }
                });

                active_devices.sort(function(first, second){
                    return  (first.device_name > second.device_name ? 1 : -1)
                });
                inactive_devices.sort(function(first, second){
                    return  (first.device_name > second.device_name ? 1 : -1)
                });
                setActiveDevices(active_devices);
                setInactiveDevices(inactive_devices);
            }
        }
        getActiveDevices();
    }, [deviceStatisticsList, statistics])

    function timeRangeSelection() {
            let time_range_list= [
                "Last_24_hours",
                "Last_7_days",
                "Last_30_days",
                "Last_60_days",
                "Last_90_days",
                "Last_180_days",
                "Last_360_days"
            ]
        return time_range_list.map((time_range) => (
            <option key={time_range} value={time_range}>{time_range.replace(/_/g, " ")}</option>
            ));
    }

    function newActiveDevices() {
        return (
            <div>
                <h4>New devices (less than {MaxShadowVersionForNewDevice} connections)</h4>

            <Card className="devicesList tableFixHead">
                <Card.Body>
                    <Table striped bordered hover>
                        <thead>
                        <tr key="head">
                            <th>Device name</th>
                            <th>LastSeen</th>
                            <th>Shadow #</th>
                            <th>Rauc Updates</th>
                        </tr>
                        </thead>
                        <tbody>
                            {newDevicesList.length ?
                                newDevicesList.map( (element, index) => {
                                return (
                                    ( <tr key={index}>
                                        <td>{element.device_name}</td>
                                        <td><Moment unix tz="Europe/Berlin" format="YYYY-MM-DD HH:mm:ss" utc local>{element.last_seen}</Moment> <br/>
                                            <Badge variant="info"><Moment fromNow unix tz="Europe/Berlin" utc local>{element.last_seen}</Moment></Badge></td>
                                        <td>{element.extra_data.shadow_version}</td>
                                        <td>{element.number_rauc_updates}</td>
                                        </tr> )
                                );
                            })
                            :
                            <tr key="default">
                                <td>No device</td>
                                <td>in this</td>
                                <td>category</td>
                                <td>or filter set</td>
                            </tr>
                            }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            </div>
        )
    }

    function producedDevices() {
        return (
            <div>
                <h4>New produced (less than {MaxShadowVersionForProduction+1} connections)</h4>

            <Card className="devicesList tableFixHead">
                <Card.Body>
                <Table striped bordered hover>
                                <thead>
                                <tr key="head">
                                    <th>Device name</th>
                                    <th>LastSeen</th>
                                    <th>Shadow Version</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {producedDevicesList.length ?
                                        producedDevicesList.map( (element, index) => {
                                        return (
                                            ( <tr key={index}>
                                                <td>{element.device_name}</td>
                                                <td><Moment unix tz="Europe/Berlin" format="YYYY-MM-DD HH:mm:ss" utc local>{element.last_seen}</Moment> <br/>
                                                    <Badge variant="info"><Moment fromNow unix tz="Europe/Berlin" utc local>{element.last_seen}</Moment></Badge></td>
                                                <td>{element.extra_data.shadow_version}</td>
                                                </tr> )
                                        );
                                    })
                                    :
                                    <tr key="default">
                                        <td>No device</td>
                                        <td>in this</td>
                                        <td>category or filter set</td>
                                    </tr>
                                    }
                                </tbody>
                                </Table>
                </Card.Body>
            </Card>
            </div>
        )
    }


    function getRaucUpdates() {
        let last_24_hours = [];
        let last_seven_days = [];
        let last_30_days = [];
        let last_60_days = [];
        let last_90_days = [];
        let last_180_days = [];
        let last_360_days = [];
        if (statistics.customer_devices) {
            statistics.customer_devices.forEach(element => {
                    last_24_hours.push(element.data.statistics.rauc_updates.Last_24_hours);
                    last_seven_days.push(element.data.statistics.rauc_updates.Last_7_days);
                    last_30_days.push(element.data.statistics.rauc_updates.Last_30_days);
                    last_60_days.push(element.data.statistics.rauc_updates.Last_60_days);
                    last_90_days.push(element.data.statistics.rauc_updates.Last_90_days);
                    last_180_days.push(element.data.statistics.rauc_updates.Last_180_days);
                    last_360_days.push(element.data.statistics.rauc_updates.Last_360_days);
                })
            }
        let number_rauc_updates_last_24_hours = last_24_hours.reduce((a, b) => a + b, 0);
        let number_rauc_updates_last_seven_days = last_seven_days.reduce((a, b) => a + b, 0);
        let number_rauc_updates_last_30_days = last_30_days.reduce((a, b) => a + b, 0);
        let number_rauc_updates_last_60_days = last_60_days.reduce((a, b) => a + b, 0);
        let number_rauc_updates_last_90_days = last_90_days.reduce((a, b) => a + b, 0);
        let number_rauc_updates_last_180_days = last_180_days.reduce((a, b) => a + b, 0);
        let number_rauc_updates_last_360_days = last_360_days.reduce((a, b) => a + b, 0);
        return (
        <Card>
            <Card.Body>
                <Card.Title>
                Reported SOTA Updates
                </Card.Title>

                <Table striped bordered hover>
                <thead><tr key="head"><th>Period</th><th>RAUC Updates</th></tr>
                </thead>
                <tbody>
                    <tr key={"Last_24_hours"}><td>24 hours</td><td>{number_rauc_updates_last_24_hours}</td></tr>
                    <tr key={"Last_7_days"}><td>7 days</td><td>{number_rauc_updates_last_seven_days}</td></tr>
                    <tr key={"Last_30_days"}><td>30 days</td><td>{number_rauc_updates_last_30_days}</td></tr>
                    <tr key={"Last_60_days"}><td>60 days</td><td>{number_rauc_updates_last_60_days}</td></tr>
                    <tr key={"Last_90_days"}><td>90 days</td><td>{number_rauc_updates_last_90_days}</td></tr>
                    <tr key={"Last_180_days"}><td>180 days</td><td>{number_rauc_updates_last_180_days}</td></tr>
                    <tr key={"Last_360_days"}><td>360 days</td><td>{number_rauc_updates_last_360_days}</td></tr>
                </tbody>
                </Table>
            </Card.Body>
        </Card>
        );
    }

    function getRaucBundles() {
        let list_bundles = [];

        if (statistics.devices_bundle) {
            statistics.devices_bundle.forEach(element => {
                const result = list_bundles.findIndex( bundle => bundle.version === element.version );
                if (result >= 0){
                    list_bundles[result].count += element.devices.length;
                }else{
                    list_bundles.push({version : element.version, count: element.devices.length});
                }
            })
            // Renaming
            const index_stock = list_bundles.findIndex( bundle => bundle.version === "unknown-or-notset" );
                if (index_stock >= 0){
                    list_bundles[index_stock].version="Stock-Firmware (unknown)"
                }
        }

        var sorted_list_bundles = list_bundles;


        sorted_list_bundles.sort(function(first, second){
            return  (first.count < second.count ? 1 : -1);
        });

        return (
            <div>
                <Card>
                    <Card.Body className="raucList tableFixHead">
                        <Card.Title>
                        <h5>RAUC Firmware Bundles</h5>
                        </Card.Title>
                            <Row>
                            <Col xs={6}>
                                <Table striped bordered hover>
                                <thead><tr key="head"><th>RAUC bundles</th><th>Total number</th></tr>
                                </thead>
                                <tbody>
                                    {list_bundles.map( (element, index) => {
                                        return (
                                            element?.count >= MinRaucBundleInstallations ?
                                            ( <tr key={index}><td>{element.version}</td><td>{element.count}</td></tr> )
                                            : (<></>)
                                        );
                                    })}
                                </tbody>
                                </Table>
                            </Col>
                            <Col xs={6}>
                            <h5>Top5 Installed Versions</h5>
                            <ul className="listUnstyled">
                                    {sorted_list_bundles.slice(0, maxBundlesElements).map( (element, index) => {
                                        return (<li key={index}>{element.version} <br/><Badge variant="success">Count: {element.count}</Badge></li>);
                                    })}
                            </ul>
                            {/* <h5>Least5 Installed Versions</h5>
                                <ul className="listUnstyled">
                                    {sorted_list_bundles.slice(-maxBundlesElements).map( (element, index) => {
                                        return (<li key={index}>{element.version} <br/><Badge variant="secondary">Count {element.count}</Badge></li>);
                                    })}
                                    </ul> */}
                            </Col>
                            </Row>

                    </Card.Body>
                </Card>

            </div>
        );
    }

    function pieActiveInactive(){
        return (
            <div>
            <PieChart
                data={[
                    { title: 'Active', value: activeDevices.length, color: '#28a745'},
                    { title: 'Inactive', value: inactiveDevices.length, color: '#6c757d' },
                ]}
                label={({ dataEntry }) => dataEntry.value + " " + dataEntry.title}

                labelStyle = {{
                    fontSize: '5px',
                    fontFamily: 'sans-serif',
                }}

                animate
                animationDuration={1000}
                radius={40}
                // labelPosition={40}
                //viewBoxSize={[300,300]}
                // rounded
                lineWidth={30}
                paddingAngle={1}
                startAngle={-90}
                />
                <p>How many devices were connected in the {currentTimeRange.replace(/_/g, " ")}? </p>
            </div>
        );
    }


    function pieFleetCatagorization(){
        return (
            <div><PieChart
                data={[
                    { title: 'Mature',
                        value: (activeDevices.length + inactiveDevices.length
                                - producedDevicesList.length - newDevicesList.length), color: '#20c997' },
                    { title: 'Produced', value: producedDevicesList.length, color: '#007bff'},
                    { title: 'New', value: newDevicesList.length, color: '#6610f2' },

                ]}
                label={({ dataEntry }) => dataEntry.value + " " + dataEntry.title}

                labelStyle = {{
                    fontSize: '5px',
                    fontFamily: 'sans-serif',
                }}

                animate
                animationDuration={1000}
                radius={40}
                // labelPosition={60}
                // rounded
                lineWidth={30}
                paddingAngle={1}
                startAngle={-90}
                />
                <p>How many devices had frequent connections to the backend?
                </p>
            </div>
        );
    }

    function showInactiveDevices(){
        return (
            <div><h4> Inactive Devices</h4>
                        <p> (in selected Range: {currentTimeRange.replace(/_/g, " ") } ) </p>
                            <div className="card devicesList tableFixHead">
                                <Table striped bordered hover>
                                <thead><tr key="head">
                                    <th>Device name</th>
                                    <th>LastSeen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inactiveDevices.length ?
                                        inactiveDevices.map( (element, index) => {
                                        return (
                                            ( <tr key={index}>
                                                <td>{element.device_name}</td>
                                                <td>Last Seen:
                                                    <Moment unix tz="Europe/Berlin" format="YYYY-MM-DD HH:mm:ss" utc local>{element.last_seen}</Moment> <br/>
                                                    <Badge variant="info"><Moment fromNow unix tz="Europe/Berlin" utc local>{element.last_seen}</Moment></Badge></td>

                                                </tr> )
                                        );
                                    })
                                    :
                                    <tr key="default">
                                        <td>No device</td>
                                        <td>in this</td>
                                        <td>category</td>
                                        <td>or filter set</td>
                                    </tr>
                                }
                                </tbody>
                                </Table>
                            </div>
                            </div>
        );
    }

    function showActiveDevices(){
        return (
            <div>
                <h4> Active Devices</h4>
                        <p> (in selected Range: {currentTimeRange.replace(/_/g, " ") } ) </p>
                        <div className="card devicesList tableFixHead">

                                <Table striped bordered hover>
                                <thead><tr key="head">
                                    <th>Device name</th>
                                    <th>LastSeen</th>
                                    <th>Activities</th>
                                    <th>RaucUpdates</th>
                                    </tr>
                                </thead>
                                <tbody>
                                 {activeDevices.length ?
                                    activeDevices.map( (element, index) => {
                                        return (
                                            ( <tr key={index}>
                                                <td>{element.device_name}</td>
                                                <td>Last Seen:
                                                    <Moment unix tz="Europe/Berlin" format="YYYY-MM-DD HH:mm:ss" utc local>{element.last_seen}</Moment> <br/>
                                                    <Badge variant="info"><Moment fromNow unix tz="Europe/Berlin" utc local>{element.last_seen}</Moment></Badge></td>
                                                <td>{element.number_shadow_updates}</td>
                                                <td>{element.number_rauc_updates}</td>
                                                </tr> )
                                        );
                                    })
                                    :
                                    <tr key="default">
                                        <td>No device</td>
                                        <td>in this</td>
                                        <td>category</td>
                                        <td>or filter set</td>
                                    </tr>
                                }
                                </tbody>
                                </Table>
                                </div>
            </div>
        )
    }

    return (
            <div style={{marginBottom: "100px", marginTop: "10px"}}>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Statistics</Breadcrumb.Item>
                </Breadcrumb>

                <h1>Customer Fleet Statistics</h1>

                <ReactPlaceholder showLoadingAnimation className='text-center' ready={props.isAuthenticated && !isLoading} customPlaceholder={
                    <div className='my-awesome-placeholder text-center'>
                        <br/>
                        <TextRow color='#f2f2f3' style={{width: 'auto', height: 800}}/>
                    </div>
                }>
                <div>
                    <Row className="dev-attributes-row">
                    <Col xs={12}><h4>Activity Statistics (Messaging to Device Management)</h4></Col>
                    </Row>
                    <Row className="dev-attributes-row">
                        <Col xs={3}>
                            {deviceStatisticsList.length < 10 ?
                            <Alert variant="warning">
                                <Alert.Heading>Small Fleet Info</Alert.Heading>
                                Your fleet size = {deviceStatisticsList.length} is small.
                                <br/>Some calculations or diagrams may not make much sense.
                            </Alert>
                            : <Alert variant="info">
                                <Alert.Heading>Statistics</Alert.Heading>
                                Your fleet has = {deviceStatisticsList.length} devices.
                                <br/>Find out how many of were active in which period.
                            </Alert>
                            }
                            <Form.Group controlId="timeWindow">
                                <Form.Control as="select"
                                    defaultValue={currentTimeRange}
                                    onChange={onChangeDropdown}>
                                    {timeRangeSelection()}
                                </Form.Control>
                                <Form.Label>please change time range</Form.Label>
                            </Form.Group>

                        </Col>
                        <Col xs={3}>
                        {pieActiveInactive()}
                        </Col>
                        <Col xs={3}>
                        {pieFleetCatagorization()}
                        </Col>
                        <Col xs={3}>
                            <p>
                            <h4>Legend Activity (interval)</h4>
                            <b>Active:</b> Devices with more than one connections (interval)
                            <br/><b>Inactive:</b> Devices with no connections (interval)

                            <h4>Legend Maturity (Lifetime)</h4>
                            <b>Mature:</b> more than {MaxShadowVersionForNewDevice} connections (ever)
                            <br/><b>New:</b> between {MaxShadowVersionForNewDevice} and {MaxShadowVersionForProduction +1} connections (ever)
                            <br/><b>Production:</b> below {MaxShadowVersionForProduction +1} connections (ever)
                        </p>
                        </Col>

                        </Row>
                        <Row>
                        <Col xs={7}>
                        {showActiveDevices()}
                        </Col>
                        <Col xs={5}>
                        {showInactiveDevices()}

                        </Col>
                    </Row>
                    <Row>
                            <Col xs={5}>{producedDevices()}</Col>
                            <Col xs={7}>{newActiveDevices()}</Col>
                    </Row>

                    <Row className="dev-attributes-row">
                    <Col xs={12}><h4>Software Updates via RAUC and Hawkbit</h4></Col>
                    </Row>
                    <Row className="dev-attributes-row">
                        <Col xs={3}><div className="dev-attributes-heading raucList">{getRaucUpdates()}</div> <br/></Col>
                        <Col xs={9}>{getRaucBundles()}</Col>
                    </Row>
                </div>
                </ReactPlaceholder>
            </Container>
            </div>
    )
}