import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Alert, Button, Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";


export default function CustomerTunnelSettings(props) {

    console.log("CustomerTunnelSettings")
    console.log(props);
    async function deactivateRemoteConnection(UID) {
        props.onRemoteConnectionActions({
            action: "deactivateRemoteConnection",
            uid: UID
        })
    }

    async function abortRemoteConnectionRollout(UID, DELTA_STATUS) {
        props.onRemoteConnectionActions({
            action: "abortRemoteConnectionRollout",
            delta_status: DELTA_STATUS,
            uid: UID
        })
    }

    function getDeviceName(UID) {
        var deviceName = "";
        props.devicesOverviewList.forEach((device) => {
            if (UID === device.uid) {
                deviceName = device.device_name;
            }
        })
        return(
            <p>{deviceName}</p>
        )
    }

    function getPortsList(){
        return (
            <div className="card tableFixHead">
                <Table bordered>
                    <thead>
                        <tr>
                        <th >Port</th>
                        <th >Assigned Device</th>
                        <th >Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {   props.remoteConnectionData ? 
                            Object.keys(props.remoteConnectionData?.portsData?.current_ports_overview).map((port, index) => (
                            props.remoteConnectionData?.portsData?.current_ports_overview[port][0] ?
                                <tr key={index}>
                                    <td>
                                        <Badge variant="primary">{port}</Badge>
                                    </td>
                                    <td>
                                        {getDeviceName(props.remoteConnectionData?.portsData?.current_ports_overview[port][0]['thing_name'])}
                                        {props.remoteConnectionData?.portsData?.current_ports_overview[port][0]['delta_status'] !== "NO_PENDING_JOB" ?
                                            <Badge variant="danger">{props.remoteConnectionData?.portsData?.current_ports_overview[port][0]['delta_status'].replace(/_/g, " ")}</Badge>
                                        : <Badge variant="secondary">{props.remoteConnectionData?.portsData?.current_ports_overview[port][0]['delta_status'].replace(/_/g, " ")}</Badge> }
                                    </td>
                                    <td>
                                        {/* <Badge variant="info">Port is used. Creating the tunnel will deactivate the existing.</Badge> */}
                                        {/* <br/>
                                        <br/> */}
                                        {props.remoteConnectionData?.portsData?.current_ports_overview[port][0]['delta_status'] !== "NO_PENDING_JOB" ?
                                        <div>
                                            <Button variant="danger" onClick={() => abortRemoteConnectionRollout(
                                                    props.remoteConnectionData?.portsData?.current_ports_overview[port][0]['thing_name'],
                                                    props.remoteConnectionData?.portsData?.current_ports_overview[port][0]['delta_status'])}
                                                    size="sm" block>
                                                    <FontAwesomeIcon icon="window-close" /> ABORT {props.remoteConnectionData?.portsData?.current_ports_overview[port][0]['delta_status'].replace(/_/g, " ")}
                                            </Button>
                                            <br/> Info: A change was triggered by the managamenet system, but the device did not receive its new configuration yet.
                                        </div>
                                        :
                                        <Button variant="danger" onClick={() => deactivateRemoteConnection(props.remoteConnectionData?.portsData?.current_ports_overview[port][0]['thing_name'])} size="sm" block>
                                                <FontAwesomeIcon icon="unlink" /> Close Tunnel
                                        </Button>
                                        }
                                    </td>
                                </tr>
                            :
                            <></>
                            ))
                            :
                            <></>
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    function showCustomerSettings() {
        return(

            <Alert variant={"secondary"}>
                <Alert.Heading><FontAwesomeIcon icon="industry" /> Customer Subscription Settings</Alert.Heading>
                <div>
                <Row>
                    <Col xs={6}>
                        <div><FontAwesomeIcon icon="barcode" /> Port range: <Badge variant="primary">{props.remoteConnectionData?.portsData?.tunnel_remote_range}</Badge></div>
                    </Col>
                    <Col xs={6}>
                    <div><FontAwesomeIcon icon="flag-checkered" /> Port offset: <Badge variant="primary">{props.remoteConnectionData?.portsData?.tunnel_remote_port}</Badge></div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={6}>
                        <div><FontAwesomeIcon icon="bell" /> Available ports: <Badge variant="primary">
                        {props.remoteConnectionData?.portsData?.current_ports_overview ?
                            props.remoteConnectionData?.portsData?.tunnel_remote_range - props.remoteConnectionData?.used_port_number
                            : props.remoteConnectionData?.portsData?.tunnel_remote_range
                        }</Badge></div>
                    </Col>
                    <Col xs={6}>
                    <div><FontAwesomeIcon icon="bell-slash" /> Occupied ports: <Badge variant="primary">
                        {props.remoteConnectionData?.used_port_number ?
                            props.remoteConnectionData?.used_port_number
                            : "No Used Ports"}</Badge></div>
                    </Col>
                </Row>
                <br/>
                <div><FontAwesomeIcon icon="info-circle" /> If you want to expand your port range, please contact our <Link to="/infoAndSupport" target="_blank">support team</Link>.</div>
                <br/>
                <div><FontAwesomeIcon icon="info-circle" /> To open a tunnel for a device, use Remote Access tab in Device overview.</div>
                <br/>
                {/* {props.remoteConnectionData?.statusData?.status ?  // maybe show only under certain circumstances */}
                   { getPortsList() }
                {/* : <></>} */}
                </div>
            </Alert>
            )
        }

    return (<div>
                {showCustomerSettings()}
            </div>)

}