import './Home.css'
import { API, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Spinner from "../components/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom';
import WhereverSIMTable from '../components/Administration/WhereverSimAdministration/WhereverSIMTable';

export default function WhereverSimAdministration(props) {
    // const [filterConfiguration, setFilterConfiguration] = useState([]);
    const filterConfiguration = [];
    const [loadTime, setLoadTime] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [simsToBeActivated, setSimsToBeActivated] = useState([]);
    const [userSession, setUserSession] = useState(undefined);
    const [message, setMessage] = useState({show: "", variant: "secondary", headline: "", body: ""});


    const def_service_profile_id = "372976"     // ## Data & SMS - Geht nur bei ESEC
    const def_tariff_profile_id = "361881"      // ## Global Roaming - Geht nur bei ESEC

    useEffect(() => {
        async function onLoad() {
            if (!props.isAuthenticated || !props.loggedInUser) {
                return;
            }
            try {
                const starDbQuery_timestamp = Date.now()

                console.log("onLoad SIMs")
                if (!props.isAuthenticated) {
                    return;
                }
                try {
                    const session = await Auth.currentSession();
                    setUserSession(session);
                    const headers = {};
                    if (session) {
                        headers.Authorization = session.getIdToken().getJwtToken();
                    }

                    const sims = await loadSimCards(headers);
                    const issuedSims = getIssuedSims(sims)

                    setSimsToBeActivated(issuedSims);
                    // console.log("Sims To Be Activated With Endpoint")
                    // console.log(issuedSims)

                    // save the path if session expired
                    localStorage.setItem('prevPath', '/whereverSimAdministration');

                } catch (e) {
                    alert(e);
                }

            // const filterConfigurationLocalStorage = JSON.parse(localStorage.getItem('filterConfigurationSim'));
            // console.log('############################')
            // console.log(filterConfigurationLocalStorage);
            // console.log('############################')
            // setFilterConfiguration(filterConfigurationLocalStorage);
            const stopDbQuery_timestamp = Date.now()
            const loadTime = stopDbQuery_timestamp - starDbQuery_timestamp;
            setLoadTime(loadTime)
            } catch (e) {
                alert(e);
            }
            setIsLoading(false);
        }
        onLoad();
    }, [props.isAuthenticated, props.loggedInUser]);


    async function loadSimCards(header){
        return await API.get("whereversim", `/sim`,{
            headers: header
        });
    }

    function getIssuedSims(sims){
        var filtred_sims = [];
        sims.map( (sim) => {
            if ( (sim.status.id === 0 || sim.status.id === 2 ) && sim.customer_org.id === 10088 ) { // ESEC SIMs Only
                filtred_sims.push(sim);
            }
            return filtred_sims;
        })
        return filtred_sims;
    }


    function setColumnFilteredDevices(filteredDevices, updatedFilterConfiguration) {
        setSimsToBeActivated(filteredDevices);
        localStorage.setItem('filterConfigurationSim', JSON.stringify(updatedFilterConfiguration));
    }


    async function whereverSimHandler(simAction) {
        const headers = {};

        if (userSession) {
            headers.Authorization = userSession.getIdToken().getJwtToken();
        }

        try {
            console.log("Info: Changing Endpoint Settings");
            await whereverSimAction(simAction);

            const sims = await loadSimCards(headers);
            const issuedSims = getIssuedSims(sims)
            setSimsToBeActivated(issuedSims);
        } catch (e) {
            alert(e.message);
        }
    }

    async function whereverSimAction(simAction) {
        const headers = {};
        if (userSession) {
            headers.Authorization = userSession.getIdToken().getJwtToken();
        }
        try {
            //https://hfsykh1cub.execute-api.eu-central-1.amazonaws.com/prod/createEndpoint/{sim_id}
            //             name = "ESEC-" + str(sim_id) ## oder etwas anders
            // service_profile_id = "372976" ## Data & SMS
            // tariff_profile_id = "361881" ## Global Roaming K
            // endpoint_status_id = "0" ## 0 heißt enabled, 1 heißt disabled
            // sim_activate = "false" ## "false" oder "true"

            console.log("Checking Action");
            console.log(simAction.action);
            console.log(simAction.sim_id);
            console.log(simAction);

            if (simAction.action === "create_Endpoint_InactiveSIM"){
                setMessage({
                    show: true,
                    variant: "info",
                    headline: "Action: Create Endpoint and leave sim inactive for SIM-ID: " + simAction?.sim_id,
                    body: "This may take some seconds..."
                })

                if (simAction.sim_id){
                    console.log("create_Endpoint_InactiveSIM SIM")
                    const sim_activate = "false"
                    const endpoint_status_id = "0" // Standard = Aktiv
                    const name = "ESEC-"+simAction.sim_id
                    const res = await API.post("whereversim", `/admin/createEndpoint/${simAction.sim_id}?sim_activate=`+encodeURIComponent(sim_activate)+`&name=`+encodeURIComponent(name)+`&endpoint_status_id=`+encodeURIComponent(endpoint_status_id)+`&tariff_profile_id=`+encodeURIComponent(def_tariff_profile_id)+`&service_profile_id=`+encodeURIComponent(def_service_profile_id),{
                        headers: headers
                    });

                    if (res.message === "Successful"){
                        setMessage({
                            show: true,
                            variant: "success",
                            headline: "Action: Create Endpoint and leave sim inactive for SIM-ID: " + simAction?.sim_id,
                            body: "Success"
                        })
                    }else{
                        setMessage({
                            show: true,
                            variant: "danger",
                            headline: "Action: Create Endpoint and leave sim inactive for SIM-ID: " + simAction?.sim_id,
                            body: "Failed"
                        })
                    }

                    console.log(res)
                }
            }
            if (simAction.action === "create_Endpoint_ActiveSIM"){
                setMessage({
                    show: true,
                    variant: "info",
                    headline: "Action: Create Endpoint and activate SIM for SIM-ID:" + simAction?.sim_id,
                    body: "This may take some seconds..."
                })

                console.log("create_Endpoint_ActiveSIM")
                const sim_activate = "true"
                const endpoint_status_id = "0" // Standard = Aktiv
                const name = "ESEC-"+simAction.sim_id
                const res = await API.post("whereversim", `/admin/createEndpoint/${simAction.sim_id}?sim_activate=`+encodeURIComponent(sim_activate)+`&name=`+encodeURIComponent(name)+`&endpoint_status_id=`+encodeURIComponent(endpoint_status_id)+`&tariff_profile_id=`+encodeURIComponent(def_tariff_profile_id)+`&service_profile_id=`+encodeURIComponent(def_service_profile_id),{
                    headers: headers
                });
                if (res.message === "Successful"){
                    setMessage({
                        show: true,
                        variant: "success",
                        headline: "Action: Create Endpoint and activate SIM for SIM-ID:" + simAction?.sim_id,
                        body: "Success"
                    })
                }else{
                    setMessage({
                        show: true,
                        variant: "danger",
                        headline: "Action: Create Endpoint and activate SIM for SIM-ID:" + simAction?.sim_id,
                        body: "Failed"
                    })
                }
                console.log(res)
            }
            if (simAction.action === "activate_SIM"){
                setMessage({
                    show: true,
                    variant: "info",
                    headline: "Action: Activate SIM " + simAction?.sim_id,
                    body: "This may take some seconds..."
                })

                console.log("Activate SIM")
                const res = await API.patch("whereversim", `/admin/sim/${simAction.sim_id}?status=activate`,{
                    headers: headers
                });

                if (res.message === "Successful"){
                    setMessage({
                        show: true,
                        variant: "success",
                        headline: "Action: Activate SIM " + simAction?.sim_id,
                        body: "Success"
                    })
                }else{
                    setMessage({
                        show: true,
                        variant: "danger",
                        headline: "Action: Activate SIM " + simAction?.sim_id,
                        body: "Failed"
                    })
                }

                console.log(res)
            }
            if (simAction.action === "deactivate_SIM"){
                setMessage({
                    show: true,
                    variant: "info",
                    headline: "Action: DeActivate SIM " + simAction?.sim_id,
                    body: "This may take some seconds..."
                })
                console.log("Deactivate SIM")
                const res = await API.patch("whereversim", `/admin/sim/${simAction.sim_id}?status=suspend`,{
                    headers: headers
                });
                if (res.message === "Successful"){
                    setMessage({
                        show: true,
                        variant: "success",
                        headline: "Action: DeActivate SIM " + simAction?.sim_id,
                        body: "Success"
                    })
                }else{
                    setMessage({
                        show: true,
                        variant: "danger",
                        headline: "Action: DeActivate SIM " + simAction?.sim_id,
                        body: "Failed"
                    })
                }
                console.log(res)
            }
        } catch (e) {
            alert(e.message);
        }
    }



    function renderDevices() {
        return (
            <div className="devices">
                <Row className="dev-attributes-row">
                    <Col xs={12}><div className="dev-attributes-heading-annotations"> <FontAwesomeIcon icon="sim-card" /> Issued (unactivated): {simsToBeActivated.length} &nbsp; &nbsp; <FontAwesomeIcon icon="hourglass-end" /> {loadTime} ms</div>  </Col>
                </Row>
                <WhereverSIMTable onColumnFilterChanged={setColumnFilteredDevices}
                    filterConfiguration={filterConfiguration}
                    simsToBeActivated={simsToBeActivated}
                    onWhereverSimActions={whereverSimHandler}
                    message={message}>
                    </WhereverSIMTable>
            </div>
        );
    }

    return (
        <div className="Home">
            <Container>
                <Breadcrumb>
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
                        <Breadcrumb.Item active> WhereverSIM Activation</Breadcrumb.Item>
                </Breadcrumb>
                <h1>WhereverSIM Activation (ESEC SIMs only)</h1>
                {props.isAuthenticated && !isLoading ? renderDevices() : <Spinner></Spinner>}
            </Container>
        </div>
    );
}