import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSVLink } from "react-csv";

export default function ConvertToCSV(props) {
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + ":" + today.getMinutes();
    let currentDate = date + "_" + time;
    let fileName = props.filename + "_" + currentDate + ".csv";

    function pivot(arr) {
        if (props.excluded) {
            arr.forEach(element => {
                props.excluded.forEach(excluded_element => {
                    delete element[excluded_element];
                    }
                )
            })
        }
        var mp = new Map();
        function setValue(a, path, val) {
            if (Object(val) !== val) { // primitive value
                var pathStr = path.join('.');
                var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
                a[i] = val;
            } else {
                for (var key in val) {
                    setValue(a, key === '0' ? path : path.concat(key), val[key]);
                }
            }
            return a;
        }

        var result = arr.map( obj => setValue([], [], obj) );
        return [[...mp.keys()], ...result];
    }


    return  (
            <CSVLink data={pivot(props.data)}
                filename={fileName}
                target="_blank">
                {props.isDataChanged === true ?
                    <div style={{'color': 'red'}}>
                        <FontAwesomeIcon icon="file-csv" /> Download filtered data as CSV file.
                    </div>
                :   <div>
                        <FontAwesomeIcon icon="file-csv" /> Download data as CSV file.
                    </div>
                }
            </CSVLink>
        )
}