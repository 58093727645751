import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, InputGroup, Card, CardColumns, Badge, Alert } from "react-bootstrap";
import "./Home.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLock, faCogs, faHeartbeat, faSimCard, faInfoCircle, faTags, faRoad, faCubes, faPlusSquare, faLifeRing, faUsers, faCloudUploadAlt} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Moment from "react-moment";
import { Auth } from "aws-amplify";

export default function Home(props) {

    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const showMaxDevicesThreshhold = 12;
    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {

        Auth.currentSession().then(( () => {
            var devices_list_filtered = null;
            if (searchTerm.length >= 2) { // Do not start if less then 2 characters

                // Find all devices ending with string
                    devices_list_filtered = props.devicesOverviewList.filter((device) => {
                    const devicename = device.device_name.toLowerCase();
                    const searchTermLower = searchTerm.toLowerCase();
                    if (devicename.substring(devicename.length  - searchTermLower.length) === searchTermLower ){
                        return device;
                    }else
                        return null;
                });
                if (devices_list_filtered.length === 0){
                    devices_list_filtered = props.devicesOverviewList.filter((device) => {
                        const devicename = device.device_name.toLowerCase();
                        const searchTermLower = searchTerm.toLowerCase();
                        if (devicename.indexOf(searchTermLower) !== -1 ){
                            return device;
                        }else
                            return null;
                    });
                }
            }else {
                devices_list_filtered = null;
            }
            console.log("Search  Filtering")
            setSearchResults(devices_list_filtered);

            // save the path if session expired
            localStorage.setItem('prevPath', '/');
        })
        ).catch(e => {
            if (e === 'No current user') {
                props.history.push("/login");
            }
        });

      }, [searchTerm, props.devicesOverviewList]);


    function renderDevices() {
        return (
            searchResults.map(device => (
                <Card key={device?.uid} className="DeviceCards">
                <Card.Header as="h6"><FontAwesomeIcon icon="microchip" />  {device?.device_name}<br/>
                <div>Open in <Link  to={`/device/${device?.uid}`}> <Badge variant="primary">Same Page</Badge></Link>  <Link  target="_blank" rel="noreferrer" to={`/device/${device?.uid}`}> <Badge variant="secondary">New Tab</Badge></Link></div>
                </Card.Header>
                <Card.Body>
                <FontAwesomeIcon icon="clock" /> Last Seen: <Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DD HH:mm:ss">{device?.last_seen?.trim().split("\n")[0]}</Moment></Badge> <br/>
                <FontAwesomeIcon icon="heartbeat" /> Shadow #: {device?.shadow_version} <br/>
                <FontAwesomeIcon icon="sim-card" /> SIM: <small>{device?.simno?.substring(0,19) || 'unknown'}</small><br/>
                {props?.loggedInUser?.group === "Admin" ?
                <div>
                <FontAwesomeIcon icon="user" /> Owner: {device?.device_owner}<br/>
                {/* <FontAwesomeIcon icon="user-friends" /> Reseller: {device?.device?.device_reseller}<br/> */}
                <FontAwesomeIcon icon="hand-holding-heart" /> Admin: {device?.admin}<br/>
                {/* <FontAwesomeIcon icon="industry" /> ECU-Manufacturer:{device?.device?.oem}<br/> */}
                </div>
                :<></>
                }
                   {/* <div><Link  to={`/device/${device.uid}`}> Open details page</Link> </div> */}
                </Card.Body>
              </Card>
            ))
        );
    }


    return (
        <div className="Home">
            <Container className="Home">
                { props.loggedInUser && props.loggedInUser.customer_name.startsWith("phytec") ?
                        <h1>PHYTEC IoT Device Suite</h1>
                        :
                        props.loggedInUser && props.loggedInUser.customer_name.startsWith("satel") ?
                        <h1>SATEL IoT Device Suite</h1>
                        :
                        <h1>iot rocket GmbH IoT Device Suite</h1>
                }

                {
                    /* <Alert variant="warning">
                            <Alert.Heading><FontAwesomeIcon icon="tools" /> Ongoing Maintenance</Alert.Heading>
                        <div>We are currently working on the IoT Device Suite.<br/>
                        We will finish the maintenance this week.</div>
                    </Alert> */
                }

                <h3>Quick Access</h3>

                <Row>
                    <Col md="3">
                        <Form.Group>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Search Device Name or Serial"
                                    value={searchTerm}
                                    onChange={handleChange.bind(this)}
                                />
                            </InputGroup>
                            <br/>
                            {searchTerm >= 2 ?
                            <div><Form.Label>Devices total: {props.devicesOverviewList ? props.devicesOverviewList.length : "0" } </Form.Label><br/>
                            <Form.Label>Results: {searchResults ? searchResults.length : "0"}</Form.Label> </div>
                            : <></>
                            }
                        </Form.Group>
                    </Col>
                    <Col md="9">
                            {searchResults?.length > "0" && searchResults?.length <= showMaxDevicesThreshhold?
                            <CardColumns>
                                {renderDevices()}
                            </CardColumns> :
                            searchResults?.length > showMaxDevicesThreshhold ?
                                <Alert variant="warning">
                                    Too many ({searchResults?.length}) devices match your search string. Please try to narrow down your search to {showMaxDevicesThreshhold} results.
                                </Alert>:
                                <Alert variant="secondary">
                                    Please enter any part of the device name to search. There are {props.devicesOverviewList ? props.devicesOverviewList.length: "0"} devices registered for your account.
                                </Alert>
                            }
                    </Col>
                </Row>

                <h3>Fleet Tables</h3>

                <Row>
                    <Col md="2">
                    <Link className="supportURL" to='/fleet'>
                            <Button style={{margin: "5px", width: "100%"}} variant="success" className="supportButtonHome">
                                <FontAwesomeIcon icon={faCubes} className="supportIconHome"/> Overview
                            </Button>
                    </Link>
    
                        {props?.loggedInUser?.group === "Admin" ?
                                <Link className="supportURL" to='/fleetAdmin'>
                                <Button style={{margin: "5px", width: "100%"}} variant="danger" className="supportButtonHome">
                                    <FontAwesomeIcon icon={faCubes} className="supportIconHome"/> OverView (Admin)
                                </Button>
                                </Link> : <></> }
                         <br/>
                        <ul className="featureList">
                            <li>Last Seen</li>
                            <li>Health Summary</li>
                            <li>Shadow Version</li>
                            <li>Rollout Server</li>
                            <li>Owner</li>
                            <li>Reseller</li>
                        </ul>
                    </Col>
                    <Col md="2">
                        <Link className="supportURL" to='/health'>
                            <Button style={{margin: "5px", width: "100%"}} variant="primary" className="supportButtonHome">
                                <FontAwesomeIcon icon={faHeartbeat} className="supportIconHome"/> Health & Filesystems
                            </Button>
                        </Link><br/>
                        <ul className="featureList">
                            <li>RAM usage</li>
                            <li>RootFS usage</li>
                            <li>AppFS usage</li>
                            <li>SD Card usage</li>
                            <li>Uptime</li>
                            <li>Last Seen</li>
                        </ul>
                    </Col>
                    <Col md="2">
                        <Link className="supportURL" to='/whereversim'>
                            <Button style={{margin: "5px",  width: "100%"}} variant="primary" className="supportButtonHome">
                                <FontAwesomeIcon icon={faSimCard} className="supportIconHome"/> WhereverSIM
                            </Button>
                        </Link><br/>
                        <ul className="featureList">
                            <li>SIM/ICCID</li>
                            <li>Online status</li>
                            <li>IP Address</li>
                            <li>Tariff</li>
                            <li>Data Consumption</li>
                            <li>Costs</li>
                        </ul>
                    </Col>
                    <Col md="2">
                        <Link className="supportURL" to='/software'>
                            <Button style={{margin: "5px",  width: "100%"}} variant="primary" className="supportButtonHome">
                                <FontAwesomeIcon icon={faCogs} className="supportIconHome"/> Software
                            </Button>
                        </Link><br/>
                        <ul className="featureList">
                            <li>Kernel</li>
                            <li>Active SW Bundle</li>
                            <li>Installation Dates</li>
                            <li>Slot Status</li>
                            <li>Fallback SW Bundle</li>
                            <li>Installation Status</li>
                        </ul>
                    </Col>
                    {/* <Col md="2">
                        <Link className="supportURL" to='/statistics'>
                            <Button style={{margin: "5px",  width: "100%"}} variant="primary" className="supportButtonHome">
                                <FontAwesomeIcon icon={faChartArea} className="supportIconHome"/> Statistics
                            </Button>
                        </Link><br/>
                        <ul className="featureList">
                            <li>Activity</li>
                            <li>RAUC Updates</li>
                            <li>Hawkbit Updates</li>
                            <li>WhereverSIM</li>
                            <li>Remote Connections</li>
                        </ul>
                    </Col> */}
                    <Col md="2">
                        <Link className="supportURL" to='/ownership'>
                            <Button style={{margin: "5px",  width: "100%"}} variant="warning" className="supportButtonHome">
                                <FontAwesomeIcon icon={faUserLock} className="supportIconHome"/> Ownership & Integrations
                            </Button>
                        </Link><br/>
                        <ul className="featureList">
                            <li>Device Name</li>
                            <li>SIM/ICCID</li>
                            <li>WherverSIM Account</li>
                            {/* <li>TeamViewer ID</li> */}
                            <li>Hawkbit URL</li>
                            <li>Ownership</li>
                        </ul>
                    </Col>
                </Row>

                <h3>Feature & Configuration</h3>

                <Row>
                    <Col md="2">
                        <Link className="supportURL" to='/tunnelAndFirewall'>
                            <Button style={{margin: "5px",  width: "100%"}} variant="primary" className="supportButtonHome">
                                <FontAwesomeIcon icon={faUserLock} className="supportIconHome"/> Tunnel and Firewall
                            </Button>
                        </Link><br/>
                        <ul className="featureList">
                            <li>Tunnel</li>
                            <li>Firewall</li>
                        </ul>
                    </Col>
                    
                    <Col md="2">
                        <Link className="supportURL" to='/hawkbitOverview'>
                            <Button style={{margin: "5px",  width: "100%"}} variant="primary" className="supportButtonHome">
                                <FontAwesomeIcon icon={faCloudUploadAlt} className="supportIconHome"/> hawkBit Administration
                            </Button>
                        </Link><br/>
                        <ul className="featureList">
                            <li>Firmware images overview</li>
                            <li>Upload signed firmware image</li>
                            <li>Add or remove firmware tags</li>
                        </ul>
                    </Col>
                </Row>

                { props.loggedInUser && props.loggedInUser.customer_name.startsWith("phytec") ?
                <div>
                    <h3 >PHYTEC Features</h3>
                    <Row >
                        <Col md="2">
                            <Link className="supportURL" to='/addDevice'>
                                <Button variant="outline-primary" className="supportButtonHome">
                                    <FontAwesomeIcon icon={faPlusSquare} className="supportIconHome"/> Add Device
                                </Button>
                            </Link>
                        </Col>
                        <Col md="4">
                        </Col>
                        <Col md="2">
                            <Link className="supportURL" to='/infoAndSupport'>
                                <Button variant="outline-primary" className="supportButtonHome">
                                    <FontAwesomeIcon icon={faUsers} className="supportIconHome"/> Add Users
                                </Button>
                            </Link>
                        </Col>
                        <Col md="4">
                        </Col>
                    </Row>
                    <Row >
                        <Col md="6">
                        As Phytec customer you can create one management account for every device. <p/>
                        However, if you own more than one device, you can manage all in the same account. <p/>
                        Please register any additional devices by proving the ownership.
                        </Col>

                        <Col md="6">
                        Currently you can only automatically add one useraccount (mail adress) with a demo kit.<br/>
                        If you would like to have more user accounts to manage your devices, please request them from our support.
                        </Col>
                    </Row>
                </div>
                :
                    <></>
                }

                <h3 >Information & Administration</h3>

                <Row>
                    <Col md="2">
                            <a className="supportURL" target="_blank" rel="noopener noreferrer" href='https://doc.iot-suite.io/'>
                                <Button variant="info" className="supportButtonHome">
                                    <FontAwesomeIcon icon={faInfoCircle} className="supportIconHome"/> Documentation
                                </Button>
                            </a>
                    </Col>

                    <Col md="2">
                        <Link className="supportURL" to='/infoAndSupport'>
                            <Button variant="info" className="supportButtonHome">
                                <FontAwesomeIcon icon={faLifeRing} className="supportIconHome"/> Support
                            </Button>
                        </Link>
                    </Col>

                    <Col md="4">
                        {props.isCustomerRoleAdmin ?
                            <Link className="supportURL" to='/administration'>
                                <Button variant="info" className="supportButtonHome">
                                    <FontAwesomeIcon icon={faUserLock} className="supportIconHome"/> User and Customer Administration
                                </Button>
                            </Link>
                            : <></>
                        }
                    </Col>
                    <Col md="4">
                        {props.isCustomerRoleAdmin ?
                            <Link className="supportURL" to='/whereverSimAdministration'>
                                <Button variant="info" className="supportButtonHome">
                                    <FontAwesomeIcon icon={faSimCard} className="supportIconHome"/> WhereverSIM Activation
                                </Button>
                            </Link>
                            : <></>
                        }
                    </Col>
                </Row>

                <h3 >IoT Device Suite Product</h3>

                <Row >
                    <Col md="2">
                        <a className="supportURL" target="_blank" rel="noopener noreferrer" href='https://doc.iot-suite.io/Releases_&_Changelog.html'>
                            <Button variant="outline-primary" className="supportButtonHome">
                                <FontAwesomeIcon icon={faTags} className="supportIconH ome"/> Changelog
                            </Button>
                        </a>
                    </Col>
                    <Col md="2">
                        <a className="supportURL" target="_blank" rel="noopener noreferrer" href='https://doc.iot-suite.io/Roadmap.html'>
                            <Button variant="outline-primary" className="supportButtonHome">
                                <FontAwesomeIcon icon={faRoad} className="supportIconH ome"/> Roadmap
                            </Button>
                        </a>
                    </Col>
                    {/* {props.isCustomerRoleAdmin ? */}
                    <Col md="2">
                            <a className="supportURL" target="_blank" rel="noopener noreferrer" href='https://openapi.iot-suite.io/'>
                                <Button variant="outline-primary" className="supportButtonHome">
                                    <FontAwesomeIcon icon={faCubes} className="supportIconHome"/> IoT Suite API
                                </Button>
                            </a>
                    </Col>
                    {/* : <></> } */}
                    <Col md="2">
                            <a className="supportURL" target="_blank" rel="noopener noreferrer" href='https://www.eclipse.org/hawkbit/apis/'>
                                <Button variant="outline-primary" className="supportButtonHome">
                                    <FontAwesomeIcon icon={faCubes} className="supportIconHome"/> Hawkbit API
                                </Button>
                            </a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
