import './Home.css'
import { API, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TableWhereverSim from "../components/Devices/DevicesTable/TableWhereverSim";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from "react-router-dom";
// React placeholder
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import {TextRow} from 'react-placeholder/lib/placeholders';
import ConvertToCSV from './ConvertToCSV.js'


export default function WhereverSIM(props) {
    // const [filterConfiguration, setFilterConfiguration] = useState([]);
    const filterConfiguration = [];
    const [fleetData, setFleetData] = useState([]);
    const [fleetDataLength, setFleetDataLength] = useState();
    const [loadTime, setLoadTime] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [fleetDataError, setFleetDataError] = useState(false);
    const [fleetDataEmpty, setFleetDataEmpty] = useState(false);

    useEffect(() => {
        async function onLoad() {
            if (!props.isAuthenticated || !props.loggedInUser) {
                return;
            }
            if (props.isCustomerRoleAdmin === undefined){
                return;
            }
            try {
                // Getting JSON Web Token
                const session = await Auth.currentSession();
                const headers = {};
                if (session) {
                    headers.Authorization = session.getIdToken().getJwtToken();
                }

                const startDbQuery_timestamp = Date.now()

                let fleetData = [];
                if (props.isCustomerRoleAdmin){
                    try {
                        fleetData = await API.get("whereversim", `/fleet/all`,{
                            headers: headers,
                        });
                        // console.log(fleetData);
                        // fleetDataAll.forEach((fleet) => {
                        //     fleetData.push(...fleet);
                        // })
                    } catch (e){
                        // alert("Error WhereverSIM Fleet Data for Admin: " + e.message);
                        console.log(e.message);
                        setFleetDataError(true);
                    }
                } else {
                    try {
                        fleetData = await API.get("whereversim", `/fleet`,{
                            headers: headers,
                        });
                    } catch (e){
                        // alert("Error WhereverSIM Fleet Data: " + e.message);
                        console.log(e);
                        console.log(e.message);
                        setFleetDataError(true);
                    }
                }
                // console.log("WhereverSIm Fleet Data");

                // Augment WhereverSIm data with IoT data
                try {
                    const devices_loaded = await loadDevices();
                    // console.log(devices_loaded);
                    fleetData.forEach(endpoint => {
                        const device_data = devices_loaded.find( device => device.device_name === endpoint.db?.device_name );
                        endpoint.device_data = device_data;
                    })
                } catch (e) {
                    console.log("Error load devices API call" + e.message);
                    setFleetDataError(true);
                }
                // console.log(fleetData);
                fleetData.sort(function(first, second){
                    return  (first.sim?.activation_date < second.sim?.activation_date ? 1 : -1)
                });
                if (fleetData.length === 0) {
                    setFleetDataEmpty(true);
                }
                // setFleetData(fleetData);
                setFleetData(fleetData);
                setFleetDataLength(fleetData.length);

                //const filterConfigurationLocalStorage = JSON.parse(localStorage.getItem('filterConfigurationWherverSIM'));
                //setFilterConfiguration(filterConfigurationLocalStorage);

                const stopDbQuery_timestamp = Date.now()
                const loadTime = stopDbQuery_timestamp - startDbQuery_timestamp;
                setLoadTime(loadTime);

                // save the path if session expired
                localStorage.setItem('prevPath', '/whereversim');
            } catch (e) {
                console.log(e);
                setFleetDataError(true);
            }
            setIsLoading(false);
        }
        onLoad();
    }, [props.isAuthenticated, props.loggedInUser, props.isCustomerRoleAdmin]);

    function loadDevices() {
        return API.get("iot_registry-endpoint", "/iot_devices_overview");
    }


    function setColumnFilteredDevices(filteredDevices, updatedFilterConfiguration) {
        setFleetData(filteredDevices);
        localStorage.setItem('filterConfigurationWherverSIM', JSON.stringify(updatedFilterConfiguration));

        if (fleetDataLength === filteredDevices.length) {
            setIsDataChanged(false);
        } else {
            setIsDataChanged(true);
        }
    }

    function renderDevices() {
        return (
            <div className="devices">
                {!fleetDataError?
                    !fleetDataEmpty?
                        <div>
                            <Row className="dev-attributes-row">
                                <Col xs={4}>
                                    <ConvertToCSV data={fleetData} filename="WhereverSimFleetInfo.csv" isDataChanged={isDataChanged} />
                                </Col>
                                <Col xs={4}></Col>
                                <Col xs={4}><div className="dev-attributes-heading-annotations"> <FontAwesomeIcon icon="sim-card" /> Endpoints: {fleetData.length} &nbsp; &nbsp; <FontAwesomeIcon icon="hourglass-end" /> {loadTime} ms</div>  </Col>
                            </Row>
                            <TableWhereverSim onColumnFilterChanged={setColumnFilteredDevices} filterConfiguration={filterConfiguration} whereversim={fleetData} isCustomerRoleAdmin={props.isCustomerRoleAdmin}></TableWhereverSim>
                        </div>
                    :
                        <div className="text-center" style={{"paddingTop": "100px"}}>
                            <h2><FontAwesomeIcon icon="folder-open" /></h2>
                            <h4>We didn&apos;t find any active SIM cards in your account. This could be for 2 reasons:</h4>
                            <li key="sales">You have no Wherever SIM cards. If you&apos;re interested, please contact our sales team: sales@iot-suite.io.</li>
                            <li key="support">A problem occurred while loading your WheverSIM data. Please try again later or contact our <Link to="/infoAndSupport" target="_blank">support</Link>.</li>
                        </div>
                :
                <div className="text-center" style={{"paddingTop": "100px"}}>
                    <h2><FontAwesomeIcon icon="exclamation-triangle" /></h2>
                    <h4>Oops! Something went Wrong. Please try again later or contact our <Link to="/infoAndSupport" target="_blank">support</Link>.</h4>
                </div>
                }
            </div>
        );
    }

    return (
        <div className="Home">
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/fleet" }}>Fleet</Breadcrumb.Item>
                    <Breadcrumb.Item active>WhereverSIM</Breadcrumb.Item>
                </Breadcrumb>
                <h1>WhereverSIM</h1>
                <ReactPlaceholder showLoadingAnimation className='text-center' ready={props.isAuthenticated && !isLoading} customPlaceholder={
                    <div className='my-awesome-placeholder text-center'>
                        <br/>
                        <TextRow color='#e4e4e7' style={{width: 'auto', height: 40}}/>
                        <TextRow color='#f2f2f3' style={{width: 'auto', height: 600}}/>
                    </div>
                }>
                    {renderDevices()}
                </ReactPlaceholder>

            </Container>
        </div>
    );
}