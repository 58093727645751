import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row, Table } from "react-bootstrap";
import '../../../containers/Home.css'
import "./RemoteCommand.css";
import { useFormFields } from "../../../libs/hooksLib";
import Badge from "react-bootstrap/Badge";
import Moment from 'react-moment';
import 'moment-timezone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faRocket, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
// React placeholder
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import {TextRow} from 'react-placeholder/lib/placeholders';
import { Link } from "react-router-dom";

export default function RemoteCommand(props){
    // const minimalIoTClientVersion = "ESEC_BHT_V01.03.000";
    // const [isCommandExists, setIsCommandExists] = useState(true);
    const [showInfo, setShowInfo] = useState(true);
    // const [sortUp, setSortUp] = useState(true);
    const [fields, handleFieldChange] = useFormFields({
        command: "",
        parameter: "",
        hidden_parameter: "",
        cwd: "/",
        runinbackground: false,
        addjobidasargument: false,
        description: ""
    });

    useEffect(() => {
        async function onLoad() {
            return 0
        }
        onLoad();
    }, [props.remoteCommandData?.jobsInfo]);

    // function commandExists(current_command) {
    //     setIsCommandExists(true);
    //     if (props.remoteCommandData?.commandsInfo !== undefined) {
    //         props.remoteCommandData.commandsInfo.forEach((command) => {
    //             if (current_command?.command === command?.command &&
    //                 current_command.cwd === command.cwd &&
    //                 current_command.parameter === command.parameter &&
    //                 current_command.hidden_parameter === command.hidden_parameter &&
    //                 current_command.runinbackground === command.runinbackground &&
    //                 current_command.addjobidasargument === command.addjobidasargument &&
    //                 current_command.description === command.description )
    //                 {
    //                     setIsCommandExists(false);
    //                     fields.command = "";
    //                     fields.cwd = "";
    //                     fields.parameter = "";
    //                     fields.hidden_parameter = "";
    //                     fields.runinbackground = false;
    //                     fields.addjobidasargument = false;
    //                     fields.description = "";
    //                 }
    //         });
    //     }
    // }

    function validateForm() {
        let valid = false;
        if (fields.cwd?.charAt(0) === "/" && fields.command){
            valid = true;
        }
        return valid;
    }

    function loadMoreJobs() {
        props.onRemoteCommandActions({
            action: "loadMoreJobs"
        })
    }

    async function registerCommand() {
        // commandExists(fields);
        // if (isCommandExists === false) {
            props.onRemoteCommandActions({
                action: "registerCommand",
                command: fields.command,
                parameter: fields.parameter,
                hidden_parameter: fields.hidden_parameter,
                cwd: fields.cwd,
                runinbackground: fields.runinbackground,
                addjobidasargument: fields.addjobidasargument,
                description: fields.description,
            })
            fields.command = "";
            fields.description = "";
    }

    async function registerCommandAndSend() {
        // commandExists(fields);
        // if (isCommandExists === false) {
            props.onRemoteCommandActions({
                action: "registerCommandAndSend",
                command: fields.command,
                parameter: fields.parameter,
                hidden_parameter: fields.hidden_parameter,
                cwd: fields.cwd,
                runinbackground: fields.runinbackground,
                addjobidasargument: fields.addjobidasargument,
                description: fields.description,
            })
            fields.command = "";
            fields.description = "";
    }

    async function daleteCommand(command, commandId) {
        props.onRemoteCommandActions({
            action: "deleteCommand",
            name: command,
            commandid: commandId
        })
    }

    function MessageInfo() {
        if (props.message.show && showInfo) {
          return (
            <Alert variant={props.message.variant} onClose={() => setShowInfo(false)} dismissible>
              <Alert.Heading>{props.message.headline}</Alert.Heading>
              {props.message.body}
            </Alert>
          );
        }
        return <></>;
    }

    async function createJob(commandid, description) {
        props.onRemoteCommandActions({
            action: "createCommandJob",
            command_id: commandid,
            description: description
        })
    }


    // function showInstructions(){
    //     return (
    //     <div>
    //         <Alert variant={props.device?.iot_client >= minimalIoTClientVersion ? "success" : "warning"} >
    //         <Alert.Heading> Further Configuration needed</Alert.Heading>

    //         {props.device?.iot_client >= minimalIoTClientVersion ?
    //             <div>The OSB IoT Client Version <Badge variant="secondary"> {props.device?.iot_client? props.device?.iot_client : "NOT SET"} </Badge> &nbsp; is &nbsp; <Badge variant="success">compatible </Badge> </div>:
    //             <div>The OSB IoT Client Version <Badge variant="secondary"> {props.device?.iot_client? props.device?.iot_client : "NOT SET"} </Badge> &nbsp; is &nbsp; <Badge variant="danger">incompatible </Badge> <br/>
    //                 Please upgrade to at least <Badge variant="success">{minimalIoTClientVersion}</Badge>
    //                 &nbsp; Commands cannot be processed by this device.</div> }
    //         </Alert>
    //     </div>
    //     )
    // }

    function uploadForm(){
        return(
            <div>
                <h4><FontAwesomeIcon icon={faCode}/> Upload new commands to the cloud (optional)</h4>
                <form>
                    <Row>
                        <Col xs="8">
                            <Row>
                                <Col xs="12">
                                <Form.Group controlId="command">
                                        <Form.Label>Command</Form.Label>
                                        <Form.Control
                                        autoFocus
                                        type="text"
                                        placeholder="e.g. /home/root/install.sh"
                                        value={fields.command}
                                        onChange={handleFieldChange}
                                        />
                                    </Form.Group>
                                </Col>
                                {/* <Col xs="6">
                                <Form.Group controlId="parameter">
                                        <Form.Label>Parameter (optional)</Form.Label>
                                        <Form.Control
                                        autoFocus
                                        type="text"
                                        placeholder="parameter"
                                        value={fields.parameter}
                                        onChange={handleFieldChange}
                                        />
                                    </Form.Group>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Group controlId="description">
                                        <Form.Control
                                        autoFocus
                                        type="text"
                                        placeholder="add a description here"
                                        value={fields.description}
                                        onChange={handleFieldChange}
                                        />
                                        </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="4">
                            <Row>
                                <Col xs="12">
                                    <Button
                                        className="btn btn-success UploadCommandButtons"
                                        disabled={!validateForm()}
                                        onClick={() => registerCommand()}
                                        block>
                                        <FontAwesomeIcon icon={faSave} className="supportIconHome"/> Register Command
                                    </Button>
                                </Col>
                                <Col xs="12">
                                    <Button
                                        className="btn btn-primary UploadCommandButtons"
                                        disabled={!validateForm()}
                                        onClick={() => registerCommandAndSend()}
                                        block>
                                        <FontAwesomeIcon icon={faRocket} className="supportIconHome"/> Register and Schedule for Execution
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </form>
            </div>
        )
    }

    function renderJobsTable(){
        return (
            <div>
                <h4><FontAwesomeIcon icon="history" /> Job History Table</h4>
                <div className="card-header d-flex">
                    <div className="align-self-start mr-auto">
                        All Jobs created by members of your organisation
                    </div>
                    <div className="align-self-end ml-auto">
                        {props.remoteCommandData?.lastEvaluatedKey !== "" &&  props.remoteCommandData?.jobsInfo?.length !== 0?
                            <Button variant="info" onClick={() => loadMoreJobs()} size="sm">{props.remoteCommandData?.jobsInfo?.length} jobs - Fetch more... <FontAwesomeIcon icon="chevron-circle-down" /></Button>
                        :
                            <Button variant="secondary" size="sm" disabled>{props.remoteCommandData?.jobsInfo?.length} jobs - All jobs fetched</Button>
                        }
                    </div>
                </div>
                <div className="table table-responsive border tableFixHeadRemoteCommand">
                    <Table bordered>
                        <thead className="border">
                            <tr key="commands-jobs">
                            <th>Job Id</th>
                            <th>Job Info</th>
                            <th>Logs
                                {/* <Row className="sortClass">
                                    Logs
                                    { sortUp === true?
                                        <Button className="ml-auto" variant="success" onClick={() => sortDownLogs()} size="sm">Sort <FontAwesomeIcon icon="sort-numeric-down-alt"/></Button>
                                    :
                                        <Button className="ml-auto" variant="success" onClick={() => sortUpLogs()} size="sm">Sort <FontAwesomeIcon icon="sort-numeric-up" /></Button>
                                    }
                                </Row> */}
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.remoteCommandData?.jobsInfo &&
                                props.remoteCommandData?.jobsInfo.map((job, index) => (
                                <tr key={index}>
                                    <td>{job.actions[0].jobid}</td>
                                    <td>
                                        Command: <Badge variant="info">{job.actions[0].operation_details?.command}</Badge>&nbsp;<br/>
                                        Description: <Badge variant="info">{job.actions[0].operation_details?.description}</Badge>&nbsp;<br/>
                                        Parameter: <Badge variant="info">{job.actions[0].operation_details?.parameter? job.actions[0].operation_details?.parameter : "No parameter"}</Badge>&nbsp;<br/>
                                        CWD: <Badge variant="info">{job.actions[0].operation_details?.cwd}</Badge>&nbsp;<br/>
                                        Command id: <Badge variant="info">{job.actions[0].operation_details?.commandid}</Badge>&nbsp;<br/>
                                        Created by: <Badge variant="info">{job.actions[0].user_id}</Badge><br/>
                                        {/* Device owner: <Badge variant="info">{job.actions[0].device_owner}</Badge>&nbsp;<br/> */}
                                        {/* Hold on fail: <Badge variant="info">{job.actions[0].hold_on_fail? job.actions[0].hold_on_fail : "No"}</Badge>&nbsp;<br/> */}
                                    </td>
                                    <td>
                                        {job?.actions.map((jobid, index_job) => (
                                            <li key={index_job} className="list-group-item listItem">
                                                <p>
                                                    <b><Moment format="YYYY-MM-DD HH:mm:ss" tz="Europe/Berlin" utc local>{jobid?.last_updated_timestamp*1000}</Moment></b> <Badge
                                                        variant={jobid?.operation_status === "FAILED" ? "danger": jobid?.operation_status === "SUCCEEDED" ? "success" : "info"}>
                                                        {jobid?.operation_status}
                                                    </Badge> {jobid?.description}
                                                </p>
                                            </li>
                                        ))}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {props.remoteCommandData?.jobsInfo?.length === 0 ?
                        <div> There have been no jobs for this device. Please contact our <Link to="/infoAndSupport" target="_blank">support</Link> if you think this might be an issue.</div>
                    : <></>}
                </div>
            </div>
        )
    }

    function renderCommandsTable(){
        return (
            <div>
            <h4><FontAwesomeIcon icon="folder-open" /> Command List Table</h4>
            <div className="card">
                <div className="card-header">All Commands uploaded by members of your organisation</div>
                <Table bordered>
                    <thead>
                        <tr>
                        <th >Command</th>
                        <th >Uploaded At</th>
                        <th >Execution Directory</th>
                        <th >Description</th>
                        <th >More Info</th>
                        <th >Create Job</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.remoteCommandData?.commandsInfo &&
                            props.remoteCommandData?.commandsInfo.map((command, index) => (
                            <tr key={index}>
                                <td>{command.command} <br/> (ID: {command.commandid})</td>
                                <td>
                                <Moment format="YYYY-MM-DD HH:mm:ss" tz="Europe/Berlin" utc local>
                                    {command.timestamp}
                                </Moment><br/>
                                <Badge variant="info">
                                    <Moment tz="Europe/Berlin" utc local fromNow>
                                        {command.timestamp}
                                    </Moment>
                                </Badge>
                                </td>
                                <td>{command.cwd}</td>
                                <td>{command.description}</td>
                                <td>
                                    Parameter: <Badge variant="info">{command.parameter? command.parameter : "No parameters"}</Badge>&nbsp;<br/>
                                    Hidden parameter: <Badge variant="info">{command.hidden_parameter? command.hidden_parameter : "No hidden parameters"}</Badge>&nbsp;<br/>
                                    Type: <Badge variant="info">{command.command_type ? command.command_type : "NO TYPE SET"}</Badge>&nbsp;<br/>
                                    Device owner: <Badge variant="info">{command.device_owner}</Badge>&nbsp;<br/>
                                    Uploaded by: <Badge variant="info">{command.user_id}</Badge>&nbsp;<br/>
                                    Running in background: <Badge variant="info">{command.runinbackground? command.runinbackground : "No"}</Badge>&nbsp;<br/>
                                </td>
                                <td>
                                    <Button variant="primary" className="supportButtonHome" size="sm" onClick={() => createJob(command.commandid, command.description)}>
                                        <FontAwesomeIcon icon={faRocket} className="supportIconHome"/> Schedule for Execution
                                    </Button>
                                    <br/>
                                    {command?.jobs === undefined || (command?.jobs?.length === 0 && props.customer_name === "osb")?
                                        <Button variant="danger" className="supportButtonHome" size="sm" onClick={() => daleteCommand(command.command, command.commandid)}>
                                            <FontAwesomeIcon icon={faTrash} className="supportIconHome"/> Delete Command
                                        </Button>
                                    :
                                        <></>
                                    }
                                </td>
                            </tr>
                            ))}
                    </tbody>
                </Table>
                {props.remoteCommandData?.commandsInfo?.length === 0 ?
                    <div>There have been no Commands added by your community. Please contact our <Link to="/infoAndSupport" target="_blank">support</Link> if you think this might be an issue.</div>
                : <></>}
            </div>
        </div>
        )
    }


    return (
        <div>
        {/* {showInstructions()} */}

        {/* {!isCommandExists ? <Alert variant="danger" onClose={() => setIsCommandExists(false)} dismissible>
            <Alert.Heading>Command already exists</Alert.Heading>
            The command you are trying to add is already exist! please check the Command List Table below.
        </Alert> : <></>} */}

        <MessageInfo />

        {uploadForm()}

        <ReactPlaceholder showLoadingAnimation className='text-center' ready={!props.isLoadingRemoteCommand} customPlaceholder={
                <div className='text-center'>
                    <br/>
                    <TextRow color='#e4e4e7' style={{width: 'auto', height: 40}}/>
                    <TextRow color='#f2f2f3' style={{width: 'auto', height: 400}}/>
                </div>
            }>
        {renderJobsTable()}
        </ReactPlaceholder>

        <ReactPlaceholder showLoadingAnimation className='text-center' ready={!props.isLoadingRemoteCommand} customPlaceholder={
                <div className='text-center'>
                    <br/>
                    <TextRow color='#e4e4e7' style={{width: 'auto', height: 40}}/>
                    <TextRow color='#f2f2f3' style={{width: 'auto', height: 400}}/>
                </div>
            }>
        {renderCommandsTable()}
        </ReactPlaceholder>

        </div>
    );
}
