import React from "react";
import "./NotFound.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NotFound() {
    return (
        <div className="NotFound">
            <h3>Page could not be found!</h3>
            <br/>
            <br/>
            <h3><FontAwesomeIcon icon="ban"/></h3>
        </div>
    );
}