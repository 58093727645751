import React, { useState } from "react";
import { Form, Alert, Breadcrumb, Container, Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "./ChangePassword.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ChangePassword(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [fields, handleFieldChange] = useFormFields({
        oldPassword: "",
        newPassword: "",
        passwordConfirmation: ""
    });
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);


    function validateForm() {
        return (
            (fields.oldPassword.length > 7) &&
            (fields.newPassword.length > 11) &&
            (fields.passwordConfirmation === fields.newPassword)
        );
    }

    function validateFormNonAuthenticated() {
        return (
            (fields.newPassword.length > 7) &&
            (fields.passwordConfirmation === fields.newPassword)
        );
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            if (props.isAuthenticated) {
                await Auth.currentAuthenticatedUser()
                .then(user => {
                    return Auth.changePassword(user, fields.oldPassword, fields.newPassword);
                })
                setIsLoading(false);
                props.userHasAuthenticated(true);
                setIsPasswordChanged(true);
            } else {
                Auth.completeNewPassword(props.location.user, fields.newPassword);
                setIsLoading(false);
                props.userHasAuthenticated(true);
                setIsPasswordChanged(true);
            }

        } catch (e) {
            if (e.code === "InvalidParameterException" || e.code === "InvalidPasswordException") {
                setErrorMessage(
                    <Alert variant="danger" className="pwAlert">
                        <p className="AlertText">Password does not match the requirements.</p>
                    </Alert>
                );
            } else {
                setErrorMessage(
                    <Alert variant="danger">
                        <p>{e.message}</p>
                    </Alert>
                );
            }
            setIsLoading(false);
        }
    }

    function renderChangePasswordForm() {
        return (
            <form onSubmit={handleSubmit}>
                {props.isAuthenticated ?
                    <div>
                        <Form.Group controlId="oldPassword">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control
                                autoFocus
                                type="password"
                                value={fields.oldPassword}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="newPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={fields.newPassword}
                                onChange={handleFieldChange}
                            />
                            {(fields.newPassword?.length === 0 || fields.newPassword?.length > 11) ? "" : <p style={{color: "red"}}>Passwords must be at least 12 characters long</p>}
                        </Form.Group>
                        <Form.Group controlId="passwordConfirmation">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={fields.passwordConfirmation}
                                onChange={handleFieldChange}
                            />
                            {(fields.passwordConfirmation === fields.newPassword) ? "": <p style={{color: "red"}}>Passwords do not match</p>}
                        </Form.Group>
                        {errorMessage}
                        <LoaderButton
                            block
                            type="submit"
                            isLoading={isLoading}
                            disabled={!validateForm()}>
                                Change Password
                        </LoaderButton>
                        <br/>
                        <Alert variant="secondary" className="pwAlert">
                                <h5>Password requirements:</h5>
                                <ul>
                                    <li>Minumum length: 12</li>
                                    <li>At least one number required</li>
                                    <li>Uppercase letter required</li>
                                    <li>Lowercase letter required</li>
                                    <li>At least one special character from this set required: <br/>
                                        <span className="SpecialCharacters">{"^ $ * . [ ] { } ( ) ? \" ! @ # % & / \\ , > < ' : ; | _ ~ "}</span>
                                    </li>
                                </ul>
                        </Alert>
                    </div>
                :
                    <div>
                        <Alert variant="warning" className="pwAlert">
                                <h5>You have to set a new password in order to be able to login to the IoT Device Suite.</h5>
                        </Alert>
                        <br/>
                        <Form.Group controlId="newPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={fields.newPassword}
                                onChange={handleFieldChange}
                            />
                            {(fields.newPassword?.length === 0 || fields.newPassword?.length > 11) ? "" : <p style={{color: "red"}}>Passwords must be at least 12 characters long</p>}
                        </Form.Group>
                        <Form.Group controlId="passwordConfirmation">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={fields.passwordConfirmation}
                                onChange={handleFieldChange}
                            />
                            {(fields.passwordConfirmation === fields.newPassword) ? "": <p>Passwords do not match</p>}
                        </Form.Group>
                        {errorMessage}
                        <LoaderButton
                            block
                            type="submit"
                            isLoading={isLoading}
                            disabled={!validateFormNonAuthenticated()}>
                                Change Password
                        </LoaderButton>
                        <br/>
                        <Alert variant="secondary" className="pwAlert">
                                <h5>Password requirements:</h5>
                                <ul>
                                    <li>Minumum length: 12</li>
                                    <li>At least one number required</li>
                                    <li>Uppercase letter required</li>
                                    <li>Lowercase letter required</li>
                                    <li>At least one special character from this set required: <br/>
                                        <span className="SpecialCharacters">{"^ $ * . [ ] { } ( ) ? \" ! @ # % & / \\ , > < ' : ; ` | _ ~"}</span>
                                    </li>
                                </ul>
                        </Alert>
                    </div>
                }
            </form>
        );
    }

    function renderPasswordChangedForm() {
        return (
            <form className="ChangedPasswordForm">
                <Alert variant="success">
                    <p>Password changed successfully.</p>
                </Alert>
                <Link to='/'><Button variant="info" className="ChangePasswordHomeButton">Proceed to Home <FontAwesomeIcon icon="home"/></Button></Link>
            </form>
        )
    }

    return (
        <div className="ChangePassword">
            <Container>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon="home"/> Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Change Password</Breadcrumb.Item>
            </Breadcrumb>

            <div className="description">
                <h1>Change Password</h1>

                {!isPasswordChanged?
                    renderChangePasswordForm()
                :
                    renderPasswordChangedForm()
                }

            </div>
            </Container>
        </div>
    );
}