import React, { useState } from "react";
import TableFilter from 'react-table-filter';
import "../../../../../node_modules/react-table-filter/lib/styles.css";
import { Table, ListGroup } from "react-bootstrap";
import "./UsersTable.css";

export default function DeletedUsersTable(props) {
    const [filteredUsers, setUsers] = useState(null);
    const users = filteredUsers !== null ? filteredUsers : props.users;

    function filterUpdated(newData) {
        setUsers(newData);
    }

    function renderUsersTable(Users) {
        return (
            <Table striped bordered>
                <thead>
                    <TableFilter
                        rows={props.users}
                        onFilterUpdate={filterUpdated.bind(this)}>
                        <th key="email" filterkey="email" >Email</th>
                        <th key="customer_name" filterkey="customer_name">Customer Affiliation</th>
                        <th key="group" filterkey="group">User Permissions</th>
                    </TableFilter>
                </thead>
                <tbody>
                    {renderTableRows(Users)}
                </tbody>
            </Table>
        );
    }

    function renderTableRows(Users) {
        return [{}].concat(Users).map((user, i) =>
            i !== 0 ? (
                props.users_attributes[user['user_id']] ?
                    <tr key={i}></tr>
                :
                    <tr key={i}>
                        <td key="email"><p>{user?.email?.trim().split("\n")[0]}</p></td>
                        <td key="customer_name"><p>{user?.customer_name?.trim().split("\n")[0]}</p></td>
                        <td key="group"><p>{user?.group?.trim().split("\n")[0]}</p></td>
                    </tr>
            ) : (<tr key={i}></tr>)
        );
    }

    return (
        <div>
            <ListGroup>
                <div className="responsiveTableUserTable">
                    {renderUsersTable(users)}
                </div>
            </ListGroup>
        </div>
    )
}
