import React, { Component } from 'react'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import './FeatureRoadmap.css'

import markdownText from '../markdown/contact.md'

class ContactInformation extends Component {
  constructor(props) {
    super(props)

    this.state = { markdown: null }
    fetch(markdownText).then((response) => response.text()).then((text) => {
        this.setState({ markdown: text })
      })
  }

  render() {
    return (
      <Markdown
      rehypePlugins={[rehypeRaw, rehypeSanitize]}>
      {this.state.markdown}
    </Markdown>
    )
  }
}

export default ContactInformation