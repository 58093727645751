import React, { useEffect, useState } from "react";
import { Form, Alert, Breadcrumb, Container } from "react-bootstrap";
import { API, Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "./AddDevice.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";


export default function AddDevice() {
    const [isLoadingAddDevice, setIsLoadingAddDevice] = useState(false);
    const [userHeaders, setUserHeaders] = useState();
    const [errorMessageAddDevice, setErrorMessageAddDevice] = useState();
    const [fields, handleFieldChange] = useFormFields({
        token: "",
        device_name: ""
    });

    function validateForm() {
        return ( fields.token.length > 0 && fields.device_name.length > 0 );
    }

    useEffect(() => {
		async function onLoad() {
			try {
                // Getting JSON Web Token
                const session = await Auth.currentSession();
                const headers = {};
                if (session) {
                    headers.Authorization = session.getIdToken().getJwtToken();
                }
                setUserHeaders(headers);
			}
			catch (e) {
				alert(e);
			}
		}
        onLoad();
	}, []);

    async function handleAddDevice(event) {
        event.preventDefault();

        setIsLoadingAddDevice(true);

        try {
            await API.put("feature_management", `/registry_manager/${fields.device_name}/device_owner?token=${fields.token}`, {
                    headers: userHeaders,
            });
            setErrorMessageAddDevice(
                <Alert variant="success">
                    <p>You have successfly added the device {fields.device_name}.</p>
                </Alert>
            );
            setIsLoadingAddDevice(false);
        } catch (e) {
            setErrorMessageAddDevice(
                <Alert variant="danger">
                    <p>Something went wrong! please check if your token is correct.</p>
                </Alert>
            );
            setIsLoadingAddDevice(false);
        }
    }

    function renderForm() {
        return (
            <div>
                <div className="description">
                    <h1>PHYTEC Devices</h1>


                    <form onSubmit={handleAddDevice}>
                    <Alert variant="info" className="pwAlert">
                    <Alert.Heading>Add or move devices to your account</Alert.Heading>
                        <p>It is possible to manage multiple devices in your account.
                        If you purchased Demo-Boards from Phytec, you can manage each device in a separate account, or manage all devices in a single account.
                        <br/>
                        This function allows to add or relocate the devices into the current account. If you migrate the device to this account, it will be no longer associated with the previous account! </p>
                        <br/>

                        <h4>Proving the ownbership</h4>
                        <p><b>Step 1:</b> Please generate a token for your device using the phytec-board-config script that is part of the Phytec SW-Image<br/>
                        You will receive the Unique device ID (UID) and a security token</p>
                        <p><b>Step 2:</b> Enter the UID and the token in the fields below.</p>
                        <br/>
                        <b>Please be aware:</b>
                            <ul>
                                <li>For security reasons the token is only valid for a limited time</li>
                                <li>After every try (typing error etc.) you need to run the token generation skript again</li>
                                <li>Attemps to add devices are logged!</li>
                            </ul>

                    </Alert>
                        <Form.Group controlId="device_name">
                            <Form.Label>UID (Unique device ID)</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.device_name}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="token">
                            <Form.Label>Token</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.token}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        {errorMessageAddDevice}
                        <br/>
                        <LoaderButton
                            block
                            type="submit"
                            isLoading={isLoadingAddDevice}
                            disabled={!validateForm()}>
                            Submit
                        </LoaderButton>
                    </form>
                </div>
            </div>
        );
    }

    return (
        <div className="AddDevice">
            <Container>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Add Device</Breadcrumb.Item>
            </Breadcrumb>

            { renderForm()}


            </Container>
        </div>
    );
    }