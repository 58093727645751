export default {
    apiMainGateway: {
        REGION: "eu-central-1",
        URL: "https://api.aws.iot-suite.io/main"
    },
    apiFeatureGateway: {
        REGION: "eu-central-1",
        URL: "https://api.aws.iot-suite.io/feature"
    },
    cognito: {
        REGION: "eu-central-1",
        USER_POOL_ID: "eu-central-1_u3YeXoQtL",
        APP_CLIENT_ID: "58cn4ra5bg54m30jnklhludn9n",
        IDENTITY_POOL_ID: "eu-central-1:51e7519b-4b3b-4723-b356-7bec0273316d"
    },
    wherverSimEsecGateway: {
        REGION: "eu-central-1",
        URL: "https://api.aws.iot-suite.io/whereversim"
    },
    wherverSimIntegration: {
        REGION: "eu-central-1",
        URL: "https://api.aws.iot-suite.io/whereversimintegration"
    },
    fileManagerGateway: {
        REGION: "eu-central-1",
        URL: "https://api.aws.iot-suite.io/filemanager"
    },
    jobManagerGateway: {
        REGION: "eu-central-1",
        URL: "https://api.aws.iot-suite.io/jobmanager"
    },
    remoteCommandGateway: {
        REGION: "eu-central-1",
        URL: "https://api.aws.iot-suite.io/commandmanager"
    },
    hawkbitIntegrationGateway: {
        REGION: "eu-central-1",
        URL: "https://api.aws.iot-suite.io/hawkbitintegration"
    },
    deviceLoggerGateway: {
        REGION: "eu-central-1",
        URL: "https://api.aws.iot-suite.io/devicelogger"
    },
    licenseManagerGateway: {
        REGION: "eu-central-1",
        URL: "https://api.aws.iot-suite.io/license"
    },
    apiCreateUsersGateway: {
        REGION: "eu-central-1",
        URL: "https://yx681tlxwe.execute-api.eu-central-1.amazonaws.com/dev"
    },
    apiCreateCustomerGateway: {
        REGION: "eu-central-1",
        URL: "https://api.aws.esec-experts.com/fota/customer"
    },
    apiFirewallGateway: {
        REGION: "eu-central-1",
        URL: "https://api.aws.iot-suite.io/firewall"
    }
};