import React from "react";
import {
    Row, Col, Container
} from "react-bootstrap";

import "../Device.css";

export default function DeviceIoT(props) {
    return (
            <div style={{marginBottom: "100px"}}>
                <Container>
                <Row className="dev-attributes-row">
                    <Col xs={12}><h5>Device Shadow</h5></Col>
                </Row>
                <Row className="dev-attributes-row">
                    <Col xs="12" >
                        <pre className="dev-shadow-state">
                            <code >
                                {JSON.stringify(props?.iotCoreData?.state?.reported, null, 2) }
                            </code>
                        </pre>
                    </Col>
                </Row>
                </Container>
            </div>
    )
}