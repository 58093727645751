import React, { useState } from "react";
import {Alert, Image, Badge, Col, Row, Button, Form, Table, Modal} from "react-bootstrap";
import 'moment-timezone';
import { useFormFields } from "../../../libs/hooksLib";
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactCountryFlag from "react-country-flag"
import "../Device.css";
import UpdateSimServiceProfile from "./UpdateSimServiceProfile";


export default function WhereverSim(props) {
    const [fields, handleFieldChange] = useFormFields({
        endpointname: props.device?.device_name,
    });
    const [showInfo, setShowInfo] = useState(true);
    const [modalShow, setModalShow] = useState(false);


    async function activateSIM(){
        setShowInfo(true)
        props.onWhereverSimActions({
            action: "activate_SIM",
            device: props.device?.uid
        })
    }

    async function deactivateSIM(){
        setShowInfo(true)
        props.onWhereverSimActions({
            action: "deactivate_SIM",
            device: props.device?.uid
        })
    }

    async function setImeiLock(){
        setShowInfo(true)
        props.onWhereverSimActions({
            action: "lockIMEI",
            device: props.device?.uid
        })
    }

    async function unsetImeiLock(){
        setShowInfo(true)
        props.onWhereverSimActions({
            action: "unlockIMEI",
            device: props.device?.uid
        })
    }

    async function renameEndpoint(){
        setShowInfo(true)
        props.onWhereverSimActions({
            action: "renameEndpoint",
            device: props.device?.uid,
            newname: fields.endpointname
        })
    }

    async function disableEndpoint(){
        setShowInfo(true)
        props.onWhereverSimActions({
            action: "disableEndpoint",
            device: props.device?.uid
        })
    }

    async function enableEndpoint(){
        setShowInfo(true)
        props.onWhereverSimActions({
            action: "enableEndpoint",
            device: props.device?.uid
        })
    }


    function validateForm() {
        let valid = false;
        if (
            fields.endpointname
        ){
            valid = true
        }
        // console.log(fields.endpointname)
        // console.log("Valid = " + valid)
        return valid;
    }

    function showWhereverSimInfo(){
        return (
            <div><Alert variant="secondary">
            <Alert.Heading>WhereverSIM </Alert.Heading>
            <p>
                The WhereverSIM Data could not be fetched for the current Device/SIM.
            </p>

            <p>
                Please contact us about our <a href="mailto:support@iot-suite.io?subject=IoT%20Device%20Management%20Support%20Request"> WhereverSIM Integration</a>
                <br/>
                <a href={"mailto:support@iot-suite.io"}>support@iot-suite.io</a>
            </p>
            <hr />
            <p className="mb-0">
                If you think this might be an error, please <a href="mailto:support@iot-suite.io?subject=IoT%20Device%20Management%20Support%20Request"> contact our support</a>.
            </p>
            </Alert>
            </div>
        )
    }

    function MessageInfo() {
        if (props.whereverSimMessage?.show && showInfo) {
          return (
            <Alert variant={props.whereverSimMessage?.variant} onClose={() => setShowInfo(false)} dismissible>
              <Alert.Heading>{props.whereverSimMessage?.headline}</Alert.Heading>
              {props.whereverSimMessage?.body}
            </Alert>
          );
        }
        return <></>;
      }


    function showWhereverSIMDetails(props){
        return (
            <div style={{marginBottom: "100px"}}>


            <h4>Endpoint: {props.whereverSimData?.endpoint?.name || 'unknown'} (ID: {props.whereverSimData?.endpoint?.id || 'unknown'}) </h4>

            <Row className="dev-attributes-row">
                <Col xs={12}><h5>Status <Badge variant={props.whereverSimData?.endpoint?.status?.id === 0 ? "success" : "warning" }> Endpoint: {props.whereverSimData?.endpoint?.status?.description}</Badge> &nbsp; <Badge variant={props.whereverSimData?.sim?.status?.id === 1 ? "success" : "warning" }> SIM: {props.whereverSimData?.sim?.status?.description}</Badge></h5></Col>
                </Row>
            <Row className="dev-attributes-row">
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="sim-card" /> SIM-ID: </div>{props.whereverSimData?.sim?.iccid || 'unknown'}</Col>
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="long-arrow-alt-right" /> VPN-IP: </div>{props.whereverSimData?.endpoint?.ip_address || 'unknown'}</Col>
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="hand-holding" /> Provider: </div>{props.whereverSimData?.sim?.issuer_org?.name || 'unknown'} ({props.whereverSimData?.sim?.issuer_org?.country?.name || 'unknown'})</Col>
            </Row>
            <hr/>
            <Row className="dev-attributes-row">
                <Col xs={12}><h5>Connectivity <Badge variant={props.whereverSimData?.connectivity?.status?.description === "ATTACHED" ? "success" : "warning" }> {props.whereverSimData?.connectivity?.status?.description || 'unknown'}</Badge></h5></Col>
                </Row>
            <Row className="dev-attributes-row">
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="sim-card" /> Operator: </div>{props.whereverSimData?.connectivity?.location?.operator?.name || 'unknown'}</Col>
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="sim-card" /> Services: </div>{props.whereverSimData?.connectivity?.services || 'unknown' }</Col>
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="globe-europe" /> Country Location: </div>{props.whereverSimData?.connectivity?.location?.operator?.country?.name || 'unknown'}<ReactCountryFlag countryCode={props.whereverSimData?.connectivity?.location?.operator?.country?.iso_code || ''}/> </Col>
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="globe-europe" /> Location Update:</div>
                        {props.whereverSimData?.connectivity?.location?.last_updated ?  <div>
                        <Moment tz="Europe/Berlin" utc local parse="YYYY-MM-DDTHH:mm:ss" format="YYYY-MM-DD HH:mm:ss">{props.whereverSimData?.connectivity?.location?.last_updated}</Moment><br/>
                        <Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DDTHH:mm:ss">{props.whereverSimData?.connectivity?.location?.last_updated}</Moment></Badge> </div>
                        : ''}
                    </Col>
            </Row>
            <hr/>
            <Row className="dev-attributes-row">
                <Col xs={12}><h5>Usage</h5></Col>
            </Row>
            <div style={{marginLeft: "15px"}}>
                <Row className="dev-attributes-row">
                    <Col xs={12}><h6>Previous Month</h6></Col>
                        <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="cloud-download-alt" /> Downstream:</div>{props.whereverSimData?.stats?.last_month?.data?.volume_rx ? parseFloat(props.whereverSimData?.stats?.last_month?.data?.volume_rx).toFixed(2) + " MB" : '-- MB'}</Col>
                        <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="cloud-upload-alt" /> Upstream:</div>{props.whereverSimData?.stats?.last_month?.data?.volume_tx ? parseFloat(props.whereverSimData?.stats?.last_month?.data?.volume_tx).toFixed(2) + " MB"  : '-- MB'}</Col>
                        <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="cloud-upload-alt"/><FontAwesomeIcon icon="cloud-download-alt"/> Total Stream:</div> {props.whereverSimData?.stats?.last_month?.data?.volume ? parseFloat(props.whereverSimData?.stats?.last_month?.data?.volume).toFixed(2) + " MB"  : '-- MB'}</Col>
                </Row>
                <Row className="dev-attributes-row">
                    <Col xs={12}><h6>Current Month</h6></Col>
                    </Row>
                <Row className="dev-attributes-row">
                        <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="cloud-download-alt" /> Downstream:</div>{props.whereverSimData?.stats?.current_month?.data?.volume_rx ? parseFloat(props.whereverSimData?.stats?.current_month?.data?.volume_rx).toFixed(2) + " MB" : '-- MB'}</Col>
                        <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="cloud-upload-alt" /> Upstream:</div>{props.whereverSimData?.stats?.current_month?.data?.volume_tx ? parseFloat(props.whereverSimData?.stats?.current_month?.data?.volume_tx).toFixed(2) + " MB"  : '-- MB'}</Col>
                        <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="cloud-upload-alt"/><FontAwesomeIcon icon="cloud-download-alt"/> Total Stream:</div> {props.whereverSimData?.stats?.current_month?.data?.volume ? parseFloat(props.whereverSimData?.stats?.current_month?.data?.volume).toFixed(2) + " MB"  : '-- MB'}</Col>
                </Row>
            </div>
            <hr/>
            <Row className="dev-attributes-row">
                <Col xs={12}><h5>Profile</h5></Col>
                </Row>
            <Row className="dev-attributes-row">
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="layer-group" /> Service Profile:</div>{props.whereverSimData?.endpoint?.service_profile?.name || 'unknown'}</Col>
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="list" /> Tariff:</div>{props.whereverSimData?.endpoint?.tariff_profile?.name || 'unknown'}</Col>
                    <Col xs={3}><div className="dev-attributes-heading"><div className="align-self-end ml-auto">
                                <Button className="EditButton" variant="warning" onClick={() => setModalShow(true)} size="sm">
                                    Update SIM Profile
                                </Button>
                            </div></div></Col>
            </Row>
            <hr/>
            <Row className="dev-attributes-row">
                <Col xs={12}><h5>Modem</h5></Col>
            </Row>
            <Row className="dev-attributes-row">
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="microchip" /> IMEI:</div>{props.whereverSimData?.endpoint?.imei || 'unknown'}</Col>
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="lock" /> IMEI-Lock:</div>{props.whereverSimData?.endpoint?.imei_lock ? <Badge variant="success">Locked</Badge> :  <Badge variant="warning">Unlocked</Badge>}</Col>



            </Row>
            <hr/>
            <Row className="dev-attributes-row">
                <Col xs={12}><h5>Additional Information</h5></Col>
            </Row>
            <Row className="dev-attributes-row">
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="clock" /> Last Update:</div>
                        {props.whereverSimData?.endpoint?.last_updated ?  <div>
                        <Moment tz="Europe/Berlin" utc local parse="YYYY-MM-DDTHH:mm:ss" format="YYYY-MM-DD HH:mm:ss">{props.whereverSimData?.endpoint?.last_updated}</Moment><br/>
                        <Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DDTHH:mm:ss">{props.whereverSimData?.endpoint?.last_updated}</Moment></Badge> </div>
                        : ''}
                    </Col>
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="clock" /> SIM Activation Date:</div>
                    {props.whereverSimData?.sim?.activation_date ? <div>
                        <Moment tz="Europe/Berlin" utc local parse="YYYY-MM-DDTHH-mm:ss" format="YYYY-MM-DD HH:mm:ss">{props.whereverSimData?.sim?.activation_date}</Moment> <br/>
                        <Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DDTHH-mm:ss"> {props.whereverSimData?.sim?.activation_date}</Moment></Badge> </div>
                        : ''}
                    </Col>
                    <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="clock" /> Created:</div>
                        {props.whereverSimData?.endpoint?.created ?  <div>
                        <Moment tz="Europe/Berlin" utc local parse="YYYY-MM-DDTHH-mm:ss" format="YYYY-MM-DD HH:mm:ss">{props.whereverSimData?.endpoint?.created}</Moment><br/>
                        <Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DDTHH-mm:ss">{props.whereverSimData?.endpoint?.created}</Moment></Badge> </div>
                        : ''}
                    </Col>

            </Row>

            <hr/>
            <Row className="dev-attributes-row">
                <Col xs={12}><h4>Actions</h4></Col>
            </Row>
            <Row className="dev-attributes-row">
                             {/*
                             endpoint.status.id = 1  Disabled
                             endpoint.status.id = 0  Enabled
                             sim.status.id = 0 Issued
                             sim.status.id = 1 Activated
                             sim.status.id = 2 Suspended
                             */}

                    <Col xs={2}>
                        <div className="dev-attributes-heading"><FontAwesomeIcon icon="microchip" /> Endpoint:</div>
                            {props.whereverSimData?.endpoint?.status?.id === 0?
                                <Button variant="warning" onClick={disableEndpoint} size="sm" ><FontAwesomeIcon icon="lock" /> Disable Endpoint</Button>
                            :
                                <Button variant="success" onClick={enableEndpoint} size="sm" ><FontAwesomeIcon icon="lock-open" /> Enable Endpoint</Button>
                            }
                    </Col>
                    <Col xs={2}>
                        <div className="dev-attributes-heading"><FontAwesomeIcon icon="sim-card" /> SIM:</div>
                            {props.whereverSimData?.sim?.status?.id === 1 ?
                                <Button variant="warning" onClick={deactivateSIM} size="sm" ><FontAwesomeIcon icon="sim-card" /> Deactivate SIM </Button>
                            :
                                <Button variant="success" onClick={activateSIM} size="sm" ><FontAwesomeIcon icon="sim-card" /> Activate SIM </Button>
                            }
                    </Col>
                    <Col xs={2}>
                        <div className="dev-attributes-heading"><FontAwesomeIcon icon="lock" /> IMEI:</div>
                            {props.whereverSimData?.endpoint?.imei_lock ?
                                <Button variant="warning" onClick={unsetImeiLock} size="sm" ><FontAwesomeIcon icon="lock-open" /> Release IMEI Lock</Button>
                            :
                                <Button variant="success" onClick={setImeiLock} size="sm" ><FontAwesomeIcon icon="lock" /> Set IMEI Lock</Button>
                            }
                    </Col>
                        <Col xs="4">
                            <Form.Group controlId="endpointname">
                                <Form.Control
                                autoFocus
                                type="text"
                                placeholder="New Endpoint Name"
                                value={fields.endpointname}
                                onChange={handleFieldChange}
                                />
                                <Form.Label>Default = Device Name</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs="2">
                            <Button
                                className="btn btn-success"
                                disabled={!validateForm()}
                                type="submit"
                                onClick={renameEndpoint}
                                block>
                                Rename
                            </Button>
                        </Col>
                    </Row>

                    <Row className="dev-attributes-row">
                        <Col xs={12}><h4>Event Log</h4></Col>
                    </Row>
                    <Row className="dev-attributes-row">
                     <Col xs="12">
                            {renderEvents(props.whereverSimData?.events)}
                        </Col>
                    </Row>
            </div>

        )
    }

    function renderEvents(events) {
        return (
            <Table striped bordered hover>
                <thead>
                <tr>
                        <th key="time" >Time</th>
                        <th key="type" >Type</th>
                        <th key="severity" >Severity</th>
                        <th key="source" >Source</th>
                        {/* <th key="alert" >Alert</th> */}
                        <th key="description" >Description</th>
                </tr>
                </thead>
                <tbody>
                    {renderTableRows(events)}
                </tbody>
            </Table>
        );
    }

    function renderTableRows(events) {
        return [{}].concat(events).map((event, i) =>
            i !== 0 ? (
                <tr key={i}>
                    <td><Moment tz="Europe/Berlin" utc local parse="YYYY-MM-DDTHH:mm:ss" format="YYYY-MM-DD HH:mm:ss">{event?.timestamp}</Moment><br/>
                    <Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DDTHH:mm:ss">{event?.timestamp}</Moment></Badge>
                    </td>
                    <td>{event?.event_type?.description || "--"}</td>
                    <td> <Badge variant={event?.event_severity?.description === "Info" ? "info" : "warning"}> {event?.event_severity?.description} </Badge></td>
                    <td>{event?.event_source?.description || "--"}</td>
                    {/* <td>{event.alert ? <Badge variant="warning">True</Badge> : <Badge variant="info">False</Badge>}</td> */}
                    <td>{event?.description || "--"}</td>
                </tr>
            ) : (<tr key={i}></tr>)
        );
    }

    function UpdateSimServiceProfileModal() {
        const handleClose = () => setModalShow(false);
        return (
            <Modal
                show={modalShow}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="UserTableModel"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update SIM Profile
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UpdateSimServiceProfile
                        device={props.device}
                        whereverSimData={props.whereverSimData}
                        whereverSimProfiles={props.whereverSimProfiles}
                        onWhereverSimActions={props.onWhereverSimActions}
                    ></UpdateSimServiceProfile>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div>
            <Row>
            <Col xs={4}><Image style={{"marginBottom": "15px", width: 200, height: 'auto'}} src="/images/cropped-whereverSIM-logo-navi.png"/></Col>
            <Col xs={8}>{props.whereverSimData?.sim?.iccid ? <div> WhereverSIM Account <Badge variant="info">{props.whereverSimData?.sim?.customer_org?.name} (Customer-ID: {props.whereverSimData?.sim?.customer_org?.id})</Badge> </div> : <div>WhereverSIM Account <Badge variant="secondary">{"not configured"}</Badge> </div>}   </Col>
            </Row>
            <MessageInfo />
            {props.whereverSimData?.sim?.iccid ? showWhereverSIMDetails(props) : showWhereverSimInfo()}

            {modalShow ? UpdateSimServiceProfileModal() : <></>}
        </div>
        );

}