import React, { useLayoutEffect, useState } from "react";
import CustomerTunnelSettings from '../components/CustomerTunnelSettings';
import { API, Auth } from "aws-amplify";
import FirewallTable from "../components/FirewallTable";
import { Row, Col, Container, Alert } from "react-bootstrap";
import "./TunnelAndFirewall.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import './Home.css'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function TunnelAndFirewall(props) {

    // main data
    const [userHeaders, setUserHeaders] = useState(undefined);
    const [isLoadingMain, setIsLoadingMain] = useState(true);

    // RemoteAccess
    const [remoteConnectionData, setRemoteConnectionData] = useState();
    const [isLoadingRemoteConnection, setIsLoadingRemoteConnection] = useState(false);
    const [remoteConnectionMessage, setRemoteConnectionMessage] = useState({show: "", variant: "secondary", headline: "", body: ""});
    const [refreshRemoteConnection, setRefreshRemoteConnection] = useState(true);

    // Firewall settings
    const [firewallData, setFirewallData] = useState();

    useLayoutEffect(() => {
        async function onLoadMain() {
            if (!props.isAuthenticated) {
                return;
            }
            try {
                // Getting JSON Web Token
                const session = await Auth.currentSession();
                //setUserSession(session);

                const headers = {};
                if (session) {
                    headers.Authorization = session.getIdToken().getJwtToken();
                }
                console.log("setUserHeaders")
                setUserHeaders(headers);
                // console.log(headers)

                // save the path if session expired
                localStorage.setItem('prevPath', '/tunnelAndFirewall');

                } catch (e) {
                    alert(e);
            }
            console.log("setIsLoadingMain")
            setIsLoadingMain(false);
        }
        onLoadMain();
    }, [props.isAuthenticated]);


    useLayoutEffect(() => {
        async function onLoadCustomerTunnelSettings() {
            if (!refreshRemoteConnection || !userHeaders || isLoadingMain) {
                return;
            }

            try {
                    if (userHeaders) {
                        let data = {};

                        let response_remote_connection_ports_overview = [];
                        response_remote_connection_ports_overview = await API.get("feature_management", `/remote_connection/customer_ports_overview`, {
                            headers: userHeaders,
                        });

                        try {
                            let used_port_list = Object.keys(response_remote_connection_ports_overview.current_ports_overview);
                            data['used_port_number'] = used_port_list.length;
                            const PORT_END = response_remote_connection_ports_overview.tunnel_remote_port + response_remote_connection_ports_overview.tunnel_remote_range;
                            for(let i = response_remote_connection_ports_overview.tunnel_remote_port; i < PORT_END; i++) {
                                i = i.toString();
                                if(!(used_port_list.includes(i))) {
                                    response_remote_connection_ports_overview['current_ports_overview'][i] = [];
                                }
                            }
                        } catch (error) {
                            console.log(error);
                        }

                        setIsLoadingRemoteConnection(true);

                        data['portsData'] = response_remote_connection_ports_overview;

                        setRemoteConnectionData(data);
                    }
                    else {
                        console.log("Remote Connection Status - No Headers");
                    }
                } catch (e) {
                    alert(e);
            }

            setIsLoadingRemoteConnection(false);
            setRefreshRemoteConnection(false);
    }
    onLoadCustomerTunnelSettings();
    }, [userHeaders, refreshRemoteConnection, isLoadingMain]);


    useLayoutEffect(() => {
        async function onLoadFirewallSettings() {

            if (!userHeaders || isLoadingMain) {
                return;
            }

            try
            {
                let firewallData = [];
                firewallData = await API.get("firewall", "/get_security_group_rules", {
                    headers: userHeaders,
                });

                setFirewallData(firewallData);

            }
            catch(error)
            {
                console.log(error);
            }
        }
        onLoadFirewallSettings();
    }, [userHeaders, isLoadingMain]);


    async function remoteConnectionHandler(remoteConnectionAction){
        if (userHeaders) {
            try {
                if ((remoteConnectionAction.action === "activateRemoteConnection")){
                    console.log("setRemoteConnectionMessage")
                    setRemoteConnectionMessage({
                        show: true,
                        variant: "info",
                        headline: "Remote Connection Activation Is Pending",
                        body: "The port related to this device will be available soon!"
                    })
                    // try {
                    //     await API.post("feature_management", `/remote_connection/${device.uid}`, {
                    //         headers: userHeaders,
                    //         body: {}
                    //     });
                    //     setRefreshRemoteConnection(true);
                    // } catch (e) {
                    //     console.log(e);
                    // }
                }

                if (remoteConnectionAction.action === "deactivateRemoteConnection"){
                    console.log("setRemoteConnectionMessage")
                    setRemoteConnectionMessage({
                        show: true,
                        variant: "info",
                        headline: "Remote Connection Deactivation Is Pending",
                        body: "The port related to device " + remoteConnectionAction.uid + " will no more be available soon!"
                    })
                    try {
                        await API.del("feature_management", `/remote_connection/${remoteConnectionAction.uid}`, {
                            headers: userHeaders,
                        });
                        console.log("setRefreshRemoteConnection")
                        setRefreshRemoteConnection(true);
                        // ToDo: Check if Result is OK or Not, and make different message
                    } catch(e) {
                        console.log(e);
                    }
                }

                if (remoteConnectionAction.action === "abortRemoteConnectionRollout"){
                    console.log("setRemoteConnectionMessage")
                    setRemoteConnectionMessage({
                        show: true,
                        variant: "info",
                        headline: "Remote Connection Rollout Is Aborted",
                        body: remoteConnectionAction.delta_status + " is aborted!"
                    })
                    try {
                        await API.del("feature_management", `/remote_connection/${remoteConnectionAction.uid}/abort`, {
                            headers: userHeaders,
                        });
                        console.log("setRefreshRemoteConnection")
                        setRefreshRemoteConnection(true);
                        // ToDo: Check if Result is OK or Not, and make different message
                    } catch(e) {
                        console.log(e);
                    }

                }

                if ((remoteConnectionAction.action === "activateRemoteConnectionForceDevicePort")){
                    console.log("setRemoteConnectionMessage")
                    setRemoteConnectionMessage({
                        show: true,
                        variant: "info",
                        headline: "Remote Connection Force Activation Is Pending",
                        body: "The port related to this device will be available soon!"
                    })
                    // try {
                    //     await API.post("feature_management", `/remote_connection/${device.uid}`, {
                    //         headers: userHeaders,
                    //         body: {
                    //             // "desired_config": remoteConnectionAction.desired_config,
                    //             "force_device_port": remoteConnectionAction.force_device_port
                    //         }
                    //     });
                    //     setRefreshRemoteConnection(true);
                    // } catch(e) {
                    //     console.log(e);
                    // }
                }

            }catch (e) {
                alert(e.message);
            }
        }
    }


    return (<div className="Home">
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
                <Breadcrumb.Item active> Tunnel and Firewall</Breadcrumb.Item>
            </Breadcrumb>
            <h1>Tunnel and Firewall</h1>
            <Container className="TunnelAndFirewallContainer">
            <Row>
                <Col xs={6}>
                    <CustomerTunnelSettings
                        remoteConnectionData={remoteConnectionData}
                        onRemoteConnectionActions={remoteConnectionHandler}
                        isLoadingRemoteConnection={isLoadingRemoteConnection}
                        remoteConnectionMessage={remoteConnectionMessage}
                        devicesOverviewList={props.devicesOverviewList}
                    ></CustomerTunnelSettings>
                </Col>
                <Col xs={6}>
                <Alert variant={"secondary"}>
                    <h4 className="RemoteAccessHeader">Firewall overview</h4>
                    <FirewallTable
                        firewallData={firewallData}
                    ></FirewallTable>
                </Alert>
                </Col>
            </Row>
            </Container>
    </div>)
    
}