import React, { useState } from "react";
import TableFilter from 'react-table-filter';
import "../../../../../node_modules/react-table-filter/lib/styles.css";
import { Table, ListGroup, Button, Modal, Alert } from "react-bootstrap";
import { CognitoIdentityServiceProvider } from 'aws-sdk/dist/aws-sdk-react-native';
import "./UsersTable.css";
import config from "../../../../config";

export default function DisabledUsersTable(props) {
    const [filteredUsers, setUsers] = useState(null);
    const users = filteredUsers !== null ? filteredUsers : props.users;
    const [modalShow, setModalShow] = useState(false);
    const [userName, setUserName] = useState(null);
    const [showInfo, setShowInfo] = useState(false);
    const [messageInfoVariant, setMessageInfoVariant] = useState("success");
    const [messageInfoResponseBody, setMessageInfoResponseBody] = useState("");

    var cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
        region: config.cognito.REGION,
        credentials: {
            accessKeyId: props.essential_credentials?.accessKeyId,
            secretAccessKey: props.essential_credentials?.secretAccessKey,
            sessionToken: props.essential_credentials?.sessionToken
            }
    });

    function filterUpdated(newData) {
        setUsers(newData);
    }

    function updateUsersData() {
        props.onUpdateUsersDataActions({
            action: "updateUsersData",
        })
    }

    function MessageInfo() {
        if (showInfo) {
          return (
            <Alert variant={messageInfoVariant} onClose={() => setShowInfo(false)} dismissible>
              <Alert.Heading>{messageInfoResponseBody}</Alert.Heading>
            </Alert>
          );
        }
        return <></>;
    }

    function DeleteUserModal() {
        const handleClose = () => {
            setModalShow(false)
            setShowInfo(false);
        };
        return (
            <Modal
                show={modalShow}
                onHide={handleClose}

                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="UserTableModel">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Delete User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <MessageInfo />
                        <br/>
                        <h4>Are you sure you want to delete {userName}?</h4>
                        <Button className="EditButton" variant="danger" onClick={() => handleDeleteUser(userName)} size="sm">
                            Yes! I am sure.
                        </Button>
                        <br/>
                        <br/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function handleModelShow(user_name) {
        setUserName(user_name);
        setModalShow(true);
    }

    async function handleDeleteUser(email) {
        var params = {
            UserPoolId: config.cognito.USER_POOL_ID,
            Username: email
        };

        cognitoidentityserviceprovider.adminDeleteUser(params).promise().then(() => {
            setMessageInfoVariant("success");
            setMessageInfoResponseBody(email + " has been deleted.");
        }).catch(() => {
            setMessageInfoVariant("danger");
            setMessageInfoResponseBody("Error: " + email + " can't be deleted. Check your permissions!");
        });
        setShowInfo(true);
        updateUsersData();
        // setModalShow(!modalShow);
    }

    function renderUsersTable(Users) {
        return (
            <Table striped bordered>
                <thead>
                    <TableFilter
                        rows={props.users}
                        onFilterUpdate={filterUpdated.bind(this)}>
                        <th key="email" filterkey="email" >Email</th>
                        <th key="customer_name" filterkey="customer_name">Customer Affiliation</th>
                        <th key="group" filterkey="group">User Permissions</th>
                    </TableFilter>
                </thead>
                <tbody>
                    {renderTableRows(Users)}
                </tbody>
            </Table>
        );
    }

    function renderTableRows(Users) {
        return [{}].concat(Users).map((user, i) =>
            i !== 0 ? (
                props.users_attributes[user['user_id']]?.Enabled === true ?
                    <tr key={i}></tr>
                :
                    <tr key={i}>
                        <td key="email" className="d-flex">
                            <div className="align-self-start mr-auto">
                                {user.email.trim().split("\n")[0]}
                            </div>
                            <div className="align-self-end ml-auto">
                                <Button className="EditButton" variant="danger" onClick={() => handleModelShow(user.email)} size="sm">
                                    Delete User
                                </Button>
                            </div>
                        </td>
                        <td key="customer_name"><p>{user?.customer_name?.trim().split("\n")[0]}</p></td>
                        <td key="group"><p>{user?.group?.trim().split("\n")[0]}</p></td>
                    </tr>
            ) : (<tr key={i}></tr>)
        );
    }

    return (
        <div>
            <ListGroup>
                <div className="responsiveTableUserTable">
                    {renderUsersTable(users)}
                </div>
            </ListGroup>
            {modalShow ? DeleteUserModal() : <></>}
        </div>
    )
}
