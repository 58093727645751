import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, OverlayTrigger, Tooltip, Button, Form, NavDropdown } from "react-bootstrap";
import { Auth, API } from "aws-amplify";
import Routes from "./Routes";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faChartArea, faBellSlash, faCheckCircle, faCheck, faEye,
        faExclamation, faExclamationCircle, faFileCode, faFolder, faCoins, faExternalLinkAlt,
        faFingerprint, faHeart, faHeartBroken, faHistory, faHome, faLaptopCode, faList, faLockOpen,
        faMemory, faParachuteBox, faPlay, faPause, faRoad, faRocket, faRobot, faQrcode, faPowerOff,
        faSave, faBell, faCode, faBullhorn, faExclamationTriangle, faCog, faCogs, faCar, faLaptop,
        faMicrochip, faMobileAlt, faQuestion, faIdCard, faIndustry, faInfo, faInfoCircle, faInfinity,
        faLock, faMedkit, faMobile, faServer, faShieldAlt, faSignal, faSlidersH, faSpaceShuttle,
        faStar, faSpinner, faStickyNote, faSync, faSyncAlt, faTasks, faTags, faThumbsDown, faThumbsUp,
        faToggleOff, faToggleOn, faUserAlt, faUser, faUserEdit, faUserCog, faWifi, faWrench, faClock,
        faBriefcaseMedical, faCloud, faCloudDownloadAlt, faBriefcase, faCloudUploadAlt, faBox, faBoxOpen,
        faUserCheck, faCommentAlt, faUserClock, faUserTie, faBarcode, faHourglass, faHourglassStart,
        faHourglassHalf, faLongArrowAltRight, faHashtag, faClone, faHeartbeat, faUserFriends, faTag,
        faArrowAltCircleRight, faChevronCircleRight, faSimCard, faHandHolding, faHandHoldingHeart,
        faHandHoldingMedical, faLayerGroup, faLessThanEqual, faPlayCircle, faStopCircle, faTerminal,
        faHourglassEnd, faEnvelope, faGlobeEurope, faUserLock, faLink, faCopyright, faUsers, faSignOutAlt,
        faSignInAlt, faChartPie, faCubes, faUserCircle, faWindowClose, faCrosshairs, faCopy, faFlagCheckered,
        faSquare, faSquareFull, faUpload, faRedoAlt, faPuzzlePiece, faListUl, faListAlt, faListOl, faMagic,
        faFolderOpen, faUnlink, faFileAlt, faFileUpload, faFileDownload, faDotCircle, faCircle,
        faFileCsv, faTools, faKey, faDoorClosed, faDoorOpen, faArchive, faPlusSquare, faEyeSlash, faSortDown,
        faSortUp, faSortNumericUp, faSortNumericDownAlt, faChevronCircleDown, faUserSecret, faUserSlash,
        faUserTimes, faBuilding, faMicroscope, faIdBadge, faCalendarCheck, faShoppingCart, faAward, faBookmark,
        faDolly, faWindowRestore, faBan, faSatellite
    } from '@fortawesome/free-solid-svg-icons'
import logoEsec from './images/iot-device-suite.png'
import logoSatel from './images/satel-netco-logo_web.png'
import logoPhytec from './images/iot-phytec-weiss.png'
import Footer from './containers/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./App.css";
import Moment from 'react-moment';
import moment from 'moment'


// Added all available FontAwesome Icons here into library. If you need more, you can add them here or directly in each component.
library.add(
    fab, faCheckSquare, faCheck, faCoffee, faCode, faChartArea, faBell, faBellSlash, faBullhorn,
    faCar, faCheckCircle, faEye, faExclamation, faExclamationCircle, faExclamationTriangle,
    faFileCode, faFolder, faCog, faCogs, faCoins, faExternalLinkAlt, faFingerprint, faHeart,
    faHeartBroken, faHistory, faHome, faIdCard, faIndustry, faInfo, faInfoCircle, faInfinity,
    faLaptop, faLaptopCode, faList, faLock, faLockOpen, faMemory, faMedkit, faMobile, faCloudUploadAlt,
    faMicrochip, faMobileAlt, faParachuteBox,faPlay, faPause, faRoad, faRocket, faRobot, faSquare,
    faQuestion, faQrcode, faPowerOff, faSave, faServer, faRoad, faShieldAlt, faSignal, faSlidersH,
    faSpaceShuttle, faStar, faSpinner, faStickyNote, faSync, faSyncAlt, faTasks, faTags,
    faThumbsDown, faThumbsUp, faToggleOff, faToggleOn, faUserAlt, faUser, faUserEdit, faUserCog,
    faWifi, faWrench, faClock, faBriefcaseMedical, faBriefcase, faCloud, faCloudDownloadAlt,
    faBox, faBoxOpen, faCommentAlt, faUserCheck, faUserClock, faUserTie, faSignOutAlt, faSignInAlt,
    faEye, faBarcode, faHourglass, faHourglassHalf, faHourglassStart , faHourglassEnd, faLongArrowAltRight,
    faClone, faHashtag, faHeartbeat, faUserFriends, faTag, faArrowAltCircleRight, faChevronCircleRight,
    faHandHolding, faHandHoldingHeart, faHandHoldingMedical, faLessThanEqual, faPlayCircle, faSimCard,
    faStopCircle, faTerminal, faEnvelope, faGlobeEurope, faUserLock, faLink, faCopyright, faUsers,
    faLayerGroup, faChartPie, faCubes , faUserCircle, faWindowClose, faCrosshairs, faCopy, faFlagCheckered,
    faSquareFull, faUpload, faRedoAlt, faPuzzlePiece, faList, faListAlt, faListOl, faListUl, faMagic,
    faTerminal, faFolderOpen, faUnlink, faFileAlt, faFileUpload, faFileDownload,
    faLaptopCode, faDotCircle, faCircle, faFileCsv, faTools, faDoorOpen, faDoorClosed, faArchive,
    faPlusSquare, faEyeSlash, faSortDown, faSortUp, faSortNumericUp, faSortNumericDownAlt, faChevronCircleDown,
    faUserSecret, faUserSlash, faUserTimes, faBuilding, faMicroscope, faHome, faIdBadge, faCalendarCheck,
    faShoppingCart, faKey, faAward, faBookmark, faDolly, faWindowRestore, faBan, faSatellite
    )


function App(props) {
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [isCustomerRoleAdmin, setIsCustomerRoleAdmin] = useState(false);
    const [devicesOverviewList, setDevicesOverviewList] = useState([]);
    const [sessionExp, setSessionExp] = useState();
    const [remainingMinutes, setRemainingMinutes] = useState(null);
    const [remainingTime, setRemainingTime] = useState(null);
    const [userLoggedOut, setUserLoggedOut] = useState(false);
    const [tokenCopied, setTokenCopied] = useState(false);
    let intervalTimeoutSessionId = null;
    const [searchTerm, setSearchTerm] = useState("");
    const [idToken, setIdToken] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const handleChange = event => {
        setSearchTerm(event.target.value);
    };
    const showMaxDevicesThreshhold = 16;


    useEffect(() => {
        async function onLoad() {
            Auth.currentSession().then((async session => {
                // console.log(session);
                let token = "unset";
                token = session.getIdToken().getJwtToken();
                setIdToken(token);
                if (loggedInUser) {
                    // Get today's date and time
                    if (!intervalTimeoutSessionId || remainingTime < 0) {
                        console.log("A Timer Interval has been Started")
                        setSessionExp(session?.accessToken?.payload?.exp);
                        intervalTimeoutSessionId = setInterval(async function() {
                            var now = Math.round(new Date().getTime()/1000);
                            var diffTime = session.accessToken?.payload?.exp - now;
                            var duration = moment.duration(diffTime*1000, 'milliseconds');
                            var minutes = duration.minutes();
                            // console.log(session.accessToken?.payload?.exp)
                            // console.log(diffTime)
                            console.log("The Interval Timeout Session Id is: " + intervalTimeoutSessionId)
                            setRemainingMinutes(minutes);
                            setRemainingTime(diffTime);
                            if (diffTime < 0 || userLoggedOut) {
                                handleLogout(intervalTimeoutSessionId);
                            }
                        }, 2000);
                    }

                    userHasAuthenticated(true);

                    // This check is only for the GUI, the API also handles if the user is admin or not.
                    if (loggedInUser.group && loggedInUser.customer_name === "osb") {
                        setIsCustomerRoleAdmin(true);
                    }
                    const devicesOverviewList = await API.get("iot_registry-endpoint", "/iot_devices_overview");
                    setDevicesOverviewList(devicesOverviewList);
                } else {
                    const cognitoUserId = session.idToken.payload.sub;
                    loadUser(cognitoUserId).then((user) => {
                        setLoggedInUser(user);
                        userHasAuthenticated(true);
                    });
                }
            })).catch(e => {
                if (e === 'No current user') {
                    console.log("NO CURRENT USER HAS LOGGED IN!");
                    handleLogout();
                } else {
                    console.log(e.message);
                    handleLogout();
                }
            });
        }
        onLoad();
        setIsAuthenticating(false);
        return () => clearInterval(intervalTimeoutSessionId);
    }, [loggedInUser, isAuthenticated, userLoggedOut]);


    useEffect(() => {
        console.log("###  Listner Window has Started ###");
        const handleInvalidToken = event => {
            // console.log(event);
            if (event.key === 'amplify-signin-with-hostedUI' && event.oldValue && !event.newValue) {
                handleLogout();
            } else if (event.key === 'prevPath'){
                setLoggedInUser(loggedInUser);
                userHasAuthenticated(true);
                // props.history.push('/');
            }
            else {
                setLoggedInUser(loggedInUser);
                userHasAuthenticated(true);
                props.history.push('/');
            }
        }

        window.addEventListener('storage', handleInvalidToken);
        return function cleanup() {
            clearInterval(intervalTimeoutSessionId);
            window.removeEventListener('storage', handleInvalidToken);
        }
    }, [loggedInUser])


    useEffect(() => {
            var devices_list_filtered = null;
            if (searchTerm.length >= 2) { // Do not start if less then 2 characters

                // Find all devices ending with string
                    devices_list_filtered = devicesOverviewList.filter((device) => {
                    const devicename = device.device_name.toLowerCase();
                    const searchTermLower = searchTerm.toLowerCase();
                    if (devicename.substring(devicename.length  - searchTermLower.length) === searchTermLower ){
                        return device;
                    }else
                        return null;
                });
                if (devices_list_filtered.length === 0){
                    devices_list_filtered = devicesOverviewList.filter((device) => {
                        const devicename = device.device_name.toLowerCase();
                        const searchTermLower = searchTerm.toLowerCase();
                        if (devicename.indexOf(searchTermLower) !== -1 ){
                            return device;
                        }else
                            return null;
                    });
                }
            }else {
                devices_list_filtered = null;
            }
            console.log("Search  Filtering")
            setSearchResults(devices_list_filtered);
      }, [searchTerm, devicesOverviewList]);

    function renderDevices() {
        return (
            searchResults.map(device => (
                <NavDropdown.Item key={device?.uid} href={`/device/${device?.uid}`}>{device?.device_name}</NavDropdown.Item>
            ))
        );
    }

    async function handleLogout() {
        try {
            console.log("###   Logging out  ###");
            clearInterval(intervalTimeoutSessionId);
            setRemainingMinutes(null);
            setRemainingTime(null);
            if (loggedInUser) {
                await Auth.signOut();
            }
            userHasAuthenticated(false);
            setIsCustomerRoleAdmin(false);
            setUserLoggedOut(false);
            setLoggedInUser(null);
            // localStorage.clear();
            props.history.push("/login");
        } catch(e) {
            alert(e);
        }
    }

    function loadUser(userId) {
        return API.get("iot_users", `/iot_users/${userId}`);
    }


    function showUserDataAsNavItem(user){
    // updated_at, user_id, created_at, updated_by, created_by, group, email, customer_name}
        return (
            user?
                <div>
                    <OverlayTrigger
                        trigger="click"
                        className="input-group"
                        key="user-overlay"
                        placement="auto"
                        rootClose={true}
                        overlay={
                            <Tooltip  id={`tooltip-user-overlay`}>
                                <p style={{"color":"white"}}>
                                    <strong>Mail</strong>
                                    <br/>
                                    {user.email}
                                </p>
                                <p style={{"color":"white"}}>
                                    <strong>Group</strong>
                                    <br/>
                                    {user.group}
                                </p>
                                <p style={{"color":"white"}}>
                                    <strong>Customer</strong>
                                    <br/>
                                    {user.customer_name}
                                </p>
                                <p style={{"color":"white"}}>
                                    <strong>Session Timeout</strong>
                                    <br/>
                                    <FontAwesomeIcon icon={faHourglassHalf}/> <Moment unix fromNow>{sessionExp}</Moment>
                                </p>

                                <Link to='/changePassword'>
                                    <Button block className="actionbuttons" variant="warning" size="sm"><FontAwesomeIcon icon={faKey} /> Change Password</Button>
                                </Link>

                                <Link to='/activateTOTP'>
                                    <Button block className="actionbuttons" variant="warning" size="sm"><FontAwesomeIcon icon={faFingerprint} /> Manage MFA</Button>
                                </Link>

                                <CopyToClipboard text={idToken}
                                    onCopy={() => setTokenCopied(true)}
                                    >
                                    {tokenCopied? 
                                        <Button block className="actionbuttons" variant="success" size="sm"><FontAwesomeIcon icon="copy" /> Copied</Button>:
                                        <Button block className="actionbuttons" variant="secondary" size="sm"><FontAwesomeIcon icon="copy" /> Copy Token</Button>
                                    }
                                </CopyToClipboard>

                                <Button onClick={() =>{setUserLoggedOut(true)}} block className="actionbuttons" variant="secondary" size="sm"><FontAwesomeIcon icon={faSignOutAlt} /> Logout</Button>


                            </Tooltip>
                        }
                        >
                        <Button className="SessionTimeoutButton" size="sm" variant={isCustomerRoleAdmin ? "danger" : "info"}>
                            {remainingMinutes < 5 ?
                            <div><FontAwesomeIcon icon={faUser}/> {user.email} <FontAwesomeIcon icon={faSignOutAlt}/> Session Timeout <Moment unix fromNow>{sessionExp}</Moment></div>
                            :
                            <div><FontAwesomeIcon icon={faUser}/> {user.email}</div>
                            }
                        </Button>
                    </OverlayTrigger>
                </div>
            :
                <></>
        );
    }

    return (
        !isAuthenticating && <div className="AppContainer">
            <Navbar sticky="top">

                { ( (loggedInUser && loggedInUser.customer_name.startsWith("phytec")) || window.location.hostname.includes("phytec") ) ?
                    <Link to="/"><img src={logoPhytec} alt="iot rocket's IoT Device Suite for PHYTEC" className="logo"/></Link>
                :
                loggedInUser && loggedInUser.customer_name.startsWith("satel") ?
                    <Link to="/"><img src={logoSatel} alt="iot rocket's IoT Device Suite for SATEL" className="logo"/></Link>
                :
                    <Link to="/"><img src={logoEsec} alt="iot rocket's IoT Device Suite" className="logo"/></Link>
                }

                <Navbar.Brand>

                    { loggedInUser && loggedInUser.customer_name.startsWith("phytec") ?
                    <Link to="/"> Device Management </Link>
                    :
                    loggedInUser && loggedInUser.customer_name.startsWith("satel") ?
                        <Link to="/"> IoT Device Management</Link>
                    :
                    <Link to="/">Embedded Security</Link>
                    }

                </Navbar.Brand>
                { loggedInUser ?
                    <Navbar.Collapse className="justify-content">
                        <Form className="d-flex">
                            <Form.Control
                                type="text"
                                placeholder="Search devices"
                                aria-label="Search"
                                value={searchTerm}
                                onChange={handleChange.bind(this)}
                            />
                            {searchResults?.length > "0" && searchResults?.length <= showMaxDevicesThreshhold?
                                <NavDropdown show>{renderDevices()}</NavDropdown>
                            : searchResults?.length > showMaxDevicesThreshhold ?
                                <NavDropdown show>
                                    <NavDropdown.Item key="tooMany">
                                        Too many results ({searchResults?.length} devices)! Please narrow your search.
                                    </NavDropdown.Item>
                                </NavDropdown>
                            : <></>}
                        </Form>
                    </Navbar.Collapse>
                :
                    <></>
                }

                <Navbar.Collapse className="justify-content-end">
                    {isAuthenticated ?
                        <Nav>
                            <Nav.Item as="li">
                                <Link  className="nav-link" to="/"> <FontAwesomeIcon icon={faHome} /> Home</Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                    <Link className="nav-link" to="/infoAndSupport"><FontAwesomeIcon icon={faInfoCircle} /> Info&Support</Link>
                            </Nav.Item>
                            {isCustomerRoleAdmin ?
                                <Nav.Item as="li">
                                    <Link className="nav-link" to="/administration"><FontAwesomeIcon icon={faUserLock} /> Administration</Link>
                                </Nav.Item>
                                : <></>
                            }
                            <Nav.Item as="li">
                                {showUserDataAsNavItem(loggedInUser)}
                            </Nav.Item>
                        </Nav>
                        :
                        <Nav>
                            <Nav.Item as="li">
                                <Link className="link" to="/login"><FontAwesomeIcon icon={faSignInAlt} /> Login</Link>
                            </Nav.Item>
                        </Nav>
                    }
                </Navbar.Collapse>
            </Navbar>
            <Routes appProps={{ isAuthenticated, userHasAuthenticated, loggedInUser, setLoggedInUser, isCustomerRoleAdmin, devicesOverviewList }} />
            {isAuthenticated ?
                <Footer></Footer>
            :
                <></>
            }
        </div>
    );
}

export default withRouter(App);
