import React, { useEffect, useState } from "react";
import { Auth, API } from "aws-amplify";
import { Form, Alert, InputGroup } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { Container, Button } from "react-bootstrap";
import "./Login.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faUserPlus} from '@fortawesome/free-solid-svg-icons'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export default function Login(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: ""
    });
    const [errorMessage, setErrorMessage] = useState();
    const state = props.location.state;
    const [passwordType, setPasswordType] = useState("password");

    useEffect(() => {

        Auth.currentSession().then(( () => {
            props.history.push("/");
        })
        ).catch(e => {
            if (e === 'No current user') {
                console.log("No user session!");
            } else {
                console.log(e.message);
            }
        });
      }, []);

    function handleShowHidePassword() {
        passwordType === 'text' ? setPasswordType('password') : setPasswordType('text')
    }

    function validateForm() {
        return (fields.email.length > 0 || state) && fields.password.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const email = state && state.email ? state.email : fields.email;
            // Sign In function
            Auth.signIn(email, fields.password).then((data) => {
                if (data.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    // console.log("Account with token");
                    var prevPath = localStorage.getItem('prevPath') || "/";
                    console.log(prevPath);
                    props.history.push({pathname: "/multiFactorAuth", data: data, prevPath: prevPath});
                } else {
                    if (data.challengeName  === 'NEW_PASSWORD_REQUIRED') {
                        props.userHasAuthenticated(false);
                        props.history.push({pathname: "/changePassword", user: data});
                    } else {
                        loadUser(data.username).then((user) => {
                            props.setLoggedInUser(user);
                        });
                        props.userHasAuthenticated(true);
                        props.history.push("/activateTOTP");
                    }
                }
            }
            ).catch(e => {
                if (e.code === "UserNotConfirmedException") {
                    setErrorMessage(
                    <Alert variant="danger">
                        Your user account has not been activated yet. Please enter the user confirmation code
                        <Link to="/userConfirmation"> here</Link>.
                    </Alert>
                    );
                }
                else if (e.code === "NotAuthorizedException") {
                    if (e.message === "User is disabled.") {
                        setErrorMessage(
                            <Alert variant="warning">
                                This user is disabled. <br/>
                                <br/>
                                If you think this might be an error, please contact our <Link to="/infoAndSupport" target="_blank">support</Link>.
                            </Alert>
                        );
                    }
                    else {
                        setErrorMessage(
                            <Alert variant="danger">
                                Incorrect email address or password. <br/>
                                Please be aware that email adresses are CASE SENSITIVE.
                                <br/> You can check the spelling in the recepient field of your welcome mail.
                            </Alert>
                        );
                    }
                }
                else {
                    setErrorMessage(
                        <Alert variant="danger">
                            {e.message}
                        </Alert>
                    );
                }
                setIsLoading(false);
            });
        } catch (e) {
            alert(e.message);
            setIsLoading(false);
        }
    }

    function loadUser(userId) {
        return API.get("iot_users", `/iot_users/${userId}`);
    }

    return (
        <div className="Login">
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/login"><FontAwesomeIcon icon={faSignInAlt}/> Login</Breadcrumb.Item>
                </Breadcrumb>
            <h1 style={{ marginBottom: "50px", marginTop: "20px"}}>Welcome to the IoT Device Suite by iot rocket GmbH</h1>

            <h3 className="text-center">Login</h3>
            <form onSubmit={handleSubmit}>
                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon="envelope" />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            autoFocus
                            type="email"
                            value={state && state.email ? state.email : fields.email}
                            onChange={handleFieldChange}
                            placeholder="Enter email"
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon="lock" />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type={passwordType}
                                value={fields.password}
                                onChange={handleFieldChange}
                                placeholder="Password" />
                            <InputGroup.Prepend>
                                <InputGroup.Text onClick={() => handleShowHidePassword()}>
                                    {passwordType === 'text' ? <FontAwesomeIcon icon="eye" /> : <FontAwesomeIcon icon="eye-slash" />}
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                    </InputGroup>
                </Form.Group>
                {errorMessage}
                <br/>
                <LoaderButton block type="submit" isLoading={isLoading} disabled={!validateForm()}>
                    <FontAwesomeIcon icon={faSignInAlt} /> Login
                </LoaderButton>
                <br/>
                <p>Forgot Password? <Link to="/forgotPassword">
                <br/>
                Click here to reset your password.</Link></p>

            </form>

                <br style={{marginBottom: "50px", marginTop: "20px"}} />
                <hr/>
                <h3>Demo Account or Live Demonstration</h3>
                <a target="_blank" rel="noopener noreferrer" href="mailto:support@iot-suite.io?subject=Demo%20Account%20request">
                    <Button variant="outline-success">
                        <FontAwesomeIcon icon={faUserPlus} />
                        &nbsp; Register for Demo Account
                    </Button>
                </a> We would be glad to show you around <a target="_blank" rel="noopener noreferrer" href="mailto:support@iot-suite.io?subject=Demo%20Account%20request">
                    Please register for a demonstration account.
                </a>
            </Container>
        </div>
    );
}