import React, { useState, useRef, useEffect } from "react";
import { Form, Alert, Breadcrumb, Container } from "react-bootstrap";
import { Auth, API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "./ChangePassword.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";


export default function MultiFactorAuth(props) {
    const [isLoadingVerifyCode, setIsLoadingVerifyCode] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [fields, handleFieldChange] = useFormFields({
        totp_password: ""
    });
    const data = props.location.data;
    const inputRef = useRef(null);


    function validateVerifyCodeForm() {
        return ( fields.totp_password.length > 3 );
    }

    async function handleVerifyCode(event) {
        event.preventDefault();
        setIsLoadingVerifyCode(true);

        // console.log(data)
        Auth.confirmSignIn(data, fields.totp_password, 'SOFTWARE_TOKEN_MFA')
            .then((data) => {
                loadUser(data.username).then((user) => {
                    props.setLoggedInUser(user);
                    setIsLoadingVerifyCode(false);
                    console.log("Multifactor page prevPath = " + props.location?.prevPath )
                    props.history.push(props.location?.prevPath || "/");
                    localStorage.removeItem('prevPath');
                });
            }).catch(error => {
                setErrorMessage(
                    <Alert variant="danger">
                        <p>{error.message}</p>
                    </Alert>
                );
                setIsLoadingVerifyCode(false);
        })
    }

    function loadUser(userId) {
        return API.get("iot_users", `/iot_users/${userId}`);
    }

    useEffect(() => {
        // Check if the input ref is available
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, []);

    function renderForm() {
        return (
            <div className="description">
                <h1>Multi-factor Authentication</h1>
                <p style={{textAlign: 'center'}}>Please enter an MFA code to complete sign-in.</p>
                <br/>
                <form onSubmit={handleVerifyCode}>
                    <Form.Group controlId="totp_password">
                        <Form.Label>MFA Code</Form.Label>
                        <br/>
                        <br/>
                        <Form.Control
                            type="text"
                            value={fields.totp_password}
                            onChange={handleFieldChange}
                            ref={inputRef}
                        />
                    </Form.Group>
                    <br/>
                    {errorMessage}
                    <LoaderButton
                        block
                        type="submit"
                        isLoading={isLoadingVerifyCode}
                        disabled={!validateVerifyCodeForm()}>
                        Submit
                    </LoaderButton>
                </form>

                <br style={{marginTop: "20px"}} />
                <hr/>
                <br style={{marginTop: "20px"}} />
                <h6>If you have any problem with your TOTP, please contact our support team via: </h6>
                <div className="text-center">
                    <a href="mailto:support@iot-suite.io?subject=IoT%20Device%20Management%20Support%20Request-MFA">
                        <Button style={{margin: "10px"}} variant="primary" >
                            <FontAwesomeIcon icon={faTicketAlt} className="supportIcon"/> Create Support Ticket
                        </Button>
                    </a>
                    <h6>In urgent cases you may contact: +49 15678 / 51 74 82</h6>
                </div>
            </div>
        );
    }

    return (
        <div className="ChangePassword">
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/login" }}><FontAwesomeIcon icon={faSignInAlt}/> Login</Breadcrumb.Item>
                    <Breadcrumb.Item active>MFA</Breadcrumb.Item>
                </Breadcrumb>

                {renderForm()}
            </Container>
        </div>
    );

}