import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCloudDownloadAlt, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import ContactInformation from '../components/ContactInformation'
import './InfoAndSupport.css';
import { Link } from "react-router-dom";

export default function InfoAndSupport() {

    // save the path if session expired
    localStorage.setItem('prevPath', '/infoAndSupport');

    return (
        <div className='support'>
            <Container>

            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Info & Support</Breadcrumb.Item>
            </Breadcrumb>
                <h1>Info & Support</h1>
                <div>
                    <Row>
                        <Col md="6">
                            <ContactInformation/>
                        </Col>
                        <Col md="6">

                        <h4>Support Request</h4>

                        <p>Emailing our support address &quot;<b>support@iot-suite.io</b>&quot; will automatically open a support ticket.</p>
                        <p>Please attach the necessary information.</p>
                        <br/>

                        <Button style={{margin: "10px"}} variant="primary" onClick={()=> window.open("mailto:support@iot-suite.io?subject=IoT%20Device%20Management%20Support%20Request", "_blank")}>
                        <FontAwesomeIcon icon={faTicketAlt} className="supportIcon"/> Click here to create a Ticket
                        </Button>
                        <br/>



                        <h4>Downloads</h4>
                        <Button style={{margin: "10px"}} variant="primary" onClick={()=> window.open("/docs/ESEC_IoT_Training_HandsOn_V20200728.pdf", "_blank")}>
                        <FontAwesomeIcon icon={faCloudDownloadAlt} className="supportIcon"/> IoT Trainings and Demonstrator (PDF)
                        </Button>
                        <br/>
                        <Button style={{margin: "10px"}} variant="primary" onClick={()=> window.open("/docs/ESEC_ProductDataSheet-DeviceManagementTool-V6.pdf", "_blank")}>
                            <FontAwesomeIcon icon={faCloudDownloadAlt} className="supportIcon"/> IoT Devicemanagement Data Sheet (PDF)
                        </Button>

                        </Col>
                    </Row>

                </div>
            </Container>
        </div>
    );
}