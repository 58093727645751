import React, { useState } from "react";
import TableFilter from 'react-table-filter';
import "../../../../../node_modules/react-table-filter/lib/styles.css";
import { Table, ListGroup, Modal, Button } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";
import "./UsersTable.css";
import UpdateUser from "./UpdateUsers/UpdateUser";

export default function UsersTable(props) {
    const [filteredUsers, setUsers] = useState(null);
    const users = filteredUsers !== null ? filteredUsers : props.users;
    const [modalShow, setModalShow] = useState(false);
    const [userData, setUserData] = useState(null);


    function filterUpdated(newData) {
        setUsers(newData);
    }

    function renderUsersTable(Users) {
        return (
            <Table striped bordered hover>
                <thead>
                    <TableFilter
                        rows={props.users}
                        onFilterUpdate={filterUpdated.bind(this)}>
                        <th key="email" filterkey="email" >Email</th>
                        <th key="customer_name" filterkey="customer_name">Customer Affiliation</th>
                        <th key="group" filterkey="group">User Permissions</th>
                        <th key="user_status" filterkey="user_status">User Status</th>
                        <th key="enabled" filterkey="enabled">Account Enabled</th>
                        <th key="mfa" filterkey="mfa">MultiFactor (TOTP)</th>
                    </TableFilter>
                </thead>
                <tbody>
                    {renderTableRows(Users)}
                </tbody>
            </Table>
        );
    }

    function UpdateUserModal() {
        const handleClose = () => setModalShow(false);
        return (
            <Modal
                show={modalShow}
                onHide={handleClose}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="UserTableModel">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UpdateUser
                        user={userData}
                        customers={props.customers}
                        users_attributes={props.users_attributes}
                        essential_credentials={props.essential_credentials}
                        onUpdateUsersDataActions={props.onUpdateUsersDataActions}>
                    </UpdateUser>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function handleModelShow(user_data) {
        setUserData(user_data);
        setModalShow(true);
    }


    function renderTableRows(Users) {
        return [{}].concat(Users).map((user, i) =>
            i !== 0 ? (
                props.users_attributes[user['user_id']] ?
                    <tr key={i}>
                        <td key="email" className="d-flex">
                            <div className="align-self-start mr-auto">
                                {user.email.trim().split("\n")[0]}
                            </div>
                            <div className="align-self-end ml-auto">
                                <Button className="EditButton" variant="secondary" onClick={() => handleModelShow(user)} size="sm">
                                    Edit User
                                </Button>
                            </div>
                        </td>
                        <td key="customer_name"><p>{user?.customer_name?.trim().split("\n")[0]}</p></td>
                        <td key="group"><p>{user?.group?.trim().split("\n")[0]}</p></td>
                        <td key="user_status">{props.users_attributes['length'] !== 0 ? props.users_attributes[user['user_id']]?.UserStatus === "CONFIRMED" ?
                            <p style={{color: "rgb(30, 113, 161)"}}>{props.users_attributes[user['user_id']]?.UserStatus}</p>
                            :
                            <p style={{color: "red"}}>{props.users_attributes[user['user_id']]?.UserStatus}</p> : "unauthorized"}
                        </td>
                        <td key="enabled">{props.users_attributes['length'] !== 0 ? props.users_attributes[user['user_id']]?.Enabled === true ?
                            <p style={{color: "green"}}>Enabled</p> : <p style={{color: "red"}}>Disabled</p> : "unauthorized"
                        }</td>
                        <td key="mfa">{props.users_attributes['length'] !== 0 ?
                            props.users_attributes[user['user_id']]?.PreferredMfaSetting && props.users_attributes[user['user_id']]?.PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA' ? <p style={{color: "green"}}>Enabled</p>
                                : <p style={{color: "red"}}>Disabled</p> : "unauthorized"
                        }</td>
                    </tr>
                :
                    <tr key={i}></tr>
            ) : (<tr key={i}></tr>)
        );
    }

    return (
        <div>
            <ListGroup>
                <div className="responsiveTableUserTable">
                    {renderUsersTable(users)}
                </div>
            </ListGroup>

            {modalShow ? UpdateUserModal() : <></>}
        </div>
    )
}
