import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function CustomerRoleAdminRoute({ component: C, appProps, ...rest }) {
    if (appProps.isCustomerRoleAdmin === undefined) {
        return (
            <Route>
                <Redirect
                        to={`/`}
                    />
            </Route>
        );
    }
    return (
        <Route
            {...rest}
            render={props =>
                appProps.isCustomerRoleAdmin
                    ? <C {...props} {...appProps} />
                    : <Redirect
                        to={`/permissionDenied`}
                    />}
        />
    );
}