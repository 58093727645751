import React from "react";
import DeviceStatus from "./DeviceStatus/DeviceStatus";
import DeviceManagement from "./DeviceManagement/DeviceManagement";
import DeviceMeta from "./DeviceMeta/DeviceMeta";
import DeviceHealth from "./DeviceHealth/DeviceHealth";
import DeviceFirmware from "./DeviceFirmware/DeviceFirmware";
import RemoteAccess from "./RemoteAccess/RemoteAccess";
import FeatureHawkbit from "./FeatureHawkbit/FeatureHawkbit";
// import TeamViewerIot from "./TeamViewerIot/TeamViewerIot";
import WhereverSim from "./WhereverSim/WhereverSim";
import UploadFiles from "./UploadFiles/UploadFiles";
import RemoteCommand from "./RemoteCommand/RemoteCommand";
import DeviceLogger from "./DeviceLogger/DeviceLogger";
import DeviceIoT from "./DeviceIoT/DeviceIoT";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { faArchive } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// React placeholder
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import {TextRow} from 'react-placeholder/lib/placeholders';
import LicenseRollout from "./LicenseRollout/LicenseRollout";
import UserData from "./UserData/UserData";

export default function Device(props) {
    return (
        <div className="device-body">
            <Tabs className="dev-tabs" 
                defaultActiveKey="overview" 
                id="device-tab-selection"
                >
                <Tab eventKey="overview" title="Overview">
                    <div className="dev-details">
                        <DeviceStatus
                        device={props.device}
                        hawkbitData={props.hawkbitData}
                        whereverSimData={props.whereverSimData}
                        remoteConnectionData={props.remoteConnectionData}
                        // userRemarksData={props.userRemarksData}
                        isCustomerRoleAdmin={props.isCustomerRoleAdmin}
                        onMetaDataChange={props.onMetaDataChange}
                        iotCoreData={props.iotCoreData}
                        ></DeviceStatus>
                    </div>
                    {props?.loggedInUser?.group === "Admin" ? <div>
                        <h4>Manage Device Ownership</h4>
                        <DeviceManagement device={props.device} customers={props.customers}
                        isCustomerRoleAdmin={props.isCustomerRoleAdmin}
                        isLoadingAssignment={props.isLoadingAssignment}
                        onDeviceAssignment={props.onDeviceAssignment}
                        />
                    </div>
                    : <></>}
                </Tab>
                
                <Tab eventKey="meta" title="Meta">
                    <DeviceMeta device={props.device}
                    onMetaDataChange={props.onMetaDataChange}
                    isCustomerRoleAdmin={props.isCustomerRoleAdmin}
                    metaDataMessage={props.metaDataMessage}
                    deviceTagsData={props.deviceTagsData}
                    onDeviceTagAction={props.onDeviceTagAction}
                    />
                </Tab>

                <Tab eventKey="user_data" title="User Data">
                    <div>
                        <UserData
                            iotCoreData={props.iotCoreData}
                        />
                    </div>
                </Tab>

                <Tab eventKey="featureWhereverSIM" title="SIM">
                    <ReactPlaceholder showLoadingAnimation className='text-center' ready={!props.isLoadingWhereverSimData} customPlaceholder={
                    <div className='text-center'>
                        <br/>
                        <TextRow color='#e4e4e7' style={{width: 'auto', height: 40}}/>
                        <TextRow color='#f2f2f3' style={{width: 'auto', height: 300}}/>
                    </div>
                        }>
                        <div>
                            <WhereverSim device={props.device}
                            onWhereverSimActions={props.onWhereverSimActions}
                            whereverSimData={props.whereverSimData}
                            whereverSimMessage={props.whereverSimMessage}
                            whereverSimProfiles={props.whereverSimProfiles}
                            />
                        </div>
                    </ReactPlaceholder>
                </Tab>

                <Tab eventKey="health" title="Health">
                    <DeviceHealth device={props.device} 
                                  iotCoreData={props.iotCoreData}></DeviceHealth>
                </Tab>

                <Tab eventKey="firmware" title="Firmware Update">
                    <div>
                        <DeviceFirmware
                        device={props.device}
                        iotCoreData={props.iotCoreData}
                        />
                        <FeatureHawkbit
                        device={props.device}
                        hawkbitData={props.hawkbitData}
                        hawkbitDeploymentListData={props.hawkbitDeploymentListData}
                        isLoadingHawkbitData={props.isLoadingHawkbitData}
                        isLoadingHawkbitDeploymentListData={props.isLoadingHawkbitDeploymentListData}
                        onHawkbitActions={props.onHawkbitActions}
                        message={props.hawkbitMessage}
                        />
                    </div>
                </Tab>

                <Tab eventKey="remoteAccess" title="Remote Access">
                    <RemoteAccess device={props.device}
                    //HOTP
                    onHOTPGeneration={props.onHOTPGeneration}
                    hotpResponse={props.hotpResponse}
                    // Remote Access Feature
                    remoteConnectionData={props.remoteConnectionData}
                    onRemoteConnectionActions={props.onRemoteConnectionActions}
                    isLoadingRemoteConnection={props.isLoadingRemoteConnection}
                    message={props.remoteConnectionMessage}
                    devicesOverviewList={props.devicesOverviewList}
                    firewallData={props.firewallData}
                    onWhitelistIP={props.onWhitelistIP}
                    onRenewIP={props.onRenewIP}
                    onDeleteIP={props.onDeleteIP}
                    />
                </Tab>

                <Tab eventKey="featureFileUpload" title="File Transfer">
                    {/* <h4><FontAwesomeIcon icon={faFileAlt}/> File Transfer</h4> */}
                    <UploadFiles
                    device={props.device}
                    fileUploadData={props.fileUploadData}
                    onFileUploadActions={props.onFileUploadActions}
                    message={props.fileUploadMessage}
                    isLoadingFileUpload={props.isLoadingFileUpload}
                    customer_name={props?.loggedInUser?.customer_name}
                    />
                </Tab>

                <Tab eventKey="featureRemoreCommand" title="Remote Command">
                    {/* <h4><FontAwesomeIcon icon={faLaptopCode}/> Remote Command</h4> */}
                    <RemoteCommand
                    device={props.device}
                    remoteCommandData={props.remoteCommandData}
                    onRemoteCommandActions={props.onRemoteCommandActions}
                    message={props.remoteCommandMessage}
                    isLoadingRemoteCommand={props.isLoadingRemoteCommand}
                    customer_name={props?.loggedInUser?.customer_name}
                    />
                </Tab>

                <Tab eventKey="featureLicenseRollout" title="License Rollout">
                    <LicenseRollout
                    device={props.device}
                    licenseManagerData={props.licenseManagerData}
                    onLicenseManagerActions={props.onLicenseManagerActions}
                    message={props.licenseManagerMessage}
                    isLoadingLicenseManagerData={props.isLoadingLicenseManagerData}
                    customer_name={props?.loggedInUser?.customer_name}
                    />
                </Tab>


                <Tab eventKey="featureDeviceLogger" title="Logger">
                    <h4><FontAwesomeIcon icon={faArchive}/> Device Logger</h4>
                    <DeviceLogger
                    deviceLoggerData={props.deviceLoggerData}
                    onDeviceLoggerActions={props.onDeviceLoggerActions}
                    isLoadingDeviceLogger={props.isLoadingDeviceLogger}
                    filterConfigurationDeviceLogger={props.filterConfigurationDeviceLogger}
                    onColumnFilterChangedDeviceLogger={props.onColumnFilterChangedDeviceLogger}
                    />
                </Tab>

                <Tab eventKey="iot" title="IoT">
                    <DeviceIoT device={props.device}
                    isCustomerRoleAdmin={props.isCustomerRoleAdmin}
                    iotCoreData={props.iotCoreData}
                    />
                </Tab>

            </Tabs>
        </div>
    );
}