import React from 'react'
import { Table, Badge } from "react-bootstrap";
import Moment from "react-moment";

export default function FirewallTable(props) {

    function renderFirewallTableRows()
    {
        return [{}].concat(props.firewallData?.SecurityGroupRules).map((securityRule, i) =>{

            if (i !== 0) {return (
                <tr key={"log"+i}>
                    <td>
                    {securityRule?.Tags?.find(tag => tag.Key === "expiry_date")?.Value? 
                                ( <>
                                    <Moment tz="Europe/Berlin" unix local format="YYYY-MM-DD HH:mm:ss">{(parseInt(securityRule?.Tags?.find(tag => tag.Key === "expiry_date")?.Value))}</Moment>
                                    &nbsp;
                                    <Badge variant="info"><Moment tz="Europe/Berlin" unix local fromNow>{(parseInt(securityRule?.Tags?.find(tag => tag.Key === "expiry_date")?.Value))}</Moment></Badge>
                                </> ) : (
                                    <Badge variant="secondary">static IP</Badge>
                                ) }
                    
                    </td>
                    <td>{securityRule?.CidrIpv4 || securityRule?.CidrIpv6 || <Badge variant="secondary">special case</Badge>}<br/><br/>


                    { securityRule?.Tags?.find(tag => tag.Key === "user_id")?.Value?
                        <div>User ID: <Badge variant="secondary">{securityRule?.Tags?.find(tag => tag.Key === "user_id")?.Value}</Badge></div>
                        :
                        <></>}
                        </td>
                    <td>{securityRule?.FromPort}</td>
                    <td>{securityRule?.ToPort}</td>
                    <td>{securityRule?.Description}</td>
                </tr>
            )}
            else {return (<tr key={i}></tr>)}
        });
    }

    return <Table bordered hover responsive>
                <thead>
                <tr>
                    <th key="timestamp" >Expiry date</th>
                    <th key="type" >IP Address <br/> User ID</th>
                    <th key="severity" >FROM port</th>
                    <th key="source" >TO port</th>
                    <th key="description" >Description</th>
                </tr>
                </thead>
                <tbody>
                    {renderFirewallTableRows()}
                </tbody>
            </Table>
}