import React, { useState } from "react";
import { Form, Alert, Container } from "react-bootstrap";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "./ChangePassword.css";

export default function ResetPassword(props) {
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        verificationCode: "",
        newPassword: "",
        passwordConfirmation: ""

    });
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    function validateForm() {
        return (
            fields.email.length > 0 &&
            fields.verificationCode.length > 0 &&
            fields.newPassword.length > 0 &&
            fields.passwordConfirmation === fields.newPassword
        );
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            await Auth.forgotPasswordSubmit(fields.email, fields.verificationCode, fields.newPassword);
                setIsLoading(false);
                props.history.push("/login");
        } catch (e) {
            if (e.code === "InvalidParameterException" || e.code === "InvalidPasswordException") {
                setErrorMessage(
                    <Alert variant="danger">
                        <h4>Password does not match requirements:</h4>
                        <ul>
                            <li>Minumum length: 8</li>
                            <li>At least one number required</li>
                            <li>At least one special character required</li>
                            <li>At least one capital letter required</li>
                            <li>At least one lowercase letter required</li>
                        </ul>
                    </Alert>
                );
            } else {
                setErrorMessage(
                    <Alert variant="danger">
                        <p>{e.message}</p>
                    </Alert>
                );
            }
            setIsLoading(false);
        }
    }

    function renderForm() {
        return (
            <div className="description">
            <Container>
                <h1>Reset Password</h1>
                <p></p>
                <form onSubmit={handleSubmit}>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                        autoFocus
                        type="email"
                        value={fields.email}
                        onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="verificationCode">
                    <Form.Label>Verification Code</Form.Label>
                    <Form.Control
                        autoFocus
                        type="text"
                        value={fields.verificationCode}
                        onChange={handleFieldChange}
                    />
                    </Form.Group>
                    <Form.Group controlId="newPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={fields.newPassword}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="passwordConfirmation">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={fields.passwordConfirmation}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    {errorMessage}
                        <LoaderButton
                            block
                            type="submit"
                            isLoading={isLoading}
                            disabled={!validateForm()}
                        >
                            Reset Password
                    </LoaderButton>
                </form>
            </Container>
            </div>
        );
    }

    return (
        <div className="ChangePassword">
            {renderForm()}
        </div>
    );
    }