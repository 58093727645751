import React, { Component } from 'react'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import { Container, Alert} from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faHome } from "@fortawesome/free-solid-svg-icons";
import './FeatureRoadmap.css'

import markdownText from '../markdown/changelog.md'
import { Link } from 'react-router-dom';

class Changelog extends Component {
  constructor(props) {
    super(props)

    this.state = { markdown: null }
    fetch(markdownText).then((response) => response.text()).then((text) => {
        this.setState({ markdown: text })
      })
  }

  render() {
    return (
        <div className='Featureroadmap'>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Change Log</Breadcrumb.Item>
                </Breadcrumb>
                <h1>Changelog</h1>
                <Alert variant="primary">
                <Alert.Heading>We continuously improve our platform!</Alert.Heading>
                Please stop by from time to time and inform yourself about new features, improvements and bugfixes.
            </Alert>

            <Markdown
              rehypePlugins={[rehypeRaw, rehypeSanitize]}>
              {this.state.markdown}
            </Markdown>

            </Container>
        </div>
    )
  }
}

export default Changelog