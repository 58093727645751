import React from "react";
import { css } from "@emotion/react";
import BarLoader from "react-spinners/BarLoader";

export default function Spinner (props) {
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: "#114c92";
    `;
    return (
        <div>
            <BarLoader
            css={override}
            height="7px"
            // width={props.button ? 15: 500}
            // size={props.button ? 25: 25}
            color={props.button ? "white" : "#007bff;"}
            />
        </div>
    );
}
