import React, { useLayoutEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import axios from 'axios';
import Device from "../components/Device/Device";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from "react-router-dom";
// React placeholder
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import {TextRow} from 'react-placeholder/lib/placeholders';
import "./DeviceDetails.css";
import { format } from 'date-fns'


export default function DeviceDetails(props) {
    const [isLoadingMain, setIsLoadingMain] = useState(true);
    const [device, setDevice] = useState();
    //const [refreshDeviceDetails, setRefreshDeviceDetails] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [userSession, setUserSession] = useState(undefined);
    // const [messageExpirationTime, setMessageExpirationTime] = useState("");
    const [userHeaders, setUserHeaders] = useState(undefined);

    const [loadTime, setLoadTime] = useState();
    const [updateGuiToggle, setUpdateGuiToggle] = useState(false);
    const [updateDataToggle, setUpdateDataToggle] = useState(false);

    const [firewallData, setFirewallData] = useState();

    //Assignment
    const [isLoadingAssignment, setIsLoadingAssignment] = useState(false);

    //HOTP
    const [isLoadingHOTPparameters, setIsLoadingHOTPparameters] = useState(false);
    const [hotp, setHOTPResponse] = useState('');

    // WhereverSIM
    const [whereverSimData, setWhereverSIMData] = useState();
    const [isLoadingWhereverSimData, setIsLoadingWhereverSimData] = useState(false);
    const [whereverSimMessage, setWhereverSimMessage] = useState({show: "", variant: "secondary", headline: "", body: ""});
    const [refreshWhereverSim, setRefreshWhereverSim] = useState(true);
    // const [whereverSimFisrtElement, setWhereverSimFisrtElement] = useState();
    const [whereverSimProfiles, setWhereverSimProfiles] = useState();

    // FileUpload
    const [fileUploadData, setFileUploadData] = useState({});
    const [isLoadingFileUpload, setIsLoadingFileUpload] = useState(false);
    const [fileUploadMessage, setFileUploadMessage] = useState({show: "", variant: "secondary", headline: "", body: ""});
    const [refreshFileUpload, setRefreshFileUploads] = useState(true);
    const [fileUploadFisrtElement, setFileUploadFisrtElement] = useState();
    const [fileUploadLength, setFileUploadLength] = useState();

    // RemoteAccess
    const [remoteConnectionData, setRemoteConnectionData] = useState();
    const [isLoadingRemoteConnection, setIsLoadingRemoteConnection] = useState(false);
    const [remoteConnectionMessage, setRemoteConnectionMessage] = useState({show: "", variant: "secondary", headline: "", body: ""});
    const [refreshRemoteConnection, setRefreshRemoteConnection] = useState(true);
    const [remoteAccessFisrtElement, setRemoteAccessFisrtElement] = useState();

    // Hawkbit
    const [hawkbitData, setHawkbitData] = useState();
    const [hawkbitDeploymentListData, setHawkbitDeploymentListData] = useState();
    const [isLoadingHawkbitData, setIsLoadingHawkbitData] = useState(false);
    const [hawkbitMessage, setHawkbitMessage] = useState({show: "", variant: "secondary", headline: "", body: ""});
    const [refreshHawkbit, setRefreshHawkbit] = useState(true);
    const [refreshHawkbitDeploymentList, setRefreshHawkbitDeploymentList] = useState(true);
    const [isLoadingHawkbitDeploymentListData, setIsLoadingHawkbitDeploymentListData] = useState(false);
    const [hawkbitFisrtElement, setHawkbitFisrtElement] = useState();

    // RemoteComand
    const [remoteCommandData, setRemoteCommandData] = useState();
    const [isLoadingRemoteCommand, setIsLoadingRemoteCommand] = useState(false);
    const [remoteCommandMessage, setRemoteCommandMessage] = useState({show: "", variant: "secondary", headline: "", body: ""});
    const [refreshRemoteCommand, setRefreshRemoteCommand] = useState(true);
    const [remoteCommandFisrtElement, setRemoteCommandFisrtElement] = useState();
    const [remoteCommandLength, setRemoteCommandLength] = useState();

    // DeviceLogger
    const [deviceLoggerData, setDeviceLoggerData] = useState();
    const [isLoadingDeviceLogger, setIsLoadingDeviceLogger] = useState(false);
    const [refreshDeviceLogger, setRefreshDeviceLogger] = useState(true);
    const [filterConfigurationDeviceLogger, setFilterConfigurationDeviceLogger] = useState([]);

    // LicenseManager
    const [licenseManagerData, setLicenseManagerData] = useState();
    const [isLoadingLicenseManagerData, setIsLoadingLicenseManagerData] = useState(false);
    const [refreshLicenseManagerData, setRefreshLicenseManagerData] = useState(true);
    const [licenseManagerMessage, setLicenseManagerMessage] = useState({show: "", variant: "secondary", headline: "", body: ""});

    // Meta
    const [metaMessage, setMetaMessage] = useState({show: "", variant: "secondary", headline: "", body: ""});
    const guiUpdateIntervalSeconds = 120;
    const dataUpdateIntervalSeconds = 30;
    // IoT
    const [iotCoreData, setIotCoreData] = useState();

    const [deviceTagsData, setDeviceTagsData] = useState();
    const [refreshDeviceTagsData, setRefreshDeviceTagsData] = useState(true);


    // onLoadMain
    useLayoutEffect(() => {
        async function onLoadMain() {
            // console.log("onLoad: Main")
            if (!props.isAuthenticated) {
                return;
            }
            console.log("onLoadMain");
            try {
                // Getting JSON Web Token
                const session = await Auth.currentSession();
                setUserSession(session);

                const headers = {};
                if (session) {
                    headers.Authorization = session.getIdToken().getJwtToken();
                }
                setUserHeaders(headers);
                // console.log(headers)
                const starDbQuery_timestamp = Date.now()

                let response = await API.get("registry_management", `/registry_manager/${props.match.params.id}`, {
                    headers: headers
                })
             
                const device = response.Item

                let responseIoT = null;

                try {
                    responseIoT = await API.get("registry_management", `/iot_core/device_shadow/${props.match.params.id}`, {
                        headers: headers
                    });
                    
                } catch (error) {
                    console.log("Error Reading device_shadow")
                    console.log(error.message);
                }

                setIotCoreData(responseIoT)

                let firewallData = await API.get("firewall", "/get_security_group_rules", {
                    headers: headers,
                });
                setFirewallData(firewallData);

                //response = await API.get("registry_management", `/health/${device.device_name}`, {
                //    headers: headers
                //})
                //device.device_health=response.Item
                
                // Do legacy call only to get the Health info - TODO: Delete and replace it with "registry_management", `/health/${device.device_name}`
                const device_legacy = await API.get("iot_registry-endpoint", `/iot_registry/${props.match.params.id}`);
                //console.log(device_legacy)
                device.device_health = device_legacy.device_health

                // ToDo : Need to be updated with a better Call
                device.rauc_status = device_legacy.rauc_status

                device.ssh_last_seen_timestamp = device_legacy?.device.ssh_last_seen_timestamp

                //console.log(device)
                setDevice(device);
                //console.log(device.uid)

                // save the path if session expired
                localStorage.setItem('prevPath', `/device/${device.uid}`);

                // console.log(device)
                if (props.isCustomerRoleAdmin) {
                    await API.get("iot_customer_config", "/iot_customer_config").then(customers => {
                        customers.sort(function(first, second){
                            return  (first.customer_name > second.customer_name ? 1 : -1);
                        });
                        setCustomers(customers);
                    }).catch(err => {
                        console.log("Error getting all the customers for admin");
                        console.log(err.response)
                    });
                }
                const stopDbQuery_timestamp = Date.now();
                setLoadTime(stopDbQuery_timestamp - starDbQuery_timestamp);
                } catch (e) {
                    alert(e);
            }
            setIsLoadingMain(false);
        }
        onLoadMain();
    }, [props.isAuthenticated, props.match.params.id, props.isCustomerRoleAdmin, props.history]);

    // Load WhereverSIM
    useLayoutEffect(() => {
        async function onLoadWhereverSim() {
            if (!userHeaders || !device || !refreshWhereverSim || isLoadingMain) {
                return;
            } else {
                console.log("onLoadWhereverSim");
                setIsLoadingWhereverSimData(true);
                try {
                    if (device.uid) {
                        // WhereverSIM
                        let getWhereverSimData = [];
                        try {
                            getWhereverSimData = await API.get("whereversim", `/whereversimdata/${device.uid}`,{
                                headers: userHeaders,
                            });
                        } catch (error) {
                            console.log("Error Reading WherverSIM")
                            console.log(error.message);
                        }
                        setWhereverSIMData(getWhereverSimData);

                        let tariffProfileData = [];

                        try {
                            tariffProfileData = await API.get("whereversimintegration","/tariffProfile",{
                                headers: userHeaders,
                            });
                        } catch (error) {
                            console.log("Error Reading tariffProfile")
                            console.log(error.message);
                        }

                        let serviceProfileData = [];
                        try {
                            serviceProfileData = await API.get("whereversimintegration","/serviceProfile",{
                                headers: userHeaders,
                            });
                        } catch (error) {
                            console.log("Error Reading serviceProfile")
                            console.log(error.message);
                        }

                        setWhereverSimProfiles({
                            tariffProfile : tariffProfileData,
                            serviceProfile : serviceProfileData
                        });
                    }
                } catch (e) {
                    alert(e);
            }
        setRefreshWhereverSim(false);
        setIsLoadingWhereverSimData(false);
        }
    }
    onLoadWhereverSim();
    }, [device, userHeaders, refreshWhereverSim, isLoadingMain]);


    // Load Remote Connection Feature Status
    useLayoutEffect(() => {
        async function onLoadRemoteConnection() {
            if (!userHeaders || !device || !refreshRemoteConnection || isLoadingMain) {
                return;
            } else {
                console.log("onLoadRemoteConnection")
                try {
                    if (device.uid) {
                        // Remote Connection Feature
                        try {
                            setIsLoadingRemoteConnection(true);

                            if (userHeaders) {
                                let data = {};

                                const response_remote_connection_status = await API.get("feature_management", `/remote_connection/${device.uid}`, {
                                    headers: userHeaders,
                                });
                                // console.log(response_remote_connection_status);

                                let response_remote_connection_ports_overview = [];
                                response_remote_connection_ports_overview = await API.get("feature_management", `/remote_connection/customer_ports_overview`, {
                                    headers: userHeaders,
                                });
                                // console.log(response_remote_connection_ports_overview);

                                try {
                                    let used_port_list = Object.keys(response_remote_connection_ports_overview.current_ports_overview);
                                    data['used_port_number'] = used_port_list.length;
                                    const PORT_END = response_remote_connection_ports_overview.tunnel_remote_port + response_remote_connection_ports_overview.tunnel_remote_range;
                                    for(let i = response_remote_connection_ports_overview.tunnel_remote_port; i < PORT_END; i++) {
                                        i = i.toString();
                                        if(!(used_port_list.includes(i))) {
                                            response_remote_connection_ports_overview['current_ports_overview'][i] = [];
                                        }
                                    }
                                    // console.log(response_remote_connection_ports_overview.current_ports_overview);
                                } catch (error) {
                                    console.log(error);
                                }

                                let response_remote_connection_history = [];
                                try {
                                    response_remote_connection_history = await API.get("feature_management",
                                        `/remote_connection/${device.uid}/history?limit=20`, {
                                        headers: userHeaders
                                    });
                                    // console.log(response_remote_connection_history);

                                } catch (e) {
                                    console.log(e);
                                }

                                let response_user_ip_data = [];
                                try {
                                    response_user_ip_data = await API.get("firewall",
                                        `/user_ip_status`, {
                                        headers: userHeaders
                                    });
                                } catch (e) {
                                    console.log(e);
                                }

                                try {
                                    const response_hotp_history = await API.get("feature_management", `/hotp/${device.uid}?limit=20`, {
                                        headers: userHeaders
                                    });
                                    // console.log(response_hotp_history);

                                    response_hotp_history['Items'].forEach(element => {
                                        response_remote_connection_history['Items'].push(element);
                                    });
                                } catch (e) {
                                    console.log(e);
                                }

                                response_remote_connection_history.Items.sort(function(first, second){
                                    return  (first.timestamp < second.timestamp ? 1 : -1)
                                });

                                // console.log(response_remote_connection_history);
                                if (response_remote_connection_history.Items.length !== 0) {
                                    if (remoteAccessFisrtElement !== response_remote_connection_history.Items[0]['timestamp']) {
                                        console.log("Remote Access: data has changed");
                                        setRemoteAccessFisrtElement(response_remote_connection_history.Items[0]['timestamp']);

                                        data['statusData'] = response_remote_connection_status;
                                        data['historyData'] = response_remote_connection_history;
                                        data['portsData'] = response_remote_connection_ports_overview;
                                        data['userIpData'] = response_user_ip_data;
                                        // console.log(data)
                                        setRemoteConnectionData(data);
                                    } else {
                                        console.log("Remote Access data didn't change");
                                    }
                                } else {
                                    data['statusData'] = response_remote_connection_status;
                                    data['historyData'] = {'Items': []};
                                    data['portsData'] = response_remote_connection_ports_overview;
                                    data['userIpData'] = response_user_ip_data;
                                    // console.log(data)
                                    setRemoteConnectionData(data);
                                }
                            }
                            else {
                                console.log("Remote Connection Status - No Headers");
                            }
                        } catch (error) {
                            console.log("Error Reading Remote Connection Feature Data");
                            console.log(error);
                            setRemoteConnectionData("");
                        }
                    }
                } catch (e) {
                    alert(e);
            }
            setIsLoadingRemoteConnection(false);
            setRefreshRemoteConnection(false);
        }
    }
    onLoadRemoteConnection();
    }, [device, userHeaders, refreshRemoteConnection, remoteAccessFisrtElement, isLoadingMain]);


    // Load Hawkbit Feature Status
    useLayoutEffect(() => {
        async function onLoadHawkbit() {
            if (!userHeaders || !device || !refreshHawkbit || isLoadingMain) {
                return;
            } else {
                console.log("onLoadHawkbit")
                try {
                    if (device.uid) {
                        // Hawkbit Feature
                        try {
                            setIsLoadingHawkbitData(true);
                            let response_hawkbit_status = [];
                            try {
                                response_hawkbit_status = await API.get("feature_management", `/hawkbit/${device.uid}`, {
                                    headers: userHeaders,
                                });
                                // console.log(response_hawkbit_status);
                            } catch (e) {
                                console.log(e.message)

                            }

                            let response_hawkbit_target_distributions = [];
                            if ( userSession?.idToken?.payload?.iot_customer_name.includes("phytec") ) {
                                await API.get("hawkbit_integration", `/getTargetDistributions/${device.uid}?distribution_tag_filter=gui_release`, {
                                    headers: userHeaders,
                                }).then(response => {
                                    response_hawkbit_target_distributions = response;
                                }).catch(err => {
                                    console.log("Error getting Target Distributions")
                                    console.log(err.response)
                                });
                            } else {
                                await API.get("hawkbit_integration", `/getTargetDistributions/${device.uid}`, {
                                    headers: userHeaders,
                                }).then(response => {
                                    response_hawkbit_target_distributions = response;
                                }).catch(err => {
                                    console.log("Error getting Target Distributions")
                                    console.log(err.message)
                                });
                            }
                            // console.log(response_hawkbit_target_distributions);


                            // re-arrange the target distributions metadata
                            try {
                                response_hawkbit_target_distributions.map(distribution => {
                                    let new_metadata = [];
                                    distribution?.metadata?.map(data => {
                                        new_metadata[data.key] = data.value
                                    })
                                    distribution["arranged_metadata"] = new_metadata;
                                    // console.log(distribution)
                                })
                            } catch (e) {
                                console.log(e.message)
                            }

                            // Get tags
                            let response_hawkbit_get_tages = [];
                            await API.get("hawkbit_integration", "/feature/hawkbit/distributionSetTags", {
                                headers: userHeaders,
                            }).then((response) => {
                                response_hawkbit_get_tages = response;
                                // console.log(response_hawkbit_get_tages);
                            } ).catch((err) => {
                                console.log(err.message);
                            });
                            
                            // Sorting all Hawkbit Distribution by name
                            try {
                                response_hawkbit_target_distributions.sort(function(first, second){
                                    return  (first.lastModifiedAt < second.lastModifiedAt ? 1 : -1)
                                });
                            } catch (e){
                                console.log("Error Sorting Files");
                                console.log(e);
                            }

                            let data = [];

                            if (response_hawkbit_target_distributions.length !== 0) {
                                if (hawkbitFisrtElement !== response_hawkbit_target_distributions[0]['lastModifiedAt']) {
                                    console.log("Hawkbit: data has been changed")
                                    setHawkbitFisrtElement(response_hawkbit_target_distributions[0]['lastModifiedAt']);

                                    data['hawkbitStatus'] = response_hawkbit_status;
                                    data['targetDistributions'] = response_hawkbit_target_distributions;
                                    data['distributionTags'] = response_hawkbit_get_tages;
                                    // console.log(data);
                                    setHawkbitData(data);
                                } else {
                                    console.log("Hawkbit data didn't change");
                                }
                            } else {
                                data['hawkbitStatus'] = response_hawkbit_status;
                                data['targetDistributions'] = response_hawkbit_target_distributions;
                                data['distributionTags'] = response_hawkbit_get_tages;
                                // console.log(data);
                                setHawkbitData(data);
                            }
                        } catch (error) {
                            console.log("Error Reading Hawkbit Feature Data")
                            console.log(error.message)
                            setHawkbitData([]);
                        }
                    }
                } catch (e) {
                    alert(e);
            }
            setIsLoadingHawkbitData(false);
            setRefreshHawkbit(false);
        }
    }
    onLoadHawkbit();
    }, [device, userHeaders, refreshHawkbit, hawkbitFisrtElement, isLoadingMain]);


    // Load Hawkbit Deployment List
    useLayoutEffect(() => {
        async function onLoadHawkbitDeploymentList() {
            if (!userHeaders || !device || !refreshHawkbitDeploymentList || isLoadingMain) {
                return;
            } else {
                console.log("onLoadHawkbitDeploymentList")
                try {
                    setIsLoadingHawkbitDeploymentListData(true);
                    if (device.uid) {
                        // Hawkbit Feature
                        await API.get("hawkbit_integration", `/deploymentData/${device.uid}?limit=5`, {
                            headers: userHeaders,
                        }).then(response_hawkbit_deploymentData => {
                            // console.log(response_hawkbit_deploymentData)
                            let data = {};
                            data['deploymentData'] = response_hawkbit_deploymentData;
                            setHawkbitDeploymentListData(data);
                        }).catch (error => {
                            console.log("Error Reading Hawkbit Deployment List Data")
                            console.log(error.message);
                            setHawkbitDeploymentListData("");
                        });
                    }
                } catch (e) {
                    alert(e);
            }
            setIsLoadingHawkbitDeploymentListData(false);
            setRefreshHawkbitDeploymentList(false);
        }
    }
    onLoadHawkbitDeploymentList();
    }, [device, userHeaders, refreshHawkbitDeploymentList, isLoadingMain]);

    // Load Device Logger
    useLayoutEffect(() => {
        async function onLoadDeviceLogger() {
            if (!userHeaders || !device || !refreshDeviceLogger || isLoadingMain) {
                return;
            } else {
                console.log("on Load Device Logger")
                try {
                    setIsLoadingDeviceLogger(true);
                    if (device.uid) {
                        var now = Math.round(new Date().getTime());
                        // Device Logger
                        try {
                            let response_device_logger = await API.get("device_logger", `/log/${device.uid}?high_value=${now}&limit=50`, {
                                        headers: userHeaders,
                                    });
                            console.log(response_device_logger)
                            setDeviceLoggerData(response_device_logger);

                            const filterConfigurationLocalStorage = JSON.parse(localStorage.getItem('filterConfigurationDeviceLogger'));
                            setFilterConfigurationDeviceLogger(filterConfigurationLocalStorage);
                        } catch (error) {
                            console.log("Error Reading Device Logger Data");
                            setDeviceLoggerData("");
                        }
                    }
                } catch (e) {
                    alert(e);
            }
            setIsLoadingDeviceLogger(false);
            setRefreshDeviceLogger(false);
        }
    }
    onLoadDeviceLogger();
    }, [device, userHeaders, refreshDeviceLogger, isLoadingMain]);


    // Load License Manager Data
    useLayoutEffect(() => {
        async function onLoadLicenseManagerData() {
            if (!userHeaders || !device || !refreshLicenseManagerData || isLoadingMain) {
                return;
            } else {
                console.log("onLoad License Manager Data")
                try {
                    if (device) {
                        setIsLoadingLicenseManagerData(true);
                        // License Manager
                        try {
                            let response_get_all_available_feature_licenses = await API.get("license_manager", '/licenses/features/available', {
                                headers: userHeaders,
                            });
                            // console.log(response_get_all_available_feature_licenses);

                            let licenses_by_device = {};
                            try {
                                licenses_by_device = await API.get("license_manager", `/license/device/${device.device_name}`, {
                                    headers: userHeaders,
                                });
                            } catch (e) {
                                console.log(e.message);
                            }
                            // console.log(licenses_by_device);

                            try {
                                response_get_all_available_feature_licenses?.Items?.map((license) => {
                                    licenses_by_device?.Items?.map((device_license) => {
                                        if (device_license?.feature_name.includes(license?.feature_name)) {
                                            if ('license_count' in license) {
                                                license['license_count'] = license['license_count'] + 1;
                                            } else {
                                                license['license_count'] = 1;
                                            }
                                            license['order_info'] = {};
                                            license['order_info']["timestamp"] = device_license?.timestamp;
                                            license['order_info']["created_at_timestamp"] = device_license?.license_type_metadata?.created_at_timestamp;
                                            license['order_info']["license_metadata"] = device_license?.license_metadata;
                                            license['order_info']["license_type_metadata"] = device_license?.license_type_metadata;
                                            license['order_info']["log"] = device_license?.log ? device_license?.log : [];
                                            license['order_info']["invoice_metadata"] = device_license?.invoice_metadata ? device_license?.invoice_metadata : {};
                                        }
                                    });
                                })
                            } catch (e){
                                console.log("Error in setting license ordering info for licenses");
                                console.log(e.message);
                            }

                            // console.log(response_get_all_available_feature_licenses);
                            setLicenseManagerData(response_get_all_available_feature_licenses);
                        } catch (error) {
                            console.log("Error Reading License Manager Data");
                            console.log(error.message);
                            setLicenseManagerData("");
                        }
                    }
                } catch (e) {
                    alert(e);
            }
            setIsLoadingLicenseManagerData(false);
            setRefreshLicenseManagerData(false);
        }
    }
    onLoadLicenseManagerData();
    }, [device, userHeaders, refreshLicenseManagerData, isLoadingMain]);

    // Load FileUpload Feature Status
    useLayoutEffect(() => {
        async function onLoadFileUpload() {
            if (!userHeaders || !device || !refreshFileUpload || isLoadingMain) {
                return;
            } else {
                console.log("onLoadFileUpload")
                try {
                    if (device.uid) {
                        // File Upload Feature
                        try {
                            console.log("onload File Upload Feature Data")
                            let files_list = [];

                            let response_job_manager = await API.get("job_manager",
                                `/jobs?thing_name=${device.uid}&operation_type=file&limit=50`, {
                                headers: userHeaders,
                            });

                            // Sorting jobs by time
                            try {
                                response_job_manager.Items.sort(function(first, second){
                                    return  (first.timestamp < second.timestamp ? 1 : -1)
                                });
                            } catch (e){
                                console.log("Error Sorting Jobs");
                                console.log(e);
                            }

                            const response_get_files = await API.get("file_upload", "/file?limit=50", {
                                headers: userHeaders,
                            });

                            // Sorting files by time
                            try {
                                response_get_files.Items.sort(function(first, second){
                                    return  (first.uploaded_at < second.uploaded_at ? 1 : -1)
                                });
                            } catch (e){
                                console.log("Error Sorting Jobs");
                                console.log(e);
                            }

                            var lastEvaluatedKey = "";
                            if (response_job_manager.LastEvaluatedKey !== undefined) {
                                lastEvaluatedKey = JSON.stringify(response_job_manager.LastEvaluatedKey);
                            }
                            // console.log(lastEvaluatedKey);

                            if (response_job_manager.Items.length !== 0) {
                                if (fileUploadFisrtElement !== response_job_manager.Items[0]['timestamp'] ||
                                    fileUploadLength !== response_get_files.Items.length) {
                                    console.log("File Upload: data has changed")
                                    setFileUploadFisrtElement(response_job_manager.Items[0]['timestamp']);
                                    setFileUploadLength(response_get_files.Items.length);
                                    setIsLoadingFileUpload(true);

                                    try {
                                        response_get_files.Items.forEach((file) => {
                                            file.jobs=[];
                                            files_list.push(file);
                                        })
                                    } catch (e) {
                                        console.log(e.message)
                                    }
                                    // Jobs list
                                    let Jobs = [];
                                    try {
                                        response_job_manager.Items.forEach((job) => {
                                            Jobs.push(job);
                                            files_list.forEach((file) => {
                                                if (file.file_id === job.operation_id){
                                                    file.jobs.push(job)
                                                }
                                            });
                                        })
                                    } catch (e){
                                        console.log("Error Job Filtering");
                                        console.log(e);
                                    }

                                    let reduce_job_list = [];
                                    var reduced_job_list = [];
                                    try {
                                        Jobs.forEach((job) => {
                                            reduce_job_list.push({'jobid':job.jobid,  'actions': [job]})
                                        });
                                        reduce_job_list.forEach(function(item) {
                                            var existing = reduced_job_list.filter(function(v) {
                                                return v.jobid === item.jobid;
                                            });
                                            if (existing.length) {
                                                var existingIndex = reduced_job_list.indexOf(existing[0]);
                                                reduced_job_list[existingIndex].actions = reduced_job_list[existingIndex].actions.concat(item.actions);
                                            } else {
                                                if (typeof item.actions === 'string')
                                                item.actions = [item.actions];
                                                reduced_job_list.push(item);
                                            }
                                        });
                                        // console.log(reduced_job_list);
                                    } catch(e) {
                                        console.log(e.message);
                                    }

                                    let data = {}
                                    // if (fileUploadData){
                                    //     data = fileUploadData;
                                    // }
                                    data["fileInfos"] = files_list;
                                    data["jobsInfo"] = reduced_job_list;
                                    data["lastEvaluatedKey"] = lastEvaluatedKey;
                                    // console.log(data["jobsInfo"]);
                                    setFileUploadData(data);
                                } else {
                                    console.log("File Upload data didn't change");
                                }
                            } else {
                                console.log("There are no new jobs")
                                let data = {}
                                // console.log(response_get_commands.Items);
                                data["fileInfos"] = response_get_files.Items;
                                data["jobsInfo"] = [];
                                setFileUploadData(data);
                            }
                        } catch (error) {
                            console.log("Error Reading File Feature Data")
                            setFileUploadData("");
                        }
                    }
                } catch (e) {
                    alert(e);
            }
            setIsLoadingFileUpload(false);
            setRefreshFileUploads(false);
        }
    }
    onLoadFileUpload();
    }, [device, userHeaders, refreshFileUpload, fileUploadFisrtElement, fileUploadLength, isLoadingMain]);
    // }, [device, userHeaders, refreshFileUpload, fileUploadData, fileUploadFisrtElement, fileUploadLength]);

    // Load RemoteCommand Feature Status
    useLayoutEffect(() => {
        async function onLoadRemoteCommand() {
            if (!userHeaders || !device || !refreshRemoteCommand || isLoadingMain) {
                return;
            } else {
                console.log("onLoadRemoteCommand")
                try {
                    if (device.uid) {
                        // Remote Command Feature
                        try {
                            console.log("onload Remote Command Feature Data")
                            let commands_list = [];

                            let response_job_manager = await API.get("job_manager",
                                `/jobs?thing_name=${device.uid}&operation_type=command&limit=30`, {
                                headers: userHeaders,
                            });

                            // let response_job_manager = await API.get("job_manager",
                            //     `/jobs?thing_name=${device.uid}&limit=50`, {
                            //     headers: userHeaders,
                            // });

                            // Sorting jobs by time
                            try {
                                response_job_manager.Items.sort(function(first, second){
                                    return  (first.timestamp < second.timestamp ? 1 : -1)
                                });
                            } catch (e) {
                                console.log("Error Sorting Jobs");
                                console.log(e);
                            }

                            const response_get_commands = await API.get("remote_command", "/feature/commands?limit=30", {
                                headers: userHeaders,
                            });
                            // console.log(response_get_commands.Items);

                            // Sorting commands by time
                            try {
                                response_get_commands.Items.sort(function(first, second){
                                    return  (first.timestamp > second.timestamp ? 1 : -1)
                                });
                            } catch (e) {
                                console.log("Error Sorting Commands");
                                console.log(e);
                            }

                            var lastEvaluatedKey = "";
                            if (response_job_manager.LastEvaluatedKey !== undefined) {
                                lastEvaluatedKey = JSON.stringify(response_job_manager.LastEvaluatedKey);
                            }

                            if (response_job_manager.Items.length !== 0) {
                                if (remoteCommandFisrtElement !== response_job_manager.Items[0]['timestamp'] ||
                                    remoteCommandLength !== response_get_commands.Items.length ) {
                                    console.log("Remote Command: data has changed")
                                    setRemoteCommandFisrtElement(response_job_manager.Items[0]['timestamp']);
                                    setRemoteCommandLength(response_get_commands.Items.length)
                                    setIsLoadingRemoteCommand(true);

                                    try {
                                        response_get_commands.Items.forEach((command) => {
                                            command.jobs=[];
                                            commands_list.push(command);
                                        })
                                    } catch (e) {
                                        console.log(e.message)
                                    }
                                    // Jobs list
                                    let Jobs = [];
                                    try {
                                        response_job_manager.Items.forEach((job) => {
                                            Jobs.push(job);
                                            commands_list.forEach((command) => {
                                                if (command.commandid === job.operation_id){
                                                    command.jobs.push(job);
                                                }
                                            });
                                        })
                                    } catch (e){
                                        console.log("Error Job Filtering");
                                        console.log(e);
                                    }

                                    let reduce_job_list = [];
                                    var reduced_job_list = [];
                                    try {
                                        Jobs.forEach((job) => {
                                            reduce_job_list.push({'jobid':job.jobid,  'actions': [job]})
                                        });
                                        reduce_job_list.forEach(function(item) {
                                            var existing = reduced_job_list.filter(function(v) {
                                                return v.jobid === item.jobid;
                                            });
                                            if (existing.length) {
                                                var existingIndex = reduced_job_list.indexOf(existing[0]);
                                                reduced_job_list[existingIndex].actions = reduced_job_list[existingIndex].actions.concat(item.actions);
                                            } else {
                                                if (typeof item.actions === 'string')
                                                item.actions = [item.actions];
                                                reduced_job_list.push(item);
                                            }
                                        });
                                        // console.log(reduced_job_list);
                                    } catch(e) {
                                        console.log(e.message);
                                    }

                                    try {
                                        commands_list.sort(function(first, second){
                                            return  (first.timestamp < second.timestamp ? 1 : -1)
                                        });
                                    } catch (e){
                                        console.log("Error Jobs");
                                        console.log(e);
                                    }

                                    let data = {}
                                    data["commandsInfo"] = commands_list;
                                    data["jobsInfo"] = reduced_job_list;
                                    data["lastEvaluatedKey"] = lastEvaluatedKey;
                                    setRemoteCommandData(data);
                                } else {
                                    console.log("Remote Command data didn't change");
                                }
                            } else {
                                console.log("There are no new jobs")
                                let data = {}
                                // console.log(response_get_commands.Items);
                                data["commandsInfo"] = response_get_commands.Items;
                                data["jobsInfo"] = [];
                                setRemoteCommandData(data);
                            }
                        } catch (error) {
                            console.log("Error Reading Remote Command Feature Data");
                            setRemoteCommandData("");
                        }
                    }
                } catch (e) {
                    alert(e);
                }
            setIsLoadingRemoteCommand(false);
            setRefreshRemoteCommand(false);
        }
    }
    onLoadRemoteCommand();
    }, [device, userHeaders, refreshRemoteCommand, remoteCommandFisrtElement, remoteCommandLength, isLoadingMain]);
    // }, [device, userHeaders, refreshRemoteCommand, remoteCommandData, isLoadingMain, remoteCommandFisrtElement, remoteCommandLength]);


    useLayoutEffect(() => {
        async function onLoadDeviceTags() {
            if (!userHeaders || !device || !refreshDeviceTagsData || isLoadingMain) {
                return;
            } else {
                console.log("onLoadDeviceTags");
                try {
                    if (device.uid) {
                        let getDeviceTags = [];
                        try {
                            getDeviceTags = await API.get("registry_management", `/registry_manager/${device.uid}/tags`,{
                                headers: userHeaders,
                            });
                        } catch (error) {
                            console.log("Error Reading DeviceTags")
                            console.log(error.message);
                        }
                        setDeviceTagsData(getDeviceTags);
                    }
                } catch (e) {
                    alert(e);
            }
        setRefreshDeviceTagsData(false);
        }
    }
    onLoadDeviceTags();
    }, [device, userHeaders, refreshDeviceTagsData, isLoadingMain]);


    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // GUI Updating
    useLayoutEffect(() => {
        async function onUpdateGui() {
            if (!isLoadingMain) {
                console.log("GUI Refresh after " + guiUpdateIntervalSeconds + " Seconds" )
                setRefreshFileUploads(true);
                setRefreshRemoteCommand(true);
                setRefreshRemoteConnection(true);
                setRefreshWhereverSim(true);
                setRefreshLicenseManagerData(true);
                setRefreshDeviceTagsData(true);

                // setRefreshHawkbit(true);
                await timeout(guiUpdateIntervalSeconds * 1000)
                setUpdateGuiToggle(!updateGuiToggle)
            }
        }
    onUpdateGui();
    },[updateGuiToggle, isLoadingMain]);

    useLayoutEffect(() => {
        async function onFastDataUpdate() {
            if (!isLoadingMain) {
                console.log("Fast Data Refresh after " + dataUpdateIntervalSeconds + " Seconds" )
                updateDeviceDetails(device?.uid);

                await timeout(dataUpdateIntervalSeconds * 1000)
                setUpdateDataToggle(!updateDataToggle);
            }
        }
        onFastDataUpdate();
    },[updateDataToggle, isLoadingMain]);

    async function updateDeviceDetails(uid) {
        if (userHeaders) {
            console.log(uid)

            let response = await API.get("registry_management", `/registry_manager/${uid}`, {
                headers: userHeaders
            })
         
            const device = response.Item

            // Do legacy call only to get the Health info - TODO: Delete and replace it with "registry_management", `/health/${device.device_name}`
            const device_legacy = await API.get("iot_registry-endpoint", `/iot_registry/${uid}`)

            //console.log(device_legacy)
            device.device_health = device_legacy.device_health
            // ToDo : Need to be updated with a better Call
            device.rauc_status = device_legacy.rauc_status

            //console.log(device)
            setDevice(device);
            //console.log(device.uid)

        }
    }

    async function assignDeviceHandler(assignment) {
        setIsLoadingAssignment(true);
        try {
            await assignDevice(assignment);
            setIsLoadingAssignment(false);
            setIsLoadingMain(true);
            const device = await API.get("iot_registry-endpoint", `/iot_registry/${props.match.params.id}`);
            setDevice(device);
        } catch (e) {
            alert(e.message);
            setIsLoadingAssignment(false);
        }
        setIsLoadingMain(false);
    }

    async function whereverSimHandler(simAction) {
        if (userHeaders){
            try {
                console.log("Info: Changing Endpoint Settings");
                const res = await whereverSimAction(simAction);
                // console.log(message);
                if (res.message === "Successful"){
                    setWhereverSimMessage({
                        show: true,
                        variant: "success",
                        headline: whereverSimMessage.headline,
                        body: "Success"
                    })
                }
                else if (res == "no action"){
                    // nothing to do
                }
                else{
                    setWhereverSimMessage({
                        show: true,
                        variant: "danger",
                        headline: whereverSimMessage.headline,
                        body: "Failed"
                    })
                }
                let getWhereverSimData = [];
                try {
                    getWhereverSimData = await API.get("whereversim", `/whereversimdata/${device.uid}`,{
                        headers: userHeaders,
                    });
                    // console.log("Refreshing WhereverSIM Data");
                } catch (e) {
                    console.log("Error reloading WhereverSIM Data")
                    console.log(e.message)
                }

                getWhereverSimData.events.sort(function(first, second){
                    return  (first.timestamp < second.timestamp ? 1 : -1)
                });

                setWhereverSIMData(getWhereverSimData);
                setIsLoadingWhereverSimData(false);

            } catch (e) {
                console.log(e.message);
            }
        } else {
            console.log("No Headers - WhereverSIM")
        }
    }

    async function HOTPGenerationHandler() {
        if (userHeaders){
            setIsLoadingHOTPparameters(true)
            try {
                const hotp_response_string = await API.patch("feature_management", `/hotp/${device.uid}`,{
                    headers: userHeaders,
                });
                const hotp = hotp_response_string.hotp;
                setHOTPResponse(hotp);
                setIsLoadingHOTPparameters(false);
            } catch (e) {
                alert(e.message);
                setIsLoadingHOTPparameters(false);
            }
            setIsLoadingHOTPparameters(false);
        } else {
            console.log("No Headers - HOTP Generation");
        }
    }

    async function WhitelistIPHandler() {
        if (userHeaders){
            setRemoteConnectionMessage({
                show: true,
                variant: "info",
                headline: "Whitelist IP Is Pending",
                body: "IP address will be whitelisted soon!"
            });

            try {
                const whitelistIpResponse = await API.put("firewall", `/learn_ip_from_previous_auth_event`,{
                    headers: userHeaders,
                });

                setRefreshRemoteConnection(true); // refresh data on Remote access tab

                setRemoteConnectionMessage({
                    show: true,
                    variant: "success",
                    headline: "Your IP address is whitelisted",
                    body: "IP address: " + (whitelistIpResponse?.SecurityGroupRules[0]?.CidrIpv6 || whitelistIpResponse?.SecurityGroupRules[0]?.CidrIpv4)
                })
            } catch (e) {
                alert(e.message);

                setRemoteConnectionMessage({
                    show: true,
                    variant: "danger",
                    headline: "Error:",
                    body: e.message
                });
            }
        } else {
            console.log("No Headers - WhitelistIPHandler");
        }
    }

    async function RenewIPHandler() {
        if (userHeaders){
            setRemoteConnectionMessage({
                show: true,
                variant: "info",
                headline: "Your IP Is being extended...",
                body: "IP address will be extended soon!"
            });

            try {
                const whitelistIpResponse = await API.put("firewall", `/learn_ip_from_previous_auth_event`,{
                    headers: userHeaders,
                });

                setRefreshRemoteConnection(true); // refresh data on Remote access tab

                setRemoteConnectionMessage({
                    show: true,
                    variant: "success",
                    headline: "Your IP address is successfully extended for additional 4 hours",
                    body: "IP address: " + (whitelistIpResponse?.SecurityGroupRules[0]?.CidrIpv6 || whitelistIpResponse?.SecurityGroupRules[0]?.CidrIpv4)
                })
            } catch (e) {
                alert(e.message);

                setRemoteConnectionMessage({
                    show: true,
                    variant: "danger",
                    headline: "Error:",
                    body: e.message
                });
            }
        } else {
            console.log("No Headers - RenewIPHandler");
        }
    }

    async function DeleteIPHandler() {
        if (userHeaders){
            setRemoteConnectionMessage({
                show: true,
                variant: "info",
                headline: "Deleting whitelisted IP pending...",
                body: "IP address will be deleted soon!"
            });

            try {
                await API.del("firewall", `/user_ip`,{
                    headers: userHeaders,
                });

                setRefreshRemoteConnection(true); // refresh data on Remote access tab

                setRemoteConnectionMessage({
                    show: true,
                    variant: "success",
                    headline: "Your IP address is deleted",
                    body: ""
                })
            } catch (e) {
                alert(e.message);

                setRemoteConnectionMessage({
                    show: true,
                    variant: "danger",
                    headline: "Error:",
                    body: e.message
                });
            }
        } else {
            console.log("No Headers - DeleteIPHandler");
        }
    }

    async function getUploadFilesStatus(){
        if (userHeaders) {
            const files_list = [];
            const response_job_manager = await API.get("job_manager", "/jobs", {
                headers: userHeaders,
            });
            // console.log("Job Manager:");
            // console.log(response_job_manager);

            const response_get_files = await API.get("file_upload", "/file", {
                headers: userHeaders,
            });
            // console.log("File Manager:");
            // console.log(response_get_files);

            // console.log("Files List #1");
            // console.log(files_list);

            response_get_files.Items.forEach((file) => {
                file.jobs=[];
                files_list.push(file);
            })
            // console.log("Files List #2");
            // console.log(files_list);

            try {
                response_job_manager.Items.forEach((job) => {
                    files_list.forEach((file) => {
                        if (file.file_id === job.operation_id){
                            if (job.thing_name === device.uid){
                                file.jobs.push(job)
                            }
                    }
                    });
                })
            } catch (e){
                console.log("Error Job Filtering");
                console.log(e);
            }


            // Sorting all Files by name
            try {
                files_list.sort(function(first, second){
                    return  (first.uploaded_at < second.uploaded_at ? 1 : -1)
                });
            } catch (e){
                console.log("Error Sorting Files");
                console.log(e);
            }
            try {
                files_list.forEach((file) => {
                    file.jobs.sort(function(first, second){
                        return  (first.jobid < second.jobid ? 1 : -1)
                    });
                });

            } catch (e){
                console.log("Error Jobs");
                console.log(e);
            }

            let data = {}
            if (fileUploadData){
                data = fileUploadData;
            }

            data["fileInfos"] = files_list;

            setFileUploadData(data);
        }
        else {
            console.log("FilesStatus - No Headers");
        }
    }

    async function fileUploadHandler(fileAction){
        if (userHeaders) {
            try {
                if (fileAction.action === "getFileAndJobStatus"){
                    console.log("getFileAndJobStatus")
                    setIsLoadingFileUpload(true);
                    await getUploadFilesStatus();
                    setIsLoadingFileUpload(false);
                }

                if (fileAction.action === "createFileJob"){
                    // Message
                    setFileUploadMessage({
                        show: true,
                        variant: "info",
                        headline: "Creating Send File Job",
                        body: "File ID: " + fileAction.file_id
                    })
                    try {
                        await API.post("job_manager", "/job", {
                            headers: userHeaders,
                            body: {
                                "thing_name": device.uid,
                                "operation_type": "file",
                                "operation_id": fileAction.file_id,
                                "description": fileAction.description
                            }
                        });
                        await timeout(500);
                        setRefreshFileUploads(true);
                        // ToDo: Check if Result is OK or Not, and make different message
                        setFileUploadMessage({
                            show: true,
                            variant: "success",
                            headline: "Job Created",
                            body: "File ID: " + fileAction.file_id
                        })
                    } catch(e) {
                        setFileUploadMessage({
                            show: true,
                            variant: "danger",
                            headline: "Error:",
                            body: e.message
                        })
                    }
                }

                if (fileAction.action === "deleteFile"){
                    setFileUploadMessage({
                        show: true,
                        variant: "info",
                        headline: "trying to delete File",
                        body: "File ID" + fileAction.file_id
                    })
                    try {
                        await API.del("file_upload", "/file/"+fileAction.file_id, {
                            headers: userHeaders,
                        });
                        setRefreshFileUploads(true);
                        // ToDo: Check if Result is OK or Not, and make different message
                    } catch (e) {
                        setFileUploadMessage({
                            show: true,
                            variant: "danger",
                            headline: "Error:",
                            body: e.message
                        })
                    }
                }

                if (fileAction.action === "getFileInfos"){
                    const fileInfos = await API.get("file_upload", "/file/"+fileAction.file_id, {
                        headers: userHeaders,
                    });
                    return fileInfos;
                }

                if (fileAction.action === "getDownloadLink"){
                    const fileInfos = await API.get("file_upload", "/file/"+fileAction.file_id+"/initiate_download/?folder=uploads", {
                        headers: userHeaders,
                    });
                    return fileInfos;
                }

                if ((fileAction.action === "uploadFile") ||  (fileAction.action === "uploadFileAndSend")){
                    setFileUploadMessage({
                        show: true,
                        variant: "info",
                        headline: "File Upload started",
                        body: "File Name: " + fileAction.file.name
                    })

                    const post_response = await API.post("file_upload", "/file", {
                        headers: userHeaders,
                        body: {
                            "file_name": fileAction.file.name,
                            "desired_location": fileAction.desired_location,
                            "description": fileAction.description,
                        }
                    });
                    // console.log(post_response)

                    // This call has no Authorization mechanism, it only use the presigned_url given by the POST call.
                    try {
                        await axios.put(post_response?.presigned_url, fileAction?.file,  {
                            headers: {
                                "X-Amz-meta-file_name": fileAction?.file?.name,
                                "X-Amz-meta-desired_location": fileAction?.desired_location,
                                "X-Amz-meta-description": fileAction?.description,
                                "X-Amz-meta-customer_name": userSession?.idToken?.payload?.iot_customer_name,
                                "X-Amz-meta-user_id": userSession?.idToken?.payload?.sub
                            }
                        }
                        );

                        // ToDo: Check if Result is OK or Not, and make different message

                        await timeout(2000);

                        if (fileAction.action === "uploadFileAndSend"){
                            setFileUploadMessage({
                                show: true,
                                variant: "success",
                                headline: "File Upload finished",
                                body: "File Name: " + fileAction.file.name
                            })
                            await timeout(2000);
                            fileUploadHandler({
                                action: "createFileJob",
                                file_id: post_response.file_id,
                                description: fileAction.description
                            })
                        }else{
                            setFileUploadMessage({
                                show: true,
                                variant: "success",
                                headline: "File Upload finished",
                                body: "File Name: " + fileAction.file.name
                            })
                            setRefreshFileUploads(true);
                        }
                    }catch (e) {
                        setFileUploadMessage({
                            show: true,
                            variant: "danger",
                            headline: "Error:",
                            body: e.message
                        })
                    }
                }

                if (fileAction.action === "loadMoreJobs"){
                    console.log("Load More File Upload Jobs");
                    try {
                        let response_job_manager_exclusive_start_key = await API.get("job_manager",
                            `/jobs?thing_name=${device.uid}&operation_type=file&limit=50&exclusive_start_key=${fileUploadData.lastEvaluatedKey}`, {
                            headers: userHeaders,
                        });

                        // Sorting jobs by time
                        try {
                            response_job_manager_exclusive_start_key.Items.sort(function(first, second){
                                return  (first.timestamp < second.timestamp ? 1 : -1)
                            });
                        } catch (e) {
                            console.log("Error Sorting Jobs");
                            console.log(e);
                        }

                        let Jobs = [];
                        try {
                            response_job_manager_exclusive_start_key.Items.forEach((job) => {
                                Jobs.push(job);
                                fileUploadData.fileInfos.forEach((file) => {
                                    if (file.file_id === job.operation_id){
                                        file.jobs.push(job);
                                    }
                                });
                            })
                        } catch (e){
                            console.log("Error Job Filtering");
                            console.log(e);
                        }

                        let reduce_job_list = [];
                        var reduced_job_list = [];
                        try {
                            Jobs.forEach((job) => {
                                reduce_job_list.push({'jobid':job.jobid,  'actions': [job]})
                            });
                            reduce_job_list.forEach(function(item) {
                                var existing = reduced_job_list.filter(function(v) {
                                    return v.jobid === item.jobid;
                                });
                                if (existing.length) {
                                    var existingIndex = reduced_job_list.indexOf(existing[0]);
                                    reduced_job_list[existingIndex].actions = reduced_job_list[existingIndex].actions.concat(item.actions);
                                } else {
                                if (typeof item.actions === 'string')
                                    item.actions = [item.actions];
                                reduced_job_list.push(item);
                                }
                            });
                            // console.log(reduced_job_list);
                        } catch(e) {
                            console.log(e.message);
                        }

                        var old_fileUploadData = fileUploadData;
                        old_fileUploadData.jobsInfo = old_fileUploadData.jobsInfo.concat(reduced_job_list);

                        // Sorting all the jobs
                        try {
                            old_fileUploadData.jobsInfo.sort(function(first, second){
                                return  (first.jobid < second.jobid ? 1 : -1)
                            });
                        } catch (e){
                            console.log("Error Sorting Jobs");
                            console.log(e);
                        }

                        // Saving the last Evaluated Key in a global variable
                        var lastEvaluatedKey = "";
                        if (response_job_manager_exclusive_start_key.LastEvaluatedKey !== undefined) {
                            lastEvaluatedKey = JSON.stringify(response_job_manager_exclusive_start_key.LastEvaluatedKey);
                        }

                        let data = {}
                        data["fileInfos"] = fileUploadData.fileInfos;
                        data["jobsInfo"] = old_fileUploadData.jobsInfo;
                        data["lastEvaluatedKey"] = lastEvaluatedKey;
                        setFileUploadData(data);

                    } catch (e) {
                        console.log(e.message);
                    }
                }

            }catch (e) {
                alert(e.message);
            }
        }
    }

    async function remoteConnectionHandler(remoteConnectionAction){
        if (userHeaders) {
            try {
                if ((remoteConnectionAction.action === "activateRemoteConnection")){
                    setRemoteConnectionMessage({
                        show: true,
                        variant: "info",
                        headline: "Remote Connection Activation Is Pending",
                        body: "The port related to this device will be available soon!"
                    })
                    try {
                        await API.post("feature_management", `/remote_connection/${device.uid}`, {
                            headers: userHeaders,
                            body: {}
                        });
                        setRefreshRemoteConnection(true);
                    } catch (e) {
                        console.log(e);
                    }
                }

                if (remoteConnectionAction.action === "deactivateRemoteConnection"){
                    setRemoteConnectionMessage({
                        show: true,
                        variant: "info",
                        headline: "Remote Connection Deactivation Is Pending",
                        body: "The port related to device " + remoteConnectionAction.uid + " will no more be available soon!"
                    })
                    try {
                        await API.del("feature_management", `/remote_connection/${remoteConnectionAction.uid}`, {
                            headers: userHeaders,
                        });
                        setRefreshRemoteConnection(true);
                        // ToDo: Check if Result is OK or Not, and make different message
                    } catch(e) {
                        console.log(e);
                    }
                }

                if (remoteConnectionAction.action === "abortRemoteConnectionRollout"){
                    setRemoteConnectionMessage({
                        show: true,
                        variant: "info",
                        headline: "Remote Connection Rollout Is Aborted",
                        body: remoteConnectionAction.delta_status + " is aborted!"
                    })
                    try {
                        await API.del("feature_management", `/remote_connection/${remoteConnectionAction.uid}/abort`, {
                            headers: userHeaders,
                        });
                        setRefreshRemoteConnection(true);
                        // ToDo: Check if Result is OK or Not, and make different message
                    } catch(e) {
                        console.log(e);
                    }

                }

                if ((remoteConnectionAction.action === "activateRemoteConnectionForceDevicePort")){
                    setRemoteConnectionMessage({
                        show: true,
                        variant: "info",
                        headline: "Remote Connection Force Activation Is Pending",
                        body: "The port related to this device will be available soon!"
                    })
                    try {
                        await API.post("feature_management", `/remote_connection/${device.uid}`, {
                            headers: userHeaders,
                            body: {
                                // "desired_config": remoteConnectionAction.desired_config,
                                "force_device_port": remoteConnectionAction.force_device_port
                            }
                        });
                        setRefreshRemoteConnection(true);
                    } catch(e) {
                        console.log(e);
                    }
                }

            }catch (e) {
                alert(e.message);
            }
        }
    }

    async function licenseManagerHandler(licenseAction){
        if (userHeaders) {
            try {
                if (licenseAction.action === "addLicenseToDevice"){
                    // Message
                    setLicenseManagerMessage({
                        show: true,
                        variant: "info",
                        headline: "Adding License to Device " + device.device_name,
                        body: "License Name: " + licenseAction.feature_name
                    })
                    console.log(licenseAction.invoice_metadata);

                    await API.post("license_manager", "/license", {
                        headers: userHeaders,
                        body: {
                            "device_name": device.device_name,
                            "feature_name": licenseAction.feature_name,
                            "license_metadata": licenseAction.license_metadata,
                            "description": licenseAction.description,
                            "license_type_metadata": licenseAction.license_type_metadata,
                            "invoice_metadata": licenseAction.invoice_metadata
                        }
                    }).then(() => {
                        setLicenseManagerMessage({
                            show: true,
                            variant: "success",
                            headline: "License Rollout Triggered",
                            body: "The license " + licenseAction.feature_name + " was added to the device " + device.device_name
                        })
                        setRefreshLicenseManagerData(true);
                    }).catch((e) => {
                        console.log(e);
                        console.log(e.response);
                        setLicenseManagerMessage({
                            show: true,
                            variant: "danger",
                            headline: "Error:",
                            body: e.message + ": " + JSON.stringify(e.response.data)
                        })
                    });
                }
            } catch (e) {
                setLicenseManagerMessage({
                    show: true,
                    variant: "danger",
                    headline: "Error:",
                    body: e.message
                })
            }
        }
    }

    async function hawkbitServerHandler(hawkbitActions){
        if (userHeaders) {
            try {
                if ((hawkbitActions.action === "updateTarget")){
                    const post_response = await API.post("hawkbit_integration", `/updateTarget/${device.uid}?distributionId=${hawkbitActions.distribution_id}`, {
                        headers: userHeaders,
                        body: ""
                    });
                    // console.log(post_response);
                    if (post_response['alreadyAssigned']) {
                        setHawkbitMessage({
                            show: true,
                            variant: "warning",
                            headline: `Updating target ${device.device_name} on Hawkbit`,
                            body: "Distribution already assigned to this device. Make sure you picked up the correct version!"
                        })
                    } else {
                        setHawkbitMessage({
                            show: true,
                            variant: "info",
                            headline: `Updating target ${device.device_name} on Hawkbit`,
                            body: "Distribution will be assigned to this device."
                        })
                    }
                    // setRefreshHawkbit(true);
                }

                if ((hawkbitActions.action === "reloadDeploymentList")){
                    setRefreshHawkbitDeploymentList(true);
                }

                if ((hawkbitActions.action === "resyncHawkbit")){
                    await API.patch("feature_management", `/hawkbit/${device.uid}`, {
                        headers: userHeaders,
                    }).then(() => {
                        setHawkbitMessage({
                            show: true,
                            variant: "info",
                            headline: "Re-sync Hawkbit",
                            body: "Resync has been triggered. Please reload the page."
                        })
                    }).catch(() => {
                        setHawkbitMessage({
                            show: true,
                            variant: "danger",
                            headline: "Re-sync Hawkbit",
                            body: "Error in Resync hawkbit server feature"
                        })
                    });


                }

            }catch (e) {
                alert(e.message);
            }
        }
    }
    async function metaDataHandler(metaDataAction){
        if (userHeaders) {
            if ((metaDataAction.action === "updateUserRemark")){
                const current = new Date();
                const dstr = format(current,'yyyy-MM-dd HH:mm:ss')
                
                let fullcomment = "["+dstr + "] **" + props.loggedInUser.email + "**  \n" + metaDataAction.user_remark + "  \n  \n---  \n" + metaDataAction.comment_prev;                console.log("Prev Comment :" + metaDataAction.comment_prev)
                
                await API.put("registry_management", `/registry_manager/${device.uid}/user_remark`, {
                    headers: userHeaders,
                    body: {
                        "user_remark": fullcomment
                    }
                }).then((result) => {
                    console.log(result);
                }).catch((err) => {
                    console.log(err.message);
                });
                updateDeviceDetails(device.uid);
            }

            if ((metaDataAction.action === "updateAdminRemark") && (props.isCustomerRoleAdmin)){
                const current = new Date();
                const dstr = format(current,'yyyy-MM-dd HH:mm:ss')
                
                let fullcomment = "["+dstr + "] **" + props.loggedInUser.email + "**  \n" + metaDataAction.admin_remark + "  \n  \n---  \n" + metaDataAction.comment_prev;
                
                await API.put("registry_management", `/registry_manager/${device.uid}/admin_remark`, {
                    headers: userHeaders,
                    body: {
                        "admin_remark": fullcomment
                    }
                }).then((result) => {
                    console.log(result);
                }).catch((err) => {
                    console.log(err.message);
                });
                updateDeviceDetails(device.uid);
            }

            if ((metaDataAction.action === "updateCustomDeviceName")){
                setMetaMessage({
                    show: true,
                    variant: "info",
                    headline: "",
                    body: "Updating the custom device name... "
                })
                    await API.put("registry_management", `/registry_manager/${device.uid}/custom_name`, {
                    headers: userHeaders,
                    body: {
                        "custom_name": metaDataAction.custom_device_name
                    }
                }).then((result) => {
                    console.log(result);
                    setMetaMessage({
                        show: true,
                        variant: "success",
                        headline: "",
                        body: "Updating the custom device name... DONE"
                    })
                }).catch((err) => {
                    console.log(err.message);
                    setMetaMessage({
                        show: true,
                        variant: "danger",
                        headline: "",
                        body: "Updating the custom device name... ERROR " + err.message
                    })
                });
                updateDeviceDetails(device.uid);
            }
        }
    }

    async function deviceTagHandler(tagAction)
    {
        if(userHeaders)
        {
            if(tagAction.action === "addTag")
            {
                return API.patch("registry_management", `/registry_manager/${device.uid}/tags`, {
                    headers: userHeaders,
                    body: {
                        "tags": tagAction.value
                    }}).then(() => {
                        setRefreshDeviceTagsData(true);
                        return true;
                    }).catch(error => {
                        setMetaMessage({
                            show: true,
                            variant: "danger",
                            headline: "",
                            body: "Adding tag \"" + tagAction.value + "\" failed... ERROR: " + error.message
                        });
                        //console.log(error);
                        setRefreshDeviceTagsData(true);
                        return false;
                    });
            }
            else if(tagAction.action === "deleteTag")
            {
                return API.del("registry_management", `/registry_manager/${device.uid}/tags`, {
                    headers: userHeaders,
                    body: {
                        "tags": tagAction.value
                    }}).then(() => {
                        setRefreshDeviceTagsData(true);
                        return true;
                    }).catch(error => {
                        setMetaMessage({
                            show: true,
                            variant: "danger",
                            headline: "",
                            body: "Deleting tag \"" + tagAction.value + "\" failed... ERROR: " + error.message
                        });
                        //console.log(error);
                        setRefreshDeviceTagsData(true);
                        return false;
                    });
            }
        }
    }

    async function remoteCommandManagerHandler(commandAction){
        if (userHeaders) {
            try {
                if (commandAction.action === "createCommandJob"){
                    // Message
                    setRemoteCommandMessage({
                        show: true,
                        variant: "info",
                        headline: "Creating Send Command Job",
                        body: "Command ID: " + commandAction.command_id
                    })
                    try {
                        await API.post("job_manager", "/job", {
                            headers: userHeaders,
                            body: {
                                "thing_name": device.uid,
                                "operation_type": "command",
                                "operation_id": commandAction.command_id + "",
                                "description": commandAction.description
                            }
                        });
                        await timeout(500);
                        setRemoteCommandMessage({
                            show: true,
                            variant: "success",
                            headline: "Job Created",
                            body: "Command ID: " + commandAction.command_id
                        })
                        setRefreshRemoteCommand(true);
                    } catch (e) {
                        setRemoteCommandMessage({
                            show: true,
                            variant: "danger",
                            headline: "Error:",
                            body: e.message
                        })
                    }
                }

                if ((commandAction.action === "registerCommand") ||  (commandAction.action === "registerCommandAndSend")){
                    setRemoteCommandMessage({
                        show: true,
                        variant: "info",
                        headline: "Command Register started",
                        body: "Command: " + commandAction.command
                    })
                    try {
                        const response_create_command = await API.post("remote_command", "/feature/command", {
                            headers: userHeaders,
                            body: {
                                "command": commandAction.command,
                                "parameter": commandAction.parameter,
                                "hidden_parameter": commandAction.hidden_parameter,
                                "cwd": commandAction.cwd,
                                "runinbackground": commandAction.runinbackground,
                                "addjobidasargument": commandAction.addjobidasargument,
                                "description": commandAction.description
                            }
                        });
                        setRefreshRemoteCommand(true);
                        try {
                            await timeout(2000);
                            if (commandAction.action === "registerCommandAndSend"){
                                setRemoteCommandMessage({
                                    show: true,
                                    variant: "success",
                                    headline: "Command Register finished",
                                    body: "Command: " + commandAction.command
                                })
                                await timeout(2000);
                                remoteCommandManagerHandler({
                                    action: "createCommandJob",
                                    command_id: response_create_command.commandid.toString(),
                                    description: commandAction.description
                                })
                            }else{
                                setRemoteCommandMessage({
                                    show: true,
                                    variant: "success",
                                    headline: "Command Register finished",
                                    body: "Command: " + commandAction.command
                                })
                                setRefreshRemoteCommand(true);
                            }
                        }catch (e) {
                            setRemoteCommandMessage({
                                show: true,
                                variant: "danger",
                                headline: "Error:",
                                body: e.message
                            })
                        }
                    } catch (e) {
                        setRemoteCommandMessage({
                            show: true,
                            variant: "danger",
                            headline: "Error:",
                            body: e.message
                        })
                    }
                }

                if (commandAction.action === "deleteCommand"){
                    setRemoteCommandMessage({
                        show: true,
                        variant: "info",
                        headline: "Trying to delete command: " + commandAction.name,
                        body: "Command ID: " + commandAction.commandid
                    })
                    try {
                        await API.del("remote_command", "/feature/command/"+commandAction.commandid, {
                        headers: userHeaders,
                    });
                    setRemoteCommandMessage({
                        show: true,
                        variant: "success",
                        headline: "Deleting Command finished",
                        body: "Command " + commandAction.command + " has been deleted successfully"
                    })
                    setRefreshRemoteCommand(true);
                    // ToDo: Check if Result is OK or Not, and make different message
                    } catch (e) {
                        setRemoteCommandMessage({
                            show: true,
                            variant: "danger",
                            headline: "Error:",
                            body: e.message
                        })
                    }
                }

                if (commandAction.action === "loadMoreJobs"){
                    console.log("Load More Remote Command Jobs");
                    try {
                        let response_job_manager_exclusive_start_key = await API.get("job_manager",
                        `/jobs?thing_name=${device.uid}&operation_type=command&limit=50&exclusive_start_key=${remoteCommandData.lastEvaluatedKey}`, {
                        headers: userHeaders,
                        });

                        // Sorting jobs by time
                        try {
                            response_job_manager_exclusive_start_key.Items.sort(function(first, second){
                                return  (first.timestamp < second.timestamp ? 1 : -1)
                            });
                        } catch (e) {
                            console.log("Error Sorting Jobs");
                            console.log(e);
                        }

                        let Jobs = [];
                        try {
                            response_job_manager_exclusive_start_key.Items.forEach((job) => {
                                Jobs.push(job);
                                remoteCommandData.commandsInfo.forEach((command) => {
                                    if (command.commandid === job.operation_id){
                                        command.jobs.push(job);
                                    }
                                });
                            })
                        } catch (e){
                            console.log("Error Job Filtering");
                            console.log(e);
                        }

                        let reduce_job_list = [];
                        var reduced_job_list = [];
                        try {
                            Jobs.forEach((job) => {
                                reduce_job_list.push({'jobid':job.jobid,  'actions': [job]})
                            });
                            reduce_job_list.forEach(function(item) {
                                var existing = reduced_job_list.filter(function(v) {
                                return v.jobid === item.jobid;
                                });
                                if (existing.length) {
                                var existingIndex = reduced_job_list.indexOf(existing[0]);
                                reduced_job_list[existingIndex].actions = reduced_job_list[existingIndex].actions.concat(item.actions);
                                } else {
                                if (typeof item.actions === 'string')
                                    item.actions = [item.actions];
                                reduced_job_list.push(item);
                                }
                            });
                            // console.log(reduced_job_list);
                        } catch(e) {
                            console.log(e.message);
                        }

                        var old_remoteCommandData = remoteCommandData;
                        old_remoteCommandData.jobsInfo = old_remoteCommandData.jobsInfo.concat(reduced_job_list);

                        // Sorting all the jobs
                        try {
                            old_remoteCommandData.jobsInfo.sort(function(first, second){
                                return  (first.jobid < second.jobid ? 1 : -1)
                            });
                        } catch (e){
                            console.log("Error Sorting Jobs");
                            console.log(e);
                        }

                        // Saving the last Evaluated Key in a global variable
                        var lastEvaluatedKey = "";
                        if (response_job_manager_exclusive_start_key.LastEvaluatedKey !== undefined) {
                            lastEvaluatedKey = JSON.stringify(response_job_manager_exclusive_start_key.LastEvaluatedKey);
                        }

                        let data = {}
                        data["commandsInfo"] = remoteCommandData.commandsInfo;
                        data["jobsInfo"] = old_remoteCommandData.jobsInfo;
                        data["lastEvaluatedKey"] = lastEvaluatedKey;
                        setRemoteCommandData(data);

                    } catch (e) {
                        console.log(e.message);
                    }
                }

            } catch (e) {
                alert(e.message);
            }
        }
    }

    function assignDevice(assignment) {
        return API.put("iot_registry-endpoint", `/iot_registry/assignDevice/${device.uid}`, {
            body: {
                device_reseller: assignment.device_reseller,
                device_owner: assignment.device_owner
            }
        });
    }


    async function whereverSimAction(simAction) {
        const headers = {};

        if (userSession) {
            headers.Authorization = userSession.getIdToken().getJwtToken();
        }
        try {

        if (simAction.action === "activate_SIM"){
            setWhereverSimMessage({
                show: true,
                variant: "info",
                headline: "Activate SIM",
                body: "This may take some seconds..."
            })

            console.log("Activate SIM")
            return await API.patch("whereversim", `/sim/${device.uid}?status=activate`,{
                headers: headers
            });
        }
        if (simAction.action === "deactivate_SIM"){
            setWhereverSimMessage({
                show: true,
                variant: "info",
                headline: "Deactivate SIM",
                body: "This may take some seconds..."
            })
            console.log("Deactivate SIM")
            return await API.patch("whereversim", `/sim/${device.uid}?status=suspend`,{
                headers: headers
            });
        }
        if (simAction.action === "enableEndpoint"){
            setWhereverSimMessage({
                show: true,
                variant: "info",
                headline: "Enable Endpoint",
                body: "This may take some seconds..."
            })
            console.log("enableEndpoint")
            return await API.patch("whereversim", `/endpoint/${device.uid}?status=activate`,{
                headers: headers
            });
        }
        if (simAction.action === "disableEndpoint"){
            setWhereverSimMessage({
                show: true,
                variant: "info",
                headline: "Disable Endpoint",
                body: "This may take some seconds..."
            })
            console.log("DisableEndpoint")
            return await API.patch("whereversim", `/endpoint/${device.uid}?status=deactivate`,{
                headers: headers
            });
        }
        if (simAction.action === "lockIMEI"){
            setWhereverSimMessage({
                show: true,
                variant: "info",
                headline: "Lock IMEI",
                body: "This may take some seconds..."
            })
            console.log("Lock IMEI")
            return await API.patch("whereversim", `/endpoint/${device.uid}?imei=lock`,{
                headers: headers
            });
        }
        if (simAction.action === "unlockIMEI"){
            setWhereverSimMessage({
                show: true,
                variant: "info",
                headline: "Unlock IMEI",
                body: "This may take some seconds..."
            })
            console.log("Unlock IMEI")
            return await API.patch("whereversim", `/endpoint/${device.uid}?imei=unlock`,{
                headers: headers
            });
        }
        if (simAction.action === "renameEndpoint"){
            setWhereverSimMessage({
                show: true,
                variant: "info",
                headline: "Rename Endpoint",
                body: "This may take some seconds..."
            })
            console.log("RenamingEndpoint")
            if (simAction.newname) {
                return await API.patch("whereversim", `/endpoint/${device.uid}?rename=`+encodeURIComponent(simAction.newname),{
                    headers: headers
                });
            }else {
                console.log("No Name given");
                return "Failed"
            }
        }
        if(simAction.action === "change_sim_profile"){

            simAction.ret_val = false;
            simAction.ret_error_msg = "";

            try
            {
                await API.patch("whereversimintegration", `/tariffProfile/${device.uid}?tariff_profile_id=${simAction.tariff_profile_id}`,{
                    headers: headers
                });
    
                await API.patch("whereversimintegration", `/serviceProfile/${device.uid}?service_profile_id=${simAction.service_profile_id}`,{
                    headers: headers
                });
            }
            catch(error)
            {
                simAction.ret_error_msg = error.msg;
            }

            simAction.ret_val = true;

            return "no action";
        }
        } catch (e) {
            alert(e.message);
        }
        return ""
    }

    async function deviceLoggerHandler(deviceLoggerActions){
        if (userHeaders) {
            try {
                if ((deviceLoggerActions.action === "reloadDeviceLogger")){
                    setRefreshDeviceLogger(true);
                }

            }catch (e) {
                alert(e.message);
            }
        }
    }

    function setColumnFilteredDeviceLogs(filteredDeviveLogs, updatedFilterConfiguration) {
        let Items = {
            'Items': filteredDeviveLogs
        };

        setDeviceLoggerData(Items);
        // setRefreshDeviceLogger(true);
        localStorage.setItem('filterConfigurationDeviceLogger', JSON.stringify(updatedFilterConfiguration));
    }

    return (
        <div className="DeviceDetailsContainer">
            <Container>
                <ReactPlaceholder showLoadingAnimation className='text-center' ready={!isLoadingMain}
                    customPlaceholder={
                    <div className='text-center'>
                        <TextRow color='#e4e4e7' style={{width: 'auto', height: 40}}/>
                        <br/>
                        <Row>
                            <Col xs="4"><TextRow color='#e4e4e7' style={{width: 'auto', height: 40}}/></Col>
                            <Col xs="4"></Col>
                            <Col xs="4"><TextRow color='#e4e4e7' style={{width: 'auto', height: 40}}/></Col>
                        </Row>
                        <br/>
                        <TextRow color='#e4e4e7' style={{width: 'auto', height: 40}}/>
                        <TextRow color='#f2f2f3' style={{width: 'auto', height: 700}}/>
                    </div>
                }>
                    <div>
                        <Breadcrumb>
                            {/* <Breadcrumb.Item><Link to="/"><FontAwesomeIcon icon="home"/> Home</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to="/fleet">Fleet</Link> </Breadcrumb.Item> */}
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon="home"/> Home</Breadcrumb.Item>
                            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/fleet" }}>Fleet </Breadcrumb.Item>
                            <Breadcrumb.Item active>{device?.device_name}</Breadcrumb.Item>
                        </Breadcrumb>

                        
                        <Row>
                            {device?.custom_name ?
                                <Col xs={6}><div className="dev-details-devicename">{device?.custom_name} </div><div className="dev-details-device-name-secondary">({device?.device_name})</div>
                                </Col>
                                :
                                <Col xs={6}><h3>{device?.device_name} - Details </h3></Col>
                            }
                            <Col xs={6}><div className="dev-details-annotations" >UID: {device?.uid}<br/><FontAwesomeIcon icon="hourglass-end" /> {loadTime} ms</div></Col>
                        </Row>

                        {iotCoreData?
                            <></> :
                            <div>
                                <Alert variant="danger">
                                    <Alert.Heading>No Shadow state data available</Alert.Heading>
                                    <p>
                                        It seems that this device never sent a Shadow state update.<br/>
                                    </p>
                                    <hr />
                                    <p>
                                        If you think this might be an error, please contact our <Link to="/infoAndSupport" target="_blank">support</Link>.
                                    </p>
                                </Alert>
                                <br/>
                            </div>
                        }
                        
                        
                        <Device device={device}
                            customers={customers}
                            isCustomerRoleAdmin={props.isCustomerRoleAdmin}
                            loggedInUser={props.loggedInUser}
                            //Assignment
                            isLoadingAssignment={isLoadingAssignment}
                            onDeviceAssignment={assignDeviceHandler}
                            // HOTP
                            isLoadingHOTPparameters={isLoadingHOTPparameters}
                            onHOTPGeneration={HOTPGenerationHandler}
                            hotpResponse={hotp}
                            //WhereverSIM
                            whereverSimData={whereverSimData}
                            isLoadingWhereverSimData={isLoadingWhereverSimData}
                            onWhereverSimActions={whereverSimHandler}
                            whereverSimMessage={whereverSimMessage}
                            whereverSimProfiles={whereverSimProfiles}
                            //FileUpload
                            fileUploadData={fileUploadData}
                            onFileUploadActions={fileUploadHandler}
                            isLoadingFileUpload={isLoadingFileUpload}
                            fileUploadMessage={fileUploadMessage}
                            // RemoteConnection
                            remoteConnectionData={remoteConnectionData}
                            onRemoteConnectionActions={remoteConnectionHandler}
                            isLoadingRemoteConnection={isLoadingRemoteConnection}
                            remoteConnectionMessage={remoteConnectionMessage}
                            devicesOverviewList={props.devicesOverviewList}
                            firewallData={firewallData}
                            //Hawkbit
                            hawkbitData={hawkbitData}
                            hawkbitDeploymentListData={hawkbitDeploymentListData}
                            isLoadingHawkbitData={isLoadingHawkbitData}
                            isLoadingHawkbitDeploymentListData={isLoadingHawkbitDeploymentListData}
                            onHawkbitActions={hawkbitServerHandler}
                            hawkbitMessage={hawkbitMessage}
                            // RemoteCommand
                            remoteCommandData={remoteCommandData}
                            isLoadingRemoteCommand={isLoadingRemoteCommand}
                            onRemoteCommandActions={remoteCommandManagerHandler}
                            remoteCommandMessage={remoteCommandMessage}
                            // Device Logger
                            deviceLoggerData={deviceLoggerData}
                            isLoadingDeviceLogger={isLoadingDeviceLogger}
                            onDeviceLoggerActions={deviceLoggerHandler}
                            filterConfigurationDeviceLogger={filterConfigurationDeviceLogger}
                            onColumnFilterChangedDeviceLogger={setColumnFilteredDeviceLogs}
                            // License Manager
                            licenseManagerData={licenseManagerData}
                            isLoadingLicenseManagerData={isLoadingLicenseManagerData}
                            onLicenseManagerActions={licenseManagerHandler}
                            licenseManagerMessage={licenseManagerMessage}
                            // User Meta Data Handler
                            onMetaDataChange={metaDataHandler}
                            metaDataMessage={metaMessage}
                            // Meta data Device tags
                            onDeviceTagAction={deviceTagHandler}
                            deviceTagsData={deviceTagsData}
                            // IoT Core
                            iotCoreData={iotCoreData}
                            // Whitelist, Delete, Remove IP
                            onWhitelistIP={WhitelistIPHandler}
                            onRenewIP={RenewIPHandler}
                            onDeleteIP={DeleteIPHandler}
                            >
                        </Device>
                    </div>
                </ReactPlaceholder>
            </Container>
        </div>
    );
}
