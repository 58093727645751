import React, { useState } from "react";
import TableFilter from 'react-table-filter';
import "../../../../node_modules/react-table-filter/lib/styles.css";
import { Table, ListGroup, Button, Alert, Badge } from "react-bootstrap";
import "./WhereverSIMTable.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPlus, faSimCard } from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";

export default function WhereverSIMTable(props) {
    // const [filteredSims, setSims] = useState(null);
    // const sims = filteredSims !== null ? filteredSims : props.simsToBeActivated;

    const [showInfo, setShowInfo] = useState(true);


    // function filterUpdated(newData) {
    //     setSims(newData);
    // }
    function filterUpdated(newData, filterConfiguration) {
        let filteredDevicesByColumn = newData;
        props.onColumnFilterChanged(filteredDevicesByColumn, filterConfiguration);
    }

    function MessageInfo() {
      if (props.message.show && showInfo) {
        return (
          <Alert variant={props.message.variant} onClose={() => setShowInfo(false)} dismissible>
            <Alert.Heading>{props.message.headline}</Alert.Heading>
            {props.message.body}
          </Alert>
        );
      }
      return <></>;
    }

    async function activateSIM(selectedsim){
      // console.log(selectedsim)
      setShowInfo(true)
      props.onWhereverSimActions({
          action: "activate_SIM",
          // endpoint_status_id = "0" ## 0 heißt enabled, 1 heißt disabled
          // sim_activate = "false" ## "false" oder "true"
          sim_id: selectedsim.id,
          iccid: selectedsim.iccid,
          customer: selectedsim.customer_org.name
      })
    }

    // async function deactivateSIM(sim){
    //     props.onWhereverSimActions({
    //         action: "deactivate_SIM",
    //         // endpoint_status_id = "0" ## 0 heißt enabled, 1 heißt disabled
    //         // sim_activate = "false" ## "false" oder "true"
    //         sim_id: sim.sim_id
    //     })
    // }

    async function createActiveEndpointAndSim(selectedsim){
      // console.log(selectedsim)
      setShowInfo(true)
      props.onWhereverSimActions({
            action: "create_Endpoint_ActiveSIM",
            sim_id: selectedsim.id,
            iccid: selectedsim.iccid,
            customer: selectedsim.customer_org.name
        })
    }

    async function createActiveEndpointAndDeactivatedSim(selectedsim){
      setShowInfo(true)
      // console.log(selectedsim)
      props.onWhereverSimActions({
            action: "create_Endpoint_InactiveSIM",
            sim_id: selectedsim.id,
            iccid: selectedsim.iccid,
            customer: selectedsim.customer_org.name
        })
    }


    function renderUsersTable() {
        return (
            <Table striped bordered hover>
                <thead>
                    <TableFilter
                        rows={props.simsToBeActivated}
                        initialFilters={props.filterConfiguration}
                        onFilterUpdate={filterUpdated.bind(this)}>
                        <th key="iccid" filterkey="iccid" >ICCID</th>
                        <th key="id" filterkey="id">SIM ID</th>
                        <th key="imsi" filterkey="imsi">IMSI</th>
                        <th key="customer" filterkey="customer_org.name">Customer</th>
                        <th key="status" filterkey="status.description">Status</th>
                        <th key="Formfactor" filterkey="model.description">Formfactor</th>
                        <th key="Produced" filterkey="production_date">Produced</th>
                        <th key="Endpoint" filterkey="endpoint">Endpoint</th>
                        <th key="Actions" filterkey="actions">Actions</th>
                    </TableFilter>
                </thead>
                <tbody>
                    {renderTableRows()}
                </tbody>
            </Table>
        );
    }

    function renderTableRows() {
        return [{}].concat(props.simsToBeActivated).map((sim, i) =>
            i !== 0 ? (
                <tr key={i}>
                    <td>{sim.iccid}</td>
                    <td>{sim.id}</td>
                    <td>{sim.imsi}</td>
                    <td>{sim.customer_org.name}</td>
                    <td><Badge variant={sim?.status?.id === 0 ? "secondary" : "warning" }>{sim.status.description}</Badge></td>
                    <td>{sim.model.description} <br/>{sim.model.formfactor.name} </td>
                    <td><Moment parse="YYYY-MM-DDTHH:mm:ss" format="YYYY-MM-DD" utc local>{sim.production_date}</Moment><br/>
                    <Badge variant="info"><Moment fromNow parse="YYYY-MM-DDTHH:mm:ss">{sim.production_date}</Moment></Badge>
                    </td>
                    <td>{sim.endpoint?.name || "No Endpoint"}</td>
                    <td>
                      {sim.endpoint ?
                        <div><Button variant="success" className="supportButtonHome" size="sm" onClick={() => activateSIM(sim)}>
                          <FontAwesomeIcon icon={faPlay}/> Activate SIM
                      </Button>
                      </div>
                      :
                      <div><Button variant="success" className="supportButtonHome" size="sm" onClick={() => createActiveEndpointAndSim(sim)}>
                          <FontAwesomeIcon icon={faSimCard}/> Create Endpoint + Activate SIM
                      </Button><br />
                      <Button variant="secondary" className="supportButtonHome" size="sm" onClick={() => createActiveEndpointAndDeactivatedSim(sim)}>
                          <FontAwesomeIcon icon={faPlus}/> Create Endpoint only
                      </Button>
                      </div>

                      }
                    </td>
                </tr>
            ) : (<tr key={i}></tr>)
        );
    }

    return (
        <div>
        <MessageInfo />
            <ListGroup>
                <div className="responsiveTable">
                    {renderUsersTable()}
                </div>
            </ListGroup>
        </div>
    )
}
