import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { Form, Container, InputGroup, Col } from "react-bootstrap";
// import { Multiselect } from 'multiselect-react-dropdown';
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
// import RolloutServerManagement from "../components/Administration/CustomerAdministration/CustomerCreation/RolloutServerManagement";
import "./CreateCustomer.css";
import Spinner from "../components/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie, faHome, faLink } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";

export default function CreateCustomer(props) {
    const [userHeaders, setUserHeaders] = useState(undefined);
    const [fields, handleFieldChange] = useFormFields({
        customerName: "",
        hawkbit_server_url: "https://hawkbit.prod.esec.aws.esec-experts.com",
        hawkbit_config_file: "https://configuration-rollout.s3.eu-central-1.amazonaws.com/templates/hawkbit_default.cfg",
    });
    const [isLoading, setIsLoading] = useState(false);
    // const [groups, setGroups] = useState([]);

    // const customerGroupMultiSelectStyle = {
    //     searchBox: { // To change search box element look
    //     minHeight: "46px"
    //     },
    //     chips: { // To change css chips(Selected options)
    //     background:  "#114c92"
    //     }
    // }

    useEffect(() => {
        async function onLoad() {
            if (!props.isAuthenticated || !props.loggedInUser) {
                return;
            }
            setIsLoading(false);
            // Getting JSON Web Token
            const session = await Auth.currentSession();
            const headers = {};
            if (session) {
                headers.Authorization = session.getIdToken().getJwtToken();
            }
            setUserHeaders(headers);
        }
        onLoad();
    }, [props.isAuthenticated, props.loggedInUser, props.location.param]);

    function validateForm() {
        return (
            fields.customerName.length > 0
        );
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await createCustomer();
            setIsLoading(false);
            props.history.push("/administration");
        } catch (e) {
            alert(e.message);
            setIsLoading(false);
        }
    }

    async function createCustomer() {
        try{
            return API.post("iot_fota_api_python", `/${fields.customerName}`, {
                headers: userHeaders,
                body: {
                    remote_server: "remote2.aws.esec-experts.com",
                    tunnel_remote_range: 5,
                    reserved_ports_multiplicator: 2,
                    remote_connection_config_file: "https://feature-manager-configuration-rollout-prod.s3.eu-central-1.amazonaws.com/templates/remote_connection_default.cfg",
                    initial_hawkbit_server: fields.rollout_server_url1,
                    initial_hawkbit_config: "https://configuration-rollout.s3.eu-central-1.amazonaws.com/templates/hawkbit_default.cfg"
                }}
            );
        } catch (e) {
            console.log(e.message);
        }

    }

    // function onSelectGroup(selectedList) {
    //     setGroups(selectedList);
    // }

    // function onRemoveGroup(selectedList) {
    //     setGroups(selectedList);
    // }

    // function onHandleFieldChangeHawkbitServer(field, event) {
    //     fields[`${field}`] = event.target.value;
    // }

    // function onHandleFieldChangeHawkbitConfigFile(field, event) {
    //     fields[`${field}`] = event.target.value;
    // }

    function renderForm() {
        return (
            <div className="description">
                <Container>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon={faHome}/> Home</Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/administration" }}>Administration</Breadcrumb.Item>
                    <Breadcrumb.Item active>Create Customer</Breadcrumb.Item>
                </Breadcrumb>
                    <h1>Create Customer</h1>
                    <form onSubmit={handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="customerName">
                                <Form.Label>Customer Name (advise: lowercase in one word)</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faUserTie} />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        autoFocus
                                        type="text"
                                        value={fields.customerName}
                                        onChange={handleFieldChange}
                                        placeholder="Enter the name"
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                        <h4 className="subHeadline">Hawkbit Server</h4>
                        <p>Default Production Server: https://hawkbit.prod.esec.aws.esec-experts.com</p>
                        <Form.Group controlId="hawkbit_server_url">
                            <Form.Label>URL</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faLink} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    value={fields.hawkbit_server_url}
                                    onChange={handleFieldChange}
                                    placeholder="Enter URL"
                                />
                            </InputGroup>
                        </Form.Group>
                        <h4 className="subHeadline">Hawkbit Configuration File</h4>
                        <p>Default Config file: https://configuration-rollout.s3.eu-central-1.amazonaws.com/templates/hawkbit_default.cfg</p>
                        <Form.Group controlId="hawkbit_config_file">
                            <Form.Label>URL</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faLink} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    value={fields.hawkbit_config_file}
                                    onChange={handleFieldChange}
                                    placeholder="Enter URL"
                                />
                            </InputGroup>
                        </Form.Group>
                        <LoaderButton
                            block
                            type="submit"
                            isLoading={isLoading}
                            disabled={!validateForm()}
                        >
                            Create Customer
                        </LoaderButton>
                    </form>
                </Container>
            </div>
        );
    }

    return (
        <div className="CreateCustomer">
            {isLoading ? <Spinner></Spinner> : renderForm()}
        </div>
    );
}

