import React from "react";
import TableFilter from 'react-table-filter';
import "../../../../node_modules/react-table-filter/lib/styles.css";
import 'moment-timezone';
import Badge from "react-bootstrap/Badge";
import Moment from 'react-moment';
import { Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./DevicesTable.css";

export default function DevicesSoftwareTable(props) {
    function filterUpdated(newData, filterConfiguration) {
        let filteredDevicesByColumn = newData;
        props.onColumnFilterChanged(filteredDevicesByColumn, filterConfiguration);
    }

    function kernel_string_to_multiline(kernelstring) {
        // console.log(description);
        var erg = kernelstring.toString();
        const mark = erg.indexOf("(")
        erg = erg.substring(0,mark)
        // console.log(erg);
        return erg
    }


    function renderDevicesSoftwareTableDetails() {
        return (
            <div>
                <Table striped bordered hover>
                    <thead>
                        <TableFilter
                            rows={props.devices}
                            initialFilters={props.filterConfiguration}
                            onFilterUpdate={filterUpdated.bind(this)}>
                            <th key="device_name" filterkey="device_name">Device Name</th>
                            <th key="iot_client" filterkey="iot_client">IoT Client</th>
                            <th key="krn" filterkey="device_health.krn">Kernel</th>
                            <th key="rauc_slots" filterkey="slot">RAUC Active Slot</th>
                            <th key="active_bundle" filterkey="version">Active Bundle</th>
                            <th key="active_installed" filterkey="rootfs">Active Installed</th>
                            <th key="active_status" filterkey="active_status">Active Status</th>
                            <th key="inactive_bundle" filterkey="inactive_bundle">Inactive Bundle</th>
                            <th key="inactive_installed" filterkey="inactive_installed">Inactive Installed</th>
                            <th key="inactive_status" filterkey="inactive_status">Inactive Status</th>
                            <th key="last_seen" filterkey="last_seen">LastSeen</th>
                        </TableFilter>
                    </thead>
                    <tbody>
                        {renderTableRowsDetails()}
                    </tbody>
                </Table>
            </div>
        );
    }

    function openDeviceDetail(uid, event) {
        if (event.ctrlKey || event.metaKey) {
            window.open(`/device/${uid}`);
        }
    }


    function renderTableRowsDetails() {
        return [{}].concat(props.devices).map((device, i) =>
            i !== 0 ? (
                <LinkContainer onClick={openDeviceDetail.bind(this, device?.uid)} key={device?.uid} to={`/device/${device?.uid}`}>
                    <tr key={device?.uid} to={`/iot-device-health/${device?.uid}`}>
                    <td>{device?.device_name?.trim().split("\n")[0]}</td>
                    <td>{device?.iot_client}</td>
                    <td>{device?.device_health?.krn ? kernel_string_to_multiline(device?.device_health?.krn): ""}</td>
                    <td>{device?.device_rauc_status?.active?.slot ? device?.device_rauc_status?.active?.slot : ""}</td>
                    <td>{device?.device_rauc_status?.active?.bundle?.version ? device?.device_rauc_status?.active?.bundle?.version : ""}</td>
                    <td>{device?.device_rauc_status?.active?.rootfs["installed.timestamp"] ? device?.device_rauc_status?.active?.rootfs["installed.timestamp"] : ""}</td>
                    <td>{device?.device_rauc_status?.active?.rootfs?.status ? device?.device_rauc_status?.active?.rootfs?.status : ""}</td>
                    <td>{device?.device_rauc_status?.inactive?.bundle?.version ? device?.device_rauc_status?.inactive?.bundle?.version : ""}</td>
                    <td>{device?.device_rauc_status?.inactive?.rootfs["installed.timestamp"] ? device?.device_rauc_status?.inactive?.rootfs["installed.timestamp"] : ""}</td>
                    <td>{device?.device_rauc_status?.inactive?.rootfs?.status ? device?.device_rauc_status?.inactive?.rootfs?.status : ""}</td>
                    <td>{device?.last_seen ? <div>
                        <Moment tz="Europe/Berlin" utc local format="YYYY-MM-DD HH:mm:ss" parse="YYYY-MM-DD HH:mm:ss"> {device?.last_seen?.trim().split("\n")[0]}</Moment>
                        <br/>
                        <Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DD HH:mm:ss">{device?.last_seen?.trim().split("\n")[0]}</Moment></Badge>
                        </div>
                        :
                        ''}
                    </td>
                    </tr>
                </LinkContainer>
            ) : (<tr key={i}></tr>)
        );
    }


    return (
            <div  className="responsiveTable">
            {renderDevicesSoftwareTableDetails()}
            </div>
    )
}
