import React, { useState } from "react";
import TableFilter from 'react-table-filter';
import "../../../../../node_modules/react-table-filter/lib/styles.css";
import { Table, ListGroup, Button, Alert } from "react-bootstrap";
import "./UsersTable.css";
import config from "../../../../config";
import { CognitoIdentityServiceProvider } from 'aws-sdk/dist/aws-sdk-react-native';

export default function UnconfirmedUsersTable(props) {
    const [filteredUsers, setUsers] = useState(null);
    const [showInfo, setShowInfo] = useState(false);
    const [messageInfoVariant, setMessageInfoVariant] = useState("success");
    const [messageInfoResponseBody, setMessageInfoResponseBody] = useState("");
    const users = filteredUsers !== null ? filteredUsers : props.users;

    var cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
        region: config.cognito.REGION,
        credentials: {
            accessKeyId: props.essential_credentials?.accessKeyId,
            secretAccessKey: props.essential_credentials?.secretAccessKey,
            sessionToken: props.essential_credentials?.sessionToken
            }
    });

    function filterUpdated(newData) {
        setUsers(newData);
    }

    function updateUsersData() {
        props.onUpdateUsersDataActions({
            action: "updateUsersData",
        })
    }

    async function handleDisableUser(email) {
        var params = {
            UserPoolId: config.cognito.USER_POOL_ID,
            Username: email
        };
        cognitoidentityserviceprovider.adminDisableUser(params).promise().then(() => {
            setMessageInfoVariant("success");
            setMessageInfoResponseBody(email + " has been disabled.");
        }).catch(err => {
            setMessageInfoVariant("danger");
            setMessageInfoResponseBody("Error: " + err.message);
        });
        setShowInfo(true);
        updateUsersData();
    }

    async function handleResendVerificationMail(email, user_status) {
        var params_resendConfirCode = {
            Username: email,
            ClientId: config.cognito.APP_CLIENT_ID
        };

        var params_forceChangePass = {
            Username: email,
            UserPoolId: config.cognito.USER_POOL_ID,
            MessageAction: "RESEND"
        };

        if (user_status === "UNCONFIRMED") {
            cognitoidentityserviceprovider.resendConfirmationCode(params_resendConfirCode).promise().then(() => {
                setMessageInfoVariant("success");
                setMessageInfoResponseBody("Confirmation code was sent successfully to " + email);
            }).catch(err => {
                setMessageInfoVariant("danger");
                setMessageInfoResponseBody("Error: " + err.message);
            });
        } else if (user_status === "FORCE_CHANGE_PASSWORD") {
            console.log("User will be forced to change his password")
            cognitoidentityserviceprovider.adminCreateUser(params_forceChangePass).promise().then(() => {
                setMessageInfoVariant("success");
                setMessageInfoResponseBody("An email with a new initial password was sent to " + email);
            }).catch(err => {
                setMessageInfoVariant("danger");
                setMessageInfoResponseBody("Error: " + err.message);
            });
        }
        setShowInfo(true);
        updateUsersData();
    }

    function MessageInfo() {
        if (showInfo) {
          return (
            <Alert variant={messageInfoVariant} onClose={() => setShowInfo(false)} dismissible>
              <Alert.Heading>{messageInfoResponseBody}</Alert.Heading>
            </Alert>
          );
        }
        return <></>;
    }

    function renderUsersTable(Users) {
        return (
            <div>
                <MessageInfo />
                <Table striped bordered>
                    <thead>
                        <TableFilter
                            rows={props.users}
                            onFilterUpdate={filterUpdated.bind(this)}>
                            <th key="email" filterkey="email" >Email</th>
                            <th key="user_status" filterkey="user_status" >User Status</th>
                            <th key="customer_name" filterkey="customer_name">Customer Affiliation</th>
                            <th key="group" filterkey="group">User Permissions</th>
                            <th key="action" filterkey="action">Action</th>
                        </TableFilter>
                    </thead>
                    <tbody>
                        {renderTableRows(Users)}
                    </tbody>
                </Table>
            </div>
        );
    }

    function renderTableRows(Users) {
        return [{}].concat(Users).map((user, i) =>
            // i !== 0 ? (
            props.users_attributes[user['user_id']]?.UserStatus !== "CONFIRMED" && props.users_attributes[user['user_id']]?.Enabled === true?
                <tr key={i}>
                    <td key="email" className="d-flex">
                        <div className="align-self-start mr-auto">
                            {user.email.trim().split("\n")[0]}
                        </div>
                        <div className="align-self-end ml-auto">
                            { props.users_attributes[user['user_id']]?.UserStatus === "FORCE_CHANGE_PASSWORD" ?
                                <Button className="EditButton" variant="primary" onClick={() => handleResendVerificationMail(user.email, props.users_attributes[user['user_id']]?.UserStatus)} size="sm">
                                    Resend Initial Password
                                </Button>
                            :
                                <Button className="EditButton" variant="info" onClick={() => handleResendVerificationMail(user.email, props.users_attributes[user['user_id']]?.UserStatus)} size="sm">
                                    Resend Verification Mail
                                </Button>
                            }
                        </div>
                    </td>
                    <td key="user_status"><p>{props.users_attributes[user['user_id']]?.UserStatus?.trim().split("\n")[0]}</p></td>
                    <td key="customer_name"><p>{user?.customer_name?.trim().split("\n")[0]}</p></td>
                    <td key="group"><p>{user?.group?.trim().split("\n")[0]}</p></td>
                    <td key="action">
                        <div className="d-flex">
                            {/* <div className="align-self-start mr-auto">
                                {props.users_attributes['length'] !== 0 ? props.users_attributes[user['user_id']].Enabled === true ?
                                    <p style={{color: "green"}}>Enabled</p> : <p style={{color: "red"}}>Disabled</p> : "unauthorized"
                                }
                            </div> */}
                            <div className="align-self-end ml-auto">
                                <Button className="EditButton" variant="warning" onClick={() => handleDisableUser(user.email)} size="sm">
                                    Disable User
                                </Button>
                            </div>
                        </div>
                    </td>
                </tr>

            :
                <tr key={i}></tr>
            // ) : (<></>)
        );
    }

    return (
        <div>
            <ListGroup>
                <div className="responsiveTableUserTable">
                    {renderUsersTable(users)}
                </div>
            </ListGroup>
        </div>
    )
}
