import React, { useState } from "react";
import { Form, Container } from "react-bootstrap";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "./ChangePassword.css";

export default function ForgotPassword(props) {
    const [fields, handleFieldChange] = useFormFields({
        email: ""
    });
    const [isLoading, setIsLoading] = useState(false);

    function validateForm() {
        return (
            fields.email.length > 0
        );
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.forgotPassword(fields.email);
                setIsLoading(false);
                props.history.push("/resetPassword");
        } catch (e) {
            alert(e);
        }
    }

    function renderForm() {
        return (
            <div className="description">
            <Container>
                <h1>Forgot Password</h1>
                <p className="text-center">Please enter your email to get the verification code. You will need the verification code in the next step!</p>
                <form onSubmit={handleSubmit}>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            autoFocus
                            type="email"
                            value={fields.email}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <LoaderButton
                        block
                        type="submit"
                        isLoading={isLoading}
                        disabled={!validateForm()}>
                        Get Verification Code
                    </LoaderButton>
                </form>
            </Container>
            </div>
        );
    }

    return (
        <div className="ChangePassword">
            {renderForm()}
        </div>
    );
    }