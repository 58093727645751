import React from "react";
import {
    Table, Row, Col, Alert, Container
} from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import Moment from 'react-moment';
import 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "../Device.css";
import { Link } from "react-router-dom";

export default function DeviceHealth(props) {

    function showHealthDataInfo(){
        return (
            <div><Alert variant="warning">
            <Alert.Heading>No health data available</Alert.Heading>
            <p>
                The data sets have likely been recycled after the cleaning-period (default = 90 days).<br/>
            </p>
            <hr />
            <p className="mb-0">
                If you think this might be an error, please contact our <Link to="/infoAndSupport" target="_blank">support</Link>.
            </p>
            </Alert>
            </div>
        )
    }

    function showFilesystemData(){

        const shadowStateData = props?.iotCoreData?.state?.reported;
        
        // Prefix to match
        const prefix = "fs_";
        
        // Keys to use for the output object
        const keys = ["device", "size", "used", "available", "used_percentage", "mount"];
        
        // Function to parse the string value and return an object
        const parseStringValue = (str) => {
            const values = str.trim().split(/\s+/);
            if (values.length !== 6) {
            return null; // skip this key
            }
            const obj = {};
            keys.forEach((key, index) => {
            obj[key] = values[index];
            });
            return obj;
        };
        
        // Output array
        const outputArray = [];
        
        if(shadowStateData)
        {
            // Loop through each key in the input object
            for (const key in shadowStateData) {
                if (key.startsWith(prefix)) {
                const value = shadowStateData[key];
                const parsedValue = parseStringValue(value);
                if (parsedValue !== null) {
                    outputArray.push({ key, ...parsedValue });
                }
                }
            }
        }

        outputArray.sort((a, b) => a.mount.localeCompare(b.mount));

        // console.log(outputArray);

        return outputArray.map((fs_entry) => {

                            if(fs_entry.available === "" || fs_entry.available == null)
                            {
                                return <></>;
                            }
                            
                            return  <tr key={fs_entry.key} >
                                        <td key="key">{fs_entry.key}</td>
                                        <td key="dev">{fs_entry.device}</td>
                                        <td key="mnt">{fs_entry.mount}</td>
                                        <td key="size">{fs_entry.size}</td>
                                        <td key="used">{fs_entry.used}</td>
                                        <td key="used_perc">{fs_entry.used_percentage}</td>
                                    </tr>
        });
    }

    
    return (
            <div style={{marginBottom: "100px"}}>
                <Container>
                <Row className="dev-attributes-row">
                <Col xs={6}><h4 style={{"marginTop":"5px"}}><FontAwesomeIcon icon="heartbeat" /> System Health</h4></Col>
                <Col xs={3}><div className="dev-attributes-heading"><FontAwesomeIcon icon="clock" /> Last Seen:</div>{props.device?.last_seen ? <Moment tz="Europe/Berlin" utc local format="YYYY-MM-DD HH:mm:ss" parse="YYYY-MM-DD HH:mm:ss">{props.device?.last_seen?.trim().split("\n")[0]}</Moment> : ''} <Badge variant="info"><Moment tz="Europe/Berlin" utc local fromNow parse="YYYY-MM-DD HH:mm:ss">{props.device?.last_seen?.trim().split("\n")[0]}</Moment></Badge></Col>
                <Col xs={3}><div className="dev-attributes-heading">Shadow <br/></div>{props.device?.shadow_version}</Col>
                </Row>

                <div>{props.device?.device_health?.device_health_status? <></> : showHealthDataInfo()}</div>

                <Row className="dev-attributes-row">
                    <Col xs={3}><div className="dev-attributes-heading">Health Summary:<br/></div> <Badge variant={props.device?.device_health?.device_health_status === "Good" ? 'success' : 'warning'}>{props.device?.device_health?.device_health_status ? props.device?.device_health?.device_health_status : <div>unknown</div>}</Badge></Col>
                    <Col xs={3}><div className="dev-attributes-heading">Uptime:<br/></div>{props.device?.device_health?.up?.uptime_conv}</Col>
                    <Col xs={3}><div className="dev-attributes-heading">Idletime:<br/></div>{props.device?.device_health?.up?.idletime_conv}</Col>
                    <Col xs={3}><div className="dev-attributes-heading">Latest PID:<br/></div>{props.device?.device_health?.cpu?.newest_pid}</Col>
                </Row>
                <Row className="dev-attributes-row">
                    <Col xs={3}><div className="dev-attributes-heading">CPU (avg 1 minutes):<br/></div>{props.device?.device_health?.cpu?.avg_1min}</Col>
                    <Col xs={3}><div className="dev-attributes-heading">CPU (avg 5 minutes):<br/></div>{props.device?.device_health?.cpu?.avg_5min}</Col>
                    <Col xs={3}><div className="dev-attributes-heading">CPU (avg 15 minutes):<br/></div>{props.device?.device_health?.cpu?.avg_15min}</Col>
                    <Col xs={3}><div className="dev-attributes-heading">Threads:<br/></div>{props.device?.device_health?.cpu?.threads}</Col>
                </Row>
                <Row className="dev-attributes-row">
                    <Col xs={3}><div className="dev-attributes-heading">RAM available:<br/></div>{props.device?.device_health?.memory?.mem_a} {props.device?.device_health?.memory?.unit}</Col>
                    <Col xs={3}><div className="dev-attributes-heading">RAM total:<br/></div>{props.device?.device_health?.memory?.mem_t} {props.device?.device_health?.memory?.unit}</Col>
                </Row>
                <Row className="dev-attributes-row">
                    <Col xs={12}><div className="dev-attributes-heading">Kernel<br/></div>{props.device?.device_health?.krn}</Col>
                </Row>
                <br/>

                <h4><FontAwesomeIcon icon="save" /> Filesystem Status</h4>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>System</th>
                                <th>DEV</th>
                                <th>MNT</th>
                                <th>SIZE</th>
                                <th>used</th>
                                <th>used %</th>
                            </tr>
                        </thead>
                        
                        <tbody>{ showFilesystemData() }</tbody>
                    </Table>
                </Container>
            </div>

    )
}